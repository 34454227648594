
import shipperPallet from "@/components/report/shipperPallet3dSVG.vue"

  export default {
    'name': '3dPallet',
    components: {
      'shipper': shipperPallet
    },
    

   
    setup(props){
      const  a = 1

      const palletBaseHeight = 20
      const palletHeight = 140
      const palletWidth = 80
      const palletDepth = 120

      const spaceLeft = 10
      const spaceBottom = 40

      const height = 400      
      const width = 400

      const palletBottomLeftX = 0 + spaceLeft
      const palletBottomLeftY = height - spaceBottom
    
      const palletScaling = Math.min(
          height/(palletDepth),
          width/(palletWidth),
          ) * 0.5

      // const palletScaling=1
      
      const xShiftDepth = Math.sqrt(Math.pow(palletDepth * palletScaling, 2) / 2)
      const yShiftDepth = Math.sqrt(Math.pow(palletDepth * palletScaling, 2) / 2)
         
       const shippers = [
        
          { "xPosition": 0,
            "zPosition": 77.79999999999998,
            "width": 77.8,
            "depth": 19.2,
            "height": 25.1,
            "type": "a"},
           
          {
              "xPosition": 1.0000000000000036,
              "zPosition": -1.4210854715202004e-14,
              "width": 19.2,
              "depth": 77.8,
              "height": 25.1,
              "type": "b"
          },
           {
                "xPosition": 20.2,
                "zPosition": -1.4210854715202004e-14,
                "width": 19.2,
                "depth": 77.8,
                "height": 25.1,
                "type": "b"
            },
             
            {
            "xPosition": 39.39999999999999,
                "zPosition": -1.4210854715202004e-14,
                "width": 19.2,
                "depth": 77.8,
                "height": 25.1,
                "type": "b"
            },
            {
              "xPosition": 58.599999999999994,
              "zPosition": -1.4210854715202004e-14,
              "width": 19.2,
              "depth": 77.8,
              "height": 25.1,
              "type": "b"
            }
      ]

       const shippersExample = [
        {'xPosition': 0, 'zPosition': 90, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 0, 'zPosition': 60, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 0, 'zPosition': 30, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 0, 'zPosition': 0, 'height': 10, 'width': 25, 'depth': 30},

        {'xPosition': 25, 'zPosition': 90, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 25, 'zPosition': 60, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 25, 'zPosition': 30, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 25, 'zPosition': 0, 'height': 10, 'width': 25, 'depth': 30},
      
        {'xPosition': 50, 'zPosition': 90, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 50, 'zPosition': 60, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 50, 'zPosition': 30, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 50, 'zPosition': 0, 'height': 10, 'width': 25, 'depth': 30},

        {'xPosition': 75, 'zPosition': 90, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 75, 'zPosition': 60, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 75, 'zPosition': 30, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 75, 'zPosition': 0, 'height': 10, 'width': 25, 'depth': 30},


        {'xPosition': 100, 'zPosition': 90, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 100, 'zPosition': 60, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 100, 'zPosition': 30, 'height': 10, 'width': 25, 'depth': 30},
        {'xPosition': 100, 'zPosition': 0, 'height': 10, 'width': 25, 'depth': 30},         

      ]


      return {
        palletBottomLeftX,
        palletBottomLeftY,
        palletScaling,
        palletWidth,
        palletDepth,
        palletHeight,
        xShiftDepth,
        yShiftDepth,
        a,
        height,
        palletBaseHeight,
        width,
        shippers,        
        spaceLeft,
        spaceBottom
      }
    }
  }
