
import {useStore} from 'vuex'
import {computed, ref} from 'vue'
import { useRouter} from 'vue-router'

import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';


export default function() {
  

    const env = computed(() => {return process.env.VUE_APP_ENV})
    const store = useStore()
    const router = useRouter()

    
    const errorFetch = ref(false)

    //* -- Get data from optimization functions -------------------
    const optimize = async() => {

      // Show loading
      Loading.show({
        delay: 400, // ms
        spinner: QSpinnerGears,
        message: 'Calculating'
    })
      
      
      window.console.log('Getting data for single product')

      // Reset selected options
      store.commit('decisions/updateSelectedOptionsResult', [])
      store.commit('decisions/removeSelectedOptionsSensitivity', [])
      
      const url = process.env.VUE_APP_FUNCTION_URI + "optimize"
      window.console.log('url: ' + url)

      
      // reset current results
      store.commit('general/updateResult', [])


      // reset option to draw
      store.commit('general/updateOptionToVisualize', null)      

      //* Distinguish between local request and prod requests
      let authConfig = {}

     
      if (env.value == 'dev'){
        authConfig = {
            iss: 'local-dev',
            idToken: 'local-dev',
        }

      } else {
        authConfig = {
          iss: store.state.user.userAuthInfo["idTokenClaims"]["iss"],
          idToken: store.state.user.userAuthInfo["idToken"],
        }

      }
      
      //* Define request parameters
      const requestOptions = {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: authConfig,
          product: store.state.general.selected_product,
          shippingBox: store.state.general.shippingBox,
          pallet: store.state.general.selected_pallet,      
          secondPallet: store.state.general.secondPallet,    
          parameters: store.state.userSettings.calculationParameters
          })
      };
      
      const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "optimize" , requestOptions).catch((error) => {
              window.console.log('Error handling')        
              window.console.log(error)              
                        
          }
          
          
        );
      
      const response_from_api = await response as Response;
      let data;

      if (response_from_api.status == 200) {
        window.console.log(response_from_api)
        data = await response_from_api.json();        
      }else{
        errorFetch.value = true;
        
      }
      

      window.console.log("Response:" + data)

      // Log keys
      window.console.log(Object.keys(data));

      // Convert to array
      window.console.log(Object.entries(data))
      
      const result = Object.values(data['result']);
      // Add to store
      store.commit('general/updateResult', result)

      // Add logs
      store.commit('general/updateCalculationRunLogs', data['logs'])
      Loading.hide()
      

      // Go to results page
      router.push('result')



      // Fetch results for sensitivity analysis
      //await getSensitivity();
      
      
      } //! End of optimize
      
  //* -- Check parameters for sensitivity analysis -------------------------------------------
      const checkSensitivityParameters = () => {
        
        const productVolume = computed(() => {
          return  store.state.general.selected_product.parameters.height.values.Target.value *        
          store.state.general.selected_product.parameters.width.values.Target.value  *
          store.state.general.selected_product.parameters.depth.values.Target.value
        })
        
        // Get values for sensitivity Tolerance
        const checkToleranceSensitivity = computed(() => {
          return  store.state.sensitivity.tolerance.ml / productVolume.value > 0.02
        })
        
        if (checkToleranceSensitivity.value){
          store.commit("dialogs/updateCheckSensitivityParameters", true)
        }else{
          getSensitivity()
        }
      }
      
      
  //* -- Get results from sensitivity analysis -------------------------------------------
    const getSensitivity = async () => {

      window.console.log('Running sensitivity analysis')
      store.commit('sensitivity/updateSensitivityRun', "started")
      
      // reset current results
      store.commit('general/updateResultSensitivity', [])
      store.commit('general/updateParametersSensitivity', {})
     

      //* Distinguish between local request and prod requests
      let authConfig = {}

     
      if (env.value == 'dev'){
        authConfig = {
            iss: 'local-dev',
            idToken: 'local-dev',
        }

      }else{
        authConfig = {
          iss: store.state.user.userAuthInfo["idTokenClaims"]["iss"],
          idToken: store.state.user.userAuthInfo["idToken"],
        }

      }
      
      //* Define request parameters            
      const requestOptions = {
        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: authConfig,
          product: store.state.general.selected_product,
          shippingBox: store.state.general.shippingBox,
          pallet: store.state.general.selected_pallet,
          secondPallet: store.state.general.secondPallet,    
          toleranceML: store.state.sensitivity.tolerance.ml,
          type: 'sensitivity',
          parameters: store.state.userSettings.calculationParameters
          })
      };
      

      window.console.log('fetching sensitivity from ' + process.env.VUE_APP_FUNCTION_URI + "sensitivity")
      const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "sensitivity" , requestOptions).catch((error) => {
              window.console.log('Error handling')        
              window.console.log(error)
              store.commit('sensitivity/updateSensitivityRun', "executed")              
              
        }
          
          
        );
      
      const response_from_api = await response as Response;
      let data;

      if (response_from_api.status == 200) {
        window.console.log(response_from_api)
        data = await response_from_api.json();
        
      }else{
        errorFetch.value = true;        
      }      

      //window.console.log("Response:" + data)

      // Log keys
      //window.console.log(Object.keys(data));

      // Convert to array
      //window.console.log(Object.entries(data))
      
      //window.console.log(Object.values(data['message']))

      if(data['message'] == 1){
        const result = Object.values(data['data']);
        //window.console.log(result)
        // Add to store
        store.commit('general/updateResultSensitivity', result)
        store.commit('general/updateParametersSensitivity', data['parameters'])
        // Add logs
        store.commit('sensitivity/updateSensitivityRunLogs', data['logs'])
      
      }else{                
          store.commit('dialogs/updateMessage',                
                'No solutions found for sensitivity analysis given the tolerances. Increase tolerance if possible.'
                  )
          store.commit('dialogs/updateDialog',                
                true
          )                
      }

      store.commit('sensitivity/updateSensitivityRun', "executed")      
      
      
      } // End 


      return {        
        optimize,         
        getSensitivity,
        checkSensitivityParameters        
      }






  
}
