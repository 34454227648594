


export default {
  name: "demoPage",
  components: {},

  data() {
    return {
      position: 0,
    };
  },
};
