<template>    
    <div>
      <q-dialog v-model="show" persistent transition-show="scale" transition-hide="scale">
    <!-- First dialog page -->    
        <q-card class="q-ma-md items-center" style="width:300px;height:350px" flat bordered outline>
          <q-card-section v-if="env=='dev'">
            Test Area
              Project Name {{projectName }}
          </q-card-section>
          <q-card-section>
            <q-btn-dropdown color="black" outline label="Select a category">
                   <q-list bordered v-close-popup>
                        <q-item 
                            v-for="c in categories" 
                            :key="c.name" 
                            clickable 
                            v-ripple                           
                            active-class="highlight"
                            @click="onCategoryClick(c);"
                            >
                            {{ c.name }}
                          </q-item>
                      </q-list>
            </q-btn-dropdown> 
          </q-card-section>
          
            <q-card-section>                               
                <q-input 
                    placeholder="Project Name" 
                    v-model="projectName"   
                    style="width:200px"                 
                    >
                    </q-input>                     
            </q-card-section>
        
          <q-card-actions class="text-primary">
            <q-btn 
              outline
              color="blue"
              label="Create Project" 
              @click="createProject();"
              align="left" />
            <q-btn 
              color="black"
              outline
              label="Close" 
              @click="$store.commit('dialogs/updateCreateProjectDialog', false)"
              align="right" 
              />
          <!-- <q-btn flat label="Details" @click="secondDialog = true; firstDialog = false" /> -->
          
        </q-card-actions>

      </q-card>
      </q-dialog>
            
    </div>
</template>


<script lang="ts">
import {computed, ref} from 'vue'
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'vuex';
import { useRouter} from 'vue-router'
import { Loading, QSpinnerGears } from 'quasar'
import userData from '@/components/mixins/userData.js';

export default {
  name: 'createProject',
  components: {},


  setup() {
    const store = useStore()
    const router = useRouter()

    const env = computed(() => {return process.env.VUE_APP_ENV})
    const {getUserData} = userData()

    // Get value for showing or not
    const show = computed(() => store.state.dialogs.createProjectDialog)
    
    // Get Categories
    const categories = computed(() => store.state.general.categories)

    // Project name
    const projectName = ref('')
      
    const errorKey =  ref(0)


    //* ----------- Function to create a new project --------------------------------
    const createProject = async () => {

        store.commit('general/updateResult', [])

        Loading.show({
            delay: 400, // ms
            spinner: QSpinnerGears,
            message: 'Creating project'
        })
        
        window.console.log('creating project');
        const category = store.state.general.selected_category
        const shippingBox = store.state.general.shippingBoxStandard
        const product = store.state.general.standardProduct
        const pallet = store.state.general.standardPallet
        const secondPallet = store.state.general.secondPallet
      
      // Create project ID
      const project_id = uuidv4();
        

      // Get current time for adding time stamp to product update
      const currentdate = new Date();
      const current_time =  currentdate.getDate() + "/"
                  + (currentdate.getMonth()+1)  + "/" 
                  + currentdate.getFullYear() + " @ "  
                  + currentdate.getHours() + ":"  
                  + currentdate.getMinutes() + ":" 
                  + currentdate.getSeconds();

      // Form data for post request
      const requestOptions = {
          
        method: "POST",
        headers: { "Content-Type": "application/json" },
      
        body: JSON.stringify({ 
          
          actionType: 'createProject',

          user: store.state.user.userData,

          projectID: project_id,
          name: projectName.value,

          updated_time: current_time,
          
          category: category,
          shippingBox: shippingBox,
          product: product,
          pallet: pallet,
          secondPallet: secondPallet,

          selectedOptionsSensitivity: [],
          selectedOptionsResults: [],
          selectedOptionsDecisions: []
                    
          })
          };


        window.console.log('requestOptions')
        window.console.log(requestOptions)
        
        const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "updateCosmos" , requestOptions).catch((error) => {

          Loading.hide();      
 
           store.commit('dialogs/updateErrorDialog', {
              show: true,
              message: 'Error querying the server your project could not be created in the database.'
          }) 
          //window.alert('error querying Server your project could not be created in DB')      
          window.console.log(errorKey)
          window.console.log(error)       
          }
            
            
          ) as Response;


        let data;

        if (response.status==200){
          data = await response.json();
          window.console.log("Response:" + data)
          window.console.log("Response:" + data['content'])
          Loading.hide();      

          if (data['content'] == 'project name already exists') {
            
              store.commit('dialogs/updateMessage',                
                'Error querying the server your project could not be created in the database.'
                  )
               store.commit('dialogs/updateDialog',                
                true
          )

          
          } else {        
          // Refresh user data
          
          window.console.log('refreshing user data')
          //await this.getUserData();
     
          
          // Committing values to store
          const payload = {
                name: projectName,
                id: project_id
            }

          store.commit('general/updateSelectedProject', payload)
          // Set standard shipping box
           // Update values for specific project in general store
          store.commit("general/updateProduct", product);
            
          store.commit("general/updateShippingBox", shippingBox);
            
          store.commit("general/updatePallet", pallet);

          store.commit(
              "general/updateResult",
              []
            );

          //! Update/Reset sensitivity
          store.commit(
            "general/updateResultSensitivity",
            []
          );


          // Refresh user data
          await getUserData();

          Loading.hide();



          store.commit('dialogs/updateMessage',                
                'Project successfully created.'
                  )
          store.commit('dialogs/updateDialog',                
          true
          )
          
          store.commit('dialogs/updateCreateProjectDialog', false) // close dialog
           
        

          router.push('/')
          }

        }else{
          
          // error handling
           store.commit('dialogs/updateMessage',                
                'Error creating your project.'
                  )
               store.commit('dialogs/updateDialog',                
                true
               )
          
          
        }

    }// End of create project

        // Category select
        const onCategoryClick = (category) => {
          window.console.log('category clicked')
          store.commit('general/updateSelectedCategory', category)        
      }


// ---------- Return
    return {
      env,
      categories,
      projectName,
      createProject,
      onCategoryClick,
      show 
    }
  }

  }

  

</script>


<style scoped>
h5{
  margin-bottom: 20px;
}
</style>