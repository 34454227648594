
import { useStore } from 'vuex'
import { computed } from "vue";
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';

export default function() {
   
    const store = useStore()

    const env = computed(() => process.env.VUE_APP_ENV) 
   
    const getTestData = () => {

      window.console.log('getting user data inside function')
            
    

    }

    //
    return {
      getTestData
    }
  }
          
  