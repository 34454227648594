<template>
  <div class="row items-center justify-center" style="width: 600px">
    <div class="row q-my-lg q-mx-lg">
      <div class="text-head-scalize text-dark">
        FOR A
        <span class="label bg-white text-dark">LIVE DEMO OF SCALIZE,</span>
        PLEASE CONTACT:
      </div>
    </div>

    <div class="row q-my-lg q-mx-lg">
      <div class="text-head-scalize">
        <span class="label bg-white text-blue">ROBERT.HUGHES@SCALIZE.APP</span>
      </div>
    </div>

    <div class="row q-my-lg q-mx-lg">
      <div class="text-head-scalize text-dark">
        OR
        <a href="https://calendly.com/scalize-calendar/60min">
          <span class="label bg-white text-red">BOOK AN APPOINTMENT</span></a
        >
      </div>
    </div>

    <div class="row q-my-lg q-mx-lg">
      <div class="text-head-scalize text-dark">
        FOR THE LATEST NEWS, FOLLOW SCALIZE ON
        <a href="https://www.linkedin.com/company/scalize-gmbh">
          <span class="label bg-white text-green">LINKEDIN</span></a
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">


export default {
  name: "demoPage",
  components: {},

  data() {
    return {
      position: 0,
    };
  },
};
</script>

<style scoped lang="scss">
h6 {
  margin-bottom: 0px;
}
</style>
