
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import competitorProducts from '@/components/mixins/competitorProducts.js'


export default {
     
     
     setup() {
        
        //! vue3
        const store = useStore()         

        // Get environment 
        const env = computed(() => {
            return process.env.VUE_APP_ENV
            })

        const { fetchCompetitorProducts } = competitorProducts()
        
        const showConfig = ref(false)

        const uploadCanvas = ref()

        const product = ref({
            'name': '',
            'height': 0,
            'width': 0,
            'depth': 0,
        })

        const imageSize = ref({
            width: 0,
            height: 0
        })

        const picture = ref(null)


        const test = (() => {
            window.console.log(picture)
        })


        const productImage = ref()

        // * Function to upload image
        const uploadImage = ((event: Event) => {

            window.console.log('uploading image')
            // show laoder
            Loading.show({
            delay: 200, // ms
            spinner: QSpinnerGears,
            message: 'Uploading picture'
        })

            // reset images
            const input: HTMLInputElement = event.target as HTMLInputElement;
            const image = input.files![0];
            
            const reader = new FileReader();
          
            reader.readAsDataURL(image);

            // Define what is happening on loading file
            reader.onload = ((event: Event) => {
                    
                    const img = new Image()
                    //img.src = event.target.result
        
                    img.src = (reader.result as string)                    
                    
                    // Define what is happening on image load
                    img.onload = ((event: Event) => {
                        // Get Canvas
                        const canvas = uploadCanvas.value;   
                        let height;

                        // Set canvas height depending on image width to height ratio
                        if (img.width/img.height > 2){
                            height = 100              
                        }else{
                            height = 200              
                        }
                        // Set canvas width
                        canvas.height = height
                                    
                        canvas.width = img.width/img.height * height
                        
                        var ctx = canvas.getContext("2d");

                        window.console.log('img.width:' + img.width)
                        window.console.log('img.height:' + img.height)
                        window.console.log('img.width/img.height * 200: ' + img.width/img.height * height)
                        
                        // Actual resizing
                        ctx.drawImage(img, 0, 0, img.width/img.height * height, height);                       
                        //ctx.drawImage(img, 0, 0, 200, 200);                       
                        productImage.value = uploadCanvas.value.toDataURL();
                    })
                    Loading.hide(); 
                    
                    window.console.log('original size: ' + (reader.result as string).length)
                    window.console.log('resized size: ' + productImage.value.length)
                    
                
                });      
            
        }) // End uploading image

        // * Function to upload product
        const uploadProduct = async () => {

            const payload = {
                name: product.value.name,
                product_height: product.value.height,
                product_width: product.value.width,
                product_depth: product.value.depth,
                image: productImage.value
            }

            store.commit('competitor/updateCompetitorProducts', payload)
            

        //* ---- Upload to Database            
        window.console.log('adding competitor product')
    
        // Show loading
        Loading.show({
            delay: 400, // ms
            spinner: QSpinnerGears,
            message: 'Fetching your projects'
        })

        let user_email = ''
        let userID
        if(env.value=='dev') {
            user_email = 'jan.mammen@scalize.app'
            userID = 2
        }else{
            user_email = store.state.user.userData.userEmail
            userID = store.state.user.userData.userID
        }
        
        window.console.log('Uploading to Cosmos DB')
        
        
        const url = process.env.VUE_APP_FUNCTION_URI + "updateCosmos"
        window.console.log('url: ' + url)
        
        const requestOptions = {            
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                title: "Request",
                userEmail: user_email,
                userID: userID,
                actionType: 'addCompetitorProduct',
                name: product.value.name,
                product_height: product.value.height,
                product_width: product.value.width,
                product_depth: product.value.depth,
                image: productImage.value
            })
        };

        window.console.log(requestOptions)
        const response = await fetch(url , requestOptions).catch((error) => {
                window.console.log(error)                                        
                // Hide loading
                Loading.hide(); 
                store.commit('dialogs/updateMessage',  'Error Uploading product!')
                store.commit('dialogs/updateDialog', true)             
            }
            
            
            );

        // placeholder for data
        let responseJson;
        

        if ((response as Response).status == 200) {
            window.console.log(response)
            responseJson = await (response as Response).json();
            window.console.log("Response:" + responseJson)     
            
            
            fetchCompetitorProducts()

            // Hide loading
            Loading.hide();

            store.commit('dialogs/updateMessage',  'Product sucessfully uploaded!')
            store.commit('dialogs/updateDialog', true)     

      
      }else{


        fetchCompetitorProducts()
        
        store.commit('dialogs/updateMessage',  'Error uploading!')
        store.commit('dialogs/updateDialog', true)     
        
        Loading.hide();
      }


        }

   
        
        return {
            env,
            showConfig,
            product,
            picture,
            test,
            uploadImage,
            productImage,
            uploadProduct,
            imageSize,
            uploadCanvas
            
 
        }
        
    },
}
