<template>
  <div>
    <div class="row q-mb-lg justify-center">
      <div class="text-head-scalize text-center text-dark q-my-md">
        AN ESSENTIAL TOOL FOR YOUR
        <span class="label bg-white text-no-wrap text-blue"> IT PLATFORM</span>,

        <span class="label bg-white text-dark"> ENABLING </span>
        <span class="label bg-white text-green"> SUSTAINABILITY TARGETS </span>
        AND
        <span class="label bg-white text-no-wrap text-red">
          CATEGORY MANAGEMENT
        </span>
      </div>
    </div>
    <!-- end of first row -->

    <div
      class="row text-no-wrap q-my-md q-mx-sm justify-evenly items-end text-center"
    >
      <div class="col-12">
        <!-- col-md-3 -->
        <div class="col">
          <q-img
            class="q-my-md"
            :src="require(`@/assets/landing_page/FE database.svg`)"
            id="product_image"
            style="max-width: 400px"
          />
        </div>
        <div class="col" style="">
          <div class="text-body-scalize q-my-sm q-mx-sm text-blue">
            GENERATE A PIPELINE DATABASE
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-green q-my-sm q-mx-sm">
            IMPROVE PRODUCT DATA COLLECTION
          </div>
        </div>
      </div>

      <div class="col-12 q-mt-xl">
        <!-- col-md-4 -->
        <div class="col">
          <q-img
            class="q-my-lg"
            :src="require(`@/assets/landing_page/75 DECISIONS_targets.svg`)"
            id="pallet_image"
            style="max-width: 300px"
          />
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm text-dark">
            ENSURE TARGETS ARE REACHED
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-blue q-my-sm q-mx-sm">
            OPTIMIZE CATEGORY ASSORTMENTS
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
export default {
  name: "decisionsPage",
  components: {},

  data() {
    return {
      position: 0,
    };
  },
};
</script>

<style scoped lang="scss"></style>
