
import { useStore } from 'vuex'
import { computed } from "vue";
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';


export default function() {
   
    const store = useStore()

    const env = computed(() => process.env.VUE_APP_ENV) 

    //* --- Function to get user Data
    const getUserData = async () => {

      window.console.log('getting user data inside function')
            
      // Show loading
      Loading.show({
          delay: 400, // ms
          spinner: QSpinnerGears,
          message: 'Loading your projects'
      })

      let user_email = ''
      if(env.value=='dev') {
          user_email = 'jan.mammen@scalize.app'
      }else{
          user_email = store.state.user.userData.userEmail
      }
     
      window.console.log('Getting Cosmos DB')
      
      const url = process.env.VUE_APP_FUNCTION_URI + "queryCosmos"
      window.console.log('url: ' + url)
      window.console.log('User Email: ' + user_email)
      
      const requestOptions = {
        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          userEmail: user_email,
          actionType: 'user'
          })
      };
      const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "queryCosmos" , requestOptions).catch((error) => {
              window.console.log(error)                            
              
              store.commit('dialogs/updateMessage', 'Error querying API: your projects could ne be loaded.')
              store.commit('dialogs/updateDialog', true)           
                          
              // Hide loading
              Loading.hide();              
        }
          
          
        );

      // placeholder for data
      let responseJson;
      

      if (response.status == 200) {
        window.console.log(response)
        responseJson = await response.json();

        if (responseJson.status === "user not found"){
          store.commit('dialogs/updateMessage', 'Could not find your user')
          store.commit('dialogs/updateDialog', true)                              
          Loading.hide();

        }
        else if (responseJson.status === "no user information"){
          store.commit('dialogs/updateMessage', `No user data provided, probably email adress missing in claims.
                          Check claims from User Flow in B2C tenant.`)
          store.commit('dialogs/updateDialog', true)                              
          Loading.hide();

        } else {
              
        window.console.log("response.Json")
        window.console.log(response.Json)

        // Updating user data in store
        store.commit('user/updateUserData', {
          iss: store.state.user.userData.iss,
          idToken: store.state.user.userData.idToken,
          userEmail: responseJson.data.userEmail,
          userID: responseJson.data.userID
        })
        store.commit('user/updateUserProjects', responseJson.data.projects)

        window.console.log(`responseJson.data.projects.length: ${responseJson.data.projects.length}`)
        if (responseJson.data.projects.length === 0){
          window.console.log("it seems no projects yet")
        }

        // Hide loading
        Loading.hide();

        }
      }else{
        
        store.commit('dialogs/updateMessage', 'Error loading user data')
        store.commit('dialogs/updateDialog', true)                              
        Loading.hide();
      }


    } // End of function to get user Data

    //
    return {
      getUserData
    }
  }
          
  