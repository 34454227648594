<template>    
    
      <q-dialog v-model="dialogShow" >
        <q-card class="q-ma-md items-center" flat bordered>            
          <q-card-section>
              <div class="text-body-scalize justify-center">                        
                    {{ message }}
              </div>
          </q-card-section>                  

            <q-card-actions class="justify-end">
                <q-btn                   
                  color="black"                                
                  label="Close" 
                  @click="close();signOut()"                  
                />
          
        </q-card-actions>
        
         

      </q-card>
      </q-dialog>

      
</template>


<script lang="ts">
import {computed} from 'vue'
import {useStore} from 'vuex'

export default {
  name: 'errorDialog',
  //  props: {   
  //     message: String,
  //     show: Boolean
  // },


  setup() {    
    const store = useStore()

    
    const dialogShow = computed(() => {      
        return store.state.dialogs.loginErrorDialog
        }
      
      )

      const message = computed(() => {      
        return store.state.dialogs.message
        }
      
      )

       
      const myMSALObj = computed(() => {return store.state.user.mySAML})


      const signOut = () => {
        // Choose which account to logout from.    
        const logoutRequest = {
          account: myMSALObj.value.getAccountByHomeId()
        };
    
      myMSALObj.value.logout(logoutRequest);

      }


      


    

    const close = () => {
       store.commit('dialogs/updateLoginErrorDialog', {
        show: false        
      })

    }
    return {
      close,
      store,
      dialogShow,
      message,
      signOut
    }

  },

 


}


</script>


<style scoped>

</style>