
import {computed, ref} from 'vue'
import {useStore} from 'vuex'

import decisionsTable from '@/components/decisions/decisionsTable.vue'
import shelf from '@/components/decisions/shelf.vue'
import decisionDialog from '@/components/decisions/decisionDialog.vue'


import threeDimPallet from '@/components/visualisations/threeDimPallet.vue'; // @ is an alias to /src



export default {
  name: 'mainPage',
  components: {
      //'carry-over-table':  carryOverTable,
      //'decisions-table':  decisionsTable,
        'three-dim-pallet': threeDimPallet,
      'decisions-table': decisionsTable,
      shelf,
      'decision-dialog': decisionDialog
   
  },

  setup() {
    const env = computed(() => process.env.VUE_APP_ENV)

    const store = useStore()
    
    return {
      env,
      store
    }

  },

  data(){ 
   
   return{
        position: 0,
        pages: {
            about: true,
            meetScalize: false,
            products: false,
            needs: false,
        }
      
    }
  }, // End of data

    
  
}
