
import{computed, ref} from 'vue'
import {useStore} from 'vuex'

export default {
  name: 'Config',
  components: {   

  },

  setup(){
    const env = computed(() => {return process.env.VUE_APP_ENV})
    const store = useStore()
    

    return {
      env,
    }

  }

  

};
