import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Product from '../views/Product.vue'
import ShippingBox from '../views/ShippingBox.vue'
import Pallet from '../views/Pallet.vue'
import Result from '../views/Result.vue'
import Decisions from '../views/Decisions.vue'
import Config from '../views/Config.vue'
import Database from '../views/ProjectDB.vue'
import MassAnalysis from '../views/MassAnalysis.vue'
import Report from '../views/Report.vue'
import Upload from '../views/Upload.vue'
import Testing from '../views/Testing.vue'
import Dev from '../views/Dev.vue'
import AssortmentAnalysis from '../views/MassAnalysis.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/shippingbox',
    name: 'ShippingBox',
    component: ShippingBox
  },
  {
    path: '/pallet',
    name: 'Pallet',
    component: Pallet
  },
  {
    path: '/result',
    name: 'Result',
    component: Result
  },
  {
    path: '/decisions',
    name: 'Decisions',
    component: Decisions
  },
  {
    path: '/config',
    name: 'Config',
    component: Config
  },
  {
    path: '/database',
    name: 'Database',
    component: Database
  },
  {
    path: '/report',
    name: 'Report',
    component: Report
  },
  {
    path: '/assortment_analysis',
    name: 'AssortmentAnalysis',
    component: AssortmentAnalysis
  },
  {
    path: '/testscalize',
    name: 'Upload',
    component: Upload
  },
  {
    path: '/testing',
    name: 'Testing',
    component: Testing
  },
 
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/dev',
    name: 'dev',
    component: Dev
  },
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),  // Hash mode
  routes
})

export default router
