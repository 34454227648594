
import {onMounted, onUpdated, ref} from "vue"
//import draggableDiv from '@/components/report/draggableDiv.vue'; // @ is an alias to /src


export default {
  name: 'Home',
  components: {
    //'draggable-div': draggableDiv
  },
  'props': {
    svgHeight: {
      type: Number,
      default: 200
    },
    svgWidth: {
      type: Number,
      default: 200   
    },
    
    product: {
      type: Object,
      required: true
    },
    
    shipping_box: {
      type: Object,
      required: true
    },
    shipperSpace: {
      type: Number,
      required: true
    }
    },

    setup(props){

      const strokeColorArrows = "grey"
      const productFill = "none"
      const productStrokeColor = "red"
      
      
      // Define scaling
      const shipperScaling = (
                Math.min((props.svgHeight) / (Math.sqrt(Math.pow(props.shipping_box.depth, 2)/2) + props.shipping_box.height),
                (props.svgWidth) / (Math.sqrt(Math.pow(props.shipping_box.depth, 2)/2) + props.shipping_box.width)) * 0.5
                )           

      const shippers:  {
          "xPosition": number,
          "yPosition": number,
          "xPositionEnd": number,
          "yPositionEnd": number,
          "width": number,
          "depth": number,
          "height": number
        }[]  = []

        const shipperProductsAdjusted = ref(shippers)

        const update = () => {

          shipperProductsAdjusted.value = []
        
          // Shipper products
          let x_init = (props.shipping_box.shrinks_plastic_width + props.shipping_box.thickness_carton * props.shipping_box.layer_carton_wrapping_width) * shipperScaling +  props.svgWidth * props.shipperSpace
          let init_y = props.svgHeight * (1 - props.shipperSpace) - props.product.height * shipperScaling         
          
          let x = x_init
            
          if (props.shipping_box.product_orientation === 'face'){

              for (let l = 0; l < props.shipping_box.number_layers; l++){
                window.console.log(`Layer: ${l}`)                           
                                      
                for (let i = 0; i < props.shipping_box.number_shrinks_per_layer; i++){
                  let y = init_y

                  for ( let p = 0;  p < props.shipping_box.units_per_shrink; p++){
                              
                    shipperProductsAdjusted.value.push(
                      {
                        'xPosition': x,
                        'yPosition': y,
                        'xPositionEnd': x + Math.sqrt(Math.pow(props.product.depth * shipperScaling, 2)/2),
                        'yPositionEnd': y - Math.sqrt(Math.pow(props.product.depth * shipperScaling, 2)/2),
                        'width': props.product.width * shipperScaling, 
                        'depth': props.product.depth * shipperScaling,
                        'height': props.product.height * shipperScaling
                      }              
                      )
                        y -= Math.sqrt(Math.pow(props.product.depth * shipperScaling, 2)/2)
                        x += Math.sqrt(Math.pow(props.product.depth * shipperScaling, 2)/2)
                    } // End of shrink

                    y = init_y 
                    x = x_init 
                    x += (props.product.width + props.shipping_box.shrinks_plastic_width + props.shipping_box.gap_between_shrinks) * shipperScaling * (i+1)

                  } // end of layer

                  //init_y -= props.product.height * shipperScaling.value
                  
                x = props.shipping_box.shrinks_plastic_width * shipperScaling +  props.svgWidth * props.shipperSpace
                init_y -= props.product.height * shipperScaling 
                //init_y += props.svgHeight * (1 - shipperSpace) + props.product.height * (l-1) * shipperScaling.value 
                
              }// end of layer loop

              } else { 
                // Product orientation side in shrink
                for (let l = 0; l < props.shipping_box.number_layers; l++){
                  window.console.log(`Layer: ${l}`)                           
                                      
                  for (let i = 0; i < props.shipping_box.number_shrinks_per_layer; i++){
                    let y = init_y

                    for ( let p = 0;  p < props.shipping_box.units_per_shrink; p++){
                                
                      shipperProductsAdjusted.value.push(
                        {
                          xPosition: x,
                          yPosition: y,
                          xPositionEnd: x + Math.sqrt(Math.pow(props.product.width * shipperScaling, 2)/2),
                          yPositionEnd: y - Math.sqrt(Math.pow(props.product.width * shipperScaling, 2)/2),
                          width: props.product.depth * shipperScaling, 
                          depth: props.product.width * shipperScaling,
                          height: props.product.height * shipperScaling
                        }              
                        )
                          y -= Math.sqrt(Math.pow(props.product.width * shipperScaling, 2)/2)
                          x += Math.sqrt(Math.pow(props.product.width * shipperScaling, 2)/2)
                      } // End of shrink

                    y = init_y 
                    x = x_init 
                    x += (props.product.depth + props.shipping_box.shrinks_plastic_width + props.shipping_box.gap_between_shrinks) * shipperScaling * (i+1)

                    } // end of layer

                  //init_y -= props.product.height * shipperScaling.value
                  
                x = props.shipping_box.shrinks_plastic_width * shipperScaling +  props.svgWidth * props.shipperSpace
                init_y -= props.product.height * shipperScaling 
                
                
              }// end of layer loop
              }

          }// End of update function

        update()

        onMounted(() => {
          window.console.log("MOUNTED SHIPPING BOX")          
          update()

          window.console.log(`shipperProductsAdjusted : ${shipperProductsAdjusted.value}`)
          window.console.log(`shipperProductsAdjusted : ${shipperProductsAdjusted.value}`)
          window.console.log(shipperProductsAdjusted.value)

          window.console.log(props.product)
        })

        onUpdated(() => {
          window.console.log("UPATED SHIPPING BOX")
          window.console.log(`SHIPPING Box.units_per_shrink: ${props.shipping_box.units_per_shrink}`)
      
         
        })

        return {
          strokeColorArrows,        
          shipperScaling,
          productFill,
          productStrokeColor,
          shipperProductsAdjusted
        }
    }

  
};
