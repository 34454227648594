import { render } from "./createProject.vue?vue&type=template&id=5388818a&scoped=true"
import script from "./createProject.vue?vue&type=script&lang=ts"
export * from "./createProject.vue?vue&type=script&lang=ts"

import "./createProject.vue?vue&type=style&index=0&id=5388818a&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-5388818a"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QBtnDropdown,QList,QItem,QInput,QCardActions,QBtn});qInstall(script, 'directives', {ClosePopup,Ripple});
