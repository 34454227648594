<template>
    <div class="row q-ma-md">
        <div class="text-head-scalize-large">Optimization Run</div>
    </div>
    <div class="row q-ma-md">
        <!-- Info -->
        <div class="col-auto q-ma-md">
            <div class="text-head-scalize">Info</div>
            <q-list>
                <q-item v-for="(value, key) in $store.state.general.calculationRunLogs.info" :key="key">
                <q-item-section>{{key }}: <span :style="{'color':'red'}">{{value}}</span></q-item-section>    
                </q-item>
            </q-list>
        </div>
        
        <div class="col-auto q-ma-md">
        <!-- Errors -->
            <div class="text-head-scalize">Errors</div>
            <q-list>
                <q-item v-for="(value, key) in $store.state.general.calculationRunLogs.errors" :key="key">
                <q-item-section>{{key }}: <span :style="{'color':'red'}">{{value}}</span></q-item-section>        
                </q-item>
            </q-list>

        </div>

    </div>

  <q-separator />
<!-- ------------- Sensitivity RUN ---------------------------- -->
    <div class="row q-ma-md">
        <div class="text-head-scalize-large">Sensitivity Run</div>
    </div>

    

       <div class="row q-ma-md"  v-if="$store.state.sensitivity.sensitivityRun==='started'">
          <div class="text-head-scalize" :style="{'color': 'red'}">Still running sensitivity</div>
       </div>
       
       <!-- To show if sensitivy analysis is finished -->
       <div class="row q-ma-md" v-else>                 
         
        
        <div class="col-auto q-ma-md">
            <div class="text-head-scalize">Info</div>
            <q-list>
                <q-item v-for="(value) in $store.state.sensitivity.sensitivityRunLogs" :key="value">
                <q-item-section><span :style="{'color':'red'}">{{value}}</span></q-item-section>
                </q-item>
            </q-list>
        </div>
        
        <div class="col-auto q-ma-md">
        <!-- Errors -->
            <div class="text-head-scalize">Errors</div>
            <!-- <q-list>
                <q-item v-for="(value, key) in $store.state.general.calculationRunLogs.errors" :key="key">
                <q-item-section>{{key }}: {{value}}</q-item-section>        
                </q-item>
            </q-list> -->

        </div>

      </div>


</template>

<script lang="ts">
import{computed, ref} from 'vue'
import {useStore} from 'vuex'

export default {
  name: 'Config',
  components: {   

  },

  setup(){
    const env = computed(() => {return process.env.VUE_APP_ENV})
    const store = useStore()
    

    return {
      env,
    }

  }

  

};
</script>

<style>

</style>
