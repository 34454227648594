
// https://www.angularfix.com/2021/10/error-loading-svg-data-with-html2canvas.html


<template>

  <svg :height="svgHeight" :width="svgWidth" xmlns="http://www.w3.org/2000/svg" fill="none" :style="{'border': 'none'}" >
                    
      <defs>
        <marker id="arrowheadShipper" markerWidth="2" markerHeight="3" 
          refX="0" refY="1.5" orient="auto">
          <polygon points="0 0, 2 1.5, 0 3" 
          :fill="strokeColorArrows" />
        </marker>
      </defs>
      

    <!-- Arrow for Height -->
    <line 
      :x1="svgWidth * shipperSpace * 0.8" 
      :x2="svgWidth * shipperSpace * 0.8" 
      :y1="svgHeight * (1 - shipperSpace)" 
      :y2="svgHeight * (1 - shipperSpace) - shipping_box.height * shipperScaling" 
      :stroke="strokeColorArrows" 
      stroke-width="2" 
      marker-end="url(#arrowheadShipper)"     
      />


      <text 
        :x="svgWidth * shipperSpace * 0.1" 
        :y="svgHeight * (1 - shipperSpace) - shipping_box.height * shipperScaling * 1.2" 
        class="svg-text">
        {{shipping_box.height}} cm
      </text>


    <!-- Arrow for Width -->
    <line 
      :x1="svgWidth * shipperSpace" 
      :x2="svgWidth * shipperSpace + shipping_box.width * shipperScaling" 
      :y1="svgHeight * (1 - shipperSpace) * 1.05" 
      :y2="svgHeight * (1 - shipperSpace) * 1.05" 
      :stroke="strokeColorArrows" 
      stroke-width="2" 
      marker-end="url(#arrowheadShipper)"     
      />
    
      <text 
        :x="svgWidth * shipperSpace" 
        :y="svgHeight * (1 - shipperSpace) * 1.15" 
        class="svg-text">
        {{shipping_box.width}} cm
      </text>

    <!-- Arrow for Depth -->
    <line 
      :x1="svgWidth * shipperSpace + shipping_box.width * shipperScaling + shipping_box.width * shipperScaling * 0.1" 
      :x2="svgWidth * shipperSpace + Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2) + shipping_box.width * shipperScaling * 1.1 " 
      :y1="svgHeight * (1 - shipperSpace)" 
      :y2="svgHeight * (1 - shipperSpace)  - Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2)" 
      :stroke="strokeColorArrows" 
      stroke-width="2" 
      marker-end="url(#arrowheadShipper)"     
      />

       <text 
        :x="svgWidth * shipperSpace + shipping_box.width * shipperScaling + shipping_box.width * shipperScaling * 0.4" 
        :y="svgHeight * (1 - shipperSpace)" 
        class="svg-text"   
       > {{shipping_box.depth}} cm
      </text>


    
       
  <!-- Products -->
      
          <!-- Front rectangle -->
          <rect 
              v-for="(i, key) in shipperProductsAdjusted" :key="key"
              :width="i.width"
              :height="i.height"
              :x="i.xPosition" 
              :y="i.yPosition" 
              rx="0"              
              :stroke="productStrokeColor" 
              :fill="productFill" 
              stroke-width="1" stroke-dasharray="2,2"
              /> 
          <!-- Back rectanlge-->
          <rect 
              v-for="(i, key) in shipperProductsAdjusted" :key="key"
              :width="i.width"
              :height="i.height" 
              :x="i.xPositionEnd" 
              :y="i.yPositionEnd" 
              rx="0"
              :stroke="productStrokeColor" 
              :fill="productFill" 
              stroke-width="1" stroke-dasharray="2,2"
              /> 

          
            <!-- Upper left -->
            <line
              v-for="(i, key) in shipperProductsAdjusted" :key="key"
              :x1="i.xPosition"               
              :x2="shipping_box.product_orientation === 'face' ? 
                i.xPosition + Math.sqrt(Math.pow(product.depth * shipperScaling, 2)/2) :
                i.xPosition + Math.sqrt(Math.pow(product.width * shipperScaling, 2)/2)
              " 
              :y1="i.yPosition"      
              :y2="i.yPositionEnd" 
              :stroke="productStrokeColor" 
              :fill="productFill" 
              stroke-width="1" stroke-dasharray="2,2"
            />

            <!-- Upper right -->
             <line
              v-for="(i, key) in shipperProductsAdjusted" :key="key"
              :x1="shipping_box.product_orientation === 'face' ? i.xPosition + product.width * shipperScaling : i.xPosition + product.depth * shipperScaling" 
              :x2="shipping_box.product_orientation === 'face' ?
                i.xPosition + product.width * shipperScaling + Math.sqrt(Math.pow(product.depth * shipperScaling, 2)/2) :
                i.xPosition + product.depth * shipperScaling + Math.sqrt(Math.pow(product.width * shipperScaling, 2)/2)
                " 
              :y1="i.yPosition"      
              :y2="i.yPositionEnd" 
              :stroke="productStrokeColor"
              stroke-width="1" 
              stroke-dasharray="2,2"
            />

            <!-- Lower left -->
            <line
              v-for="(i, key) in shipperProductsAdjusted" :key="key"
              :x1="i.xPosition"               
              :x2="shipping_box.product_orientation === 'face' ? 
                i.xPosition + Math.sqrt(Math.pow(product.depth * shipperScaling, 2)/2) :
                i.xPosition + Math.sqrt(Math.pow(product.width * shipperScaling, 2)/2)
              " 
              :y1="i.yPosition + product.height * shipperScaling"      
              :y2="i.yPositionEnd + product.height * shipperScaling"      
              :stroke="productStrokeColor"              
              stroke-width="1" 
              stroke-dasharray="2,2"
            />

            <line
              v-for="(i, key) in shipperProductsAdjusted" :key="key"
               :x1="shipping_box.product_orientation === 'face' ? i.xPosition + product.width * shipperScaling : i.xPosition + product.depth * shipperScaling" 
              :x2="shipping_box.product_orientation === 'face' ?
                i.xPosition + product.width * shipperScaling + Math.sqrt(Math.pow(product.depth * shipperScaling, 2)/2) :
                i.xPosition + product.depth * shipperScaling + Math.sqrt(Math.pow(product.width * shipperScaling, 2)/2)
                "  
              :y1="i.yPosition + product.height * shipperScaling"      
              :y2="i.yPositionEnd + product.height * shipperScaling"      
              :stroke="productStrokeColor" 
              stroke-width="1" 
              stroke-dasharray="2,2"  
            />

    <!-- Define Shipper -->             
          <!-- Front rectangle -->
          <rect 
              :width="shipping_box.width * shipperScaling" 
              :height="shipping_box.height * shipperScaling" 
              :x="svgWidth * shipperSpace" 
              :y="svgHeight * (1 - shipperSpace) - shipping_box.height * shipperScaling" 
              rx="0"
              stroke="blue" 
              fill="none" stroke-width="1" 
              /> 
          <!-- Back rectanlge-->
          <rect 
              :width="shipping_box.width * shipperScaling" 
              :height="shipping_box.height * shipperScaling" 
              :x="svgWidth * shipperSpace + Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2)" 
              :y="svgHeight * (1 - shipperSpace) - shipping_box.height * shipperScaling - Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2)" 
              rx="0"
              stroke="blue" fill="none" stroke-width="1" 
              /> 

          
            <!-- Upper left -->
            <line
              :x1="svgWidth * shipperSpace" 
              :x2="svgWidth * shipperSpace + Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2)" 
              :y1="svgHeight * (1 - shipperSpace) - shipping_box.height * shipperScaling"      
              :y2="svgHeight * (1 - shipperSpace) - shipping_box.height * shipperScaling - Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2)" 
              stroke="blue" fill="none" stroke-width="1" 
            />

            <!-- Upper right -->
            <line
              :x1="svgWidth * shipperSpace + shipping_box.width * shipperScaling" 
              :x2="svgWidth * shipperSpace + Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2) + shipping_box.width * shipperScaling" 
              :y1="svgHeight * (1 - shipperSpace) - shipping_box.height * shipperScaling"      
              :y2="svgHeight * (1 - shipperSpace) - shipping_box.height * shipperScaling - Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2)" 
              stroke="blue" fill="none" stroke-width="1" 
            />

            <!-- Lower left -->
            <line
              :x1="svgWidth * shipperSpace" 
              :x2="svgWidth * shipperSpace + Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2)" 
              :y1="svgHeight * (1-shipperSpace)"       
              :y2="svgHeight * (1 - shipperSpace)  - Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2)" 
              stroke="blue" fill="none" stroke-width="1" 
            />

             <!-- Lower right -->
            <line
              :x1="svgWidth * shipperSpace + shipping_box.width * shipperScaling" 
              :x2="svgWidth * shipperSpace + Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2) + shipping_box.width * shipperScaling" 
              :y1="svgHeight * (1-shipperSpace)"       
              :y2="svgHeight * (1 - shipperSpace)  - Math.sqrt(Math.pow(shipping_box.depth * shipperScaling, 2)/2)" 
              stroke="blue" fill="none" stroke-width="1" 
            /> 

    </svg>
 
   
    
</template>

<script lang="ts">
import {onMounted, onUpdated, ref} from "vue"
//import draggableDiv from '@/components/report/draggableDiv.vue'; // @ is an alias to /src


export default {
  name: 'Home',
  components: {
    //'draggable-div': draggableDiv
  },
  'props': {
    svgHeight: {
      type: Number,
      default: 200
    },
    svgWidth: {
      type: Number,
      default: 200   
    },
    
    product: {
      type: Object,
      required: true
    },
    
    shipping_box: {
      type: Object,
      required: true
    },
    shipperSpace: {
      type: Number,
      required: true
    }
    },

    setup(props){

      const strokeColorArrows = "grey"
      const productFill = "none"
      const productStrokeColor = "red"
      
      
      // Define scaling
      const shipperScaling = (
                Math.min((props.svgHeight) / (Math.sqrt(Math.pow(props.shipping_box.depth, 2)/2) + props.shipping_box.height),
                (props.svgWidth) / (Math.sqrt(Math.pow(props.shipping_box.depth, 2)/2) + props.shipping_box.width)) * 0.5
                )           

      const shippers:  {
          "xPosition": number,
          "yPosition": number,
          "xPositionEnd": number,
          "yPositionEnd": number,
          "width": number,
          "depth": number,
          "height": number
        }[]  = []

        const shipperProductsAdjusted = ref(shippers)

        const update = () => {

          shipperProductsAdjusted.value = []
        
          // Shipper products
          let x_init = (props.shipping_box.shrinks_plastic_width + props.shipping_box.thickness_carton * props.shipping_box.layer_carton_wrapping_width) * shipperScaling +  props.svgWidth * props.shipperSpace
          let init_y = props.svgHeight * (1 - props.shipperSpace) - props.product.height * shipperScaling         
          
          let x = x_init
            
          if (props.shipping_box.product_orientation === 'face'){

              for (let l = 0; l < props.shipping_box.number_layers; l++){
                window.console.log(`Layer: ${l}`)                           
                                      
                for (let i = 0; i < props.shipping_box.number_shrinks_per_layer; i++){
                  let y = init_y

                  for ( let p = 0;  p < props.shipping_box.units_per_shrink; p++){
                              
                    shipperProductsAdjusted.value.push(
                      {
                        'xPosition': x,
                        'yPosition': y,
                        'xPositionEnd': x + Math.sqrt(Math.pow(props.product.depth * shipperScaling, 2)/2),
                        'yPositionEnd': y - Math.sqrt(Math.pow(props.product.depth * shipperScaling, 2)/2),
                        'width': props.product.width * shipperScaling, 
                        'depth': props.product.depth * shipperScaling,
                        'height': props.product.height * shipperScaling
                      }              
                      )
                        y -= Math.sqrt(Math.pow(props.product.depth * shipperScaling, 2)/2)
                        x += Math.sqrt(Math.pow(props.product.depth * shipperScaling, 2)/2)
                    } // End of shrink

                    y = init_y 
                    x = x_init 
                    x += (props.product.width + props.shipping_box.shrinks_plastic_width + props.shipping_box.gap_between_shrinks) * shipperScaling * (i+1)

                  } // end of layer

                  //init_y -= props.product.height * shipperScaling.value
                  
                x = props.shipping_box.shrinks_plastic_width * shipperScaling +  props.svgWidth * props.shipperSpace
                init_y -= props.product.height * shipperScaling 
                //init_y += props.svgHeight * (1 - shipperSpace) + props.product.height * (l-1) * shipperScaling.value 
                
              }// end of layer loop

              } else { 
                // Product orientation side in shrink
                for (let l = 0; l < props.shipping_box.number_layers; l++){
                  window.console.log(`Layer: ${l}`)                           
                                      
                  for (let i = 0; i < props.shipping_box.number_shrinks_per_layer; i++){
                    let y = init_y

                    for ( let p = 0;  p < props.shipping_box.units_per_shrink; p++){
                                
                      shipperProductsAdjusted.value.push(
                        {
                          xPosition: x,
                          yPosition: y,
                          xPositionEnd: x + Math.sqrt(Math.pow(props.product.width * shipperScaling, 2)/2),
                          yPositionEnd: y - Math.sqrt(Math.pow(props.product.width * shipperScaling, 2)/2),
                          width: props.product.depth * shipperScaling, 
                          depth: props.product.width * shipperScaling,
                          height: props.product.height * shipperScaling
                        }              
                        )
                          y -= Math.sqrt(Math.pow(props.product.width * shipperScaling, 2)/2)
                          x += Math.sqrt(Math.pow(props.product.width * shipperScaling, 2)/2)
                      } // End of shrink

                    y = init_y 
                    x = x_init 
                    x += (props.product.depth + props.shipping_box.shrinks_plastic_width + props.shipping_box.gap_between_shrinks) * shipperScaling * (i+1)

                    } // end of layer

                  //init_y -= props.product.height * shipperScaling.value
                  
                x = props.shipping_box.shrinks_plastic_width * shipperScaling +  props.svgWidth * props.shipperSpace
                init_y -= props.product.height * shipperScaling 
                
                
              }// end of layer loop
              }

          }// End of update function

        update()

        onMounted(() => {
          window.console.log("MOUNTED SHIPPING BOX")          
          update()

          window.console.log(`shipperProductsAdjusted : ${shipperProductsAdjusted.value}`)
          window.console.log(`shipperProductsAdjusted : ${shipperProductsAdjusted.value}`)
          window.console.log(shipperProductsAdjusted.value)

          window.console.log(props.product)
        })

        onUpdated(() => {
          window.console.log("UPATED SHIPPING BOX")
          window.console.log(`SHIPPING Box.units_per_shrink: ${props.shipping_box.units_per_shrink}`)
      
         
        })

        return {
          strokeColorArrows,        
          shipperScaling,
          productFill,
          productStrokeColor,
          shipperProductsAdjusted
        }
    }

  
};
</script>


