<template>
  <q-page>
    <!-- Spacer row -->
    <div class="row q-ma-xl"/>

    <!-- 1. Row -->
    <div v-if="env=='test'" >
      <h5>Test Environment</h5>
      
      
    </div> 
     
     <!-- Row for Product and shipping box -->
      <div class="row q-ma-lg text-center">     
     
     <!-- Product -->
      <div class="col-4">
        <div class="row justify-center items-center">
        <div class="text-h6">Product</div>
        </div>
        <!-- Second row -->
        <div class="row q-ma-lg justify-center items-center" style="height:200px" >         
             <!-- <q-section>
               Create new
             </q-section>             -->
          
              <q-img 
                :src = "require(`@/assets/product/product.png`)" 
                id="product_image"  
                class="q-ma-md"
                style="max-width:100px" />                                

          </div>

          <q-btn label="change details" @click="$router.push('product')" outline color="red" style="width:90px" /> 
            
            
              <div class="row q-ma-xs q-mt-lg items-center justify-center text-black text-left text-body-scalize">     
                    <div class="col-3 q-mr-xl text-left">
                      <span class="item_key">Height (cm): </span>
                    </div> 
                    <div class="col-md-2 " >                  
                      <q-input                                                                
                        v-model="product.parameters.height.values.Target.value"
                        @update:model-value="val => updateProductDimension(val, 'height')"
                        dense
                        borderless
                        bg-color="white"                                              
                        :input-style="{
                          'color': '#204fffE6',
                          'font-size': '1.1em',
                          'text-align':'left',
                          'line-height': '1.5em',
                          'letter-spacing': '0.07em',
                          'font-weight': '400'
                        }"
                    />        
                  </div>        
                </div>

                <!-- Width -->
                  <div class="row q-ma-xs items-center justify-center text-black text-left text-body-scalize">     
                    <div class="col-3 q-mr-xl text-left">
                      <span class="item_key">Width (cm): </span>
                    </div> 
                    <div class="col-md-2 " >                  
                      <q-input                                                                
                        v-model="product.parameters.width.values.Target.value"
                        @update:model-value="val => updateProductDimension(val, 'width')"
                        dense
                        borderless
                        bg-color="white"                                              
                        :input-style="{
                          'color': '#204fffE6',
                          'font-size': '1.1em',
                          'text-align':'left',
                          'line-height': '1.5em',
                          'letter-spacing': '0.07em',
                          'font-weight': '400'
                        }"
                    />        
                  </div>        
                </div>
                

                <!-- DEPTH -->
                  <div class="row q-ma-xs items-center justify-center text-black text-left text-body-scalize">     
                    <div class="col-3 q-mr-xl text-left">
                      <span class="item_key">Depth (cm): </span>
                    </div> 
                    <div class="col-md-2 " >                  
                      <q-input                                                                
                        v-model="product.parameters.depth.values.Target.value"
                        @update:model-value="val => updateProductDimension(val, 'depth')"
                        dense
                        borderless
                        bg-color="white"                                              
                        :input-style="{
                          'color': '#204fffE6',
                          'font-size': '1.1em',
                          'text-align':'left',
                          'line-height': '1.5em',
                          'letter-spacing': '0.07em',
                          'font-weight': '400'
                        }"
                    />        
                  </div>        
                </div>
                
                                                 
                
                  <!-- Weight-->                
                <div class="row q-ma-xs items-center justify-center text-black text-left text-body-scalize">     
                    <div class="col-3 q-mr-xl text-left">
                      <span class="item_key">Weight (g): </span>
                    </div> 
                    <div class="col-md-2 " >                  
                      <q-input                                                                
                        v-model="product.fixedWeight.values.target.value"                  
                        dense
                        borderless
                        bg-color="white"                      
                        :disable="$store.state.general.selected_product.advancedWeightCalc===true"
                        :input-style="{
                          'color': '#204fffE6',
                          'font-size': '1.1em',
                          'text-align':'left',
                          'line-height': '1.5em',
                          'letter-spacing': '0.07em',
                          'font-weight': '400'
                        }"
                    />        
                  </div>        
                </div>
              
     
           <!-- <div class="row q-ma-lg text-body-scalize">    
             Lock W=D:
             <span style="color:#204fffe6">
             {{$store.state.general.selected_product.calculationParameters.wEqualsD}}
             </span>
           </div>
         
                                          
       -->
  
      </div> <!-- End Product -->

<!-- SHIPPING BOX -->
  
      <div class="col-4" >
        <div class="row justify-center items-center">
        <div class="text-h6">Shipper Tolerances</div>
        </div>
        <!-- Second row -->
        <div class="row q-ma-lg justify-center items-center" style="height:200px" >         
                                     
             <!-- <q-section>
               Create new
             </q-section>             -->
          
              <q-img 
                :src = "require(`@/assets/shipping_box/shipper.png`)" 
                id="shipper_image"  
                class="q-ma-md "
                style="min-width: 200px"
                
                 />                                

          </div>

            <q-btn label="change details" @click="$router.push('shippingbox')" outline color="red" style="width:90px" />  


            <!-- thickness carton-->
            <div class="row q-ma-xs q-mt-lg items-center justify-center text-black text-body-scalize text-left">     
                  <div class="col-4 q-mr-xl">
                    <span class="item_key">Thickness Carton:</span>                    
                  </div>
                  <div class="col-2">
                      <q-input                                                                
                      v-model="shipper.materialParameters.thicknessCarton.value"                        
                      dense
                      borderless
                      bg-color="white"                                              
                      :input-style="{
                        'color': '#204fffE6',
                        'font-size': '1.1em',
                        'text-align':'left',
                        'line-height': '1.5em',
                        'letter-spacing': '0.07em',
                        'font-weight': '400'
                      }"
                    />                             
                    </div>                   
                </div>


            <!-- carton layers H-->
            <div class="row q-ma-xs q-mt-md items-center justify-center text-black text-left text-body-scalize">     
                  <div class="col-4 q-mr-xl">
                    <span class="item_key">Carton Layers in H direction:</span>                    
                  </div>
                  <div class="col-2">
                      <q-input                                                                
                      v-model="shipper.materialParameters.cartonWrappingHeight.value"                        
                      dense
                      borderless
                      bg-color="white"                                              
                      :input-style="{
                        'color': '#204fffE6',
                        'font-size': '1.1em',
                        'text-align':'left',
                        'line-height': '1.5em',
                        'letter-spacing': '0.07em',
                        'font-weight': '400'
                      }"
                    />                             
                    </div>                   
              </div>


             <!-- carton layers W -->
            <div class="row q-ma-xs q-mt-md items-center justify-center text-black text-left text-body-scalize">     
                  <div class="col-4 q-mr-xl">
                    <span class="item_key">Carton Layers in W direction:</span>                    
                  </div>
                  <div class="col-2">
                      <q-input                                                                
                      v-model="shipper.materialParameters.cartonWrappingWidth.value"                        
                      dense
                      borderless
                      bg-color="white"                                              
                      :input-style="{
                        'color': '#204fffE6',
                        'font-size': '1.1em',
                        'text-align':'left',
                        'line-height': '1.5em',
                        'letter-spacing': '0.07em',
                        'font-weight': '400'
                      }"
                    />                             
                    </div>                   
              </div>

               <!-- carton layers D -->
            <div class="row q-ma-xs q-mt-md items-center justify-center text-black text-left text-body-scalize">     
                  <div class="col-4 q-mr-xl">
                    <span class="item_key">Carton Layers in D direction:</span>                    
                  </div>
                  <div class="col-2">
                      <q-input                                                                
                      v-model="shipper.materialParameters.cartonWrappingDepth.value"                        
                      dense
                      borderless
                      bg-color="white"                                              
                      :input-style="{
                        'color': '#204fffE6',
                        'font-size': '1.1em',
                        'text-align':'left',
                        'line-height': '1.5em',
                        'letter-spacing': '0.07em',
                        'font-weight': '400'
                      }"
                    />                             
                    </div>                   
              </div>
            
                
  
      </div> <!-- End Shipping Box -->

      

    <!-- Row Pallet and shelf -->
      
      <!-- Pallet Tolerances  -->

      <div class="col-4" >
        <div class="row justify-center items-center">
        <div class="text-h6">Pallet Tolerances</div>
        </div>
        <!-- Second row -->
        <div class="row q-ma-lg justify-center items-center" style="height:200px" >         
                                     
             <!-- <q-section>
               Create new
             </q-section>             -->
          
              <q-img 
                :src = "require(`@/assets/pallet/pallet.png`)" 
                id="pallet_image"  
                class="q-ma-md "
                
                 />                                

          </div>
          <q-btn label="change details" @click="$router.push('pallet')" outline color="red" style="width:90px" />  
          
                      
              <div class="row q-ma-xs q-mt-lg items-center justify-center text-black text-body-scalize">     
                  <div class="col-auto q-mr-xl">
                    <span class="item_key">Height (cm):</span>                    
                  </div>
                  <div class="col-2">
                      <q-input                                                                
                      v-model="pallet.parameters.basic.height.value"                        
                      dense
                      borderless
                      bg-color="white"                                              
                      :input-style="{
                        'color': '#204fffE6',
                        'font-size': '1.1em',
                        'text-align':'left',
                        'line-height': '1.5em',
                        'letter-spacing': '0.07em',
                        'font-weight': '400'
                      }"
                    />                             
                    </div>                   
                </div>

                <div class="row q-ma-xs items-center justify-center text-black text-body-scalize">     
                  <div class="col-auto q-mr-xl">
                    <span class="item_key">Width (cm):</span>                    
                  </div>
                  <div class="col-2">
                      <q-input                                                                
                      v-model="pallet.parameters.basic.width.value"                        
                      dense
                      borderless
                      bg-color="white"                                              
                      :input-style="{
                        'color': '#204fffE6',
                        'font-size': '1.1em',
                        'text-align':'left',
                        'line-height': '1.5em',
                        'letter-spacing': '0.07em',
                        'font-weight': '400'
                      }"
                    />                             
                    </div>                   
                </div>

                 <div class="row q-ma-xs items-center justify-center text-black text-body-scalize">     
                  <div class="col-auto q-mr-xl">
                    <span class="item_key">Depth (cm):</span>                    
                  </div>
                  <div class="col-2">
                      <q-input                                                                
                      v-model="pallet.parameters.basic.depth.value"                        
                      dense
                      borderless
                      bg-color="white"                                              
                      :input-style="{
                        'color': '#204fffE6',
                        'font-size': '1.1em',
                        'text-align':'left',
                        'line-height': '1.5em',
                        'letter-spacing': '0.07em',
                        'font-weight': '400'
                      }"
                    />                             
                    </div>                   
                </div>

                
         
      
  
      </div>

      <div class="row col-12 dev" v-if="env==='dev'">
        <div class="row">
          <h5>Dev Area</h5>
        </div>

        <div class="row">Second Pallet: {{$store.state.general.secondPallet}}</div>

      </div>
  

      <!-- Trade Shelf
        <div class="col-3"  @click="$router.push('trade_shelf')" > 
        <div class="col-3" >
        
        <div class="row justify-center items-center">
        <div class="text-h6">Trade Shelf</div>
        </div>
        
        <div class="row q-ma-lg justify-center items-center" style="height:200px" >         
          <q-tooltip content-class="bg-red" content-style="font-size: 16px"> Will be implemented soon </q-tooltip>
                                     
          
              <q-img 
                :src = "require(`@/assets/trade_shelf/trade_shelf.png`)" 
                id="pallet_image"  
                class="q-ma-md "
                
                 />                                

          </div>

         
      
  
      </div> End of Col for Trade Shelf -->
      
      
      
      </div> <!-- End of Row -->


    
  </q-page>
</template>

<script lang="ts">
import {computed, ref} from 'vue'
import { useMeta } from 'vue-meta'
import {useStore} from 'vuex'


export default {
  name: 'Home',
  setup() {
    const env = computed(() => {return process.env.VUE_APP_ENV})
    const store = useStore()
    useMeta({ title: 'Home Page ' })


    // Paramters show on configuration page
    const productParametersToShow = ['Height', 'Width']    
    const shippingBoxParameters = ['thicknessCarton', 'cartonWrappingHeight', 'cartonWrappingWidth', 'cartonWrappingDepth']
    const palletParameters = ['height', 'width', 'depth']


    // product 
    const productStore = computed(() => {return store.state.general.selected_product})
    const product = ref(productStore)

    // shipper
    const shipperStore = computed(() => {return store.state.general.shippingBox})
    const shipper = ref(shipperStore)


    // pallet
    const palletStore = computed(() => {return store.state.general.selected_pallet})
    const pallet  = ref(palletStore)


     
    //  const filteredProductParameters = computed(() => {return Object.keys(store.state.general.selected_product.parameters).filter(
    //               key => productParametersToShow.includes(key)).reduce(
    //                 (obj, key) => {obj[key] = store.state.general.selected_product.parameters[key]; return obj},{})

    //  })

    const filteredShippingBoxParameters = computed(() => {return Object.keys(store.state.general.shippingBox.materialParameters).filter(
                  key => shippingBoxParameters.includes(key)).reduce(
                    (obj, key) => {obj[key] = store.state.general.shippingBox.materialParameters[key]; return obj},{})
      })

    const filteredPalletParameters = computed(() =>  {return Object.keys(store.state.general.selected_pallet.parameters.basic).filter(
                  key =>  palletParameters.includes(key)).reduce(
                    (obj, key) => {obj[key] = store.state.general.selected_pallet.parameters.basic[key]; return obj},{})

      })


    // Update Product dimension
    const updateProductDimension = (value, dimension) => {
        window.console.log(`updating product width: ${value * 0.01}`)
        const currentProduct = store.state.general.selected_product        
        currentProduct['parameters'][dimension]['values']['Min']['value'] = Math.round(value * (1 - 0.03) * 10)/10
        currentProduct['parameters'][dimension]['values']['Max']['value'] = Math.round(value * (1 + 0.03) * 10)/10
        store.commit('general/updateProduct', currentProduct)        
      }

  


    
    return {env, 
    product,
    shipper,
    pallet,
    productParametersToShow,
    filteredShippingBoxParameters, filteredPalletParameters,
     updateProductDimension
    }
  }
  
  
  }  
</script>


<style scoped lang="scss">
@import "../styles/quasar.sass";

.text-h6 {
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.q-img{
  max-width: 150px;
  max-height: 325px
}

.q-card{
   height: 250px;
   max-width: 200px;
}

.q-item{
  color: blue;
  font-size: 1.2em;
}


.item_value{
 color: $blue;
 
}

.input_value {
  font-size: 1.4em;
}


.item_key{
 color:black;
}

</style>
