
import {computed, ref, watch} from 'vue'
import {useStore} from 'vuex'
import competitorProducts from '@/components/mixins/competitorProducts.js';


export default {
      
     setup() {
                
        const store = useStore()     
        const {fetchCompetitorProducts, removeCompetitorProducts, updateCompetitorProduct} = competitorProducts()          

        const editDialog = ref(false)


        // Get environment 
        const env = computed(() => {
            return process.env.VUE_APP_ENV
            })

        const selected_options = ref([])

        const products = computed (() => { return store.state.competitor.competitorProducts})
        watch( () => store.state.competitor.competitorProducts, function() {                                                        
            window.console.log('competitor Produts changes')
            // Reset selected options
            selected_options.value = []
            return store.state.competitor.competitorProducts
            });

        const columnWidth = "80px"
        const pagination = ref({
            rowsPerPage: 10 // current rows per page being displayed
            })
        
        const columns = [
           
                {
                    name: 'name',
                    required: true,
                    label: 'Product Name',
                    align: 'left',
                    field: 'name',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",
                    headerStyle: `max-width: ${columnWidth}px;`

                },
         
                {
                    name: 'product_height',
                    required: true,
                    label: 'Height',
                    align: 'left',
                    field: 'product_height',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: black; text-align: center",
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },
                {
                    name: 'product_width',
                    required: true,
                    label: 'Width',
                    align: 'left',
                    field: 'product_width',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',                    
                    style: "color: black; text-align: center",
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },

                 {
                    name: 'product_depth',
                    required: true,
                    label: 'Depth',
                    align: 'left',
                    field: 'product_depth',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',             
                    style: "color: black; text-align: center",       
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },

                {
                    name: 'userEmail',
                    required: true,
                    label: 'Added by',
                    align: 'left',
                    field: 'userEmail',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',                    
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },
                {
                    name: 'productID',
                    required: true,
                    label: 'Product ID',
                    align: 'left',
                    field: 'productID',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",
                    headerStyle: `max-width: ${columnWidth}px;`

                },

                


        ]


        // Edit specific product
        const optionToEdit = ref()
        const editProduct = ((productID) => {

            optionToEdit.value = store.getters['competitor/product']({productID: productID})
            editDialog.value = true

            window.console.log(productID)
            window.console.log(optionToEdit.value)

        })

        
       // Delete project
    //    const deleteProjects = () => {
    //        window.console.log(selected_options.value)
    //        selected_options.value.forEach(element => {
    //            window.console.log(element)
    //            deleteProject(element)               
    //        })
    //    }



   
        
        return {
            store,
            env,
            columns,
            products,
            selected_options,
            pagination,
            editDialog,
            fetchCompetitorProducts,
            removeCompetitorProducts,
            updateCompetitorProduct,
            editProduct,
            optionToEdit
                        
 
        }
        
    },
}
