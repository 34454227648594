<template>    
    <div >
    <!-- First dialog page -->
      <!-- First dialog page -->   
    <q-dialog v-model="show" persistent transition-show="scale" transition-hide="scale">
        
        <q-card class="q-mx-lg items-center justify-center" style="width:400px;height:150px" flat bordered>       
        <q-card-section >
          Share project with a teamember                    
            <!-- <q-input style="width:200px" label="User to share project with" v-model="shareToUser" @input="showError=false" /> -->
          
        </q-card-section>        

          <q-card-actions class="text-primary">                      
            <q-btn-dropdown label="Select a user" color="black" outline >
            <q-list>
              <q-item v-for="user in $store.state.user.users.filter(e => e.userID != $store.state.user.userData.userID)" v-bind:key="user.id" 
                @click="shareToUser=user.userEmail"
                clickable 
                v-close-popup
              >
                <q-item-section>
                  {{user.userFirstName}} {{user.userLastName}} 
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>

            <q-btn 
              outline
              color="blue"
              label="Share Project" 
              @click="shareProject()"
              align="left" />
            
            <q-btn 
              color="black"
              outline
              label="Close" 
              @click="$store.commit('dialogs/updateShareProjectDialog', false)"
              align="right" 
              />
          <!-- <q-btn flat label="Details" @click="secondDialog = true; firstDialog = false" /> -->
          
        </q-card-actions>

      </q-card>
    </q-dialog>
    </div>
</template>


<script lang="ts">

import {useStore} from 'vuex'
import {computed, ref} from 'vue'
import { Loading, QSpinnerGears } from 'quasar'


export default {
  name: 'createProject',
  
  components: {

  },

  setup() {
    const store = useStore()

    const categories = computed(() => {return store.state.general.categories})  

    const show = computed(() => { return store.state.dialogs.shareProjectDialog})

    const shareToUser = ref('')
    
    const shareMessage = ref('')

      //* Share project
    const shareProject = async() => {    
      Loading.show({
           delay: 400, // ms
           spinner: QSpinnerGears,
           message: `Sharing project with ${shareToUser.value}`
       })

      const delay = ms => new Promise(res => setTimeout(res, ms));
      
      window.console.log('sharing project');

      const currentdate = new Date();
      const current_time =  currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " @ "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
      
      // Form data for post request to share project
      const requestOptions = {
          
        method: "POST",
        headers: { "Content-Type": "application/json" },
      
        body: JSON.stringify({ 
          actionType: 'shareProject',
          updated_time: current_time,
          user: store.state.user.userData,
          viewer: {email: shareToUser.value},
          projectID: store.state.general.selected_project['id'],
          projectName: store.state.general.selected_project['name'],                    
          })
          };

                
        const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "updateCosmos" , requestOptions).catch((error) => {
          window.console.log(error)       
          }) ;

              
        const data = await (response as Response).json(); 
        
        
        if (data['content'] == "user id was not found"){
          //window.alert('user was not found in database - please verfiy email adress')
          shareMessage.value = "User was not found in database - please verfiy email adress"

        }else{
          shareMessage.value = "Project Successfully shared"
                 
        }
          // Hide loading
          Loading.hide();      
          
          store.commit('dialogs/updateMessage', shareMessage.value)
          store.commit('dialogs/updateDialog', true)

              


        
          
      }

    return {
      categories,
      show,
      shareToUser,
      shareProject
    }

  },
  
}


</script>


<style scoped>
h5{
  margin-bottom: 20px;
}
</style>