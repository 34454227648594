
import {useStore} from 'vuex'
import {ref, computed, onMounted} from 'vue'
//import drawPalletFunctions from '@/components/mixins/drawPallet.ts'
import palletScheme from '@/components/mass_analysis/palletScheme2D.vue'
import exportData from '@/components/mixins/exportData.js'

export default {
    components: {
        'pallet-scheme': palletScheme
    },

    setup() {

        const store = useStore()
        const env = computed(() => {return process.env.VUE_APP_ENV})
        //const {drawPallet} = drawPalletFunctions()
        const {exportExcel} = exportData()

        const cardHeight = computed(() => {return window.innerHeight/2.5})        
        const cardWidth = computed(() => {return window.innerWidth/3})

        
        const selectedOption = ref()

        
        const pagination = ref(5)
        const columns = [ 
                 {
                    name: 'Article',
                    required: true,
                    label: 'Article',
                    align: 'left',
                    field: row => row.article,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",                                      
                },
                {
                    name: 'boxes_per_layer',
                    required: true,
                    label: 'Boxes per Layer',
                    align: 'center',
                    field: row => row.total_boxes_per_layer,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",   
                    sortable: true,                                   
                },
                {
                    name: 'total_products',
                    required: true,
                    label: 'Products on Pallet',
                    align: 'center',
                    field: row => row.total_products,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",      
                    sortable: true,                                
                },
                {
                    name: 'delta',
                    required: true,
                    label: 'Delta Products on Pallet',
                    align: 'center',
                    field: row => row.delta,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",    
                    sortable: true,                                  
                },

                {
                    name: 'block_pattern',
                    required: true,
                    label: 'Block Pattern',
                    align: 'center',
                    field: row => row.block,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",   
                    sortable: true,                                   
                },
                
                
                ]
        
        const data = computed(() => store.state.massAnalysis.results)



        // Draw pallet scheme
        const drawOption = (data) => {
            selectedOption.value = data
            window.console.log("drawing option")
            window.console.log(data.pallet)
            store.commit('dialogs/updateMassAnalysisResultDialog', true)
            

        }

        const closeDialog = () => {
            store.commit('dialogs/updateMassAnalysisResultDialog', false)
        }
        
        
             

        return {
            env,
            columns,
            data,
            drawOption,
            closeDialog,
            exportExcel,
            pagination,     
            selectedOption,
            cardHeight,
            cardWidth
        }
}

}


