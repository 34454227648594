
import {computed} from 'vue'
import {useStore} from 'vuex'

export default {
  name: 'errorDialog',
  //  props: {   
  //     message: String,
  //     show: Boolean
  // },


  setup() {    
    const store = useStore()

    
    const dialogShow = computed(() => {      
        return store.state.dialogs.loginErrorDialog
        }
      
      )

      const message = computed(() => {      
        return store.state.dialogs.message
        }
      
      )

       
      const myMSALObj = computed(() => {return store.state.user.mySAML})


      const signOut = () => {
        // Choose which account to logout from.    
        const logoutRequest = {
          account: myMSALObj.value.getAccountByHomeId()
        };
    
      myMSALObj.value.logout(logoutRequest);

      }


      


    

    const close = () => {
       store.commit('dialogs/updateLoginErrorDialog', {
        show: false        
      })

    }
    return {
      close,
      store,
      dialogShow,
      message,
      signOut
    }

  },

 


}


