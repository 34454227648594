

import pallet from '@/components/home/pallet.vue'; // @ is an alias to /src


export default {
  name: 'ViewPallet',
  components: {    
    pallet

  },
};
