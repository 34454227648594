
import product from '@/components/home/product.vue'; // @ is an alias to /src

export default {
  name: 'ProductView',
  components: {    
    product

  },
};
