import store from ".."




export default {
    namespaced: true,
    
    //*    ----------------------------- STATE -------------------------------------------------------- * //
    state: {
      
      // --------------- Standard Product -------------------------
      product: {
        id: "CustomProduct",
        image: "",
        advancedWeightCalc: false,
        fixedWeight:  {
          values: {
            target: {value: 100, format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders"}              
            },
          name: "Weight (g)", comment: "Weight of product"
        },
        parameters: {
          height: {
            values: {
              Target: {value: 18.7, format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders"},
              Min: {value: 18.0, format: "text-body-scalize-config-items q-px-sm bg-deep-orange text-white rounded-borders"},
              Max: {value: 19.0, format: "text-body-scalize-config-items q-px-sm bg-deep-orange text-white rounded-borders"},
            }, 
          name: "Height (cm)", comment: "Minimum # of Products"
        },

          width:  {
            values: {
              Target: {value: 7.5, format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders"},
              Min: {value: 7.0, format: "text-body-scalize-config-items q-px-sm bg-deep-orange text-white rounded-borders"},
              Max: {value: 8.0, format: "text-body-scalize-config-items q-px-sm bg-deep-orange text-white rounded-borders"}, 
              }, 
            name: "Width (cm)", comment: "Minimum # of Products"},
          
          depth:  {
            values: {
              Target: {value: 3.5, format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders"},
              Min: {value: 3.0, format: "text-body-scalize-config-items  q-px-sm bg-deep-orange text-white rounded-borders"},
              Max: {value: 3.8, format: "text-body-scalize-config-items  q-px-sm bg-deep-orange text-white rounded-borders"},
              },
            name: "Depth (cm)", comment: "Minimum # of Products"},
                        
          
          // volume: {
          //   values: {Target: {value: 250, format: "q-px-sm bg-blue text-white rounded-borders"}},
          //    name: "Declared Volume (ml)", comment: "Minimum # of Products", format: "q-px-sm bg-black text-white rounded-borders"
          //   },
      },

        calculationParameters: {
          wEqualsD: false
        },

        volumeParameters: {

          brimfulVolume: {
            values: {
              target: {name: "Target", value: 265, format: "text-body-scalize-small"},
              headspace: {name: "Headspace required", value: 5.0, format: "text-body-scalize-small"}              
            },
            name: "Brimful Volume (ml)", comment: "tbd"
          },
          
          availableVolume: {
            values: {              
              fillingHead: {name: "Filling head deviation +/-", value: 5.0, format: "text-body-scalize-small"}              
            },
            name: "Available Volume (ml)", comment: "tbd"
          },

          maxFillVolume: {
            values: {              
              overfill: {name: "Overfill for Shelf Life", value: 5.0, format: "text-body-scalize-small"}              
            },
            name: "Max. Fill Volume (ml)", comment: "tbd"
          },
          
          maxDeclaredVolume: {
            values: {              
            },
            name: "Max. Declared Volume (ml)", comment: "tbd"
          },

          contentWeight: {
            values: {
              density: {name: "Density (g/ml)", value: 1.0, format: "text-body-scalize-small"},
              
            },
            name: "Content weight (g)", comment: "tbd"
          },
        },

        componentsParameters: {
          packagingStandard: {
            name: "Packaging Standard", format: "", container: "", closure: "", brand: ""
          },
          containerMaterials: [],
          closureMaterials: [],
          decorationMaterials: [],
          packagingComposition: {
            recyclability: 97,
            reused: 0,
            compostable: "no", // %            
            circularityScore: 0.1,            
          },
          
        }
    }, // End of standard product

      

    // --------------- Product Sensitivity Parameters -------------------------
      product_sensitivity: {
        parameters: {
          height: {
            min: 0,
            max: 0,
            ref: 0,
            step: 0
          },
          width: {
            min: 0,
            max: 0,
            ref: 0,
            step: 0
          },
          depth: {
            min: 0,
            max: 0,
            ref: 0,
            step: 0
          }
          

        }

      },

      all_reference_products: [],
      selected_reference_products: [],

  //* --------------------------- Shipping Box --------------------------------------------
      shippingBox: {  
        id: "standard",
        parameters: {                   
          stackingLayer: {
            name: "Product Layers",
            value: 1,
            comment: "",
            format: "q-px-sm bg-black text-white rounded-borders text-body-scalize-config-items"
          
          },
          minNumberProducts: {
            name: "min Products",
            value: 4,
            comment: "Minimum # of Products",
            format: "q-px-sm bg-black text-white rounded-borders text-body-scalize-config-items"
          },
          maxNumberProducts: {
            name: "max Products",
            value: 16,
            comment: "Maximum # of Products",
            format: "q-px-sm bg-black text-white rounded-borders text-body-scalize-config-items"
          },
          airHeight: {
            name: "Air Height (cm)",
            value: 0,
            comment: "",
            format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
          },
          airDepth: {
            name: "Air Depth (cm)",
            value: 0,
            comment: "is multiplied by 2",
            format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
          },
          airWidth: {
            name: "Air Width (cm)",
            value: 0,
            comment: "is multiplied by 2",
            format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
          },
          
          gapBetweenShrinks: {
            name: "Gap between shrinks (cm)",
            value: 0.0,
            comment: "",
            format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
          },          
          
          shrinksPlasticWidth: {
            name: "Plastic around shrinks width (cm)",
            value: 0,
            comment: "is multiplied by 2",
            format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
          },

          shrinksPlasticDepth: {
            name: "Plastic around shrinks depth (cm)",
            value: 0,
            comment: "is multiplied by 2",
            format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
          },

          shrinksMinNumberProducts: {
            name: "Min products in shrink",
            value: 2,
            comment: "",
            format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
          },

          shrinksMaxNumberProducts: {
            name: "Max products in shrink",
            value: 10,
            comment: "",
            format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
          },

          
        },

        materialParameters: {
          
          thicknessCarton: {
            name: "Thickness Carton",
            value: 0.3,
            comment: "",
            format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
          },
          cartonWrappingHeight: {
            name: "Carton Layers in H direction",
            value: 2,
            comment: "",
            format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
          },
          cartonWrappingWidth: {
            name: "Carton Layers in W direction",
            value: 2,
            format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
          },
          cartonWrappingDepth: {
            name: "Carton Layers in D direction",
            value: 2,
            comment: "",            
            format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
          },
          cartonWeight: {
            name: "Carton weight (gsm)",
            value: 300.00,
            comment: "",
            format: "q-px-sm text-body-scalize-config-items",
            formatInput: {"color": "blue"}
          }
          
          // stackingLayer: 1,    
          // toleranceBoxOpening: 0.2,
          // toleranceBoxSide: 0.2,
          // cmThicknessShippingBoxSides: 0.2,
          // cmThicknessShippingBoxBottomTop: 0.2,
          // cmSpaceRowTubes: 0.1                  
        },

        fixedBoxParameters: {
          
          outerHeight: {
            name: "Outer Height (cm)",
            value: 21.1,
            comment: "",
            format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
          },

          innerHeight: {
            name: "Inner Height (cm)",
            value: 19.85,
            comment: "",
            format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
          },

          outerDepth: {
            name: "Outer Depth (cm)",
            value: 40.0,
            comment: "",
            format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
          },

          innerDepth: {
            name: "Inner Depth (cm)",
            value: 36.7,
            comment: "",
            format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
          },

          outerWidth: {
            name: "Outer Width (cm)",
            value: 60.0,
            comment: "",
            format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
          },

          innerWidth: {
            name: "Inner Width (cm)",
            value: 56.7,
            comment: "",
            format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
          },

          boxWeight: {
            name: "Box Weight (kg)",
            value: 2.7,
            comment: "",
            format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
          }
      },

        optimizationParameters: {
          wEqualsD: false,
          fixedBox: false,
          fixedBoxTwoBlock: false
        },
        
        limitParameters: {
          weight: {
            name: "Weight limit (kg)",
            value: 15.00,
            comment: "",
            format: "q-px-sm ounded-borders text-body-scalize-config-items"
          }
        }
      },
      
  //* ------------------------- CCG 1 -- Pallet
      pallet_ccg1: {
        id: "standard",
        parameters: {
          basic: {
            height: {
              name: "Height (cm)",
              value: 90.00, 
              comment: "Height of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
            },
            width: {
              name: "Width (cm)",
              value: 80.30,
              comment: "Width of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
          },

          depth: {
              name: "Depth (cm)",
              value: 120.30,
              comment: "Depth of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
          },

          pallet_base_height: {
            name: "Base Height (cm)",
            value: 15.00,
            comment: "",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_base_weight: {
            name: "Base Weight (kg)",
            value: 25.00,
            comment: "Weight of pallets without producs",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_weight_limit: {
            name: "Total Limit (kg)",
            value: 5000,
            comment: "Weight limit pallet",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_weight_layer_limit: {
            name: "Layer Limit (kg)",
            value: 2000,
            comment: "Weight limit layer",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          }, // End of base parameter

          slip: {
            slipLayerHeight: {
              name: "Slip layer height",
              value: 0,
              comment: "Slip layer height",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "number"
              },
            
              slipLayerBottom: {
              name: "Slip layer bottom",
              value: false,
              comment: "Slip layer bottom",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "boolean"
              },
            
              slipLayerTop: {
                name: "Slip layer top",
                value: false,
                comment: "Slip layer bottom",
                format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
                type: "boolean"
                },

            slipLayerBetween: {
              name: "Slip layer between x rows",
              value: 0,
              comment: "Slip layer between layers",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "number"
            }          
          },

          // Transport Parameters
          transport: {
            height: {
              name: "Transport Internal Height (cm)",
              value: 230.00, 
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
            },
            depth: {
              name: "Transport Interal Depth (cm)",
              value: 250.00,
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
            },

          width: {
              name: "Transport Internal Depth (cm)",
              value: 600.00,
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
          },

          weight_limit: {
            name: "Transport Limit (kg)",
            value: 40000.00,
            comment: "",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },          

          }, // End of transport parameter

          optimization: {
              minBlock: 2,
              maxBlock: 5,
              minPercentageBlock: 10 // This sets thin min percentage for each block in case two block is chosen
            }
        }
      }, //* End of standard pallet



    //* --––---------- Pallet CCG2 ------------------------------------------------
       pallet_ccg2: {
        id: "standard",
        parameters: {
          basic: {
            height: {
              name: "Height (cm)",
              value: 180.00, 
              comment: "Height of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
            },
            width: {
              name: "Width (cm)",
              value: 80.30,
              comment: "Width of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
          },

          depth: {
              name: "Depth (cm)",
              value: 120.30,
              comment: "Depth of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
          },

          pallet_base_height: {
            name: "Base Height (cm)",
            value: 15.00,
            comment: "",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_base_weight: {
            name: "Base Weight (kg)",
            value: 25.00,
            comment: "Weight of pallets without producs",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_weight_limit: {
            name: "Total Limit (kg)",
            value: 5000,
            comment: "Weight limit pallet",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_weight_layer_limit: {
            name: "Layer Limit (kg)",
            value: 2000,
            comment: "Weight limit layer",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          }, // End of base parameter

          slip: {
            slipLayerHeight: {
              name: "Slip layer height",
              value: 0,
              comment: "Slip layer height",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "number"
              },
            
              slipLayerBottom: {
              name: "Slip layer bottom",
              value: false,
              comment: "Slip layer bottom",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "boolean"
              },
            
              slipLayerTop: {
                name: "Slip layer top",
                value: false,
                comment: "Slip layer bottom",
                format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
                type: "boolean"
                },

            slipLayerBetween: {
              name: "Slip layer between x rows",
              value: 0,
              comment: "Slip layer between layers",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "number"
            }          
          },

          // Transport Parameters
          transport: {
            height: {
              name: "Transport Internal Height (cm)",
              value: 230.00, 
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
            },
            depth: {
              name: "Transport Interal Depth (cm)",
              value: 250.00,
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
            },

          width: {
              name: "Transport Internal Depth (cm)",
              value: 600.00,
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
          },

          weight_limit: {
            name: "Transport Limit (kg)",
            value: 20000.00,
            comment: "",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },          

          }, // End of transport parameter

          optimization: {
              minBlock: 2,
              maxBlock: 5,
              minPercentageBlock: 10 // This sets thin min percentage for each block in case two block is chosen
            }
        }
      }, //* End of pallet CCG2


      //* --––---------- Pallet EUL1
      pallet_eul1: {
        id: "standard",
        parameters: {
          basic: {
            height: {
              name: "Height (cm)",
              value: 105.00, 
              comment: "Height of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
            },
            width: {
              name: "Width (cm)",
              value: 80.30,
              comment: "Width of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
          },

          depth: {
              name: "Depth (cm)",
              value: 120.30,
              comment: "Depth of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
          },

          pallet_base_height: {
            name: "Base Height (cm)",
            value: 15.00,
            comment: "",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_base_weight: {
            name: "Base Weight (kg)",
            value: 25.00,
            comment: "Weight of pallets without producs",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_weight_limit: {
            name: "Total Limit (kg)",
            value: 5000,
            comment: "Weight limit pallet",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_weight_layer_limit: {
            name: "Layer Limit (kg)",
            value: 2000,
            comment: "Weight limit layer",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          }, // End of base parameter

          slip: {
            slipLayerHeight: {
              name: "Slip layer height",
              value: 0,
              comment: "Slip layer height",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "number"
              },
            
              slipLayerBottom: {
              name: "Slip layer bottom",
              value: false,
              comment: "Slip layer bottom",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "boolean"
              },
            
              slipLayerTop: {
                name: "Slip layer top",
                value: false,
                comment: "Slip layer bottom",
                format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
                type: "boolean"
                },

            slipLayerBetween: {
              name: "Slip layer between x rows",
              value: 0,
              comment: "Slip layer between layers",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "number"
            }          
          },

          // Transport Parameters
          transport: {
            height: {
              name: "Transport Internal Height (cm)",
              value: 230.00, 
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
            },
            depth: {
              name: "Transport Interal Depth (cm)",
              value: 250.00,
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
            },

          width: {
              name: "Transport Internal Depth (cm)",
              value: 600.00,
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
          },

          weight_limit: {
            name: "Transport Limit (kg)",
            value: 20000.00,
            comment: "",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },          

          }, // End of transport parameter

          optimization: {
              minBlock: 2,
              maxBlock: 5,
              minPercentageBlock: 10 // This sets thin min percentage for each block in case two block is chosen
            }
        }
      }, //* End of EUL 1 pallet


    //* --––---------- Pallet EUL 2 ------------------------------
      pallet_eul2: {
        id: "standard",
        parameters: {
          basic: {
            height: {
              name: "Height (cm)",
              value: 225.00, 
              comment: "Height of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
            },
            width: {
              name: "Width (cm)",
              value: 80.30,
              comment: "Width of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
          },

          depth: {
              name: "Depth (cm)",
              value: 120.30,
              comment: "Depth of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
          },

          pallet_base_height: {
            name: "Base Height (cm)",
            value: 15.00,
            comment: "",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_base_weight: {
            name: "Base Weight (kg)",
            value: 25.00,
            comment: "Weight of pallets without producs",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_weight_limit: {
            name: "Total Limit (kg)",
            value: 5000,
            comment: "Weight limit pallet",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_weight_layer_limit: {
            name: "Layer Limit (kg)",
            value: 2000,
            comment: "Weight limit layer",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          }, // End of base parameter

          slip: {
            slipLayerHeight: {
              name: "Slip layer height",
              value: 0,
              comment: "Slip layer height",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "number"
              },
            
              slipLayerBottom: {
              name: "Slip layer bottom",
              value: false,
              comment: "Slip layer bottom",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "boolean"
              },
            
              slipLayerTop: {
                name: "Slip layer top",
                value: false,
                comment: "Slip layer bottom",
                format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
                type: "boolean"
                },

            slipLayerBetween: {
              name: "Slip layer between x rows",
              value: 0,
              comment: "Slip layer between layers",
              format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
              type: "number"
            }          
          },

          // Transport Parameters
          transport: {
            height: {
              name: "Transport Internal Height (cm)",
              value: 230.00, 
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
            },
            depth: {
              name: "Transport Interal Depth (cm)",
              value: 250.00,
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
            },

          width: {
              name: "Transport Internal Depth (cm)",
              value: 600.00,
              comment: "",
              format: "text-body-scalize-config-items q-px-xs text-black",
              textColor: "blue",
              type: "number",
              mask: "#.##"
          },

          weight_limit: {
            name: "Transport Limit (kg)",
            value: 40000.00,
            comment: "",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },          

          }, // End of transport parameter

          optimization: {
              minBlock: 2,
              maxBlock: 5,
              minPercentageBlock: 10 // This sets thin min percentage for each block in case two block is chosen
            }
        }
      }, //* End of EUL 2 pallet
      
      // Selected Options from full analysis
      calculationParameters: {
        thresholdWidthDepthRatioShippingbox: 90,
        layerEfficiency: 70,
        layerSubstraction: false,
        ignoreWeightPallet: true
  },

    }
}
