



interface innerBox {
    xPosition: number
    zPosition: number
    width: number
    depth: number
    height: number
    type: string
    
}

export default function() {

    // Draw standard shipping box
    const drawShippingBox = (canvas, option) => {

        window.console.log('drawing shipping box')

        // Get canvas        
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;            
        window.console.log('canvasheight:' + canvas.height )
        window.console.log('canvaswidth:' + canvas.width )
        
        
        // wipes the canvas context
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Get selected option                        
        const input =  option
    
        //const input = this.$store.state.general.data.filter(row => row.id == this.selected_option)[0]

        // Get shipping box
        const shipping_box = input['shipping_box']

        // scale shipping box
        const scaling = canvas.height / (Math.sqrt(Math.pow(shipping_box['depth'], 2) + Math.pow(shipping_box['width'], 2)) 
            + shipping_box['height']) * 0.9

        window.console.log('Scaling: '+ scaling)

        // add to width and height
        const factor_width = Math.sqrt(Math.pow(shipping_box['depth'], 2) / 2) * scaling
        const factor_height = Math.sqrt(Math.pow(shipping_box['depth'], 2) / 2) * scaling

        window.console.log("factor_width: " + factor_width)

        // Add Edges for canvas
        
        // Calculate correct canvas width and height


        // Get drawing context

        // Shipping box measures
        const left = 0
        const width = shipping_box['width'] * scaling
        const right = left + width      
        const bottom = canvas.height
        const height = -shipping_box['height'] * scaling 


        window.console.log('height:' + height )
        window.console.log('bottom:' + bottom )
                    
        //ctx!.arc(95, 50, 40, 0, 2 * Math.PI);

        ctx!.beginPath();                
        ctx.setLineDash([]);
        ctx.strokeStyle = '#204FFF';    

        // Front rectanlge
        ctx.rect(left, bottom, width, height);

        // back rectangle
        ctx.rect(left + factor_width, bottom - factor_height, width, height);

        // Bottom line left
        ctx.moveTo(left, bottom);
        ctx.lineTo(left + factor_width, bottom - factor_height);

        // Bottom line right
        ctx.moveTo(left + width, bottom);
        ctx.lineTo(left + width + factor_width, bottom - factor_height );


        // Top line left
        ctx.moveTo(left, bottom + height );
        ctx.lineTo(left + factor_width, bottom + height - factor_height);

        // Top line right
        ctx.moveTo(left + width, bottom + height );
        ctx.lineTo(left + width + factor_width, bottom + height - factor_height);

        // Create ligns
        ctx!.stroke();


        // ------------------- Draw prodcuts -----------------------------------

        // get product
        const product = input['product']
        

        // add to width and height
        let product_width = product['width'] * scaling
        let product_depth = product['depth'] * scaling

        // Correct for orientation of product
        if (shipping_box['product_orientation'] === 'side'){
            product_width = product['depth'] * scaling
            product_depth = product['width'] * scaling 
        }
    
        
        
        const product_height = product['height'] * scaling
        
        // const product_row_distance = this.shipping_box['cm_space_row_tubes'] * scaling
        // const product_side_distance = this.shipping_box['air_width'] * scaling

        const factor_product_width = Math.sqrt(Math.pow(product_depth, 2) / 2) 
        const factor_product_height = Math.sqrt(Math.pow(product_depth, 2) / 2) 
        

        const init_x = left + (
            shipping_box['air_width'] + 
            shipping_box['layer_carton_wrapping_width'] * shipping_box['thickness_carton'] + 
            Math.sqrt(Math.pow(shipping_box['air_depth'] + shipping_box['layer_carton_wrapping_depth'] * shipping_box['thickness_carton'], 2)/2)
            ) * scaling;

        const init_y = bottom - (product['height'] + shipping_box['air_height'] + Math.sqrt(Math.pow(shipping_box['air_depth'],2)/2)) * scaling;
        
        let running_x = init_x
        let running_y = init_y
        

        // Iterate over rows with

        // Iterate over layers
        for (let l = 0; l < shipping_box['number_layers']; l++) {
            window.console.log('---------------------')
            window.console.log(`Layer: ${l}`)
            window.console.log(`running_y: ${running_y}`)
            

            for (let i = 0; i < shipping_box['units_per_shrink']; i++) {                                                                      
                let x = running_x
                const y = running_y
                // Iterate over rows depth
                for(let n = 0; n < shipping_box['number_shrinks']/shipping_box['number_layers']; n++){        
                    drawProduct(
                        ctx,
                        x,
                        y,                                
                        product_width,
                        product_depth,
                        product_height,
                        0,
                        0
                    )
                //y += this.product['height'] * 0.5
                x += product_width + (shipping_box['gap_between_shrinks'] + shipping_box['shrinks_plastic_width'] * 2) * scaling
                
                }

                //init_x += factor_product_width
                running_x += factor_product_width 
                running_y += - factor_product_height
            }

            running_y = init_y - product_height * (l +1 )
            window.console.log(`running_y: ${running_y}`)
            
            running_x = init_x
        }

    }




    //* ---------------- Draw Smart Box ----------------
    const drawSmartBox = (canvas, option) => {

        window.console.log('drawing smart box box')

        // Get canvas
        
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;            
        
        // wipes the canvas context
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Get selected option                        
        const input =  option
        window.console.log('input:' + input)
        //const input = this.$store.state.general.data.filter(row => row.id == this.selected_option)[0]

        // Get shipping box
        const shipping_box = input['shipping_box']

                // scale shipping box
        const scaling = canvas.height / (
            Math.sqrt(Math.pow(shipping_box['depth'], 2) + Math.pow(shipping_box['width'], 2)) + shipping_box['height']
            ) * 0.9

        
        // add to width and height
        const factor_width = Math.sqrt(Math.pow(shipping_box['depth'], 2) / 2) * scaling
        const factor_height = Math.sqrt(Math.pow(shipping_box['depth'], 2) / 2) * scaling
                        
        // Shipping box measures
        const left = 0
        const width = shipping_box['width'] * scaling            
        const bottom = canvas.height
        const height = -shipping_box['height'] * scaling           
                    
        //ctx!.arc(95, 50, 40, 0, 2 * Math.PI);

        ctx!.beginPath();                
        ctx.setLineDash([]);
        ctx.strokeStyle = '#204FFF';    

        // Front rectanlge
        ctx.rect(left, bottom, width, height);

        // back rectangle
        ctx.rect(left + factor_width, bottom - factor_height, width, height);

        // Bottom line left
        ctx.moveTo(left, bottom);
        ctx.lineTo(left + factor_width, bottom - factor_height);

        // Bottom line right
        ctx.moveTo(left + width, bottom);
        ctx.lineTo(left + width + factor_width, bottom - factor_height );


        // Top line left
        ctx.moveTo(left, bottom + height );
        ctx.lineTo(left + factor_width, bottom + height - factor_height);

        // Top line right
        ctx.moveTo(left + width, bottom + height );
        ctx.lineTo(left + width + factor_width, bottom + height - factor_height);

        // Create ligns
        ctx!.stroke();


        // Draw shrinks
        window.console.log("-------------------------------")
        window.console.log(shipping_box['layer'])


        let start_height = 0
        let innerBoxHeight = 0
        // Lop over number of layers
        for (let x = 0, len = shipping_box['number_layers']; x < len; x++) {

            window.console.log('X')
            window.console.log(x)

            for (const [key, value] of Object.entries(shipping_box['layer'])) {
                window.console.log('----- layer -----------');
                window.console.log(`${key}: ${value}`);

                const innerBox = value as innerBox

            
                    window.console.log(`innerBox Width: ${innerBox.width}`)            
                    
                    innerBoxHeight = innerBox.height * scaling
                    
                    drawProduct(
                            ctx,
                            left + innerBox.xPosition * scaling + Math.sqrt(Math.pow(innerBox.zPosition * scaling, 2) / 2),
                            bottom - Math.sqrt(Math.pow(innerBox.zPosition * scaling, 2) / 2) - innerBox.height * scaling + start_height,
                            innerBox.width * scaling,
                            innerBox.depth * scaling,
                            innerBox.height * scaling,
                            0,
                            0
                            )
                
                                        
            
            

            }//end of layer loop

            start_height = start_height - innerBoxHeight
        } // end of draw products

    } //* End of Draw Smartbox


    // Function to draw products
    const drawProduct = (ctx, left, bottom, width, depth, height, space_width, space_depth) => {

            // add to width and height
            const factor_width = Math.sqrt(Math.pow(depth, 2) / 2) 
            const factor_height = Math.sqrt(Math.pow(depth, 2) / 2) 

            // window.console.log('left:' + left)
            // window.console.log('bottom:' + bottom)
            // window.console.log('width:' + width)

            ctx.setLineDash([2, 2]);
            ctx.strokeStyle = "#FF4F20"; 
            ctx!.beginPath();                
            ctx.rect(left, bottom, width, height);

            // back rectangle
            ctx.rect(left + factor_width, bottom - factor_height, width, height);
            //ctx.rect(z-scaling_width, y-scaling_height, z-scaling_width+width, y+scaling_height*height);

            // Bottom line left
            ctx.moveTo(left, bottom);
            ctx.lineTo(left + factor_width, bottom - factor_height);

            // Bottom line right
            ctx.moveTo(left + width, bottom);
            ctx.lineTo(left + width + factor_width, bottom - factor_height );


            // Top line left
            ctx.moveTo(left, bottom + height );
            ctx.lineTo(left + factor_width, bottom + height - factor_height);

            // Top line right
            ctx.moveTo(left + width, bottom + height );
            ctx.lineTo(left + width + factor_width, bottom + height - factor_height);

            // Create ligns
            ctx!.stroke();        
    }



    return {
        drawProduct,
        drawSmartBox,
        drawShippingBox
      }
}