<template>
<div>
    <q-btn class="q-ma-xs" label="Add competitor product" @click="showConfig=true"
    color="blue" outline />

    <q-dialog v-model="showConfig">
        <q-card style="min-width:200px;">
            
            <q-card-section>
                <div class="text-body-scalize" >
                     Upload a competitor product
                </div>
            </q-card-section>
            
            <q-card-section>
                <div class="row text-body-scalize" >
                    <q-input class="q-ma-xs" label="Name" v-model="product.name" placeholder="Product Name" />
                </div>
                <div class="row text-body-scalize" >
                    <q-input class="q-ma-xs" label="Height" v-model="product.height" />
                </div>
                <div class="row text-body-scalize" >
                    <q-input class="q-ma-xs" label="Width" v-model="product.width" />
                </div>
                <div class="row text-body-scalize" >
                    <q-input class="q-ma-xs" label="Depth" v-model="product.depth" />
                </div>
             </q-card-section>
              <q-card-section>
                <!-- <q-file class="q-ma-xs"  v-model="picture" label="upload an image" @pickFile="uploadImage($event)" /> -->
                <div class="row col-12 q-mx-xs text-body-scalize" >
                    Add a product image
                </div>
                <!-- <input class="q-ma-xs" type="file" accept="image/*" @change="uploadImage" id="file-input"> -->
                <div class="row col-12 text-body-scalize" >
                    <q-btn     
                        class="q-mx-xs"                  
                        size='md' 
                        name="attachment" 
                        @click="$refs['input'].click()" 
                        outline 
                        label='Select an image'
                        color="blue" 
                        > 
                                                                 
                      </q-btn>

                      <!-- invisible Input-->
                        <input
                          ref="input"
                          type="file" 
                          accept="image/*"                       
                          label="Your label here."                      
                          @change="uploadImage" 
                          id="file-input"         
                          style="display:none"             
                          >

                </div>
              
                
            </q-card-section>

            <q-card-section>
                <canvas height="200" ref="uploadCanvas" />
            </q-card-section>

            <q-card-actions>
                   <q-btn class="q-ma-xs" label="Upload" color="blue" @click="showConfig=false; uploadProduct();"/>
                   <q-space />
                    <q-btn class="q-ma-xs" label="close" color="black" @click="showConfig=false"/>
            </q-card-actions>


            
            
            
            <!-- Dev Section -->
            <!-- <q-card-section  v-if="env=='dev'" >             
                <div class="row dev">
                    {{product}}                 
                    {{imageSize}}    
                </div>
            </q-card-section> -->
        </q-card>

    </q-dialog>
</div>
</template>

<script lang="ts">
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import { Loading, QSpinnerGears } from 'quasar'
import competitorProducts from '@/components/mixins/competitorProducts.js'


export default {
     
     
     setup() {
        
        //! vue3
        const store = useStore()         

        // Get environment 
        const env = computed(() => {
            return process.env.VUE_APP_ENV
            })

        const { fetchCompetitorProducts } = competitorProducts()
        
        const showConfig = ref(false)

        const uploadCanvas = ref()

        const product = ref({
            'name': '',
            'height': 0,
            'width': 0,
            'depth': 0,
        })

        const imageSize = ref({
            width: 0,
            height: 0
        })

        const picture = ref(null)


        const test = (() => {
            window.console.log(picture)
        })


        const productImage = ref()

        // * Function to upload image
        const uploadImage = ((event: Event) => {

            window.console.log('uploading image')
            // show laoder
            Loading.show({
            delay: 200, // ms
            spinner: QSpinnerGears,
            message: 'Uploading picture'
        })

            // reset images
            const input: HTMLInputElement = event.target as HTMLInputElement;
            const image = input.files![0];
            
            const reader = new FileReader();
          
            reader.readAsDataURL(image);

            // Define what is happening on loading file
            reader.onload = ((event: Event) => {
                    
                    const img = new Image()
                    //img.src = event.target.result
        
                    img.src = (reader.result as string)                    
                    
                    // Define what is happening on image load
                    img.onload = ((event: Event) => {
                        // Get Canvas
                        const canvas = uploadCanvas.value;   
                        let height;

                        // Set canvas height depending on image width to height ratio
                        if (img.width/img.height > 2){
                            height = 100              
                        }else{
                            height = 200              
                        }
                        // Set canvas width
                        canvas.height = height
                                    
                        canvas.width = img.width/img.height * height
                        
                        var ctx = canvas.getContext("2d");

                        window.console.log('img.width:' + img.width)
                        window.console.log('img.height:' + img.height)
                        window.console.log('img.width/img.height * 200: ' + img.width/img.height * height)
                        
                        // Actual resizing
                        ctx.drawImage(img, 0, 0, img.width/img.height * height, height);                       
                        //ctx.drawImage(img, 0, 0, 200, 200);                       
                        productImage.value = uploadCanvas.value.toDataURL();
                    })
                    Loading.hide(); 
                    
                    window.console.log('original size: ' + (reader.result as string).length)
                    window.console.log('resized size: ' + productImage.value.length)
                    
                
                });      
            
        }) // End uploading image

        // * Function to upload product
        const uploadProduct = async () => {

            const payload = {
                name: product.value.name,
                product_height: product.value.height,
                product_width: product.value.width,
                product_depth: product.value.depth,
                image: productImage.value
            }

            store.commit('competitor/updateCompetitorProducts', payload)
            

        //* ---- Upload to Database            
        window.console.log('adding competitor product')
    
        // Show loading
        Loading.show({
            delay: 400, // ms
            spinner: QSpinnerGears,
            message: 'Fetching your projects'
        })

        let user_email = ''
        let userID
        if(env.value=='dev') {
            user_email = 'jan.mammen@scalize.app'
            userID = 2
        }else{
            user_email = store.state.user.userData.userEmail
            userID = store.state.user.userData.userID
        }
        
        window.console.log('Uploading to Cosmos DB')
        
        
        const url = process.env.VUE_APP_FUNCTION_URI + "updateCosmos"
        window.console.log('url: ' + url)
        
        const requestOptions = {            
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ 
                title: "Request",
                userEmail: user_email,
                userID: userID,
                actionType: 'addCompetitorProduct',
                name: product.value.name,
                product_height: product.value.height,
                product_width: product.value.width,
                product_depth: product.value.depth,
                image: productImage.value
            })
        };

        window.console.log(requestOptions)
        const response = await fetch(url , requestOptions).catch((error) => {
                window.console.log(error)                                        
                // Hide loading
                Loading.hide(); 
                store.commit('dialogs/updateMessage',  'Error Uploading product!')
                store.commit('dialogs/updateDialog', true)             
            }
            
            
            );

        // placeholder for data
        let responseJson;
        

        if ((response as Response).status == 200) {
            window.console.log(response)
            responseJson = await (response as Response).json();
            window.console.log("Response:" + responseJson)     
            
            
            fetchCompetitorProducts()

            // Hide loading
            Loading.hide();

            store.commit('dialogs/updateMessage',  'Product sucessfully uploaded!')
            store.commit('dialogs/updateDialog', true)     

      
      }else{


        fetchCompetitorProducts()
        
        store.commit('dialogs/updateMessage',  'Error uploading!')
        store.commit('dialogs/updateDialog', true)     
        
        Loading.hide();
      }


        }

   
        
        return {
            env,
            showConfig,
            product,
            picture,
            test,
            uploadImage,
            productImage,
            uploadProduct,
            imageSize,
            uploadCanvas
            
 
        }
        
    },
}
</script>


<style scoped lang="scss">

.q-input{
    min-width: 100px
}

</style>
