import { render } from "./subTable.vue?vue&type=template&id=2fc1940a&scoped=true"
import script from "./subTable.vue?vue&type=script&lang=js"
export * from "./subTable.vue?vue&type=script&lang=js"

import "./subTable.vue?vue&type=style&index=0&id=2fc1940a&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-2fc1940a"

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTd,QCheckbox,QBtn,QTooltip,QTh,QInput,QIcon});
