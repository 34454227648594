<template>
  <!-- If user is logged in -->
    <q-btn-dropdown
        label="Select Category"
        class="q-ma-md"
        outline
        color="black"
    >
        <q-list bordered v-close-popup>
        <q-item
            v-for="c in categories"
            :key="c.name"
            clickable
            v-ripple
            :active="link === c.name"
            active-class="highlight"
            @click="
            onCategoryClick(c);
            link = c.name;
            "
        >
            {{ c.name }}
        </q-item>
        </q-list>
    </q-btn-dropdown>

</template>

<script>
import {computed} from 'vue'
import {useStore} from 'vuex'

export default {
  name: "selectCategory",  
  components: {

  },


  setup () {
    const store = useStore()

    const categories = computed(() => {return store.state.general.categories;})

    const onCategoryClick = (category) => {
        window.console.log("category clicked");
        store.commit("general/updateSelectedCategory", category);
    }


    return {
      categories,
      onCategoryClick

    }
  },

  /* ---------------------------------------------------------------------------
#############################     DATA       #################################
--------------------------------------------------------------------------- */
  data() {
    return {
      link: ''
    
    };
  }, // End of data
 

};
</script>

<style lang="scss">

</style>
