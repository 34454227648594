

import {useStore} from 'vuex'
import {computed, ref} from 'vue'
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';


export default {
  name: 'createProject',
  
  components: {

  },

  setup() {
    const store = useStore()

    const categories = computed(() => {return store.state.general.categories})  

    const show = computed(() => { return store.state.dialogs.shareProjectDialog})

    const shareToUser = ref('')
    
    const shareMessage = ref('')

      //* Share project
    const shareProject = async() => {    
      Loading.show({
           delay: 400, // ms
           spinner: QSpinnerGears,
           message: `Sharing project with ${shareToUser.value}`
       })

      const delay = ms => new Promise(res => setTimeout(res, ms));
      
      window.console.log('sharing project');

      const currentdate = new Date();
      const current_time =  currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " @ "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();
      
      // Form data for post request to share project
      const requestOptions = {
          
        method: "POST",
        headers: { "Content-Type": "application/json" },
      
        body: JSON.stringify({ 
          actionType: 'shareProject',
          updated_time: current_time,
          user: store.state.user.userData,
          viewer: {email: shareToUser.value},
          projectID: store.state.general.selected_project['id'],
          projectName: store.state.general.selected_project['name'],                    
          })
          };

                
        const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "updateCosmos" , requestOptions).catch((error) => {
          window.console.log(error)       
          }) ;

              
        const data = await (response as Response).json(); 
        
        
        if (data['content'] == "user id was not found"){
          //window.alert('user was not found in database - please verfiy email adress')
          shareMessage.value = "User was not found in database - please verfiy email adress"

        }else{
          shareMessage.value = "Project Successfully shared"
                 
        }
          // Hide loading
          Loading.hide();      
          
          store.commit('dialogs/updateMessage', shareMessage.value)
          store.commit('dialogs/updateDialog', true)

              


        
          
      }

    return {
      categories,
      show,
      shareToUser,
      shareProject
    }

  },
  
}


