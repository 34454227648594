<template>
<div>
  <q-list>
        <q-item-label class="text-body1 text-grey-7" header>Project Menu</q-item-label>
        <q-item>
          <q-item-section>
            <div class="text-body1 text-grey-7">
              Category:  {{ $store.state.general.selected_category.name }}
            </div>

            <div class="text-body1 text-grey-7" v-if="env=='dev'">
              Projects in category:  {{ $store.state.user.userProjects }}
            </div>
           
          </q-item-section>
        </q-item>
        <q-item class="items-center">
          
            <div class="text-body1 text-grey-7">
              Project:  {{ $store.state.general.selected_project['name']}}     
            </div>                  
                <!-- <q-input class="text-body-scalize" 
                  borderless label="Project Name" 
                  type="text" 
                  v-model="projectName"
                  style="width:215px"
                  >                                            -->                
                <q-btn 
                  class="q-my-none q-ml-md" 
                  size="md" 
                  @click="$store.commit('dialogs/updateEditProjectDialog', true);"   
                  outline color="blue" 
                  icon="edit" />                  
                                                                                
        </q-item>
    
        <!-- <q-item clickable tag="a" target="_blank" href="https://quasar.dev">
          <q-item-section avatar>
            <q-icon name="code" />
          </q-item-section>
          <q-item-section>
            <q-item-label>See Project List</q-item-label>
            <q-item-label caption></q-item-label>
          </q-item-section>
        </q-item> -->


        </q-list>

        <q-list v-if="projectName.length>0">
        
          <q-item>           
            <q-btn            
              label="Configure"              
              outline 
              color="red"    
              @click="$router.push('/')"
              class="q-mr-xs" 
              style="width:120px" />
              <optimize v-if="$route.name=='Home'" />
          </q-item>
  <!--
          <q-item>
            <q-btn label="productDialog" @click="$router.push('product_dialog')" />
          </q-item>

          <q-item>
            <q-btn label="Shipping Box" @click="$router.push('ShippingBox')" />
          </q-item>
          <q-space />
          -->
      
          <q-item>
            <q-btn 
              label="Results" 
              :disabled="resultsButton"               
              outline 
              @click="$router.push('result')" 
              style="width:120px"
              class="q-mr-xs"               
              color="blue"          
              />
            
            <q-btn
              v-if="$route.name=='Result'"
              label="Save" 
              :disabled="resultsButton" 
              color="blue"               
              @click="saveCurrentProject()"              
              class="q-mr-xs"                                       
            />

            

          </q-item>

           <q-item>
            <q-btn 
              label="Decisions" 
              :disabled="resultsButton" 
              color="green" 
              outline 
              style="width:120px"
              @click="$router.push('decisions')" 
               class="q-mr-xs"       
              />
              
              <q-btn
                v-if="$route.name=='Decisions'"
                label="Save" 
                :disabled="resultsButton" 
                color="green"               
                @click="saveCurrentProject()"              
                class="q-mr-xs"                                       
                />
          </q-item>


          <q-item>
            <q-btn                
                label="Share Project"                 
                color="black"               
                outline
                @click="$store.commit('dialogs/updateShareProjectDialog', true)"
                class="q-mr-xs"            
                :disabled="resultsButton"                            
                />                
                <share-project />
          </q-item>    
         
  </q-list>
   

    <!-- choose new project if deleted -->
   
</div>
</template>

<style>

</style>


<script lang="ts">
import {computed, ref, watch} from 'vue'
import {useStore} from 'vuex'

import optimize from '@/components/optimize.vue'; // @ is an alias to /src
import shareProject from '@/components/menu_bar/shareProject.vue'
import userData from '@/components/mixins/userData.js';
import test from '@/components/mixins/test.js';
import saveProject from '@/components/mixins/saveProject.js';


export default {
  name: 'menuBar',   
  components:{
      'optimize': optimize,
      'share-project': shareProject,
      
 
      //'pop-up-menu': popUpMenu
    
  },


  setup() {

    const {getUserData} = userData()
    const {getTestData} = test();
    const {saveCurrentProject} = saveProject()
  
    const store = useStore() 

    const projectName = computed(() => {return store.state.general.selected_project['name']})    
    watch(projectName, (newValue) => {window.console.log('project name chaned')})
   
    // result button      
    const resultsButton = computed(() => {
        if (typeof store.state.general.data === 'undefined'){
          return true
        }else{
            return store.state.general.data.length == 0;
        }

    })
      
            
    
    return {
      store,      
      resultsButton,
      projectName,
      getUserData,
      saveCurrentProject,
      getTestData

    }
  }, //! End of Setup




  // watch: {
  //   results_button(newValue, oldValue){
  //       return newValue;
  //   }
  // }

}
</script>


<style scoped lang="scss">
.q-input{
 
}

.span {
    color: blue;

}

.q-table td, .q-table th {
    /* don't shorten cell contents */
    white-space: normal !important;
    height: 40px;
}

.text-body2{
    height: 10px;
}
</style>