<template>
  <div class="row q-mx-xl">
    <div class="row" style="height:40px">
      <q-btn         
          @click="privacy=!privacy;" 
          label="show/hide privacy statement" 
          color="black"
        />
    </div>

    
    <div class="row" v-if="privacy==true">
        <!-- <q-btn @click="privacy=false;"
          label="hide privacy statement"
          color="black"
        /> -->
      <div v-if="version == 'english'">
        <q-btn
          class="q-my-md"
          label="German Version"
          @click="version = 'german'"
          outline
        />

        <html-template :templateName="templateNameEnglish" :key="1" />
      </div>

      <div v-else>
        <q-btn
          class="q-my-md"
          label="English Version"
          @click="version = 'english'"
          outline
        />

        <html-template :templateName="templateNameGerman" :key="2" />
      </div>
      </div>
  </div>
</template>

<script lang="ts">

import htmlTemplate from "@/components/general_components/htmlTemplate.vue";

export default {
  name: "privacyPage",
  components: {
    "html-template": htmlTemplate,
  },

  data() {
    return {
      version: "english",
      templateNameGerman: "data_privacy_german.html",
      templateNameEnglish: "data_privacy_english.html",
      privacy: false
    };
  },

  mounted() {
    window.console.log("mounting");
  },
};
</script>

<style scoped lang="scss">
h6 {
  margin-bottom: 0px;
}
</style>
