import { render } from "./shippingBox.vue?vue&type=template&id=1416e98b&scoped=true"
import script from "./shippingBox.vue?vue&type=script&lang=ts"
export * from "./shippingBox.vue?vue&type=script&lang=ts"

import "./shippingBox.vue?vue&type=style&index=0&id=1416e98b&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1416e98b"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardActions,QBtnDropdown,QBtn,QInput,QIcon,QList,QItem,QCardSection,QImg,QToggle});qInstall(script, 'directives', {ClosePopup});
