    // --------------- Standard Product -------------------------
    export const productTemplate = {
        id: "CustomProduct",
        image: "",
        advancedWeightCalc: false,
        fixedWeight:  {
          values: {
            target: {value: 200, format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders"}              
            },
          name: "Weight (g)", comment: "Weight of product"}
          ,                                
        parameters: {
          height: {
            values: {
              Target: {value: 18.7, format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders"},
              Min: {value: 18.3, format: "text-body-scalize-config-items q-px-sm bg-deep-orange text-white rounded-borders"},
              Max: {value: 19.0, format: "text-body-scalize-config-items q-px-sm bg-deep-orange text-white rounded-borders"},
            }, 
          name: "Height (cm)", comment: "Minimum # of Products"},

          width:  {
            values: {
              Target: {value: 7.5, format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders"},
              Min: {value: 7.3, format: "text-body-scalize-config-items q-px-sm bg-deep-orange text-white rounded-borders"},
              Max: {value: 7.8, format: "text-body-scalize-config-items q-px-sm bg-deep-orange text-white rounded-borders"}, 
              }, 
            name: "Width (cm)", comment: "Minimum # of Products"},
          
          depth:  {
            values: {
              Target: {value: 3.5, format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders"},
              Min: {value: 3.2, format: "text-body-scalize-config-items  q-px-sm bg-deep-orange text-white rounded-borders"},
              Max: {value: 3.8, format: "text-body-scalize-config-items  q-px-sm bg-deep-orange text-white rounded-borders"},
              },
            name: "Depth (cm)", comment: "Minimum # of Products"},                   
          // volume: {
          //   values: {Target: {value: 250, format: "q-px-sm bg-blue text-white rounded-borders"}},
          //    name: "Declared Volume (ml)", comment: "Minimum # of Products", format: "q-px-sm bg-black text-white rounded-borders"
          //   },
      },

        calculationParameters: {
          wEqualsD: false
        },

        volumeParameters: {

          brimfulVolume: {
            values: {
              target: {name: "Target", value: 265, format: "text-body-scalize-small"},
              headspace: {name: "Headspace required", value: 5.0, format: "text-body-scalize-small"}              
            },
            name: "Brimful Volume (ml)", comment: "tbd"
          },
          
          availableVolume: {
            values: {              
              fillingHead: {name: "Filling head deviation +/-", value: 5.0, format: "text-body-scalize-small"}              
            },
            name: "Available Volume (ml)", comment: "tbd"
          },

          maxFillVolume: {
            values: {              
              overfill: {name: "Overfill for Shelf Life", value: 5.0, format: "text-body-scalize-small"}              
            },
            name: "Max. Fill Volume (ml)", comment: "tbd"
          },
          
          maxDeclaredVolume: {
            values: {              
            },
            name: "Max. Declared Volume (ml)", comment: "tbd"
          },

          contentWeight: {
            values: {
              density: {name: "Density (g/ml)", value: 1.0, format: "text-body-scalize-small"},
              
            },
            name: "Content weight (g)", comment: "tbd"
          },
        },

        componentsParameters: {
          packagingStandard: {
            name: "Packaging Standard", format: "", container: "", closure: "", brand: ""
          },
          containerMaterials: [],
          closureMaterials: [],
          decorationMaterials: [],
          packagingComposition: {
            recyclability: 97,
            reused: 0,
            compostable: "no", // %            
            circularityScore: 0.1,            
          }
          
        }
    }