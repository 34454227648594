export default {
    namespaced: true,
    
    /* ------------------- STATE -------------------------------------------------------- */
    state: {

     
      // Selected Options from full analysis
      selected_options_full: [],
      optionsAnalyze: [],
      decisionOptions: [],


      //! new with nested Table
      selectedOptionsSensitivity: [],    
      selectedOptionsResult: [],

      optionSelectedForReport: null
        
    },
     
    /* ------------------- MUTATIONS -------------------------------------------------------- */
    mutations: {

     // Set selected options Full
     updateSelectedOptionsFull(state, payload: []){
        state.selected_options_full = payload
      },

      updateOptionsAnalyze(state, payload: []){
          window.console.log('updating options to analyse')
        //   Array.prototype.push.apply(state.optionsAnalyze, payload)          

        //   // Filter duplicates
        //   const filteredOptions = state.optionsAnalyze.reduce((acc, current) => {
        //     const x = acc.find(item => item.id === current.id);
        //     if (!x) {
        //       return acc.concat([current]);
        //     } else {
        //       return acc;
        //     }
        //   }, []);
          
        //   state.optionsAnalyze = filteredOptions;
        
        state.optionsAnalyze = payload;          
        window.console.log(state.optionsAnalyze)
      },


      updateDecisionOptions(state, payload: []){
        state.decisionOptions = payload
      },


      updateSelectedOptionsSensitivity(state, payload: []){

        state.selectedOptionsSensitivity[payload['parentOption']] = payload

        // const partA = state.TEST_selectedOptionsFull
        // const intermediate = partA.concat(payload)
        // state.TEST_selectedOptionsFull  = intermediate.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
      },

      // Delete all options form sensitivity
      removeSelectedOptionsSensitivity(state, payload: []){

        state.selectedOptionsSensitivity = payload

        // const partA = state.TEST_selectedOptionsFull
        // const intermediate = partA.concat(payload)
        // state.TEST_selectedOptionsFull  = intermediate.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
      },

      updateSelectedOptionsResult(state, payload: []){

        state.selectedOptionsResult = payload

        // const partA = state.TEST_selectedOptionsFull
        // const intermediate = partA.concat(payload)
        // state.TEST_selectedOptionsFull  = intermediate.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
      },


      updateOptionSelectedForReport(state, payload){
        window.console.log(payload)
        state.optionSelectedForReport = payload
      }
      


    },
    actions: {
    },
    modules: {
    }
  }
  
