

export default {
    namespaced: true,
    
    /* ------------------- STATE -------------------------------------------------------- */
    state: {

        // Dialog showing errors etc
        previewData: [],
        uploadedData: [],
        results: [],
        blockPattern: 3,
        pallet: {
          width: 120,
          depth: 80,
          height: 105,
        }
        

        

    },


    /* ------------------- MUTATIONS -------------------------------------------------------- */
    mutations: {

      updatePreviewData(state, payload){        
        state.previewData = payload        
      },

      updateUploadedData(state, payload){        
        state.uploadedData = payload        
      },

      updateResults(state, payload){
        state.results = payload
      },

      updateBlockPattern(state, payload){
        state.blockPattern = payload
      },

      
    
  

    },
//! ------------------------------- ACTIONS -------------------------------------
    actions: {
    
    },
  


//! -------------------------------- Getters ---------------------------------------------
    getters: {

    }
}
