
import {computed, ref, watch} from 'vue'
import {useStore} from 'vuex'

import optimize from '@/components/optimize.vue'; // @ is an alias to /src
import shareProject from '@/components/menu_bar/shareProject.vue'
import userData from '@/components/mixins/userData.js';
import test from '@/components/mixins/test.js';
import saveProject from '@/components/mixins/saveProject.js';


export default {
  name: 'menuBar',   
  components:{
      'optimize': optimize,
      'share-project': shareProject,
      
 
      //'pop-up-menu': popUpMenu
    
  },


  setup() {

    const {getUserData} = userData()
    const {getTestData} = test();
    const {saveCurrentProject} = saveProject()
  
    const store = useStore() 

    const projectName = computed(() => {return store.state.general.selected_project['name']})    
    watch(projectName, (newValue) => {window.console.log('project name chaned')})
   
    // result button      
    const resultsButton = computed(() => {
        if (typeof store.state.general.data === 'undefined'){
          return true
        }else{
            return store.state.general.data.length == 0;
        }

    })
      
            
    
    return {
      store,      
      resultsButton,
      projectName,
      getUserData,
      saveCurrentProject,
      getTestData

    }
  }, //! End of Setup




  // watch: {
  //   results_button(newValue, oldValue){
  //       return newValue;
  //   }
  // }

}
