<template>

    <q-btn-dropdown        
        label="Select Project"
        class="q-ma-md q-px-xs"
        outline
        color="blue"
    >
        <q-list bordered v-close-popup>
        <q-item
            v-for="p in projects"
            :key="p.name"
            v-ripple
            :active="link === p.name"
            active-class="highlight"
            clickable
            @click="
            onProjectClick(p);
            link = p.name;
            "
        >
            {{ p.name }}
        </q-item>
        </q-list>
    </q-btn-dropdown>
</template>

<script>
import {computed, watch} from 'vue'
import {useStore} from 'vuex'

import loadProject from '@/components/mixins/loadProject.js';
import adminData from '@/components/mixins/adminData.js';


export default {
  name: "selectProject",
  
  
  setup () {

    const env = computed(() => {return process.env.APP_VUE_ENV})

    const store = useStore()
    
    const {loadSelectedProject} = loadProject()


    // Get projects
    const projects = computed(() => {
      window.console.log(store.state.user.userProjects)
      window.console.log(store.state.general.selected_category) 
      return store.state.user.userProjects    
      })
    
     watch( () => store.state.user.userProjects, function() {                                                        
            window.console.log('new projects!!!!!!')
      
            return store.state.user.userProjects 
            });


    // Select a project
    const onProjectClick = (project) => {
      window.console.log("project clicked");
      window.console.log(project);
      window.console.log(store.state.general.selected_category)

      store.commit("general/updateSelectedProject", project);
      window.console.log("updated project");

      // Update product accordingly
      window.console.log(project);

      // Get information for selected project
      // const selected_project = store.state.user.userProjects.filter(
      //   (m) => m.projectID == project.projectID
      // )[0];
      // window.console.log('selected_project');
      // window.console.log(selected_project);

      // fetch project from API
      loadSelectedProject(project['id']);
           
      // Close Pop up menu
                       
    }

  return {
    env,
    projects,
    onProjectClick
  }

  },

  /* ---------------------------------------------------------------------------
#############################     DATA       #################################
--------------------------------------------------------------------------- */
  data() {
    return {
      link:''
      
    };
  }, // End of data



};
</script>

<style lang="scss">

</style>
