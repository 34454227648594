<template>
    <div>
    
    <!-- First dialog page -->
     <q-dialog persistent v-model="firstDialog" transition-show="scale" transition-hide="scale" :position="windowHeight<=1280 ? 'top':'standard'">
     
      
        <q-card :style="{'min-width':'850px', 'max-height':`${windowHeight*0.7}px`}">
        

         <q-card-actions class="items-start text-body-scalize">
          <div class="row q-ma-xs col-12 justify-end">                         
            <div class="col-xs-auto">
            
            </div>
          
            <div class="col-xs-auto">

              <q-btn-dropdown class="text-body-scalize" flat color="green" label="Save" :content-style="{ backgroundColor: black }" >
                <div class="row q-ma-xs">                    
                  <q-btn flat 
                    label="Save Changes" :style="{'color': 'grey'}" 
                    outline v-close-popup 
                    @click="saveCurrentProject();$router.push('/')"
                    />
                </div>
                <div class="row q-ma-xs">                    
                  <q-btn flat label="Save as Template" :style="{'color': 'grey'}" 
                    @click="showTemplateSave=!showTemplateSave"/>
                  
                </div>  

                <!-- Save Template -->
                <div class="row q-ml-md q-my-xs" v-if="showTemplateSave">       
                  <q-input v-model="templateName" placeholder="Enter name" dense class="q-input-small" borderless
                   >
                    <template v-slot:append> 
                      <q-btn class="q-ma-xs" @click="addShippingBoxTemplate(templateName); showTemplateSave=false" outline>                   
                        <q-icon name="save" size="1em" color="green"  />                        
                      </q-btn>
                      <q-btn class="q-ma-xs" @click="showTemplateSave=false" outline>                   
                        <q-icon name="close" size="1em" color="black"  />
                      </q-btn>
                    </template>
                  </q-input>
                  
                </div>
              
              <!-- Load Template -->
                <div class="row q-ma-xs">       
                  <q-btn flat label="Load Template" :style="{'color': 'grey'}" @click="showLoadedTemplates=!showLoadedTemplates" />
                </div>  
                <div class="row q-ma-xs" v-if="showLoadedTemplates">       
                  <q-list >
                    <q-item v-for="i in shippingBoxTemplates" v-bind:key="i.templateID" clickable 
                      @click="loadTemplate(i.templateID, i.name);showLoadedTemplates=!showLoadedTemplates" >
                      {{i.name}}
                    </q-item>
                  </q-list>
                </div>  

              </q-btn-dropdown>
            
            </div>  
              
              <div class="col-xs-auto">
                <q-btn class="q-mx-xs" flat outline color="black" v-close-popup @click="$router.push('/')">
                      <q-icon name="close" />
                  </q-btn>
              </div>                   
          </div>
          
        </q-card-actions>

        <q-card-section>
         <div class="row q-ma-xs" >              
              <div class="text-head-scalize-large"> Shipping Box Tolerances</div>                               
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
  
        <!-- Basic Canvas -->
        <div class="row" >
            
            <div class="col-md-auto q-ma-md">
              <div class="row q-mt-xl" >              
                <q-img      
                    v-if="shippingBox.optimizationParameters.fixedBox===false"                                 
                    :src = "require(`@/assets/shipping_box/carton_shipper.svg`)" 
                    id="product_image" 
                    style="max-width:280px" 
                    /> 
                <q-img      
                    v-else                                 
                    :src = "require(`@/assets/shipping_box/fixed_shipper.svg`)" 
                    id="product_image" 
                    style="max-width:280px" 
                  /> 
              </div>

              <!-- Use fixed Box -->
              <div class="row q-ml-lg q-mt-lg justify-start" >              
                <q-toggle
                  label="Use Fixed Box"
                  v-model="shippingBox.optimizationParameters.fixedBox"
                />
              </div>

              <div class="row q-ml-lg q-mt-xs justify-start" v-if="shippingBox.optimizationParameters.fixedBox===false">
                <q-toggle
                  label="Exclude W=D"
                  v-model="shippingBox.optimizationParameters.wEqualsD"
                />
              </div>

              <div class="row q-ml-lg q-mt-xs justify-start" v-if="shippingBox.optimizationParameters.fixedBox===true">
                <q-toggle v-model="shippingBox.optimizationParameters.fixedBoxTwoBlock" 
                  label="2 block pattern"
                  color="green"
                  />
              </div>

      

              <!-- Weight limit -->
              <div class="row q-ml-xl q-my-md justify-start" >                                            
                
                <div class="col-md-6 col-xs-4">  
                  <q-input                          
                    label="Weight limit (kg)"
                    v-model="shippingBox.limitParameters.weight.value"
                    :input-style="{ 'width':'250px', 'color': 'blue' }"
                    borderless
                    type="number"
                  />
                </div>                
                
              </div>


            </div> <!-- End of left part of shipping box card -->

        <!-- ############ Parameter #############################-->
            <div class="col-md-auto" :style="{'border':'none'}">
               
               <div class="row"                   
                  v-for="(n, index) in shippingBox.parameters" 
                  :key="index">
                  
                    <q-input
                      :input-style="{ 'width':'30em', 'color': 'black' }"
                      filled  
                      :label="n.name" 
                      v-model="shippingBox.parameters[index].value"                 
                      standout
                      bg-color="white"                                                          
                    >      
                    <template v-slot:label>                   
                      <em :class="n.format" style="" > {{ n.name }}</em>
                      <!-- comment -->                    
                      <em v-if="n.comment.length > 1" class="q-px-sm bg-grey text-white rounded-borders"> {{ n.comment }}</em>
                    </template>
                  </q-input>               
               </div> <!-- End of basic parameters -->


              <!-- Material Parameters -->

              <!-- Fixed Box -->
              <div v-if="shippingBox.optimizationParameters.fixedBox">                
                 
                 <div class="row col-12"                   
                    v-for="(n, index) in shippingBox.fixedBoxParameters" 
                    :key="index">
                  
                    <q-input                       
                      filled  
                      :label="n.name" 
                      v-model="shippingBox.fixedBoxParameters[index].value"                 
                      standout                  
                      bg-color="white"    
                      :input-style="n.formatInput"   
                    >      
                    <template v-slot:label>                   
                      <em :class="n.format" style="" > {{ n.name }}</em>
                      <!-- comment -->                    
                      <em v-if="n.comment.length > 1" class="q-px-sm bg-grey text-white rounded-borders"> {{ n.comment }}</em>
                    </template>
                  </q-input>               
               
               </div> 

              </div>

              <!-- Flexibel Box -->
              <div v-if="shippingBox.optimizationParameters.fixedBox===false">

               <div class="row col-12"
                  v-for="(n, index) in shippingBox.materialParameters" 
                  :key="index">
                  
                    <q-input          
                      class="large"               
                      filled  
                      :label="n.name" 
                      v-model="shippingBox.materialParameters[index].value"                 
                      standout                  
                      bg-color="white"    
                      :input-style="{ 'width':'30em', 'color': 'black' }"
                    >      
                    <template v-slot:label>                   
                      <em :class="n.format" style="" > {{ n.name }}</em>
                      <!-- comment -->                    
                      <!-- <em v-if="n.comment.length > 1" class="q-px-sm bg-grey text-white rounded-borders"> {{ n.comment }}</em> -->
                    </template>
                  </q-input>               
               </div> 

              </div> <!-- End of Material Parameters -->

          
          </div>
        </div> <!-- end of right part of shipping box section -->
        
        </q-card-section>


      <!-- dev area -->
        <q-card-section  v-if="env=='dev'">

          <div class="row col-12 dev q-ma-xs">
            <q-btn label="Save as template" @click="addShippingBoxTemplate" />
          </div>

          <div class="row col-12 dev q-ma-xs">
            <h5>Shipping Box Max Products:</h5>
            {{shippingBox.parameters.maxNumberProducts}}
          </div>

          
          <div class="row col-12 dev q-ma-xs">
            <h5>Shipping Box Templates:</h5>
          </div>
          <div class="row col-12 dev q-ma-xs">
            {{$store.state.templates.templatesShippingBox.map(e => e.name)}}
          </div>

          <div class="row col-12 dev q-ma-xs">
            <h5>Shipping Box Values:</h5>
            {{shippingBox}}
          </div>


        </q-card-section>
        
      
      </q-card>

    </q-dialog>

    <!--------------------------------- 2. dialog page ----------------------------------->
    <q-dialog v-model="secondDialog" persistent transition-show="scale" transition-hide="scale">
      <q-card>
       <q-card-section>
         <div class="row q-ma-xs" style="border:2px solid #000000;">              
                  <h5>Create a Shipping Box</h5>          
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row q-ma-xs" style="border:2px solid #000000;">
              <div class="col-5 q-mx-sm">
                 <q-img :src = "require(`@/assets/product/pic.png`)" id="product_image" /> 
                <canvas id="product_canvas" ref="product_canvas" >
                    
                </canvas>
              
              </div>
              <div class="col-5 q-mx-sm">
                   
              </div>
          </div>
        </q-card-section>

        

      </q-card>
    </q-dialog>
    
     <!--------------------------------- 3. dialog page ----------------------------------->
    <q-dialog v-model="thirdDialog" persistent transition-show="scale" transition-hide="scale">
      <q-card class="bg-teal text-white" style="width: 300px">
        <q-card-section>
          <div class="text-h6">Third dialog</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Click/Tap on the backdrop.
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="previous page" @click="secondDialog = true; thirdDialog = false" />
          <q-btn flat label="Close" v-close-popup @click="$router.push('/')" />
        </q-card-actions>

      </q-card>
    </q-dialog>
    

    </div>  
</template>


<script lang="ts">
import {computed, ref, watch} from 'vue'
import {useStore} from 'vuex'
import saveProject from '@/components/mixins/saveProject.js'
import templates from '@/components/mixins/templates.js'


export default {
  name: 'shippingBox',
  data() {
    return {
      firstDialog: true,
      secondDialog: false,
      thirdDialog: false,
      canvasWidth: 100,
      canvasHeight: 100,       
    }
  },


  setup() {

    const env = computed(() => {return  process.env.VUE_APP_ENV})

    const store = useStore()
    const windowHeight = computed(() => {return window.innerHeight})

    const {saveCurrentProject} = saveProject()
    const {loadShippingBoxTemplates, addShippingBoxTemplate} = templates()

    const shippingBoxStore = computed(() => {return store.state.general.shippingBox})    
    const shippingBox = ref(shippingBoxStore.value)
    watch(shippingBoxStore, (newValue) => {
       window.console.log('shipping box changed')              
       shippingBox.value = newValue
     })



    const templateName = ref('')
    const showTemplateSave = ref(false)
    const showLoadedTemplates = ref(false)

    // Function load template
    const loadTemplate = (id, name) => {
 
      const selectedTemplate =  store.state.templates.templatesShippingBox.filter(e => e.templateID===id)[0]
 
      store.commit("general/updateShippingBox", {
        name: selectedTemplate.name,
        parameters: selectedTemplate.data.parameters,
        materialParameters: selectedTemplate.data.materialParameters,
        fixedBoxParameters: selectedTemplate.data.fixedBoxParameters,
        optimizationParameters: selectedTemplate.data.optimizationParameters,
        limitParameters: selectedTemplate.data.limitParameters
        })

      // Update message that template was loaded
      store.commit('dialogs/updateMessage', `Loaded Template: ${name}`)
      store.commit('dialogs/updateDialog', true)   
  
    }


    const shippingBoxTemplates = computed(() => store.state.templates.templatesShippingBox.map(({name, templateID}) => { 
      return {"name": name, "templateID": templateID }
    }
    ))
  


    return {
      env,
      windowHeight,
      shippingBox,
      templateName,
      showTemplateSave,
      shippingBoxTemplates,
      showLoadedTemplates,
      saveCurrentProject,
      addShippingBoxTemplate,
      loadTemplate 
          
    }

  }

  
  
}
</script>

<style lang="scss" scoped>

.q-input-large{
  width: 550px;
  
 
}
.q-input-small{   
    max-width: 200px; 

}

.text-heading{
  font-size: 1.5em;
  color:black;
  text-align: center;
  
}
</style>
