<template>
    <!-- This is shown if user is not authenticated -->
    <div>            
        <q-btn 
            class="text-white"            
            style="background-color:#262626e6;min-width:120px"
            size="md"
            label="Sign in" 
            @click="login();"             
        />
     
    
    </div>
  
</template>

<script>
//import { msalMixin } from 'vue-msal'; 
import * as Msal from "@azure/msal-browser"
import {computed, ref} from 'vue'
import {useStore} from 'vuex'


import userData from '@/components/mixins/userData.js';
import competitorProducts from '@/components/mixins/competitorProducts.js';


export default {
  name: 'login',
  
  setup(){

    const env = computed(() => {return process.env.VUE_APP_ENV})
    const store = useStore()
    const {getUserData} = userData()
    const {fetchCompetitorProducts} = competitorProducts()

    const accountId = ref('')
    const currentUsername = ref('')
    const myMSALObj = ref('')
    const msalConfig =  {
          auth: {
            clientId: process.env.VUE_APP_AD_CLIENT_ID, // This is the ONLY mandatory field; everything else is optional.
            authority:  `${process.env.VUE_APP_AD}/B2C_1_login`, // Choose sign-up/sign-in user-flow as your default.
            knownAuthorities: [process.env.VUE_APP_AD_AUTHORITY], // You must identify your tenant's domain as a known authority.
            redirectUri: process.env.VUE_APP_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
          },
          cache: {
            cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
            storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
          }
    }

      const loginRequest = {
          scopes: ["openid", ... ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]],
          //scopes: ["openid", ... ["https://mapmorize.onmicrosoft.com/1ca7a28d-c395-41dd-b72a-9c768cb387c8/Files.Read"]],
        }

        /**
        * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
        * To learn more about how to work with scopes and resources, see: 
        * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
        */
      const tokenRequest = {
          scopes: [... ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
          forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
        }
      const apiConfig = {
          b2cScopes: ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"],
          webApi: "https://fabrikamb2chello.azurewebsites.net/hello"
        }

      const b2cPolicies = {
          names: {
              SignIn: "b2c_1_login",
              forgotPassword: "B2C_1_password_reset",
              // editProfile: "b2c_1_edit_profile"
          },
        authorities: {
              SignIn: {
                  authority: `${process.env.VUE_APP_AD}/B2C_1_login`,
              },
              forgotPassword: {
                   authority: `${process.env.VUE_APP_AD}/B2C_1_password_reset`,
               },
              // editProfile: {
              //     authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile"
              // }
          },
        authorityDomain: "scalizeapp.b2c_login.com"
      }


      // Methods
  //* -------------------- Login ---------------------------------------- */
    const login = async () => {
      window.console.log('try to log in')
      //const myMSALObj = new msal.PublicClientApplication(this.msalConfig);
      myMSALObj.value = await new Msal.PublicClientApplication(msalConfig);

    // Save to store
      store.commit('user/updateMySAML', myMSALObj.value)
      
      window.console.log('mysal created')
      window.console.log('redirect URI:' + process.env.VUE_APP_REDIRECT_URI)
      
      myMSALObj.value.loginPopup(loginRequest)
          .then(response => handleResponse(response))
          .catch(error => {
            window.console.log('Error')
              window.console.log(error);                      
              // Error handling
              if (error.errorMessage) {
                  // Check for forgot password error
                  // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                  if (error.errorMessage.indexOf("AADB2C90118") > -1) {
                      myMSALObj.value.loginPopup(b2cPolicies.authorities.forgotPassword)
                          .then(response => handlePolicyChange(response));
                  }
              }
          })
      } // End of login


      // * Handle response
        const handleResponse = (response) => {
          window.console.log('handle response')
          window.console.log('------------------')
          window.console.log(response)
          window.console.log('------------------')
        

           //const account = this.myMSALObj.getAccount();
          //  window.console.log('------Claims------------')
          //  window.console.log(response.account.idTokenClaims)
          //  window.console.log('------------------')

          // window.console.log('------Company------------')
          // window.console.log(response.account.idTokenClaims.city)
          // window.console.log('Correct company ?')
          // window.console.log(`process.env.VUE_APP_CUSTOMER: ${process.env.VUE_APP_CUSTOMER}`)
          // window.console.log(process.env.VUE_APP_CUSTOMER === response.account.idTokenClaims.city)
          // window.console.log('------Company------------')


          // Check company
          if (process.env.VUE_APP_CUSTOMER === response.account.idTokenClaims.city ||
              response.account.idTokenClaims.city === "scalize")
            {

            // Save token:
            store.commit('user/updateUserAuthInfo', response)
          
           
            // commit login to store
            store.commit('user/updateLoggedIn', true)

            // Save user data to localstorage
            localStorage.setItem('scalize_user', response.account.username)
          
            /**
             * To see the full list of response object properties, visit:
             * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
             */
            if (response !== null) {
                accountId.value = response.account.homeAccountId;
                currentUsername.value = response.account.username;
                store.commit('user/updateUserData', {
                                    iss: '',
                                    idToken: response.account.idToken,
                                    userEmail: response.account.username,
                                    company: response.account.idTokenClaims.city
                                    }
                                    )
                welcomeUser(currentUsername.value);

            } else {
                selectAccount();
            }
          
          } else {                        
            store.commit('dialogs/updateMessage', "We cannot find a user account for you!")
            store.commit('dialogs/updateLoginErrorDialog', true)
            
            

          }
        } // end of handle response




//*-------------------- select account --------------------------- */
const selectAccount = () => {

    /**
     * See here for more info on account retrieval: 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */

    const currentAccounts = myMSALObj.value.getAllAccounts();
    window.console.log('Accounts: ' + currentAccounts)

    if (currentAccounts.length === 0) {
        return;
    } else if (currentAccounts.length > 1) {
        // Add your account choosing logic here
        console.log("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {

        accountId.value = currentAccounts[0].homeAccountId;
        currentUsername.value = currentAccounts[0].username;
        // Fetch data from cosmos DB
       
        welcomeUser(currentUsername.value);

      

    }
  } //! end of select account
  
  
const handlePolicyChange = (response) => {
    /**
     * We need to reject id tokens that were not issued with the default sign-in policy.
     * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
     * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
     */

    if (response.idTokenClaims['acr'] === b2cPolicies.names.editProfile) {
        window.alert("Profile has been updated successfully. \nPlease sign-in again.");
        myMSALObj.value.logout();
    } else if (response.idTokenClaims['acr'] === b2cPolicies.names.forgotPassword) {

        window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");


         store.commit('dialogs/updateErrorDialog', {
              show: true,
              message: "Password has been reset successfully. \nPlease sign-in with your new password."
          })



        // $q.notify({    
        //       timeout: 0,
        //       position: 'bottom-right',
        //       message: `Your password has been reset`,
        //       html: true,
        //       color: 'black',
        //       actions: [
        //       { label: 'Close', color: 'yellow', handler: () => {  $router.push('/')  } },
        //       //    { label: 'Get more information', color: 'white', handler: () => { this.$router.push('Cookies') } }
        //         ]        
        //     })
        
        myMSALObj.value.logout();
    }
}


/* Welcome user */
const welcomeUser = async (username) => {

  window.console.log('username:' + username )  

  await getUserData();
  await fetchCompetitorProducts();
  store.dispatch("general/getProductCategories")
  window.console.log('welcome user you are logged in')
  window.console.log(username)
    //label.classList.add('d-none');
    //signInButton.classList.add('d-none');
    //signOutButton.classList.remove('d-none');
    // titleDiv.classList.add('d-none');
    // editProfileButton.classList.remove('d-none');
    // welcomeDiv.classList.remove('d-none');
    // welcomeDiv.innerHTML = `Welcome ${username}!`
    // callApiButton.classList.remove('d-none');
}

// signout
const signOut = async () => {
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */
    // Choose which account to logout from.    
    const logoutRequest = {
        account: myMSALObj.value.getAccountByHomeId(accountId.value)
    };
    
    myMSALObj.value.logout(logoutRequest);
}


    


 return {
   env,
   login
 }
} //! End of SETUP


}





      


</script>

<style lang="scss">


</style>
