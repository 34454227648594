import { render } from "./Upload.vue?vue&type=template&id=5c982410&scoped=true"
import script from "./Upload.vue?vue&type=script&lang=ts"
export * from "./Upload.vue?vue&type=script&lang=ts"

import "./Upload.vue?vue&type=style&index=0&id=5c982410&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-5c982410"

export default script
import QImg from 'quasar/src/components/img/QImg.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSpinnerBars from 'quasar/src/components/spinner/QSpinnerBars.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QInput,QBtn,QTooltip,QSpinnerBars,QIcon});
