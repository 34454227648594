


import {ref, computed, onMounted} from 'vue'
import type { Ref } from 'vue'
import { useStore } from 'vuex'



interface Product{
    id: string;
    image: string;
    parameters: {
      height: {
        values: {
          Target: {value: number; format: string;};
          Min: {value: number; format: string;};
          Max: {value: number; format: string;};
        };
      name: string;
      comment: string;
      };
      
      width:  {
        values: {
          Target: {value: number; format: string;};
          Min: {value: number; format: string;};
          Max: {value: number; format: string;};
          };
        name: string; 
        comment: string;
        };
      
      depth:  {
        values: {
          Target: {value: number; format: string;};
          Min: {value: number; format: string;};
          Max: {value: number; format: string;};
          };
        name: string;
        comment: string;
        };
                    
      
    };

    calculationParameters: {
      wEqualsD: boolean;
    };

    volumeParameters: {

      brimfulVolume: {
        values: {
          target: {name: string; value: number; format: string;},
          headspace: {name: string; value: number; format: string;};              
        };
        name: string; comment: string;
      };
      
      availableVolume: {
        values: {              
          fillingHead: {name: string; value: number; format: string;};              
        },
        name: string; comment: string;
      };

      maxFillVolume: {
        values: {              
          overfill: {name: string; value: number; format: string;}              
        };
        name: string; comment: string;
      };
      
      maxDeclaredVolume: {
        values: {              
        };
        name: string; comment: string;
      };

      contentWeight: {
        values: {
          density: {name: string; value: number; format: string;};          
        };
        name: string; comment: string;
      };
    };

    componentsParameters: {
      packagingStandard: {
        name: string; format: string; container: string; closure: string; brand: string;
      };
      containerMaterials: [];
      closureMaterials: [];
      decorationMaterials: [];
      packagingComposition: {
        recyclability: number;
        reused: number;
        compostable: string; // %            
        circularityScore: number;
      };
      
    }
}



// result object

interface result{
  data: {
    shippingBox: {width: number};
    pallet: {block: number};
  }
}



export default {
  setup() {
    
    const env = computed(() => {return process.env.VUE_APP_ENV})
    const store = useStore()


    const optimizedShipper = ref(false)
    const optimizeDesignTooltip = ref(false)

    const calculating_sensitivity = ref(false)
    const calculating_sensitivity_ccg1 = ref(false)
    const calculating_sensitivity_ccg2 = ref(false)
    const calculating_sensitivity_eul1 = ref(false)
    const calculating_sensitivity_eul2 = ref(false)
    const calculating_shipper = ref(false)
    const calculatingText = ref("")

    const myInterval = ref()

    const logsCheckProduct = ref()
    const ccg1_design_optimized_logs = ref()
    const ccg2_design_optimized_logs = ref()
    const eul1_design_optimized_logs = ref()
    const eul2_design_optimized_logs = ref()

    const height = ref(17.0)
    const width = ref(7.3)
    const depth = ref(3.6)
    const unitsPerBox = ref(12)

  
    const units_ccg1_optimized_shipper= ref()
    const units_ccg2_optimized_shipper = ref()
    const units_eul1_optimized_shipper = ref()
    const units_eul2_optimized_shipper = ref()

    const ccg1_shipper_optimized:  Ref<result | undefined> = ref()
    const ccg2_shipper_optimized:  Ref<result | undefined> = ref()
    const eul1_shipper_optimized:  Ref<result | undefined> = ref()
    const eul2_shipper_optimized:  Ref<result | undefined> = ref()
    

    const ccg1_design_optimized:  Ref<result | undefined> = ref()
    const ccg2_design_optimized:  Ref<result | undefined> = ref()
    const eul1_design_optimized:  Ref<result | undefined> = ref()
    const eul2_design_optimized:  Ref<result | undefined> = ref()



    const resultsSensitivity = ref({})



// Calculate sensitivity for one value
const getSensitivitySingle = async (id: string) => {

      window.console.log("Product Sensitivity v0.1")
      window.console.log(`calling sensitivity single ID: ${id}`)

      if(id === "ccg1"){       
        calculating_sensitivity_ccg1.value = true
      }else if(id === "ccg2"){        
        calculating_sensitivity_ccg2.value = true
      }else if(id === "eul1"){        
        calculating_sensitivity_eul1.value = true
      }else if(id === "eul2"){        
        calculating_sensitivity_eul2.value = true
      }

      calculating_sensitivity.value = true
      
      
      const tolerance_ml = 15.0

      // Double check that this is in centimeter
      const tolerance_dimension = 0.3

      // Init product
      const product= ref(store.state.testScalize.product as Product)

      // Replace values
      product.value.parameters.depth.values.Target.value = depth.value as number
      product.value.parameters.width.values.Target.value = width.value as number
      product.value.parameters.height.values.Target.value = height.value as number
            
      product.value.parameters.depth.values.Min.value = +depth.value - tolerance_dimension
      product.value.parameters.width.values.Min.value = +width.value - tolerance_dimension
      product.value.parameters.height.values.Min.value = +height.value - tolerance_dimension

      product.value.parameters.depth.values.Max.value = +depth.value + tolerance_dimension
      product.value.parameters.width.values.Max.value = +width.value + tolerance_dimension
      product.value.parameters.height.values.Max.value = +height.value + tolerance_dimension

      window.console.log(`Type: ${typeof(product.value.parameters.height.values.Max.value)}`)
      window.console.log(`Product Height Target: ${product.value.parameters.height.values.Target.value}`)
      window.console.log(`Product Height Max: ${product.value.parameters.height.values.Max.value}`)
  
      // shipping Box
      const shippingBox = store.state.testScalize.shippingBox
      shippingBox.parameters.minNumberProducts.value = unitsPerBox.value
      shippingBox.parameters.maxNumberProducts.value = unitsPerBox.value


       // Register request
      registerRequest(`check_sensitivity`);

      
      // Get result
      const result = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize[`pallet_${id}`],
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json()).catch(error => {
        window.console.log(error)
        if(id === "ccg1"){        
          calculating_sensitivity_ccg1.value = false
        }else if(id === "ccg2"){        
          calculating_sensitivity_ccg2.value = false
        }else if(id === "eul1"){        
          calculating_sensitivity_eul1.value = false
        }else if(id === "eul2"){        
          calculating_sensitivity_eul2.value = false
        }
          calculating_sensitivity.value = false
      })

      if(id === "ccg1"){
        ccg1_design_optimized.value = result.data
        calculating_sensitivity_ccg1.value = false
      }else if(id === "ccg2"){
        ccg2_design_optimized.value = result.data
        calculating_sensitivity_ccg2.value = false
      }else if(id === "eul1"){
        eul1_design_optimized.value = result.data
        calculating_sensitivity_eul1.value = false
      }else if(id === "eul2"){
        eul2_design_optimized.value = result.data
        calculating_sensitivity_eul2.value = false
      }

      window.console.log(`Result: ${result.data}`)

    calculating_sensitivity.value = false
            
}


const getSensitivity = async () => {

      window.console.log("calling sensitivity SEQUENTIALLY")
      
      // Activate spinners
      calculating_sensitivity.value = true      
      
      const tolerance_ml = 10.0

      // Double check that this is in centimeter
      const tolerance_dimension = 0.5

      // Init product
      const product= ref(store.state.testScalize.product as Product)

      // Replace values
      product.value.parameters.depth.values.Target.value = depth.value as number
      product.value.parameters.width.values.Target.value = width.value as number
      product.value.parameters.height.values.Target.value = height.value as number
            
      product.value.parameters.depth.values.Min.value = +depth.value - tolerance_dimension
      product.value.parameters.width.values.Min.value = +width.value - tolerance_dimension
      product.value.parameters.height.values.Min.value = +height.value - tolerance_dimension

      product.value.parameters.depth.values.Max.value = +depth.value + tolerance_dimension
      product.value.parameters.width.values.Max.value = +width.value + tolerance_dimension
      product.value.parameters.height.values.Max.value = +height.value + tolerance_dimension

      window.console.log(`Type: ${typeof(product.value.parameters.height.values.Max.value)}`)
      window.console.log(`Product Height Target: ${product.value.parameters.height.values.Target.value}`)
      window.console.log(`Product Height Max: ${product.value.parameters.height.values.Max.value}`)
  
      // shipping Box
      const shippingBox = store.state.testScalize.shippingBox
      shippingBox.parameters.minNumberProducts.value = unitsPerBox.value
      shippingBox.parameters.maxNumberProducts.value = unitsPerBox.value

      
      // CCG 1
      const result_ccg1 = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_ccg1,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json())


      ccg1_design_optimized.value = result_ccg1.data

      window.console.log(result_ccg1.logs)


      window.console.log(ccg1_design_optimized.value)

      // EUL1 2
      const result_eul1 = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_eul1,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json())

      eul1_design_optimized.value = result_eul1.data

      window.console.log(eul1_design_optimized.value)


       // CCG 2
      const result_ccg2 = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_ccg2,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json())

      ccg2_design_optimized.value = result_ccg2.data

      window.console.log(ccg2_design_optimized.value)

       // EUL 2
      const result_eul2 = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_eul2,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json())

      eul2_design_optimized.value = result_eul2.data

      window.console.log(eul2_design_optimized.value)


      calculating_sensitivity.value = false      





      

      // fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ 
      //     title: "Request",
      //     auth: '',
      //     pallet: store.state.testScalize.pallet_ccg2,
      //     product: product.value,
      //     shippingBox: shippingBox,          
      //     secondPallet: {status: false},    
      //     toleranceML: tolerance_ml,
      //     type: 'check_sensitivity',
      //     parameters: store.state.testScalize.calculationParameters
      //     })
      // }).then(res => res.json()),



}
  //* ------  check Sensitivity paraellel ------------------------ *//
    const getSensitivityParallel = async () => {

      window.console.log("calling sensitivity PARALLEL")
      
      // Activate spinners
      calculating_sensitivity.value = true      
      
      const tolerance_ml = 10.0

      // Double check that this is in centimeter
      const tolerance_dimension = 0.5

      // Init product
      const product= ref(store.state.testScalize.product as Product)

      // Replace values
      product.value.parameters.depth.values.Target.value = depth.value as number
      product.value.parameters.width.values.Target.value = width.value as number
      product.value.parameters.height.values.Target.value = height.value as number
            
      product.value.parameters.depth.values.Min.value = +depth.value - tolerance_dimension
      product.value.parameters.width.values.Min.value = +width.value - tolerance_dimension
      product.value.parameters.height.values.Min.value = +height.value - tolerance_dimension

      product.value.parameters.depth.values.Max.value = +depth.value + tolerance_dimension
      product.value.parameters.width.values.Max.value = +width.value + tolerance_dimension
      product.value.parameters.height.values.Max.value = +height.value + tolerance_dimension

      window.console.log(`Type: ${typeof(product.value.parameters.height.values.Max.value)}`)
      window.console.log(`Product Height Target: ${product.value.parameters.height.values.Target.value}`)
      window.console.log(`Product Height Max: ${product.value.parameters.height.values.Max.value}`)
  
      // shipping Box
      const shippingBox = store.state.testScalize.shippingBox
      shippingBox.parameters.minNumberProducts.value = unitsPerBox.value
      shippingBox.parameters.maxNumberProducts.value = unitsPerBox.value
      
      


    const [result_ccg1, result_ccg2, result_eul1, result_eul2] = await Promise.allSettled([
      
      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" ,  {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_ccg1,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json()),

      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_ccg2,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json()),

      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_eul1,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json()),
        
      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_eul2,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json()),

    ]    
    );

    
    if (result_ccg1.status === "fulfilled"){      
      ccg1_design_optimized.value = result_ccg1.value.data
      ccg1_design_optimized_logs.value = result_ccg1.value.logs
    }
    if (result_ccg2.status === "fulfilled"){      
      ccg2_design_optimized.value = result_ccg2.value.data
      ccg2_design_optimized_logs.value = result_ccg2.value.logs
    }
    if (result_eul1.status === "fulfilled"){
      eul1_design_optimized.value = result_eul1.value.data
      eul1_design_optimized_logs.value = result_eul1.value.logs
    }
    if (result_eul2.status === "fulfilled"){
      eul2_design_optimized.value = result_eul2.value.data
      eul2_design_optimized_logs.value = result_eul2.value.logs
    }

    calculating_sensitivity.value = false
    window.console.log("finished sensitivity")     


    // Register request
    registerRequest("check_sensitivity");

    // Stop text anmiation
    stopTextAnmiation();

    }
    
  //* -------------------------------- Function to evaluate the product ---------------------
     //* --- Function to get user Data
    const checkProduct = async () => {                   
    window.console.log("calling check product")
    // register request                            
      
      // Activate spinners
      calculating_shipper.value = true


       ccg1_design_optimized.value = undefined
       ccg2_design_optimized.value = undefined
       eul1_design_optimized.value = undefined
       eul2_design_optimized.value = undefined
            
      const requestOptions = {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",          
          type: 'check_product',    
          product: {height: height.value, width: width.value, depth: depth.value},
          unitsPerBox: unitsPerBox.value
          
          })
      };
      const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProduct" , requestOptions).catch((error) => {
              window.console.log(error)                                        
        }
                    
        ) as Response;

      // placeholder for data
      let responseJson;
      

      if (response.status == 200) {
        window.console.log("-----------------")
        
        responseJson = await response.json();
        window.console.log(responseJson)
        
        units_ccg1_optimized_shipper.value = responseJson.data.ccg1_optimized_shipper.total_units
        units_ccg2_optimized_shipper.value = responseJson.data.ccg2_optimized_shipper.total_units
        units_eul1_optimized_shipper.value = responseJson.data.eul1_optimized_shipper.total_units
        units_eul2_optimized_shipper.value = responseJson.data.eul2_optimized_shipper.total_units

        ccg1_shipper_optimized.value = responseJson.data.ccg1_optimized_shipper
        ccg2_shipper_optimized.value = responseJson.data.ccg2_optimized_shipper
        eul1_shipper_optimized.value = responseJson.data.eul1_optimized_shipper
        eul2_shipper_optimized.value = responseJson.data.eul2_optimized_shipper

        // Save logs
        logsCheckProduct.value = responseJson.logs


        window.console.log("finished check product")
        calculating_shipper.value = false
        optimizedShipper.value = true
        optimizeDesignTooltip.value = true

        // Register request
        registerRequest("check_product");

          
           
      }else{        
        window.console.log("an error happened")  
        calculating_shipper.value = false
      }


    } // End of function to check the product



    // Register request 
    const registerRequest = (typeOfCheck:string) => {
     fetch("https://functions-app-marketing.azurewebsites.net/api/registerRequest", 
           {                
            method: "POST",
            headers: { "Content-Type": "application/json" },
          
            body: JSON.stringify({               
              info: 'testProduct',                        
              type: typeOfCheck,
              product: {height: height.value, width: width.value, depth: depth.value},
              unitsPerBox: unitsPerBox.value
              })
              }
            ).catch((error) => {            
          
            window.console.log(error)
      }) 

      
    }
      


    const checkAll = async () =>
    { 
      
      ccg1_design_optimized.value = undefined
      ccg2_design_optimized.value = undefined
      eul1_design_optimized.value = undefined
      eul2_design_optimized.value = undefined
      
      await checkProduct()
      window.console.log("finished getting product")
      await getSensitivity()
            
      
  }
             
      // Testing Function
    const doJob = (x,sec)  => {
      return new Promise(resolve => {
      console.log('Start: ' + x);
        setTimeout(() => {
            console.log('End: ' + x);
          resolve(x);
        }, sec *1000);
      });
    }


    // animate text

    const typeText = () => {       
          const message = "CALCULATING - TEST OPTIMIZATION CAN TAKE A MOMENT..."
          const currentMessage = calculatingText.value

          //window.console.log("running type text")
          //window.console.log(currentMessage)
      
          if (currentMessage.length < message.length){
            calculatingText.value = currentMessage + message[currentMessage.length]
          }else{
            calculatingText.value = "C"
          }
          
    }
    
    const animateText = () => {
       myInterval.value = setInterval(typeText, 150);            
    }

    const stopTextAnmiation = () => {
      clearInterval(myInterval.value)
    }


  //* --------------------------- Mounted --------------------------------------
  const testQueue = async () => {


    const storageUri = "https://jantestfunc.queue.core.windows.net/test-queue/messages"

    fetch(storageUri, 
           {                
            method: "GET",
            headers: { "Authorization": "",
            "x-ms-date":  ""},
          
            // body: JSON.stringify({               
            //   info: 'testProduct',                        
            //   type: 'check_product',
            //   product: {height: height.value, width: width.value, depth: depth.value},
            //   unitsPerBox: unitsPerBox.value
            //   })
              }
            ).catch((error) => {            
          
            window.console.log(error)
      }) 
  }

  //* --------------------------- Mounted --------------------------------------
    onMounted(() => {
      
      // Send warm up requests 
      window.console.log("sending warm up requests")

      registerRequest("loaded_check_product_page");

      // (1) Warm-up Sensitivity
      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" ,  {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",                    
          type: 'warm_up',          
          })
      })

      // (2) Warm-Up Check Product              
      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProduct" , {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",          
          type: 'warm_up'                    
          })
      })
                    
        

    })
    
    

    return {
      env,
      width,
      height,
      depth,
      unitsPerBox,

      ccg1_shipper_optimized,
      ccg2_shipper_optimized,
      eul1_shipper_optimized,
      eul2_shipper_optimized,
      
      
      ccg1_design_optimized,
      ccg2_design_optimized,
      eul1_design_optimized,
      eul2_design_optimized,

      units_ccg1_optimized_shipper,
      units_ccg2_optimized_shipper,
      units_eul1_optimized_shipper,
      units_eul2_optimized_shipper,


      checkProduct,
      getSensitivity,
      checkAll,
      registerRequest,
      resultsSensitivity,
      calculating_sensitivity,
      calculating_sensitivity_ccg1,
      calculating_sensitivity_ccg2,
      calculating_sensitivity_eul1,
      calculating_sensitivity_eul2, 
      calculating_shipper,

      logsCheckProduct,
      ccg1_design_optimized_logs,
      ccg2_design_optimized_logs,
      eul1_design_optimized_logs,
      eul2_design_optimized_logs,

      animateText,
      calculatingText,

      testQueue,
      getSensitivitySingle,
      optimizedShipper,
      optimizeDesignTooltip

    }
    
  },
}
