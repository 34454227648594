
//import MixinResult from '@/components/mixins/mixinsResult.ts';
import {computed, ref, onMounted} from 'vue'
import { useStore } from 'vuex'
import threeDimPallet from '@/components/visualisations/threeDimPallet.vue';



export default {
    name: 'detailsPallet',
    components: {
        "three-dim-pallet": threeDimPallet
    },
    
    setup() {


        const env = computed(() => {return process.env.VUE_APP_ENV})

        const store = useStore()
                             
            

        return {
            env,                                                                        
            
        }

    }
}

