<template>
  <div v-scroll="onScroll">
    <q-layout view="hHh lpR fFf">
      <q-header class="bg-white">
        <div ref="header">
          <div class="row items-center">
            <div class="col-sm-2 q-ml-lg q-pl-md gt-sm">
              <login />
            </div>

            <div class="col gt-sm text-center">
              <q-img
                class="q-my-md"
                :src="require(`@/assets/landing_page/logo.svg`)"
                style="max-width: 320px"
              />
            </div>

            <div class="col lt-md text-center">
              <q-img
                class="q-my-md"
                :src="require(`@/assets/landing_page/logo.svg`)"
                style="max-width: 280px"
              />
            </div>
            
          </div>                    
        
        </div>
                
      </q-header>

      <!-- <q-header class="bg-white" ref="header">
        <div class="row q-ma-lg">
          <div class="col-1 items-center">
             <q-img :src = "require(`@/assets/logo_boxes.png`)" style="max-width:100px" />
          </div>
          <div class="col-8 items-center justify-start">
            <q-img :src = "require(`@/assets/logo.jpg`)" style="max-width:400px" />
          </div>
        </div>
        <div class="row q-ma-lg q-pa-lg">
          <h2 class="q-ma-md text-red" >...IN DEVELOPMENT...</h2>
        </div>
      </q-header> -->

      <q-page-container>

       
        
        <div class="row" ref="all_rows">
          <div class="col-sm-2 gt-sm" />

          <div class="col">
            
            <!-- Video Button -->
             <div class="row q-my-md justify-center">
              <q-btn 
                class="q-pa-md"
                outline size="lg" label="1 minute intro video" @click="$store.commit('dialogs/updateVideo', true)" 
                color="dark"
                icon="play_arrow" 
                />                                
            </div>

            <!-- Button for test page -->
             <div class="row q-my-md justify-center" v-if="customer==='scalize'">
              <q-btn 
                class="q-pa-md"
                outline size="lg" label="Test calculation" @click="$router.push('/testscalize')" 
                color="red"
                icon="insights" 
                />                                
            </div>
            
            <div
              class="row content q-px-xs justify-center items-center"
              ref="about"
            >
              <div v-if="env == 'dev'">
                {{ position }}
              </div>
             
              <about-page />
            </div>

            <q-separator
              inset="item"
              color="dark"
              ref="separator"
              class="q-mx-xl"
            />
           
            <div
              class="row content q-my-xl q-px-xs justify-center items-center"
              ref="products"
            >
              <div v-if="env == 'dev'">
                {{ position }}
              </div>
              <products-page />
            </div>

            <q-separator
              inset="item"
              color="dark"
              ref="separator"
              class="q-mx-xl"
            />

            <div
              class="row content q-my-xl q-px-xs justify-center items-center"
              ref="scalize"
            >
              <div v-if="env == 'dev'">
                {{ position }}
              </div>
              <scalize-page />
            </div>

            <q-separator
              inset="item"
              color="dark"
              ref="separator"
              class="q-mx-xl"
            />

            <div
              class="row content q-my-xl q-px-xs justify-center items-center"
              ref="returns"
            >
              <div v-if="env == 'dev'">
                {{ position }}
              </div>
              <returns-page />
            </div>

            <q-separator
              inset="item"
              color="dark"
              ref="separator"
              class="q-mx-xl"
            />

            <div
              class="row content q-my-xl q-px-xs justify-center items-center"
              ref="decisions"
            >
              <div v-if="env == 'dev'">
                {{ position }}
              </div>
              <decisions-page />
            </div>

            <q-separator
              inset="item"
              color="dark"
              ref="separator"
              class="q-mx-xl"
            />

            <div
              class="row content q-my-xl q-px-xs justify-center items-center"
              ref="demo"
            >
              <div v-if="env == 'dev'">
                {{ position }}
              </div>
              <demo-page />
            </div>

            <q-separator
              inset="item"
              color="dark"
              ref="separator"
              class="q-mx-xl"
            />

            <div
              class="row content q-my-xl q-px-xs justify-center items-center"
              ref="meet"
            >
              <div v-if="env == 'dev'">
                {{ position }}
              </div>
              <meet-page />
            </div>

            <q-separator
              inset="item"
              color="dark"
              ref="separator"
              class="q-mx-xl"
            />

            <div
              class="row content q-my-xl q-px-xs justify-center items-center"
              ref="needs"
            >
              <div v-if="env == 'dev'">
                {{ position }}
              </div>
              <needs-page />
            </div>

            <q-separator
              inset="item"
              color="dark"
              ref="separator"
              class="q-mx-xl"
            />

            <div
              class="row content q-my-xl q-px-xs justify-left items-center"
              ref="imprint"
            >
              <div v-if="env == 'dev'">
                {{ position }}
              </div>
              <imprint-page />
            </div>

            <q-separator
              inset="item"
              color="dark"
              ref="separator"
              class="q-mx-xl"
            />

            <div
              class="row content q-my-xl q-px-xs justify-start items-top"
              ref="privacy"
            >
              <div v-if="env == 'dev'">
                {{ position }}
              </div>
              <privacy-page />
            </div>
          </div>
        </div>

        <!-- sticky menu -->
        <q-page-sticky position="top-left" :offset="[0, 0]" class="gt-sm">
          <div class="row">
            <!-- <q-btn round color="accent" icon="arrow_back" class="rotate-45" /> -->

            <!-- <q-item  
              clickable
              @click=" scrollToArea(p.name)" 
              :class="{ 'active':  pages.filter(x => x.name == p.name)[0].visible }"
              style="max-width:100px"
              >
              {{p.name}}
            </q-item> -->
            <!--<q-list v-for="p in pages" :key='p.name'>-->
            <q-list class="q-my-sm text-center">
              <!-- First Part -->
              <q-separator
                class="q-my-xs"
                color="light"
                style="max-width: 200px"
              />

              <q-item
                clickable
                @click="scrollToArea('about')"
                :class="{
                  active: pages.filter((x) => x.name == 'about')[0].visible,
                }"
              >
                <q-item-section>
                  <div class="q-my-xs text-menu-scalize">IMPROVE PRODUCTS</div>
                </q-item-section>
              </q-item>

              <!-- Second Part -->
              <q-item
                clickable
                @click="scrollToArea('products')"
                :class="{
                  active: pages.filter((x) => x.name == 'products')[0].visible,
                }"
              >
                <q-item-section>
                  <div class="q-my-xs text-menu-scalize">
                    OPTIMIZE PACKAGING
                  </div>
                </q-item-section>
              </q-item>

              <q-item
                clickable
                @click="scrollToArea('scalize')"
                :class="{
                  active: pages.filter((x) => x.name == 'scalize')[0].visible,
                }"
              >
                <q-item-section>
                  <div class="q-my-xs text-menu-scalize">
                    ACCELERATE DEVELOPMENT
                  </div>
                </q-item-section>
              </q-item>

              <q-item
                clickable
                @click="scrollToArea('returns')"
                :class="{
                  active: pages.filter((x) => x.name == 'returns')[0].visible,
                }"
              >
                <q-item-section>
                  <div class="q-my-xs text-menu-scalize">
                    INCREASE PROJECT VALUE
                  </div>
                </q-item-section>
              </q-item>
              <!-- Third Part -->
              <q-item
                clickable
                @click="scrollToArea('decisions')"
                :class="{
                  active: pages.filter((x) => x.name == 'decisions')[0].visible,
                }"
              >
                <q-item-section>
                  <div class="q-my-xs text-menu-scalize">
                    MANAGE TARGETS & PIPELINES
                  </div>
                </q-item-section>
              </q-item>

              <q-separator
                class="q-my-xs"
                color="light"
                style="max-width: 200px"
              />

              <!-- Fourth Part -->
              <q-item
                clickable
                @click="scrollToArea('demo')"
                :class="{
                  active: pages.filter((x) => x.name == 'demo')[0].visible,
                }"
              >
                <q-item-section>
                  <div class="q-my-xs text-menu-scalize">CONTACT & DEMO</div>
                </q-item-section>
              </q-item>

              <q-item
                clickable
                @click="scrollToArea('meet')"
                :class="{
                  active: pages.filter((x) => x.name == 'meet')[0].visible,
                }"
              >
                <q-item-section>
                  <div class="q-my-xs text-menu-scalize">THE FOUNDERS</div>
                </q-item-section>
              </q-item>

              <q-item
                clickable
                @click="scrollToArea('needs')"
                :class="{
                  active: pages.filter((x) => x.name == 'needs')[0].visible,
                }"
              >
                <q-item-section>
                  <div class="q-my-xs text-menu-scalize">OUR PARTNERS</div>
                </q-item-section>
              </q-item>

              <q-separator
                class="q-my-xs"
                color="light"
                style="max-width: 200px"
              />

              <!-- Fifth Part -->
              <q-item
                clickable
                @click="scrollToArea('imprint')"
                :class="{
                  active: pages.filter((x) => x.name == 'imprint')[0].visible,
                }"
              >
                <q-item-section>
                  <div class="q-my-xs text-menu-scalize">IMPRINT</div>
                </q-item-section>
              </q-item>

              <q-item
                clickable
                @click="scrollToArea('privacy')"
                :class="{
                  active: pages.filter((x) => x.name == 'privacy')[0].visible,
                }"
              >
                <q-item-section>
                  <div class="q-my-xs text-menu-scalize">PRIVACY</div>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </q-page-sticky>
      </q-page-container>
    </q-layout>
  </div>


</template>

<script lang="ts">
import { ref, computed } from "vue";

import login from "@/components/general_components/login.vue";
import aboutPage from "@/components/landing_page/aboutPage.vue";
import productsPage from "@/components/landing_page/productsPage.vue";
import scalizePage from "@/components/landing_page/scalizePage.vue";
import decisionsPage from "@/components/landing_page/decisionsPage.vue";
import returnsPage from "@/components/landing_page/returnsPage.vue";
import demoPage from "@/components/landing_page/demoPage.vue";
import meetPage from "@/components/landing_page/meetPage.vue";
import needsPage from "@/components/landing_page/needsPage.vue";
import imprintPage from "@/components/landing_page/imprintPage.vue";
import privacyPage from "@/components/landing_page/privacyPage.vue";


//import { scroll } from 'quasar'
//const { getScrollPosition, setScrollPosition } = scroll

export default {
  name: "mainPage",
  components: {
    login,
    "about-page": aboutPage,
    "products-page": productsPage,

    "scalize-page": scalizePage,
    "decisions-page": decisionsPage,
    "returns-page": returnsPage,
    "demo-page": demoPage,
    "meet-page": meetPage,
    "needs-page": needsPage,
    "imprint-page": imprintPage,
    "privacy-page": privacyPage,
    
  },

  setup() {
    const env = computed(() => {
      return process.env.VUE_APP_ENV;
    });

    const customer = computed(() => {return process.env.VUE_APP_CUSTOMER})

    const position = ref(0);


    const showVideo = ref(false)

    const header = ref();
    const about = ref();
    const decisions = ref();
    const products = ref();
    const scalize = ref();
    const returns = ref();
    const demo = ref();
    const meet = ref();
    const needs = ref();
    const privacy = ref();
    const imprint = ref();

    const pages = ref([
      {
        name: "about",
        element: about,
        order: 0,
        visible: true,
        height: 0,
        start: 0,
        end: 0,
      },

      {
        name: "products",
        element: products,
        order: 1,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },

      {
        name: "scalize",
        element: scalize,
        order: 2,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "returns",
        element: returns,
        order: 3,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "decisions",
        element: decisions,
        order: 4,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },

      {
        name: "demo",
        element: demo,
        order: 5,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "meet",
        element: meet,
        order: 6,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "needs",
        element: needs,
        order: 7,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "imprint",
        element: imprint,
        order: 8,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "privacy",
        element: privacy,
        order: 9,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
    ]);

    const scrollToTop = (val) => {
      window.scrollTo(0, val);
    };

    // Scroll to area
    const scrollToArea = (name: string) => {
      const pixPosition = pages.value.filter((x) => x.name == name)[0]["start"];
      window.scrollTo(0, pixPosition);
    };

    // Action on scroll
    const onScroll = (e) => {
      // window.console.log('Scrolling top position:' + e)
      position.value = e;

      window.console.log("e:" + e);

      //const this.elements = ["about", "meet_scalize", "products"]

      // Create array
      //const arrayElements = elements.map(x => x as HTMLDivElement)

      //const all_rows = this.$refs.all_rows as HTMLDivElement;
      //const header = header.value as HTMLDivElement;

      // window.console.log('pages');
      window.console.log(pages);

      // get height for each element
      //let sumHeight = 0;
      let sumHeight = header.value.clientHeight;
      window.console.log("header.value.clientHeight");
      window.console.log(header.value.clientHeight);

      pages.value.forEach((x) => {
        // Get element as div

        window.console.log("x.element.value");
        window.console.log(x.element);
        // window.console.log("x.element")
        // window.console.log(x.element)
        window.console.log(x.element.clientHeight);

        // Get height

        // eslint-disable-line no-use-before-define
        x.height = x.element.clientHeight;

        //x.height = 10

        // Set start height of element
        x.start = sumHeight; // - 155 would set the about page to under the header (155px high), but the other pages don't align to the top of their seperators

        // Add own height
        sumHeight += x.height;

        // set end height
        x.end = sumHeight;

        // toggle visibiltiy
        if (e >= x.start && e < x.end) {
          x.visible = true;
        } else {
          x.visible = false;
        }
      });
    }; //! end of on SCROLL

    return {
      env,
      customer,
      about,
      header,
      decisions,
      products,
      scalize,
      returns,
      demo,
      meet,
      needs,
      imprint,
      privacy,
      pages,
      showVideo,
      scrollToTop,
      scrollToArea,
      onScroll,
    };
  },
};
</script>

<style scoped lang="scss">
.q-item {
  max-width: 200px;
  &.active {
    color: #204fffe6;
    background-color: white;
  }
}

.hightlight {
  color: #32a287;
  background: yellow;
}

.row {
  &.content {
    min-height: 100px;
  }
}
</style>
