

export default {
    namespaced: true,
    
    /* ------------------- STATE -------------------------------------------------------- */
    state: {

        // Dialog showing errors etc
        dialog: false,
        message: '',
            
        shareProjectDialog: false,

        createProjectDialog: false,

        editProjectDialog: false,

        decisionsDialog: false,
        
        loginErrorDialog: false,

        video: false,

        smartBoxDetails: false,

        massAnalysisResultDialog: false,

        checkCalcParameters: false,
        
        checkSensetivityParameters: false,


        // Show details on 3D-Pallet
        palletDetails: false
        

    },


    /* ------------------- MUTATIONS -------------------------------------------------------- */
    mutations: {

      // Function to update product
      updateDialog(state, payload){
        window.console.log('updating error dialog')        
        state.dialog = payload        
      },
      updateMessage(state, payload){
        window.console.log('updating message')        
        state.message = payload        
      },

      // Function to update product
      updateShareProjectDialog(state, payload){    
        window.console.log('upadting share project dialog')            
        state.shareProjectDialog = payload        
      },
      
      updateCreateProjectDialog(state, payload){        
        window.console.log('updating create project dialog')        
        state.createProjectDialog = payload                
      },

      updateEditProjectDialog(state, payload){
        window.console.log('updating edit project dialog')        
        state.editProjectDialog = payload        
      },


      updateDecisionDialog(state, payload){
        window.console.log('updating decision dialog')        
        state.decisionDialog = payload        
      },

      updateLoginErrorDialog(state, payload){
        window.console.log('updating decision dialog')        
        state.loginErrorDialog = payload        
      },


      updateVideo(state, payload){
        window.console.log('updating video dialog')        
        window.console.log(payload)
        state.video = payload        
      },

      updateSmartBoxDetails(state, payload){
        window.console.log('updating smart Box details')        
        window.console.log(payload)
        state.smartBoxDetails = payload        
      },

      updateMassAnalysisResultDialog(state, payload){        
        state.massAnalysisResultDialog = payload        
      },


      updateCheckCalcParameters(state, payload){
        state.checkCalcParameters = payload
      },


      updateCheckSensitivityParameters(state, payload){
        state.checkSensitivityParameters = payload
      },
      

      // Show details on 3D-Pallet
      updatePalletDetails(state, payload){                
        state.palletDetails = payload        
      },

      

    },
//! ------------------------------- ACTIONS -------------------------------------
    actions: {
    
    },
  


//! -------------------------------- Getters ---------------------------------------------
    getters: {

    }
}
