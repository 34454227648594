<template>
  
   <!--Left Side for cabinet and box type a -->
    <polygon 
      :points="`
          ${box.bottomFrontLeft.x},${box.bottomFrontLeft.y} 
          ${box.bottomBackLeft.x},${box.bottomBackLeft.y}         
          ${box.topBackLeft.x },${box.topBackLeft.y}
          ${box.topFrontLeft.x},${box.topFrontLeft.y}
      `"
      :fill="boxColor"
      :fill-opacity="opacity"
      :stroke="strokeColor"
            />            

    <!-- Bottom (actually even if says top in coordinates)-->
        <polygon 
            :points="`
              ${box.topBackLeft.x},${box.topBackLeft.y}      
              ${box.topBackRight.x},${box.topBackRight.y}      
              ${box.topFrontRight.x},${box.topFrontRight.y}     
              ${box.topFrontLeft.x},${box.topFrontLeft.y}     
            `"
          :fill="boxColor"
          :fill-opacity="opacity"
          :stroke="strokeColor"
          />
     
     
     <!-- Back rectangle for Cabinet-->
    <polygon 
        :points="`
        ${box.bottomBackLeft.x},${box.bottomBackLeft.y}
        ${box.topBackLeft.x },${box.topBackLeft.y}
        ${box.topBackRight.x},${box.topBackRight.y}
        ${box.bottomBackRight.x},${box.bottomBackRight.y}         
        `"
        :fill="boxColor"
        :fill-opacity="opacity"
        :stroke="strokeColor"
        v-if="projection==='cabinet'"
        />

    <!-- Front rectangle for Cabinet-->
    <polygon 
        :points="`
        ${box.bottomFrontLeft.x},${box.bottomFrontLeft.y}
        ${box.topFrontLeft.x },${box.topFrontLeft.y}
        ${box.topFrontRight.x},${box.topFrontRight.y}
        ${box.bottomFrontRight.x},${box.bottomFrontRight.y}         
        `"
        :fill="boxColor"
        :fill-opacity="opacity"
        :stroke="strokeColor"
        v-if="projection==='cabinet'"
            />


    <!-- Front rectangle for military -->
    <polygon 
        :points="`
        ${box.bottomBackLeft.x},${box.bottomBackLeft.y}
        ${box.topBackLeft.x },${box.topBackLeft.y}
        ${box.topBackRight.x},${box.topBackRight.y}
        ${box.bottomBackRight.x},${box.bottomBackRight.y}         
        `"
        :fill="boxColor"
        :fill-opacity="opacity"
        :stroke="strokeColor"
        v-if="projection==='military'"
        />


 <!--Right Side -->
    <polygon 
        :points="`
        ${box.bottomFrontRight.x},${box.bottomFrontRight.y} 
        ${box.bottomBackRight.x},${box.bottomBackRight.y}         
        ${box.topBackRight.x },${box.topBackRight.y}
        ${box.topFrontRight.x},${box.topFrontRight.y}
        `"
        :fill="boxColor"
        :fill-opacity="opacity"
        :stroke="strokeColor"
         v-if="projection==='military'"
    /> 
  
  
  <!--Right Side for Cabinet and box type a -->
    <polygon 
        :points="`
          ${box.bottomFrontRight.x},${box.bottomFrontRight.y} 
          ${box.bottomBackRight.x},${box.bottomBackRight.y}         
          ${box.topBackRight.x },${box.topBackRight.y}
          ${box.topFrontRight.x},${box.topFrontRight.y}
        `"
        :fill="boxColor"
        :fill-opacity="opacity"
        :stroke="strokeColor"
         v-if="projection==='cabinet'"
    /> 
     
    <!-- Top (actually top even if it says bottom in coordinates)-->
      <polygon 
        :points="`
          ${box.bottomBackLeft.x},${box.bottomBackLeft.y}      
          ${box.bottomBackRight.x},${box.bottomBackRight.y}      
          ${box.bottomFrontRight.x},${box.bottomFrontRight.y}     
          ${box.bottomFrontLeft.x},${box.bottomFrontLeft.y}     
        `"
        :fill="boxColor"
        :fill-opacity="opacity"
        :stroke="strokeColor"
        />


</template>




<script lang="ts">

import {ref, watch} from 'vue'


//* Class Point
class Point {
  x: number;
  y: number;
  z: number;

  constructor(x, y, z) {
    this.x = x
    this.y = y
    this.z = z
  }   

}


//* Class Box
class Box {
  bottomFrontLeft: Point;
  bottomFrontRight: Point;
  bottomBackLeft: Point;
  bottomBackRight: Point;
  topFrontLeft: Point;
  topFrontRight: Point;
  topBackLeft: Point;
  topBackRight: Point;
  
  width: number;
  depth: number;
  height: number;
      
// Constructor function
  constructor(bottomFrontLeft, width, depth, height) {
    this.bottomFrontLeft = bottomFrontLeft
    this.width = width
    this.depth = depth
    this.height = height
            
       
    this.bottomFrontRight = new Point( this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y, this.bottomFrontLeft.z)
    this.bottomBackLeft = new Point( this.bottomFrontLeft.x, this.bottomFrontLeft.y, this.bottomFrontLeft.z + this.depth)
    this.bottomBackRight = new Point( this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y, this.bottomFrontLeft.z + this.depth)

    this.topFrontLeft = new Point( this.bottomFrontLeft.x, this.bottomFrontLeft.y + this.height, this.bottomFrontLeft.z)
    this.topFrontRight = new Point(this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y + this.height, this.bottomFrontLeft.z)
    this.topBackLeft = new Point( this.bottomFrontLeft.x, this.bottomFrontLeft.y + this.height, this.bottomFrontLeft.z + this.depth)
    this.topBackRight = new Point( this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y + this.height, this.bottomFrontLeft.z + this.depth)    
    
  }

   //function 
   disp():void { 
      console.log("Function displays Engine is  :   "+ this.width) 
   }

   // Cabinet Projection
    cabinetProjection(alpha: number): void{

        const points = [
            this.bottomFrontLeft, this.bottomFrontRight, this.bottomBackLeft, this.bottomBackRight,
            this.topFrontLeft, this.topFrontRight, this.topBackLeft, this.topBackRight
        ]


        points.forEach(p => {
            p.x = p.x + 0.5 * p.z * Math.cos(alpha)
            p.y = p.y + 0.5 * p.z * Math.sin(alpha)
        })                        

    }

    // Isometric Projection
    // https://en.wikipedia.org/wiki/Isometric_projection#cite_note-1
    isometrixProjection(): void{

        const points = [
            this.bottomFrontLeft, this.bottomFrontRight, this.bottomBackLeft, this.bottomBackRight,
            this.topFrontLeft, this.topFrontRight, this.topBackLeft, this.topBackRight
        ]


        points.forEach(p => {
            p.x = (1/Math.sqrt(6)) * (Math.sqrt(3) * p.x  - Math.sqrt(3) * p.z)
            p.y = (1/Math.sqrt(6)) * ( p.x + 2 * p.y + p.z)
        })                        

    }
    } // end of box class

 

  export default {
    'name': 'projections',
    components: {      
    },
    'props': {
      
        x: {
        type: Number,
        required: true
        },

        y: {
        type: Number,
        required: true
        },
        
      z: {
        type: Number,
        required: true
      },
      
      width: {
        type: Number,
        required: true
      },
      depth: {
        type: Number,
        required: true
      },
      height: {
        type: Number,
        required: true
      },
      boxType: {
        type: String,
        required: true
      },
       projection: {
        type: String,
        default: "cabinet"
      }
    },
    

   
    setup(props){
      
      const height = 400      
      const width = 400

    const selectedProjection = ref(props.projection)
    watch(() => props.projection, (newValue) => {
            selectedProjection.value = newValue;
        });

      let boxColor;
      if (props.boxType === "a") {
        boxColor = "#204fff"
      }else{
        boxColor = "#808080"
      }
       
      const opacity = 0.5
      const strokeColor = "white"

      const alpha = 62  // 30, 62, 68, 75, 99
   
      //const box = new Box(new Point(200, 200, 0), 80, 80, 20)
      //! new Point(props.x, 0, props.z) Zero ist value for z
      const box = new Box(new Point(props.x, props.y, props.z), props.width, props.depth, props.height)

        if (selectedProjection.value === "cabinet") {
            box.cabinetProjection(alpha)
        }else{
            box.isometrixProjection()
        }
       

      return {                
        box,
        boxColor,
        opacity,
        strokeColor
        
      }
    }
  }
</script>