import { render } from "./decisionsPage.vue?vue&type=template&id=5facc6fa&scoped=true"
import script from "./decisionsPage.vue?vue&type=script&lang=ts"
export * from "./decisionsPage.vue?vue&type=script&lang=ts"

import "./decisionsPage.vue?vue&type=style&index=0&id=5facc6fa&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-5facc6fa"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QScrollArea});
