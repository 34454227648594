<template>


    <shipper-pallet v-for="s, key in shippersCorrected"
        :key="key"
        :projection="projection"
        :x="spaceLeft + s.xPosition" 
        :z="s.zPosition" 
        :y="spaceBottom - s.height"
        :height="s.height" 
        :width="s.width" 
        :depth="s.depth"
        :boxType="s.type"
        >
      </shipper-pallet> 

</template>




<script lang="ts">
import shipperPallet from "@/components/visualisations/shipperPallet.vue"
import {onMounted} from 'vue'
 

  export default {
    'name': 'palletLayer',
    components: {      
      'shipper-pallet': shipperPallet
    },
    'props': {
      shippers: {
        type: Array,
        required: true
      },
      spaceLeft: {
        type: Number,
        required: true
      },
      spaceBottom: {
        type: Number,
        required: true
      },
      palletDepth: {
        type: Number,
        required: true
      },
      palletWidth: {
        type: Number,
        required: true
      },
      projection: {
        type: String,
        default: "cabinet"
      },   
      invert: {
        type: Boolean,
        required: true
      },         
      mirrorAxis: {
        type: String,
        default: "depth"
      },         
      scaling: {
        type: Number,
        required: true
      }                  
    },
    

   
    setup(props){
      
      const test = 1 

      let shippersCorrected ;

      // Get maximum value of depth:

      const maxDepth = Math.min(Math.max.apply(Math, props.shippers.map(item =>     
              item.type === 'a'? (item.zPosition + item.depth) * props.scaling : (item.zPosition + item.width) * props.scaling
          )), props.palletDepth)


      const maxWidth = Math.min(Math.max.apply(Math, props.shippers.map(item =>     
              item.type === 'a'? (item.xPosition + item.width) * props.scaling : (item.xPosition + item.depth) * props.scaling
          )), props.palletWidth)


      // Get minimum value of depth and width 
      const distanceThreshold = Math.min(props.shippers[0]['width'], props.shippers[0]['depth']) * 0.9

 

        shippersCorrected = props.shippers.map(item => {
          return {
              xPosition: item.xPosition * props.scaling,
              zPosition: item.zPosition * props.scaling,
              width: item.width * props.scaling,
              depth: item.depth * props.scaling,
              height: item.height * props.scaling,
              type: item.type        
          }})


          // Invert items
          shippersCorrected = props.shippers.map(item => {
          return {
              xPosition: item.xPosition * props.scaling,
              zPosition: props.palletDepth - (item.zPosition + item.depth) * props.scaling,
              width: item.width * props.scaling,
              depth: item.depth * props.scaling,
              height: item.height * props.scaling,
              type: item.type        
          }})



          // For cabinet projection
          shippersCorrected.sort((a,b) => {
            //if (b.zPosition > a.zPosition) return 1;
            //if (b.zPosition < a.zPosition) return -1;

            // ! Accounting for only small differences in z.Position
            if ((b.zPosition - a.zPosition) > distanceThreshold) return 1;
            if (b.zPosition - a.zPosition < -distanceThreshold) return -1;
            
            if (b.xPosition < a.xPosition) return 1;
            if (b.xPosition > a.xPosition) return -1;
          })


          
        
          
        

    onMounted(() => {
      window.console.log(`props.palletDepth: ${props.palletDepth}`) 
      window.console.log(`maxDepth: ${maxDepth}`) 
      })
      

      return {                
        test,
        shippersCorrected
        
      }
    }
  }
</script>