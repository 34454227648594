
import MainPage from  '@/components/decisions/decisionsPage.vue'

export default {
  name: 'Decisions',
  components: {    
    'main-page': MainPage    

  },
};
