export const palletTemplate = {
    id: "standard",    
    parameters: {
      basic: {
        height: {
          name: "Height (cm)", value: 105.00, 
          comment: "Height of pallet",
          format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
          type: "number",
          mask: "#.##"
        },
        width: {
          name: "Width (cm)",
          value: 80.00,
          comment: "Width of pallet",
          format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
          type: "number",
          mask: "#.##"
      },

        depth: {
            name: "Depth (cm)",
            value: 120.00,
            comment: "Depth of pallet",
            format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
            type: "number",
            mask: "#.##"
        },

        pallet_base_height: {
          name: "Base Height (cm)",
          value: 15.00,
          comment: "",
          format: "text-body-scalize-config-items q-px-xs text-black",
          textColor: "blue",
          type: "number",
          mask: "#.##"
        },

        pallet_base_weight: {
          name: "Base Weight (kg)",
          value: 25.00,
          comment: "Weight of pallets without producs",
          format: "text-body-scalize-config-items q-px-xs text-black",
          textColor: "blue",
          type: "number",
          mask: "#.##"
        },

        pallet_weight_limit: {
          name: "Total Limit (kg)",
          value: 1000,
          comment: "Weight limit pallet",
          format: "text-body-scalize-config-items q-px-xs text-black",
          textColor: "blue",
          type: "number",
          mask: "#.##"
        },

        pallet_weight_layer_limit: {
          name: "Layer Limit (kg)",
          value: 150,
          comment: "Weight limit layer",
          format: "text-body-scalize-config-items q-px-xs text-black",
          textColor: "blue",
          type: "number",
          mask: "#.##"
        },

        }, // End of base parameter

      slip: {
        slipLayerHeight: {
          name: "Slip layer height",
          value: 0,
          comment: "Slip layer height",
          format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
          type: "number"
          },
        
          slipLayerBottom: {
          name: "Slip layer bottom",
          value: false,
          comment: "Slip layer bottom",
          format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
          type: "boolean"
          },
        
          slipLayerTop: {
            name: "Slip layer top",
            value: false,
            comment: "Slip layer bottom",
            format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
            type: "boolean"
            },

        slipLayerBetween: {
          name: "Slip layer between x rows",
          value: 0,
          comment: "Slip layer between layers",
          format: "text-body-scalize-config-items q-px-sm bg-green text-white rounded-borders",
          type: "number"
        }          
      },

      // Transport parameters
      transport: {
        height: {
          name: "Transport Internal Height (cm)",
          value: 230.00, 
          comment: "",
          format: "text-body-scalize-config-items q-px-xs text-black",
          textColor: "blue",
          type: "number",
          mask: "#.##"
        },
        depth: {
          name: "Transport Interal Depth (cm)",
          value: 250.00,
          comment: "",
          format: "text-body-scalize-config-items q-px-xs text-black",
          textColor: "blue",
          type: "number",
          mask: "#.##"
        },

      width: {
          name: "Transport Internal Depth (cm)",
          value: 600.00,
          comment: "",
          format: "text-body-scalize-config-items q-px-xs text-black",
          textColor: "blue",
          type: "number",
          mask: "#.##"
      },

      weight_limit: {
        name: "Transport Limit (kg)",
        value: 20000.00,
        comment: "",
        format: "text-body-scalize-config-items q-px-xs text-black",
        textColor: "blue",
        type: "number",
        mask: "#.##"
      }
      },          // End of transport parameters

      optimization: {
          minBlock: 2,
          maxBlock: 3,
          minPercentageBlock: 20 // This sets thin min percentage for each block in case two block is chosen
        }
    }
  }
  