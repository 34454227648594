<template>
    <div>

    <q-dialog v-model="showDialog" persistent transition-show="scale" transition-hide="scale">
        
        <q-card style="max-width: 700px;width:700px;height:700px">
        
        <q-card-actions align="right" class="items-start text-body-scalize">
           <div class="col-xs-auto">                         
              <q-btn flat label="Dimensions" @click="selectedCard='dimensions'" 
                :style="{'color': selectedCard==='dimensions' ? 'blue': 'grey'}" />
            </div>  

          <div class="col-xs-auto">                         
              <q-btn flat label="Slip Layers" @click="selectedCard='slipLayers'" 
                :style="{'color': selectedCard==='slipLayers' ? 'blue': 'grey'}" />
          </div>  

          <div class="col-xs-auto">                         
              <q-btn flat label="Patterns" @click="selectedCard='blockPatterns'" 
                :style="{'color': selectedCard==='blockPatterns' ? 'blue': 'grey'}" />
          </div>  

          <div class="col-xs-auto">                         
              <q-btn flat label="Transport" @click="selectedCard='transport'" 
                :style="{'color': selectedCard==='transport' ? 'blue': 'grey'}" />
          </div>  
          
          
          <q-space />
            
          <div class="col-xs-auto q-mr-md">   
             
             <q-btn-dropdown class="text-body-scalize"  flat color="green" label="Save">
                <div class="row">                    
                  <q-btn flat label="Save Changes" :style="{'color': 'grey'}" outline v-close-popup 
                    @click="saveCurrentProject();$router.push('/')"
                    />
                </div>
                <div class="row">       
                  <q-btn flat label="Save as Template" :style="{'color': 'grey'}" v-close-popup />
                </div>  
                <div class="row">       
                  <q-btn flat label="Load Template" :style="{'color': 'grey'}" v-close-popup />
                </div>  
              </q-btn-dropdown>

          </div>

          <div class="col-xs-auto">   
              <q-btn class="q-mx-xs " flat outline color="black" v-close-popup  @click="$router.push('/')">
                <q-icon name="close" />
              </q-btn>
            </div>     
         

        </q-card-actions> <!-- End of header -->
        


<!-- ########################## Dimension Tab #################################################-->
        <div v-show="selectedCard === 'dimensions'" > 
          <q-card-section>
          <div class="row q-ma-xs" >              
            <div class="text-head-scalize-large">Pallet tolerances</div>
            </div>
          </q-card-section>

          <q-card-section>
            <div class="row q-ma-xs items-center justify-end">              
              <q-btn label="add a second pallet" color="blue" @click="activateSecondPallet(true)" outline v-if="secondPallet.status===false"/>
              <q-btn label="remove second pallet" color="red" @click="activateSecondPallet(false)" outline v-if="secondPallet.status===true"/>
            </div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <div class="row" >
              <div class="col-xs-6">
              <q-img :src = "require(`@/assets/pallet/pallet.png`)" id="pallet_image" style="max-width:300px" /> 

              </div>
            <!--
              <div class="col-md-6" ref="canvasBoxProduct">
                  <canvas id="myCanvasProduct" 
                    ref="canvasProduct" style="border:1px solid #000000;"
                    :width="canvasWidth" :height="canvasHeight"
                    />
                                
              </div>

            -->
              <!-- Main Pallet -->
              <div class="col-xs-3">
                <div class="row">
                  <div v-for="(n, index) in pallet.parameters.basic"  :key="index">
                    <q-input 
                      v-if="n.type === 'number'"
                      class="q-ma-xs"
                      filled  
                      :label="index" 
                      v-model="pallet.parameters.basic[index].value"                                     
                      standout
                      bg-color="white" 
                      :input-style="{ color: n.textColor }"                               
                    >                         
                      <template v-slot:label>                    
                        <em :class="n.format" > {{ n.name }}</em>
                        <!-- comment -->                    
                        <!-- <em v-if="n.comment.length > 1" class="q-px-sm bg-grey text-white rounded-borders"> {{ n.comment }}</em> -->
                      </template>
                    </q-input>

                    <q-checkbox 
                      v-if="n.type === 'boolean'"
                      class="q-ma-xs"

                      standout
                      bg-color="white"   
                      v-model="pallet.parameters.basic[index].value" 
                      >
                        <template v-slot:default>
                          <em :class="n.format" > {{ n.name }}</em>
                          <!-- comment -->                    
                          <!-- <em v-if="n.comment.length > 1" class="q-px-sm bg-grey text-white rounded-borders"> {{ n.comment }}</em> -->
                        </template>
                    </q-checkbox>
                  </div>

                </div>
                            
              </div> <!-- End of Main pallet -->

            <!-- Second Pallet -->
                <div class="col-xs-3" v-if="$store.state.general.secondPallet.status">
                  <div class="row">
                    <div v-for="(n, index) in secondPallet.parameters.basic"  :key="index">
                      <q-input 
                        v-if="n.type === 'number'"
                        class="q-ma-xs"
                        filled  
                        :label="index" 
                        v-model="secondPallet.parameters.basic[index].value"                                     
                        standout
                        bg-color="white" 
                        :input-style="{ color: n.textColor }"                               
                      >                         
                        <template v-slot:label>                    
                          <em :class="n.format" > {{ n.name }}</em>
                          <!-- comment -->                    
                          <!-- <em v-if="n.comment.length > 1" class="q-px-sm bg-grey text-white rounded-borders"> {{ n.comment }}</em> -->
                        </template>
                      </q-input>

                      <q-checkbox 
                        v-if="n.type === 'boolean'"
                        class="q-ma-xs"

                        standout
                        bg-color="white"   
                        v-model="secondPallet.parameters.basic[index].value" 
                        >
                          <template v-slot:default>
                            <em :class="n.format" > {{ n.name }}</em>
                            <!-- comment -->                    
                            <!-- <em v-if="n.comment.length > 1" class="q-px-sm bg-grey text-white rounded-borders"> {{ n.comment }}</em> -->
                          </template>
                      </q-checkbox>
                    </div>

                  </div>
                            
                </div> <!-- End of Second pallet -->

          </div>
          </q-card-section>
        </div> <!-- End of dimensions section -->
       


<!-- ########################## Block pattern ################################################# -->

      <div v-show="selectedCard === 'blockPatterns'" > 
        <q-card-section>
         <div class="row q-ma-xs" >              
            <div class="text-head-scalize-large">Block patterns</div>            
          </div>
        </q-card-section>
        
        <q-card-section>
          <div class="row" >
            <div class="col-xs-6">
            <q-img :src = "require(`@/assets/pallet/block_patterns.svg`)" id="block_image" style="max-width:300px" /> 

            </div>
            <div class="col-xs-6">
              <div class="row q-my-md items-center">              
                
                <div class="q-mr-md text-body-scalize-small">Block pattern min:</div>
                <q-select dense :options="[1, 2, 3, 4, 5]" v-model="pallet.parameters.optimization.minBlock" />
                            
                <div class="q-mr-md text-body-scalize-small">Block pattern max:</div>
                <q-select dense :options="[1, 2, 3, 4, 5]" v-model="pallet.parameters.optimization.maxBlock" />              
              
              </div>
              

              <div class="row q-my-md items-center">
                <div class="col-xs-auto q-mr-md">
                  <div class="text-body-scalize-small">Min % Block:</div>
                </div>
                
                <div class="col-xs-6">           
                   <div class="q-pa-md">
                
                  <!-- <q-input 
                    dense                     
                    outlined
                    v-model="pallet.parameters.optimization.minPercentageBlock" 
                    type="number"
                    :style="{'max-with': '20px'}"
                    >
                      <v-slot-append>
                        <i class="material-icons">%</i>
                      </v-slot-append>
                    </q-input>    -->

                    <q-slider
                      v-model="pallet.parameters.optimization.minPercentageBlock"  
                      :min="0"
                      :max="50"
                      :step="1"
                      color="red"
                      label
                      label-always
                      label-color="red"
                     />


                                                    
                </div>
                </div>

                                
              </div>   
            </div>
          </div>                         
          </q-card-section>
          
                           
      </div> <!-- End of block pattern section -->


<!-- ########################## SLIP PLAYER ################################################# -->
    <div v-show="selectedCard === 'slipLayers'">
       
        <q-card-section>
         <div class="row q-ma-xs" >              
            <div class="text-head-scalize-large">Slip Layers</div>   
            <div v-if="env=='test'" >           
            {{ pallet }} <br>
            <b>Store</b>
            {{ $store.state.general.selected_pallet }}
            </div>
          </div>
        </q-card-section>

         <q-card-section class="q-pt-none">
          <div class="row" >
            <div class="col-xs-6">
            <q-img :src = "require(`@/assets/pallet/green_slip_layers.svg`)" id="pallet_image" style="max-width:300px" /> 

            </div>
          <!--
            <div class="col-md-6" ref="canvasBoxProduct">
                <canvas id="myCanvasProduct" 
                  ref="canvasProduct" style="border:1px solid #000000;"
                  :width="canvasWidth" :height="canvasHeight"
                  />
                              
            </div>

          -->
        <!-- Main Pallet -->   
            <div class="col-xs-6">
                <div v-for="(n, index) in pallet.parameters.slip"  :key="index">
                  <q-input 
                    v-if="n.type === 'number'"
                    class="q-ma-xs"
                    filled  
                    :label="index" 
                    v-model="pallet.parameters.slip[index].value"                 
                    standout
                    bg-color="white"                                    
                  >                         
                    <template v-slot:label>
                    
                      <em :class="n.format" > {{ n.name }}</em>
                      <!-- comment -->                    
                      <!-- <em v-if="n.comment.length > 1" class="q-px-sm bg-grey text-white rounded-borders"> {{ n.comment }}</em> -->
                    </template>
                  </q-input>

                  <q-checkbox 
                    v-if="n.type === 'boolean'"
                    class="q-ma-xs"

                    standout
                    bg-color="white"   
                    v-model="pallet.parameters.slip[index].value" 
                    >
                      <template v-slot:default>
                        <em :class="n.format" > {{ n.name }}</em>
                        <!-- comment -->                    
                        <!-- <em v-if="n.comment.length > 1" class="q-px-sm bg-grey text-white rounded-borders"> {{ n.comment }}</em> -->
                      </template>
                  </q-checkbox>
                </div>
              
            </div> <!-- End of Pallet Parameters -->

          </div>
        </q-card-section>
        </div> <!-- End of Slip Layer Section -->



<!-- ########################## TRANSPORT ################################################# -->
    <div v-show="selectedCard === 'transport'">
       
        <q-card-section>
         <div class="row q-ma-xs" >              
            <div class="text-head-scalize-large">Transport</div>   
            <div v-if="env=='test'" >           
            {{ pallet }} <br>
            <b>Store</b>
            {{ $store.state.general.selected_pallet }}
            </div>
          </div>
        </q-card-section>

         <q-card-section class="q-pt-none">
          <div class="row" >
            <div class="col-xs-6">
            <q-img :src = "require(`@/assets/pallet/transport.svg`)" id="pallet_image" style="max-width:300px" /> 

            </div>
          <!--
            <div class="col-md-6" ref="canvasBoxProduct">
                <canvas id="myCanvasProduct" 
                  ref="canvasProduct" style="border:1px solid #000000;"
                  :width="canvasWidth" :height="canvasHeight"
                  />
                              
            </div>

          -->
        
            <div class="col-xs-6">
                <div v-for="(n, index) in pallet.parameters.transport"  :key="index">
                  <q-input 
                    v-if="n.type === 'number'"
                    class="q-ma-xs"
                    filled  
                    :label="index" 
                    v-model="pallet.parameters.transport[index].value"                 
                    standout
                    bg-color="white"     
                    :input-style="{ color: n.textColor }"                                     
                  >                         
                    <template v-slot:label>                    
                      <em :class="n.format" > {{ n.name }}</em>
                      <!-- comment -->                    
                      <!-- <em v-if="n.comment.length > 1" class="q-px-sm bg-grey text-white rounded-borders"> {{ n.comment }}</em> -->
                    </template>
                  </q-input>

                  <q-checkbox 
                    v-if="n.type === 'boolean'"
                    class="q-ma-xs"

                    standout
                    bg-color="white"   
                    v-model="pallet.parameters.transport[index].value" 
                    >
                      <template v-slot:default>
                        <em :class="n.format" > {{ n.name }}</em>
                        <!-- comment -->                    
                        <!-- <em v-if="n.comment.length > 1" class="q-px-sm bg-grey text-white rounded-borders"> {{ n.comment }}</em> -->
                      </template>
                  </q-checkbox>
                </div>
              
            </div>

          </div>
        </q-card-section>
        </div> <!-- End of Transport Section -->


      </q-card>




      </q-dialog>
   

    
     <!-- third dialog page -->
    <q-dialog v-model="thirdDialog" persistent transition-show="scale" transition-hide="scale">
      <q-card class="bg-teal text-white" style="width: 300px">
        

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="previous page" @click="secondDialog = true; thirdDialog = false" />
          <q-btn flat label="Close" v-close-popup @click="$router.push('/')" />
        </q-card-actions>

      </q-card>
    </q-dialog>
    

    </div>  
</template>


<script lang="ts">
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import saveProject from '@/components/mixins/saveProject.js'

export default {
  name: 'pallet',


  setup() {
    const env = computed(() => {return  process.env.VUE_APP_ENV})
    const store = useStore()

    const {saveCurrentProject} = saveProject()

    const showDialog = ref(true)      
    const pallet_id =  ref('standard')

     const selectedCard = ref('dimensions')

    const palletStore = computed(() => {return store.state.general.selected_pallet})    
    const pallet = ref(palletStore.value)
    
    
    const secondPalletStore = computed(() => {return store.state.general.secondPallet})    
    const secondPallet = ref(secondPalletStore.value)


    const activateSecondPallet = ((payload) => {
      store.commit('general/activateSecondPallet', payload)
    })


    return {
      env,
      pallet,
      secondPallet,
      showDialog,
      pallet_id,
      selectedCard,
      saveCurrentProject,
      activateSecondPallet
    }

  }
 
        
  }
  
</script>

<style lang="scss" scoped>
.q-input{
  min-width: 200px;

  
}
</style>
