
import {computed, ref} from 'vue'
import {useStore} from 'vuex'

export default {
  name: 'errorDialog',
  // props: {   
  //     message: String,
  //     show: Boolean
  //  },


  setup(props) {    
    const store = useStore()


    const showDialog = computed(() => {return store.state.dialogs.decisionDialog}) 
      
  
    const close = () => {       
      store.commit('dialogs/updateDecisionDialog', false)
  
      }
    
    return {
      close,
      store,
      showDialog      
    }

  }

 


}


