import { render } from "./competitorProductAdmin.vue?vue&type=template&id=83a39170&scoped=true"
import script from "./competitorProductAdmin.vue?vue&type=script&lang=ts"
export * from "./competitorProductAdmin.vue?vue&type=script&lang=ts"

import "./competitorProductAdmin.vue?vue&type=style&index=0&id=83a39170&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-83a39170"

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QInput from 'quasar/src/components/input/QInput.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTd,QCheckbox,QBtn,QTh,QDialog,QCard,QCardActions,QIcon,QCardSection,QImg,QInput});qInstall(script, 'directives', {ClosePopup});
