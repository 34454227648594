<template>
  <div>
    <div class="row q-my-lg q-mx-sm justify-center">
      <div class="text-head-scalize q-px-sm text-center text-dark q-my-md">
        IMPROVE YOUR PRODUCT
        <span class="label bg-white text-no-wrap text-red"> PROFIT MARGIN</span
        >,

        <span class="label bg-white text-blue">LOGISTICS</span>

        <span class="label bg-white text-dark"> AND </span>

        <span class="label bg-white text-green"> PACKAGING SUSTAINABILITY</span>
        FOR
        <span class="label bg-white text-no-wrap text-dark">
          FAST MOVING CONSUMER GOODS
        </span>
      </div>
    </div>
    <!-- end of first row -->

    <div
      class="row text-no-wrap q-my-md q-mx-sm justify-evenly items-end text-center"
    >
      <div class="col-12">
        <!-- col-md-3 -->
        <div class="col">
          <q-img
            class="q-my-md"
            :src="require(`@/assets/landing_page/dimensions box.svg`)"
            id="product_image"
            style="max-width: 130px"
          />
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm text-blue">
            OPTIMIZE PACKAGING SHAPES & SIZES
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm text-red">
            REDUCE PACKAGING WEIGHT
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm">
            MEET SUSTAINABILITY TARGETS FASTER
          </div>
        </div>
      </div>

      <div class="col-12 q-mt-xl">
        <!-- col-md-4 -->
        <div class="col">
          <q-img
            class="q-my-md"
            :src="require(`@/assets/landing_page/75 NEEDS_green pallet.svg`)"
            id="pallet_image"
            style="max-width: 210px"
          />
        </div>
        <div class="col" style="">
          <div class="text-body-scalize q-my-sm q-mx-sm text-blue">
            IMPROVE UNITS PER PALLET & EFFICIENCY
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize q-my-sm q-mx-sm text-green">
            REDUCE LOGISTICS COSTS & CO2
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm">
            SIMULATE SUPPLY CHAIN SCENARIOS
          </div>
        </div>
      </div>

      <div class="col-12 q-mt-xl q-mb-xl">
        <!-- col-md-4 -->
        <div class="col">
          <q-img
            class="q-my-md"
            :src="require(`@/assets/landing_page/75 NEEDS_green shelf.svg`)"
            id="calendar_image"
            style="max-width: 275px"
          />
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm text-blue">
            OPTIMIZE IMPACT ON SHELF
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm text-green">
            IMPROVE PRODUCT LISTINGS
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm">
            HARMONIZE CATEGORY ASSORTMENTS
          </div>
        </div>
        <!-- workaroundwhite text to center both products and about page at same width 
        <div class="col" style="">
          <div class="text-body-scalize text-white q-my-sm q-mx-sm">
            ENSURE OPTIMAL RESOURCE INVESTMENTS
          </div>
        </div>
        -->
      </div>
    </div>
    <!-- end of 2. row 
    <div class="row q-my-md q-mx-md justify-evenly items-end text-center">
      <div class="text-head-scalize q-px-md text-center text-dark">
        SCALIZE
        <span class="label bg-white text-red"
          >SIMPLIFIES THE WORK OF DEVELOPMENT TEAMS,</span
        >
        FITTING
        <span class="label bg-white text-blue">IN EXISTING PROCESSES</span>
        AND
        <span class="label bg-white text-green"
          >ENABLING STAKEHOLDER STRATEGY.</span
        >
      </div>
    </div>
    -->
    <!-- end of third row -->

    <!-- end of spacers-->
  </div>
</template>

<script lang="ts">
export default {
  name: "aboutPage",
  components: {},

  data() {
    return {
      position: 0,
    };
  },
};
</script>

<style scoped lang="scss"></style>
