
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import saveProject from '@/components/mixins/saveProject.js'


export default {
  components: {},
  name: 'product',
  
  
  setup(props) {
    
    const store = useStore()
    const env = computed(() => {return process.env.VUE_APP_ENV})
    const windowHeight = computed(() => {return window.innerHeight})


    const {saveCurrentProject} = saveProject()


    const showDialog  = ref(true)
    const selectedCard = ref('dimensions')

    // Canvas for product pic upload
    const uploadCanvas = ref()

    // product 
    const productStore = computed(() => {return store.state.general.selected_product})
    const product = ref(productStore)

    // Target volume
    const targetVolume = computed(() => {return Math.round(store.state.general.selected_product.parameters.height.values.Target.value
      * store.state.general.selected_product.parameters.width.values.Target.value 
      * store.state.general.selected_product.parameters.depth.values.Target.value * 100)/100  })

    // Tolerance values
    const toleranceStore = computed(() => {return store.state.sensitivity.tolerance})
    const tolerance = ref(toleranceStore)

    // Add material
    const addMaterial = ((type) => {
      if(product.value.componentsParameters[type].length > 0){
        const maxID = product.value.componentsParameters[type].reduce((a,b) => a.id > b.id ? a:b).id; 
        product.value.componentsParameters[type].push({id: maxID+1, material: 'material', weight: 0, pcr: '', renewable: '' })
      }else{
        product.value.componentsParameters[type].push({id: 1, material: 'material', weight: 0, pcr: '', renewable: '' })
      }      
    })

    // Remove material
    const removeMaterial = ((type, id) => {     
      const position = product.value.componentsParameters[type].findIndex(v => v.id === id);      
      product.value.componentsParameters[type].splice(position, 1)
      
      //product.value.componentsParameters[type] = cleanArray
    })


    const totalWeight = computed(() => {
      let weight = 0.0                        
      if (product.value.componentsParameters['containerMaterials'].length > 0){                
        weight += product.value.componentsParameters['containerMaterials'].reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight), 0)   
      }
      if (product.value.componentsParameters['closureMaterials'].length > 0){
        weight += (product.value.componentsParameters['closureMaterials'].reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }
      if (product.value.componentsParameters['decorationMaterials'].length > 0){
        weight += (product.value.componentsParameters['decorationMaterials'].reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }      
      return weight
    })


    // Calculate PCR
    const totalPcr = computed(() => {
      let weight = 0.0                        
      if (product.value.componentsParameters['containerMaterials'].length > 0){                
        weight += product.value.componentsParameters['containerMaterials'].filter(e => e.pcr==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight), 0)   
      }
      if (product.value.componentsParameters['closureMaterials'].length > 0){
        weight += (product.value.componentsParameters['closureMaterials'].filter(e => e.pcr==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }
      if (product.value.componentsParameters['decorationMaterials'].length > 0){
        weight += (product.value.componentsParameters['decorationMaterials'].filter(e => e.pcr==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }
      
      return ((weight/totalWeight.value) * 100).toFixed(2)

    })

  // Calculate Virgin %
    const percentageVirgin = computed(() => {
        let weight = 0.0                        
        if (product.value.componentsParameters['containerMaterials'].length > 0){                
          weight += product.value.componentsParameters['containerMaterials'].filter(e => e.pcr==='no').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight), 0)   
        }
        if (product.value.componentsParameters['closureMaterials'].length > 0){
          weight += (product.value.componentsParameters['closureMaterials'].filter(e => e.pcr==='no').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
        }
        if (product.value.componentsParameters['decorationMaterials'].length > 0){
          weight += (product.value.componentsParameters['decorationMaterials'].filter(e => e.pcr==='no').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
        }
      
      return ((weight/totalWeight.value) * 100).toFixed(2)
    })
    



// Calculate Renewable %
    const totalRenewable = computed(() => {
      let weight = 0.0                        
      if (product.value.componentsParameters['containerMaterials'].length > 0){                
        weight += product.value.componentsParameters['containerMaterials'].filter(e => e.renewable==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight), 0)   
      }
      if (product.value.componentsParameters['closureMaterials'].length > 0){
        weight += (product.value.componentsParameters['closureMaterials'].filter(e => e.renewable==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }
      if (product.value.componentsParameters['decorationMaterials'].length > 0){
        weight += (product.value.componentsParameters['decorationMaterials'].filter(e => e.renewable==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }
      
      return ((weight/totalWeight.value) * 100).toFixed(2)

    })

    // Calculate Ratio Volume Pack
    const ratioVolumePack = computed(() => {
        return `${(product.value.volumeParameters['brimfulVolume'].values.target.value/totalWeight.value).toFixed(0)} : 1 `
    })
    
    
    // Upload Image
    const uploadImage = (event: Event) => {

      // show laoder
        Loading.show({
          delay: 200, // ms
          spinner: QSpinnerGears,
          message: 'Uploading picture'
      })

          // Add dialog

          // reset images
          //const input: HTMLInputElement = event.target! as HTMLInputElement;
          const input: HTMLInputElement = event.target as HTMLInputElement;  
          const image = input.files![0];
          
          const reader = new FileReader();
          reader.readAsDataURL(image);

          reader.onload = (event: Event) => {
                  //uploaded_image.value = reader.result;

                  const img = new Image()
                  //img.src = event.target!.result
                  img.src = reader.result as string
                    
                    // Define what is happening on image load
                    img.onload = ((event: Event) => {
                        // Get Canvas
                        const canvas = uploadCanvas.value;      
                        // Set canvas size
                        canvas.height = 200
                        canvas.width = img.width/img.height * 200

                        var ctx = canvas.getContext("2d");                        
                        // Actual resizing
                        ctx.drawImage(img, 0, 0, img.width/img.height * 200, 200);                       
                        const product = store.state.general.selected_product
                        product['image'] =  uploadCanvas.value.toDataURL();
                        //product['image'] = reader.result;
                        store.commit('general/updateProduct', product)
                        window.console.log('original size: ' + (reader.result as string).length)
                        window.console.log('resized size: ' + product['image'].length)
                        Loading.hide(); 

                   
                      store.commit('dialogs/updateDialog', true)
                      store.commit('dialogs/updateMessage', 'uploaded image')

                  
                    })
              

                    

                  

                  //console.log(this.image);
                  //window.console.log(uploaded_image)1                  
              };      

             
            
        }



       
    
    return {
      env,
      store,
      
      windowHeight,
      
      product,
      uploadImage,
      showDialog,
      selectedCard,
      tolerance,
      targetVolume,
      addMaterial,
      removeMaterial,
      totalWeight,
      totalPcr,
      totalRenewable,
      saveCurrentProject,
      uploadCanvas,
      ratioVolumePack,
      percentageVirgin
      

    }
  },

 

    

 
}
