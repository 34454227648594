<template>
    
  <!-- Back rectangle -->
  <polygon 
    :points="`
      ${box.bottomBackLeft.x},${box.bottomBackLeft.y}
      ${box.topBackLeft.x },${box.topBackLeft.y}
      ${box.topBackRight.x},${box.topBackRight.y}
      ${box.bottomBackRight.x},${box.bottomBackRight.y}         
    `"
    :fill="boxColor"
    fill-opacity="0.2"
    :stroke="strokeColor"
    />

  <!--Right Side -->
  <polygon 
    :points="`
      ${box.bottomFrontRight.x},${box.bottomFrontRight.y} 
      ${box.bottomBackRight.x},${box.bottomBackRight.y}         
      ${box.topBackRight.x },${box.topBackRight.y}
      ${box.topFrontRight.x},${box.topFrontRight.y}
    `"
    :fill="boxColor"
    :fill-opacity="opacity"
    :stroke="strokeColor"
    /> 
    
  <!--Left Side -->
  <polygon 
    :points="`
      ${box.bottomFrontLeft.x},${box.bottomFrontLeft.y} 
      ${box.bottomBackLeft.x},${box.bottomBackLeft.y}         
      ${box.topBackLeft.x },${box.topBackLeft.y}
      ${box.topFrontLeft.x},${box.topFrontLeft.y}
    `"
    :fill="boxColor"
    :fill-opacity="opacity"
    :stroke="strokeColor"
    /> 
    
<!-- Bottom (actually even if says top in coordinates)-->
  <polygon 
    :points="`
      ${box.topBackLeft.x},${box.topBackLeft.y}      
      ${box.topBackRight.x},${box.topBackRight.y}      
      ${box.topFrontRight.x},${box.topFrontRight.y}     
      ${box.topFrontLeft.x},${box.topFrontLeft.y}     
      `"
    :fill="boxColor"
    :fill-opacity="opacity"
    :stroke="strokeColor"
    />

    <!-- Top (actually top even if it says bottom in coordinates)-->
    <polygon 
      :points="`
        ${box.bottomBackLeft.x},${box.bottomBackLeft.y}      
        ${box.bottomBackRight.x},${box.bottomBackRight.y}      
        ${box.bottomFrontRight.x},${box.bottomFrontRight.y}       
        ${box.bottomFrontLeft.x},${box.bottomFrontLeft.y}     
      `"
      :fill="boxColor"
      :fill-opacity="opacity"
      :stroke="strokeColor"
      />
    
    <!-- Front rectangle -->
    <polygon 
      :points="`
        ${box.bottomFrontLeft.x},${box.bottomFrontLeft.y}
        ${box.topFrontLeft.x },${box.topFrontLeft.y}
        ${box.topFrontRight.x},${box.topFrontRight.y}
        ${box.bottomFrontRight.x},${box.bottomFrontRight.y}         
      `"
      :fill="boxColor"
      fill-opacity="0.2"
      :stroke="strokeColor"
      />

   
<!-- Markers -->
    <defs>
      <marker id="arrowhead" markerWidth="2" markerHeight="3" 
        refX="0" refY="1.5" orient="auto">
        <polygon points="0 0, 2 1.5, 0 3" />
      </marker>
    </defs>
    
  <!-- Arrow for width -->
  <line 
    :x1="box.bottomFrontLeft.x" 
    :y1="box.bottomFrontLeft.y + baseHeight * 2" 
    :x2="box.bottomFrontRight.x - 10" 
    :y2="box.bottomFrontLeft.y + baseHeight * 2"  
    :stroke="arrowColor"
    stroke-width="2" 
    marker-end="url(#arrowhead)" 
    v-if="projection==='cabinet'"
    />

  <text 
      :x="(box.bottomFrontLeft.x + box.bottomFrontRight.x - baseHeight * 2)/2" 
      :y="box.bottomFrontLeft.y + baseHeight * 4"  
      class="small"
      v-if="projection==='cabinet'"
      >{{Math.round(width / scaling * 100)/100}} cm
    </text>

  <!-- Arrow for Depth -->
  <line 
    :x1="box.topFrontRight.x + baseHeight * 2" 
    :y1="box.topFrontRight.y" 
    :x2="box.topBackRight.x + baseHeight * 2" 
    :y2="box.topBackLeft.y"  
    :stroke="arrowColor"
    stroke-width="2" 
    marker-end="url(#arrowhead)" 
    v-if="projection==='cabinet'"
    />

  <text 
      :x="box.topBackRight.x + baseHeight * 2" 
      :y="(box.topFrontRight.y + box.topBackLeft.y)/2 + baseHeight"  
      class="small"
      v-if="projection==='cabinet'"
      >{{Math.round(depth / scaling * 100)/100}} cm
      
    </text>

  <!-- Arrow for Height -->
  <line 
    :x1="box.bottomFrontLeft.x - baseHeight * 2" 
    :y1="box.bottomFrontLeft.y" 
    :x2="box.bottomFrontLeft.x - baseHeight * 2" 
    :y2="box.bottomFrontLeft.y - height"  
    :stroke="arrowColor" 
    stroke-width="2" 
    marker-end="url(#arrowhead)" 
    v-if="projection==='cabinet'"
    />

  <text 
      :x="box.bottomFrontLeft.x - baseHeight * 8" 
      :y="box.bottomFrontLeft.y - height * 1.2"  class="small"
      v-if="projection==='cabinet'"
      >{{Math.round(usedHeight / scaling * 100)/100}} cm
    </text>

<!-- ********************** Military Perspective  ************************************ -->

  <!-- Arrow for Depth -->
  <line 
    :x1="box.bottomBackRight.x" 
    :y1="box.bottomBackRight.y + width * 0.15" 
    :x2="box.bottomFrontRight.x" 
    :y2="box.bottomFrontRight.y + width * 0.15"  
    :stroke="arrowColor" 
    stroke-width="2" 
    marker-end="url(#arrowhead)" 
    v-if="projection==='military'"
    />

   <text 
      :x="box.bottomBackRight.y + width * 0.15" 
      :y="box.bottomFrontRight.y + 4 * baseHeight"  
      class="small"
      v-if="projection==='military'"
      >{{Math.round(depth / scaling * 100)/100}} cm
    </text>

  <!-- Arrow for Width -->
  <line 
    :x1="box.bottomBackLeft.x - depth * 0.2" 
    :y1="box.bottomBackLeft.y + width * 0.1"
    :x2="box.bottomBackRight.x - depth * 0.2" 
    :y2="box.bottomBackRight.y + width * 0.1"
    :stroke="arrowColor"
    stroke-width="2" 
    marker-end="url(#arrowhead)" 
    v-if="projection==='military'"
    />

  <text 
      :x="box.bottomBackLeft.x - depth * 0.2" 
      :y="box.bottomBackLeft.y + width * 0.3"  
      class="small"
      v-if="projection==='military'"
      >{{Math.round(width / scaling * 100)/100}} cm
      
    </text>

</template>




<script lang="ts">
import {ref, watch} from 'vue'


//* Class Point
class Point {
  x: number;
  y: number;
  z: number;

  constructor(x, y, z) {
    this.x = x
    this.y = y
    this.z = z
  }   

}


//* Class Box
class Box {
  bottomFrontLeft: Point;
  bottomFrontRight: Point;
  bottomBackLeft: Point;
  bottomBackRight: Point;
  topFrontLeft: Point;
  topFrontRight: Point;
  topBackLeft: Point;
  topBackRight: Point;
  
  width: number;
  depth: number;
  height: number;
      
// Constructor function
  constructor(bottomFrontLeft, width, depth, height) {
    this.bottomFrontLeft = bottomFrontLeft
    this.width = width
    this.depth = depth
    this.height = height
                   
    this.bottomFrontRight = new Point( this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y, 0)
    this.bottomBackLeft = new Point( this.bottomFrontLeft.x, this.bottomFrontLeft.y, this.depth)
    this.bottomBackRight = new Point( this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y, this.depth)

    this.topFrontLeft = new Point( this.bottomFrontLeft.x, this.bottomFrontLeft.y + this.height, 0)
    this.topFrontRight = new Point(this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y + this.height, 0)
    this.topBackLeft = new Point( this.bottomFrontLeft.x, this.bottomFrontLeft.y + this.height, this.depth)
    this.topBackRight = new Point( this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y + this.height, this.depth)    
    
  }

   //function 
   disp():void { 
      console.log("Function displays Engine is  :   "+ this.width) 
   }

   // Cabinet Projection
    cabinetProjection(alpha: number): void{

        const points = [
            this.bottomFrontLeft, this.bottomFrontRight, this.bottomBackLeft, this.bottomBackRight,
            this.topFrontLeft, this.topFrontRight, this.topBackLeft, this.topBackRight
        ]


        points.forEach(p => {
            p.x = p.x + 0.5 * p.z * Math.cos(alpha)
            p.y = p.y + 0.5 * p.z * Math.sin(alpha)
        })                        

    }

    // Isometric Projection
    // https://en.wikipedia.org/wiki/Isometric_projection#cite_note-1
    isometrixProjection(): void{

        const points = [
            this.bottomFrontLeft, this.bottomFrontRight, this.bottomBackLeft, this.bottomBackRight,
            this.topFrontLeft, this.topFrontRight, this.topBackLeft, this.topBackRight
        ]


        points.forEach(p => {
            p.x = (1/Math.sqrt(6)) * (Math.sqrt(3) * p.x  - Math.sqrt(3) * p.z)
            p.y = (1/Math.sqrt(6)) * ( p.x + 2 * p.y + p.z)
        })                        

    }
    } // end of box class

 

  export default {
    'name': 'pallet',
    components: {      
    },
    'props': {
      x: {
        type: Number,
        required: true
      },
      y: {
        type: Number,
        required: true
      },      
      width: {
        type: Number,
        required: true
      },
      depth: {
        type: Number,
        required: true
      },
      height: {
        type: Number,
        required: true
      },
      scaling: {
        type: Number,
        required: true
      },
      baseHeight: {
        type: Number,
        required: true
      },
      usedHeight: {
        type: Number,
        required: true
      },
      projection: {
        type: String,
        default: "cabinet"
      }      
    },
    

//* ---------------- SETUP ------------------------------- *//
  setup(props){
    

    const selectedProjection = ref(props.projection)
    watch(() => props.projection, (newValue) => {
      window.console.log("new projection value")
            selectedProjection.value = newValue;
        });

    const boxColor = "#A47449"
    const strokeColor = "#63462D"
    const arrowColor = "grey"
    const opacity = 0.8

    const alpha = 62 // 30, 62, 68, 75, 99
  
    //const box = new Box(new Point(200, 200, 0), 80, 80, 20)
    //! new Point(props.x, props.y, 0) Zero ist value for z
    const box = new Box(new Point(props.x, props.y, 0), props.width, props.depth, props.baseHeight)

    if (selectedProjection.value === "cabinet") {
      box.cabinetProjection(alpha)
      }else{
        box.isometrixProjection()
      }
                  
    return {                
      box,
      boxColor,
      opacity,
      strokeColor,
      arrowColor        
    }
  }
  }
</script>