
import { useStore } from 'vuex'
import { computed } from "vue";
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import userData from '@/components/mixins/userData.js';

export default function() {
   
  const store = useStore()

  const env = computed(() => process.env.VUE_APP_ENV) 




  //* ---------------------- Shipping Boxes --------------------------------------------------
  const loadShippingBoxTemplates = async () => {

    window.console.log('!!!!!!!Loading Shipping Box Templates data!!!!!!!')

    // REset data
    store.commit('templates/updateTemplatesShippingBox', [])

    // Form data for post request to share project
    const requestOptions = {          
      method: "POST",
      headers: { "Content-Type": "application/json" },
    
      body: JSON.stringify({ 
        actionType: 'queryShippingBoxTemplates',            
        user: store.state.userData,                      
        })
        };

              
      const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "queryCosmos" , requestOptions).catch((error) => {
        window.console.log(error)       
        }) ;              
                

      if (response.status == 200) {                    
        const data = await response.json();                   
        store.commit('templates/updateTemplatesShippingBox', data.data)
                
      }else{          
        store.commit('dialogs/updateMessage', 'Error loading shipping box templates')
        store.commit('dialogs/updateDialog', true)                                        
      }
        

    } // End load shipping box templates

  const addShippingBoxTemplate = async (templateName) => {

    Loading.show({
      delay: 400, // ms
      spinner: QSpinnerGears,
      message: 'Saving template'
    })
      
  
      // Form data for post request to share project
      const requestOptions = {          
        method: "POST",
        headers: { "Content-Type": "application/json" },
      
        body: JSON.stringify({ 
          actionType: 'addShippingBoxTemplate',            
          user: store.state.userData,         
          data: store.state.general.shippingBox,
          name: templateName
          })
          };  
                
        const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "updateCosmos" , requestOptions).catch((error) => {
          window.console.log(error)       
          }) ;              
                  
  
        if (response.status == 200) {                    
          const data = await response.json();                   

          window.console.log(data.content)
          
          if (data.content === 'template added') {
            Loading.hide()
            store.commit('dialogs/updateMessage', 'Added shipping box template')
            store.commit('dialogs/updateDialog', true)                                        

            // Reload Templates
            loadShippingBoxTemplates();                                    

            }else{          
              Loading.hide()
              store.commit('dialogs/updateMessage', 'Template name already exists, please choose a different one')
              store.commit('dialogs/updateDialog', true)  
              
              
            
            }
          }else{
            Loading.hide()
            store.commit('dialogs/updateMessage', 'Error adding shipping box template')
            store.commit('dialogs/updateDialog', true)                                        
          }
          
  
      } // End load shipping box templates

    



    //
    return {
      loadShippingBoxTemplates,
      addShippingBoxTemplate
    }
  }
          
  