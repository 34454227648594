
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import optimizer from '@/components/mixins/optimize.ts'


export default {
  name: 'errorDialog',
  //  props: {   
  //     message: String,
  //     show: Boolean
  // },


  setup() {    
    const store = useStore()
    const {getSensitivity} = optimizer()


    // Show Dialog
    const dialogShow = computed(() => {      
        return store.state.dialogs.checkSensitivityParameters
        })

    const productVolume = computed(() => {
      return  store.state.general.selected_product.parameters.height.values.Target.value *        
        store.state.general.selected_product.parameters.width.values.Target.value  *
        store.state.general.selected_product.parameters.depth.values.Target.value
      })



    const ignoreWeights = ref(false)
    const restrictTolerance = ref(false)


    // Get values for sensitivity Tolerance
    const checkToleranceSensitivity = computed(() => {
      return  store.state.sensitivity.tolerance.ml / productVolume.value > 0.02
    })

          
    const ajustedOptimize= async () => {           

      if (restrictTolerance.value){        
        store.commit('sensitivity/updateTolerance', {ml: productVolume.value * 0.02})        
        }      
      store.commit('dialogs/updateCheckSensitivityParameters', false)

      window.console.log(store.state.sensitivity.tolerance)
      getSensitivity()


    }
    

    const close = () => {
       store.commit('dialogs/updateCheckSensitivityParameters', false)

    }
    return {
      close,
      store,
      dialogShow,      
      ajustedOptimize,
      checkToleranceSensitivity,
      productVolume,
      ignoreWeights,
      restrictTolerance
      
    }

  },

 


}


