
// https://www.angularfix.com/2021/10/error-loading-svg-data-with-html2canvas.html


<template>
  
  <svg :width="width" :height="height" xmlns="http://www.w3.org/2000/svg" fill="none" :style="{'border': 'none'}" >
  
   <defs>
        <marker id="arrowheadPallet" markerWidth="2" markerHeight="3" 
          refX="0" refY="1.5" orient="auto">
          <polygon points="0 0, 2 1.5, 0 3" 
          :fill="strokeColorArrows" />
        </marker>
      </defs>
      
    <!-- Arrow for width -->
    <line 
      :x1="startLeft" 
      :y1="pallet.depth * scaling + startBottom * 1.2" 
      :x2="startLeft + pallet.width * scaling" 
      :y2="pallet.depth * scaling + startBottom * 1.2"
      :stroke="strokeColorArrows" 
      stroke-width="2"     
      marker-end="url(#arrowheadPallet)"     
      />

  <!-- text width -->
  <text :x="startLeft" :y="pallet.depth * scaling + startBottom * 1.5" class="svg-text">     
    {{pallet.width}} cm
  </text>


  <!-- Arrow for depth -->
    <line 
      :x1="startLeft * 0.85" 
      :y1="pallet.depth * scaling + startBottom" 
      :x2="startLeft * 0.85" 
      :y2="startBottom"
      :stroke="strokeColorArrows" 
      stroke-width="2"     
      marker-end="url(#arrowheadPallet)"     
      />

  <!-- text depth -->
  <text :x="startLeft * 0.1" :y="pallet.depth/2 * scaling + startBottom" class="svg-text">     
    {{pallet.depth}} cm
  </text>
  
  
  
  <rect 
      :x="startLeft" 
      :y="startBottom" 
      :width="pallet.width * scaling" 
      :height="pallet.depth * scaling"
      stroke-width="2"
      stroke="grey" 
      fill="white" />
      
    <rect v-for="(item, index) in pallet.layer" 
        :key="index"  
        :width="item.width * scaling"
        :height="item.depth * scaling"
        :x="item.xPosition * scaling + startLeft"
        :y="item.zPosition * scaling + startBottom"
        :stroke="item.type==='a' ? '#204fffE6' : 'black'" 
        fill="none" 
        stroke-width="1" 
        />        
    
    </svg>

   
    
</template>

<script lang="ts">


//import draggableDiv from '@/components/report/draggableDiv.vue'; // @ is an alias to /src


export default {
  name: 'Home',
  components: {
    //'draggable-div': draggableDiv
  },
  props: {
    height: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: true   
    },
    pallet: {
      type: Array,
      required: true
    }
    
    },

    setup(props) {
      const scaling = Math.min(props.width/props.pallet.width, props.height/props.pallet.depth) * 0.5

      const startLeft = props.width * 0.3
      const startBottom = props.height * 0.3
      const strokeColorArrows = "grey"
      

      return {
        scaling,
        startLeft,
        startBottom,
        strokeColorArrows
      }
    }

  
};
</script>
