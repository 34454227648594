export default {
    namespaced: true,
    
    /* ------------------- STATE -------------------------------------------------------- */
    state: {

      sensitivityRun: "not_executed",
      tolerance: {ml: 3},   
      sensitivityRunLogs: {},
      executed: "false"
        
    },


    /* ------------------- MUTATIONS -------------------------------------------------------- */
    mutations: {

       // Function to update product
       updateSensitivityRun(state, payload: string){
        window.console.log(`updated sensitivity run: ${payload}`)
        state.sensitivityRun = payload
      },

      // Function to update product
      updateTolerance(state, payload){
        state.tolerance = payload   
      },

      updateSensitivityRunLogs(state, payload){
        state.sensitivityRunLogs = payload
      },


      updateSensitivityExecuted(state, payload){
          state.executed = payload
      }
     


    },
    actions: {
    },
    modules: {
    }
  }
  
