import { render } from "./MainPage.vue?vue&type=template&id=292831f5&scoped=true"
import script from "./MainPage.vue?vue&type=script&lang=ts"
export * from "./MainPage.vue?vue&type=script&lang=ts"

import "./MainPage.vue?vue&type=style&index=0&id=292831f5&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-292831f5"

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import Scroll from 'quasar/src/directives/Scroll.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QImg,QPageContainer,QBtn,QSeparator,QPageSticky,QItem,QList,QItemSection});qInstall(script, 'directives', {Scroll});
