export const shippingBoxTemplate = {  
    id: "standard",
    parameters: {                   
      stackingLayer: {
        name: "Product Layers",
        value: 1,
        comment: "",
        format: "q-px-sm bg-black text-white rounded-borders text-body-scalize-config-items"
      
      },
      minNumberProducts: {
        name: "min Products",
        value: 4,
        comment: "Minimum # of Products",
        format: "q-px-sm bg-black text-white rounded-borders text-body-scalize-config-items"
      },
      maxNumberProducts: {
        name: "max Products",
        value: 16,
        comment: "Maximum # of Products",
        format: "q-px-sm bg-black text-white rounded-borders text-body-scalize-config-items"
      },
      airHeight: {
        name: "Air Height (cm)",
        value: 0.2,
        comment: "",
        format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
      },
      airDepth: {
        name: "Air Depth (cm)",
        value: 0.2,
        comment: "is multiplied by 2",
        format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
      },
      airWidth: {
        name: "Air Width (cm)",
        value: 0.2,
        comment: "is multiplied by 2",
        format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
      },
      
      gapBetweenShrinks: {
        name: "Gap between shrinks (cm)",
        value: 0.0,
        comment: "",
        format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
      },          
      
      shrinksPlasticWidth: {
        name: "Plastic around shrinks width (cm)",
        value: 0.2,
        comment: "is multiplied by 2",
        format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
      },

      shrinksPlasticDepth: {
        name: "Plastic around shrinks depth (cm)",
        value: 0.1,
        comment: "is multiplied by 2",
        format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
      },

      shrinksMinNumberProducts: {
        name: "Min products in shrink",
        value: 2,
        comment: "",
        format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
      },

      shrinksMaxNumberProducts: {
        name: "Max products in shrink",
        value: 10,
        comment: "",
        format: "q-px-sm bg-deep-orange text-white rounded-borders text-body-scalize-config-items"
      },

      
    },

    materialParameters: {
      
      thicknessCarton: {
        name: "Thickness Carton",
        value: 0.3,
        comment: "",
        format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
      },
      cartonWrappingHeight: {
        name: "Carton Layers in H direction",
        value: 2,
        comment: "",
        format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
      },
      cartonWrappingWidth: {
        name: "Carton Layers in W direction",
        value: 2,
        format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
      },
      cartonWrappingDepth: {
        name: "Carton Layers in D direction",
        value: 2,
        comment: "",            
        format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
      },
      cartonWeight: {
        name: "Carton weight (gsm)",
        value: 300.00,
        comment: "",
        format: "q-px-sm text-body-scalize-config-items",
        formatInput: {"color": "blue"}
      }
      
      // stackingLayer: 1,    
      // toleranceBoxOpening: 0.2,
      // toleranceBoxSide: 0.2,
      // cmThicknessShippingBoxSides: 0.2,
      // cmThicknessShippingBoxBottomTop: 0.2,
      // cmSpaceRowTubes: 0.1                  
    },

    fixedBoxParameters: {
      
      outerHeight: {
        name: "Outer Height (cm)",
        value: 21.1,
        comment: "",
        format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
      },

      innerHeight: {
        name: "Inner Height (cm)",
        value: 19.85,
        comment: "",
        format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
      },

      outerDepth: {
        name: "Outer Depth (cm)",
        value: 40.0,
        comment: "",
        format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
      },

      innerDepth: {
        name: "Inner Depth (cm)",
        value: 36.7,
        comment: "",
        format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
      },

      outerWidth: {
        name: "Outer Width (cm)",
        value: 60.0,
        comment: "",
        format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
      },

      innerWidth: {
        name: "Inner Width (cm)",
        value: 56.7,
        comment: "",
        format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
      },

      boxWeight: {
        name: "Box Weight (kg)",
        value: 2.7,
        comment: "",
        format: "q-px-sm bg-blue text-white rounded-borders text-body-scalize-config-items"
      }
  },

    optimizationParameters: {
      wEqualsD: false,
      fixedBox: false,
      fixedBoxTwoBlock: false
    },
    
    limitParameters: {
      weight: {
        name: "Weight limit (kg)",
        value: 15.00,
        comment: "",
        format: "q-px-sm ounded-borders text-body-scalize-config-items"
      }
    }
  }