<template>
  <div>


    <!-- <div class="row q-ma-xs">
      <carry-over-table />
    </div> -->

    <div class="row q-ma-xs" v-if="env=='dev'">
      <h5>Dev Area</h5>    
      <div class="row col-12">         
        <three-dim-pallet />    
      </div>
    </div>
    
  
    <div class="row q-ma-xs">
      <decisions-table />
    </div>



    <div class="row q-ma-md">
      <q-btn color="red" label="Remove all options"
        @click="
        $store.commit('decisions/updateSelectedOptionsResult', []);
        $store.commit('decisions/removeSelectedOptionsSensitivity', []);
        $store.commit('decisions/updateDecisionOptions', [])
        "
        />
    </div>

      <div class="row dev" v-if="env=='dev'">
          <h4>Dev area </h4>
          
          Number Options: {{ $store.state.decisions.selected_options_full.length }}          
          <br>
          <br>
          <h4> Full set of selected Options </h4>
          {{ $store.state.decisions.selected_options_full }}
          <br>
          <br>
          <h4>Options to analyse</h4>
          {{ $store.state.decisions.optionsAnalyze }}
          
      </div>

      <!-- Decision Table -->
      <!-- <div class="row q-ma-xs">
        <decisions-table />
      </div> -->

    
    <hr>
    
    <div class="row justify-left">
            <div class="col-md-8 q-ma-md">
         
         <q-scroll-area
            :thumb-style="thumbStyle"
            :content-style="contentStyle"
            :content-active-style="contentActiveStyle"
            style="height: 500px; max-width: 800px;"
          >
             <shelf />
         </q-scroll-area>
      </div>
      <div class="col-md-5">
        <!-- <h5>Table for competitor efficiency</h5> -->
      </div>
    </div>
  </div>

  <decision-dialog />


</template>

<script lang="ts">
import {computed, ref} from 'vue'
import {useStore} from 'vuex'

import decisionsTable from '@/components/decisions/decisionsTable.vue'
import shelf from '@/components/decisions/shelf.vue'
import decisionDialog from '@/components/decisions/decisionDialog.vue'


import threeDimPallet from '@/components/visualisations/threeDimPallet.vue'; // @ is an alias to /src



export default {
  name: 'mainPage',
  components: {
      //'carry-over-table':  carryOverTable,
      //'decisions-table':  decisionsTable,
        'three-dim-pallet': threeDimPallet,
      'decisions-table': decisionsTable,
      shelf,
      'decision-dialog': decisionDialog
   
  },

  setup() {
    const env = computed(() => process.env.VUE_APP_ENV)

    const store = useStore()
    
    return {
      env,
      store
    }

  },

  data(){ 
   
   return{
        position: 0,
        pages: {
            about: true,
            meetScalize: false,
            products: false,
            needs: false,
        }
      
    }
  }, // End of data

    
  
}
</script>


<style scoped lang="scss">
div.top {
  color: white;
  background-color: #32A287;

}
div.main {
  color: white;
  background-color: #0A1128;
  height: 1600px;

}
.hightlight {
  color:#32A287;
  background: yellow;
}

.q-btn.active {
    color: red;
    background-color: white;

  }

</style>