
import {computed} from 'vue'
import selectProject from '@/components/general_components/selectProject.vue'
import {useStore} from 'vuex'


export default {
    name: 'popUpMenu',   
    components: {
        'select-project': selectProject
    },
    props: {
        show: Boolean
    },


    setup(){
        const store = useStore()

        const categories = computed(() => store.state.general.categories)

        const onCategoryClick = (category: string) => {
            window.console.log('category clicked')
            window.console.log(category)
            window.console.log(store.state.user.userProjects)
            window.console.log(store.state.general.selected_category["name"])
            window.console.log(store.state.user.userProjects.filter(p => p["category"] == category) )
            store.commit('general/updateSelectedCategory', category)
        }

      

        return {
            store,
            categories,            
            onCategoryClick
        }
    }
    ,

/* --------------------- Data ------------------------------------ */
    data() {
        return {
            counter: 1,
            projectChoice: true,
            link: ''
        }

        
    },



    // get result() {
    //     window.console.log( this.$store.state.general.data)
    //     return this.$store.state.general.data
    // },
/* --------------------- WATCH ------------------------------------ */
    

/* --------------------- Computed ------------------------------------ */
    computed: {
       
    

           // Get environment
        env() {       
          return process.env.VUE_APP_ENV
        },

      
       
        
        },
  

  
       

        
/* --------------------- Mounted ------------------------------------ */
    mounted: function() {
       window.console.log('pop up menu mounted')
       
        
} // End of mounted

}
