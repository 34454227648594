<template>
  <q-page>
    <!-- Spacer row -->
    <div class="row q-ma-xl" />

    <div class="text-body-scalize q-my-md q-ml-lg"> <h4>Settings</h4> </div>

    <div class="row q-ma-xl" >
      
      <q-btn class="q-ma-md" label="Projects" outline 
        @click="selectedCard='projects'" 
        :style="{'color': selectedCard==='projects' ? 'blue': 'grey'}" />
      
      <q-btn class="q-ma-md" label="Competitor Products" outline  
        @click="selectedCard='competitorProducts'" 
        :style="{'color': selectedCard==='competitorProducts' ? 'blue': 'grey'}" 
        />
      
      <q-btn class="q-ma-md" label="Calculation Parameters" outline 
        @click="selectedCard='calculationParameters'"
        :style="{'color': selectedCard==='calculationParameters' ? 'blue': 'grey'}" 
        />

      <q-btn class="q-ma-md" label="Logs" outline 
        @click="selectedCard='logs'"
        :style="{'color': selectedCard==='logs' ? 'blue': 'grey'}" 
        />   

      <q-btn class="q-ma-md" label="Mass Analysis (preview)" outline 
        @click="$router.push('/mass_analysis')"        
        />   


      <q-btn class="q-ma-md" label="Save" color="blue"  @click="save"/>   

          
    </div>


    <q-separator class="q-ma-xs" />

    <!-- Tab calculation paramters -->        
    <user-settings v-if="selectedCard==='calculationParameters'"/>

    <!-- Tab projects -->    
    <project-admin  v-if="selectedCard==='projects'"/>

    <div v-if="selectedCard==='competitorProducts'">
      <custom-product  class="q-my-md q-ml-lg"/>      
      <competitor-products />
    </div>
  

    <div class="dev" v-if="selectedCard==='logs'">
      <logs />
    </div>

    
    
    <!-- Row for dev Information -->
    <div class="q-my-md q-ml-lg" v-if="env=='dev'" >
      Box for Dev information          
      selected Page: {{selectedCard}}
    </div> 


    
  </q-page>
</template>

<script lang="ts">
import{computed, ref} from 'vue'
import {useStore} from 'vuex'
import customProduct from '@/components/config/customProduct.vue'
import projectAdmin from '@/components/config/projectAdmin.vue'
import competitorProducts from '@/components/config/competitorProductAdmin.vue'
import logs from '@/components/config/logs.vue'
import userSettings from '@/components/config/userSettings.vue'
import saveProject from '@/components/mixins/saveProject'

export default {
  name: 'Config',
  components: {   
      'custom-product': customProduct,
      'project-admin': projectAdmin,
      'competitor-products': competitorProducts,
      'user-settings': userSettings,
      'logs': logs
  },

  setup(){
    const env = computed(() => {return process.env.VUE_APP_ENV})
    const store = useStore()
    
    const {saveCurrentProject} = saveProject()


        //* Function to update Project Name
        const save = async () => {                                                
            await saveCurrentProject();      
            
            store.commit('dialogs/updateMessage', 'Project updated.')
            store.commit('dialogs/updateDialog', true)
        }


    const selectedCard = ref('dimensions')

    return {
      env,
      selectedCard,
      save
    }

  }

  

};
</script>

<style>

</style>
