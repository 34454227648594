import store from ".."
import { productTemplate} from "../templates/product"
import { shippingBoxTemplate} from "../templates/shippingBox"
import { palletTemplate} from "../templates/pallet"

export default {
    namespaced: true,
    
    //*    ----------------------------- STATE -------------------------------------------------------- * //
    state: {
      
      // --------------- Standard Product -------------------------
      standardProduct: productTemplate,
      

      // --------------- Selected Product -------------------------
      selected_product: productTemplate,


    // --------------- Product Sensitivity Parameters -------------------------
      product_sensitivity: {
        parameters: {
          height: {
            min: 0,
            max: 0,
            ref: 0,
            step: 0
          },
          width: {
            min: 0,
            max: 0,
            ref: 0,
            step: 0
          },
          depth: {
            min: 0,
            max: 0,
            ref: 0,
            step: 0
          }
          

        }

      },

      all_reference_products: [],
      selected_reference_products: [],

  //* --------------------------- Shipping Box --------------------------------------------
      shippingBox: shippingBoxTemplate,
      

  // ------------------------ Standard values Shipping Box ----------------------------------
      shippingBoxStandard: shippingBoxTemplate, 
       
//* --------------------------- Pallet --------------------------------------------
      standardPallet: palletTemplate,
      selected_pallet: palletTemplate, 
      
      //* ------------------ Second pallet
      secondPallet: {
        id: "standard",
        status: false,
        parameters: {
          basic: {
            height: {
              name: "Height (cm)", value: 140.00, 
              comment: "Height of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
            },
            width: {
              name: "Width (cm)",
              value: 100.00,
              comment: "Width of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
          },

          depth: {
              name: "Depth (cm)",
              value: 120.00,
              comment: "Depth of pallet",
              format: "text-body-scalize-config-items q-px-sm bg-blue text-white rounded-borders",
              type: "number",
              mask: "#.##"
          },


          pallet_base_height: {
            name: "Base Height (cm)",
            value: 15.00,
            comment: "",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_base_weight: {
            name: "Base Weight (kg)",
            value: 30.00,
            comment: "Weight of pallets without producs",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_weight_limit: {
            name: "Total Limit (kg)",
            value: 1000,
            comment: "Weight limit pallet",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          pallet_weight_layer_limit: {
            name: "Layer Limit (kg)",
            value: 150,
            comment: "Weight limit layer",
            format: "text-body-scalize-config-items q-px-xs text-black",
            textColor: "blue",
            type: "number",
            mask: "#.##"
          },

          } // End of base parameter

        }
      }, //* End of second pallet

      // Data Array for results from python
      data: [],

      // Data Array for sensitiviy results from python
      dataSensitivity: [],

      // Parameters for sensitiviy analysis
      parametersSensitivity: null,

      // Selected parameters to filter sensitivity table
      filterSensitiviyTable: [],

      // Selected options in Table
      selected_options: [],

      optionToVisualize: null,
      
      // Selected Options from sensitivity analysis
      selected_options_sensitivity: [],
          
       // Available product types with standard values
      product_types: [
         {  
          id: "CustomProduct",
          parameters: {
            height: {mean: 18.7, min: 16.0, max: 19.0},
            width: {mean: 7.9, min: 7.5, max: 8.0},
            depth: {mean: 3.3, min: 3.5, max: 3.8},
            volume: 50
          }
        },
        {
          id: "Tube",  
          parameters: {            
            volume: 150
          }
        },
        {          
          id: "Tin",              
          parameters: {            
            volume: 150
          }
        },
        {
          id: "Stick",              
          volume: 150
        }

      ],


      // Categories
      categories: [
        {
          id: "1",
          name: "Shower"
        },
        {
          id: "2",
          name: "Deo"
        },
        {
          id: "3",
          name: "Other"
        },

      ],

      selected_category:  {
        id: "3",
        name: "Other"
      },



      // Projects
      //projects: [],

      selected_project: {
        name: "",
        id: "",
      
      },

      // Pallet Types
      pallet_types: {  
        id: "standard",
        parameters: {
          height: 140.00,    
          width: 80.00,
          depth: 120.00
        }
        
      },  

      //* Logs
      calculationRunLogs: {},



    },


    /* ------------------- MUTATIONS -------------------------------------------------------- */
    mutations: {

      // Function to update product
      updateProduct(state, payload){
        window.console.log("Updating product in store")
        window.console.log(payload)
        state.selected_product = payload
        window.console.log("Product in store module general: ")
        window.console.log(state.selected_product)
      },

       // Function to update product sensitiviy
       updateProductSensitivity(state, payload){
        window.console.log("Updating product sensitivity")
        window.console.log(payload)
        state.product_sensitivity = payload
     
      },

      // Update shipping box
      updateShippingBox(state, payload){
        state.shippingBox = payload        
        window.console.log("New shipping box: " + state.shippingBox)
      },

       // Update pallet
       updatePallet(state, payload){
        window.console.log("updating pallet")
        state.selected_pallet = payload        
        window.console.log("New Pallet: " + state.selected_pallet)
      },

      // Update second pallet
      updateSecondPallet(state, payload){
        window.console.log("updating second pallet")
        state.secondPallet = payload        
      },

      // Activate second pallet
      activateSecondPallet(state, payload){
        window.console.log("activating second pallet")
        state.secondPallet.status = payload        
      },


      // Update data
      updateResult(state, payload){
        state.data = payload
        window.console.log("Result data store module general: ")
        window.console.log(state.data)
      },
 
      // Update sensitivity
      updateResultSensitivity(state, payload){
        state.dataSensitivity = payload        
        },

        // Update sensitivity
      updateParametersSensitivity(state, payload){
        state.parametersSensitivity = payload        
        },

      // Update filters sensitivity table
      updateFilterSensitiviyTable(state, payload){
        state.filterSensitiviyTable = payload        
        },
      

      // Set selected options
      updateSelectedOptions(state, payload: []){
                                
        // Reset results for Full View when new option is selected
        state.selected_options_sensitivity = []

        state.selected_options = payload
      },

      // Set selected options Sensitivity 
      // updateSelectedOptionsSensitivity(state, payload: []){
      //   state.selected_options_sensitivity = payload
      // },

      // Update option to visualize
      updateOptionToVisualize(state, payload: []){
        state.optionToVisualize =  payload
      },


      // Update selected category
      updateSelectedCategory(state, payload){
        state.selected_category = payload
      },

      // Update selected project
      updateSelectedProject(state, payload){
        state.selected_project = payload
      },

      updateSelectedReferenceProducts(state, payload: []) {
        state.selected_reference_products = payload
      },
     
      updateReferenceProducts(state, payload: []) {
        state.all_reference_products = payload
      },


      updateProjectName(state, payload: string) {
        window.console.log("updating project name")
        window.console.log("new project name: " + payload)
        state.selected_project["name"] = payload
      },


      updateCalculationRunLogs(state, payload){
        state.calculationRunLogs = payload
      },


      updateCategories(state, payload){
        state.categories = payload
      }


     

    },
//! ------------------------------- ACTIONS -------------------------------------
    actions: {

      // Get list of products from Cosmos
      getReferenceProducts: async (context) => {
        const requestOptions = {        
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
            title: "Request",
            allProducts: "true"
           
            })
        };
        
        const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "queryCosmosProduct" , requestOptions);
        window.console.log(response)
        window.console.log(response.status)
        window.console.log(response.body)

        
        let data;
  
        if (response.status == 200) {
          window.console.log(response)
          data = await response.json();
          
        }else{
          window.console.log("error")
        }

        context.commit("updateReferenceProducts", data["product"]) 
      },


      // Get a reference product
      getReferenceProductsID: async (context, id: string) => {

        window.console.log("getting product with id: " + id)
        const requestOptions = {        
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
            title: "Request",
            allProducts: "false",
            productID: id            
            })
        };
        
        const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "queryCosmosProduct" , requestOptions);
        window.console.log(response)
        window.console.log(response.status)
        window.console.log(response.body)

        
        let data;
  
        if (response.status == 200) {
          window.console.log(response)
          data = await response.json();
          
        }else{
          window.console.log("error")
        }

        context.commit("updateSelectedReferenceProducts", data) 
      },


    //------- Get product categories from Cosmos
      
      getProductCategories: async (context) => {
        window.console.log("GETTING PRODUCT CATEGORIES")
        const requestOptions = {        
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
            title: "Request",
            actionType: "productCategories"
           
            })
        };
        
        const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "queryCosmos" , requestOptions);
        window.console.log(response)
        window.console.log(response.status)
        window.console.log(response.body)

        
        let data;
  
        if (response.status == 200) {
          window.console.log(response)
          data = await response.json();
          
        }else{
          window.console.log("error")
        }

        if (data["data"].length > 0){
          context.commit("updateCategories", data["data"]) 
        }else{
          window.console.log("no product categories in database")
        }

      },
    },



    

//* -------------------------------- Getters ---------------------------------------------
    getters: {

      // Prepare data for results table
      result: state => {

        let result;      
   
        const intermediate = state.data.map(row => {
          return {                          
            // Data for columns in here:
            id: row.id,
            ml: row.product.ml,
            product_height: row.product.height,
            product_width: row.product.width,
            product_depth: row.product.depth,
            product_rotation: row.product.rotation,
            product_weight: row.product.weight,
            product_volume_weight: row.product.volume_weight,
            product_packaging_weight: row.product.packaging_weight,
            product_brimfulvolume: row.product.brimfulvolume,
            product_fillinghead: row.product.fillinghead,
            product_headspace: row.product.headspace,
            
            // Pallet
            total_boxes: row.pallet.total_boxes,
            total_boxes_per_layer: row.pallet.total_boxes_per_layer,
            layer_efficiency: row.pallet.layer_efficiency,
            pallet_efficiency: row.pallet.pallet_efficiency,
            number_layers: row.pallet.number_layers,
            total_units_per_pallet: row.pallet.total_boxes * row.shipping_box.number_products,
            pallet_total_height: row.pallet.total_height,
            pallet_height_utilisation: row.pallet.total_height/row.pallet.height,
            pallet_block: row.pallet.block,
            pallet_weight: row.pallet.total_weight,
            pallet_layer_weight: row.pallet.layer_weight,
            pallet_number_layers_to_substract: row.pallet.layers_to_subtract,
            pallet_height: row.pallet.height,
            pallet_width: row.pallet.width,
            pallet_depth: row.pallet.depth,

            // Truck
            truck_number_pallets: row.pallet.truck_number_pallets,
            truck_pallet_pattern: row.pallet.truck_pallet_pattern,
            truck_pallets_weight: row.pallet.truck_pallets_weight,
            
            // Shipping Box
            shipping_box_width: row.shipping_box.width,
            shipping_box_depth: row.shipping_box.depth,
            shipping_box_height: row.shipping_box.height,
            shipping_box_number_products: row.shipping_box.number_products,
            shipping_box_config: row.shipping_box.number_shrinks/row.shipping_box.number_layers + " x " + row.shipping_box.units_per_shrink,
            // rows_depth: row.shipping_box.rows_depth,
            // rows_width: row.shipping_box.rows_width,
            product_orientation: row.shipping_box.product_orientation,
            air_height: row.shipping_box.air_height,
            air_depth: row.shipping_box.air_depth,
            air_width: row.shipping_box.air_width,  
            shipping_box_weight: row.shipping_box.weight,
            shipping_box_packaging_material_weight: row.shipping_box.packaging_material_weight,
            units_per_shrink: row.shipping_box.units_per_shrink,
            shipping_box_number_shrinks: row.shipping_box.number_shrinks,
            shipping_box_number_layers: row.shipping_box.number_layers
            
          } 
        })
        


        // Add info on second pallet
        if(state.secondPallet.status && "second_pallet" in state.data[0]){

          window.console.log("TEST---------")
          window.console.log(state.data[0])

          
          const secondPalletData = state.data.map(row => {
            return {                                    
            // Second Pallet
            second_total_boxes:                row.second_pallet.total_boxes,
            second_total_boxes_per_layer:      row.second_pallet.total_boxes_per_layer,
            second_layer_efficiency:           row.second_pallet.layer_efficiency,
            second_pallet_efficiency:          row.second_pallet.pallet_efficiency,
            second_number_layers:              row.second_pallet.number_layers,
            second_total_units_per_pallet:     row.second_pallet.total_boxes * row.shipping_box.number_products,
            second_pallet_total_height:        row.second_pallet.total_height,
            second_pallet_height_utilisation:  row.second_pallet.total_height/row.pallet.height,
            second_pallet_block:               row.second_pallet.block,
            second_pallet_weight:              row.second_pallet.total_weight,
            second_pallet_layer_weight:        row.second_pallet.layer_weight,
            second_pallet_number_layers_to_substract: row.second_pallet.layers_to_subtract,
            second_pallet_height: row.second_pallet.height,
            second_pallet_width: row.second_pallet.width,
            second_pallet_depth: row.second_pallet.depth,

            // Truck
            second_pallet_truck_number_pallets: row.second_pallet.truck_number_pallets,
            second_pallet_truck_pallet_pattern: row.second_pallet.truck_pallet_pattern,
            second_pallet_truck_pallets_weight: row.second_pallet.truck_pallets_weight,
            
            
            }
            })

            result = []
            let start = 0; 
            for (const element of intermediate) {    
                result.push({...element, ...secondPalletData[start]})
            start +=1
          }
       
        }else{
          result = intermediate
        }
                    

    // Sort array to show first opions with highest value
    function compare(a, b) {
      const unitsA = a.total_units_per_pallet;
      const unitsB = b.total_units_per_pallet;
      if (unitsA > unitsB) return -1;
      if (unitsB > unitsA) return 1;
    
      return 0;
    }

    return result.sort(compare)

      
    }, // End of getter
       
//* ------------- Getter for specific option ------------------------------------
    resultOption: (state) => (value) => {
      window.console.log("id:" + value["id"])
        window.console.log(state.data.filter(row => row.id ===value["id"])[0])
        const intermediate = state.data.filter(row => row.id === value["id"])[0]
        window.console.log(intermediate)
        //return first_result.filter(row => row.id == 1)
        return intermediate
    },


//* ----------------------- Prepare data for results sensitivity ---------------------
    resultSensitivity: state => {

      const intermediate = state.dataSensitivity.map(row => {
      //return state.dataSensitivity.map(row => {
        return {
           
           // Data for columns in here:
           id: row.id,
           ml: row.product.ml,
           product_height: row.product.height,
           product_width: row.product.width,
           product_depth: row.product.depth,
           product_weight: row.product.weight,
           
           // Pallet
           total_boxes: row.pallet.total_boxes,
           total_boxes_per_layer: row.pallet.total_boxes_per_layer,
           layer_efficiency: row.pallet.layer_efficiency,
           pallet_efficiency: row.pallet.pallet_efficiency,
           number_layers: row.pallet.number_layers,
           total_units_per_pallet: row.pallet.total_boxes * row.shipping_box.number_products,
           pallet_total_height: row.pallet.total_height,
           pallet_height_utilisation: row.pallet.total_height/row.pallet.height,
           pallet_block: row.pallet.block,
           pallet_weight: row.pallet.total_weight,
           pallet_layer_weight: row.pallet.layer_weight,
           pallet_number_layers_to_substract: row.pallet.layers_to_subtract,
           
           // Shipping Box
           shipping_box_width: row.shipping_box.width,
           shipping_box_depth: row.shipping_box.depth,
           shipping_box_height: row.shipping_box.height,
           shipping_box_number_products: row.shipping_box.number_products,
           shipping_box_config: row.shipping_box.rows_depth + " x " + row.shipping_box.rows_width,
           rows_depth: row.shipping_box.rows_depth,
           rows_width: row.shipping_box.rows_width,
           product_orientation: row.shipping_box.product_orientation,
           air_height: row.shipping_box.air_height,
           air_depth: row.shipping_box.air_depth,
           air_width: row.shipping_box.air_width,
           shipping_box_weight: row.shipping_box.weight,
           units_per_shrink: row.shipping_box.units_per_shrink,
           shipping_box_number_shrinks: row.shipping_box.number_shrinks
           
           
           // Calculated Columns
           
        } 
    })


    // Sort array to show first opions with highest value
    function compare(a, b) {
      const unitsA = a.total_units_per_pallet;
      const unitsB = b.total_units_per_pallet;
      if (unitsA > unitsB) return -1;
      if (unitsB > unitsA) return 1;
    
      return 0;
    }

    return intermediate.sort(compare)
    
  },
     
  

  // Get 1 specific option of sensitivity results
  sensitivityOption: (state, getters) => (value) => {           
         
     const intermediate = state.dataSensitivity.filter(row => row.id === value["id"])[0]
     window.console.log(intermediate)
     
     return intermediate
 },
  
  // Getter for specific option -- Currently filtering on same number of items in shipping box
  //! Used for subtable
     sensitivityOptions: (state, getters) => (value) => {           
       window.console.log("getting option sensitivity")     
       window.console.log(value)     

        const intermediate = getters.resultSensitivity.filter(row => row.shipping_box_number_products === value["shipping_box_number_products"])
        window.console.log(intermediate)
        //return first_result.filter(row => row.id == 1)
        return intermediate
    },
  }
}
