
import { useStore } from 'vuex'
import { computed } from "vue";
import userData from '@/components/mixins/userData.js';
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';


export default function() {
   
    const store = useStore()

    const env = computed(() => process.env.VUE_APP_ENV) 
    
    const {getUserData} = userData()
   

    //* --- Function to get user Data
    const startMassAnalysis = async (blockPatterns) => {

      window.console.log('triggering mass analysis')
      // Show loading
      Loading.show({
        delay: 400, // ms
        spinner: QSpinnerGears,
        message: 'Calculating.'
    })
                  
            
            
      const requestOptions = {
        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",          
          actionType: '',
          products: store.state.massAnalysis.uploadedData,
          blockPattern: blockPatterns,
          pallet: store.state.massAnalysis.pallet
          
          })
      };
      const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "massAnalysis" , requestOptions).catch((error) => {
              window.console.log(error)              
              
              store.commit('dialogs/updateMessage', 'Error executing mass analysis please try again.')
              store.commit('dialogs/updateDialog', true)           
                          
        }
                    
        );

      // placeholder for data
      let responseJson;
      

      if (response.status == 200) {
        window.console.log("-----------------")
        
        responseJson = await response.json();
        window.console.log(responseJson.data)
      
        // Updating user data in store
        //store.commit('projects/updateProjects', responseJson.data)        

        //! Need to update this
        //store.commit('user/updateUserProjects', )
        //store.state.user.userProjects 
        //await getUserData()

        store.commit("massAnalysis/updateResults", responseJson.data)
        Loading.hide(); 
        store.commit('dialogs/updateMessage', 'Anaylsis finished')
        store.commit('dialogs/updateDialog', true)      
     
      
      }else{
        
        store.commit('dialogs/updateMessage', 'Error in running mass anaylsis')
        store.commit('dialogs/updateDialog', true)                              
        Loading.hide(); 
        
      }


    } // End of function to get user Data

    //
    return {
      startMassAnalysis
    }
  }
          
  