<template>
  <div>
    <div class="row   items-center justify-center" style="">
      <div class="text-head-scalize text-center q-mx-md q-mt-lg text-dark">
        <span class="label bg-white text-no-wrap text-green">
          SUPPORTED
        </span>
        BY IFB HAMBURG &
        <span class="label bg-white text-no-wrap text-dark">
          IFB INNOVATIONSSTARTER GMBH
        </span>
      </div>
    </div>
    <div
      class="row text-no-wrap q-my-md q-mx-sm justify-evenly items-end text-center"
    >
      <div class="col-12 ">
        <!-- col-md-4 -->
        <div class="col">
          <q-img
            class=""
            :src="require(`@/assets/landing_page/140526_IFB_IS_Logo_rgb.svg`)"
            id="calendar_image"
            style="max-width: 300px"
          />
        </div>

        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm">
            FIND US IN THEIR
            <a href="https://innovationsstarter.com/portfolio/">
              <span class="label bg-white text-dark">PORTFOLIO</span></a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row q-my-lg q-mt-xl  items-center justify-center" style="">
      <div class="text-head-scalize text-center q-mx-md q-mt-lg text-dark">
        SOLUTION
        <span class="label bg-white text-no-wrap text-blue"> PARTNERS </span
        >WITH
      </div>
    </div>
    <div
      class="row text-no-wrap q-my-md q-mx-sm justify-evenly items-end text-center"
    >
      <div class="col-12 ">
        <!-- col-md-4 -->
        <div class="col">
          <q-img
            class=""
            :src="require(`@/assets/landing_page/GS1 Solution Partner.svg`)"
            id="calendar_image"
            style="max-width: 300px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "needsPage",
  components: {},

  data() {
    return {
      position: 0,
    };
  },
};
</script>

<style scoped lang="scss"></style>
