
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import optimizer from '@/components/mixins/optimize.ts'


export default {
  name: 'errorDialog',
  //  props: {   
  //     message: String,
  //     show: Boolean
  // },


  setup() {    
    const store = useStore()
    const {optimize, getSensitivity} = optimizer()


    // Show Dialog
    const dialogShow = computed(() => {      
        return store.state.dialogs.checkCalcParameters
        })

    const productVolume = computed(() => {
      return  store.state.general.selected_product.parameters.height.values.Target.value *        
        store.state.general.selected_product.parameters.width.values.Target.value  *
        store.state.general.selected_product.parameters.depth.values.Target.value
      })


    const checkVolume = computed(() => {
      let check;
      if (store.state.general.selected_product.advancedWeightCalc){
        check = store.state.general.selected_product.volumeParameters.brimfulVolume.values.target.value > productVolume.value
      }else{
        check = false
      }
      return check
    }
    )


    const ignoreWeights = ref(false)
    const restrictTolerance = ref(false)


    // Get values for sensitivity Tolerance
    const checkToleranceSensitivity = computed(() => {
      return  store.state.sensitivity.tolerance.ml / productVolume.value > 0.02
    })

    
    

      

    const ajustedOptimize= async () => {
      
      if (ignoreWeights.value){
        const parameters = store.state.userSettings.calculationParameters
        parameters.ignoreWeightPallet = true
        store.commit('userSettings/updateCalculationParameters', parameters)
        
      }

      if (restrictTolerance.value){        
        store.commit('sensitivity/updateTolerance', {ml: productVolume.value * 0.02})
        
      }      
      store.commit('dialogs/updateCheckCalcParameters', false)

      window.console.log(store.state.sensitivity.tolerance)
      optimize()


    }
    

    const close = () => {
       store.commit('dialogs/updateCheckCalcParameters', false)

    }
    return {
      close,
      store,
      dialogShow,
      checkVolume,
      ajustedOptimize,
      checkToleranceSensitivity,
      productVolume,
      ignoreWeights,
      restrictTolerance
      
    }

  },

 


}


