
export default {
  name: "needsPage",
  components: {},

  data() {
    return {
      position: 0,
    };
  },
};
