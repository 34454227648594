
//import MixinResult from '@/components/mixins/mixinsResult.ts';
import {computed, ref, watch, onMounted} from 'vue'
import { useStore } from 'vuex'
import shippingBoxFunctions from '@/components/mixins/drawShippingBox.ts'






export default {
    name: 'resultShippingBox',
    
    
    setup() {

        const env = computed(() => {return process.env.VUE_APP_ENV})
        const store = useStore()

        const canvasHeight = computed(() => {return window.innerHeight/6})
        const canvasWidth = computed(() => {return window.innerWidth/6})

        const {drawShippingBox, drawSmartBox} = shippingBoxFunctions()


        const data = ref({
            shipping_box: {
                smart_box: false
            }
        })

        
        
        //const {cleanCanvas} = MixinResult()

        //const canvas_2dpallet = ref<HTMLCanvasElement>()
        const canvasShippingBox = ref<HTMLCanvasElement | null>(null)

        const selected_option = computed(() => {
            if (store.state.general.optionToVisualize != null) {
                    return store.state.general.optionToVisualize['id']
                }else{
                    return ''
                }
            }
            )


        // Watch selected option change to update picture
        watch(selected_option, (newValue) => {
            window.console.log('selected option changed')
            if (newValue == 'no option selected') {
                window.console.log('no option selected')
            } else {
                const dataToDraw = store.getters['general/resultOption']({id: newValue})                
                // Save for displaying
                data.value = dataToDraw
                window.console.log(dataToDraw['pallet']['layer'])
                const canvas = canvasShippingBox.value as HTMLCanvasElement

                if (dataToDraw['shipping_box']['smart_box']) {
                    window.console.log('------- USING SMART BOX')
                    drawSmartBox(canvas, dataToDraw)
                }else {
                    window.console.log('------- USING STANDARD SHIPPING BOX')
                    window.console.log(dataToDraw)
                    drawShippingBox(canvas, dataToDraw)
                }
            }
            

        })

         onMounted(() => {//     // Check for no selected Option            
              window.console.log('selected option changed')
            if (selected_option.value == '') {
                window.console.log('no option selected')
            } else {
                const dataToDraw = store.getters['general/resultOption']({id: selected_option.value})
                data.value = dataToDraw
                window.console.log(dataToDraw['pallet']['layer'])
                const canvas = canvasShippingBox.value as HTMLCanvasElement
                
                if (dataToDraw['shipping_box']['smart_box']) {
                    window.console.log('Smart box')
                    drawSmartBox(canvas, dataToDraw)
                }else {
                    drawShippingBox(canvas, dataToDraw)
                }
            }//     //drawPallet()
          })
            
            
        
            

        return {
            env,
            canvasShippingBox,
            canvasHeight,
            canvasWidth,
            selected_option,
            data,
            
            
            
        }

    }
}

