import { render } from "./selectProject.vue?vue&type=template&id=61299840"
import script from "./selectProject.vue?vue&type=script&lang=js"
export * from "./selectProject.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnDropdown,QList,QItem});qInstall(script, 'directives', {ClosePopup,Ripple});
