<template>
  <div>
    <div class="row q-mb-lg justify-center">
      <div class="text-head-scalize text-center text-dark q-my-md">
        ACCELERATE YOUR PRODUCT
        <span class="label bg-white text-no-wrap text-red"
          >DEVELOPMENT PROCESS</span
        >
        <br />
        <span class="label bg-white text-dark"> AND TRANSFORM </span>

        <span class="label bg-white text-no-wrap text-blue">
          DIGITAL COLLABORATION
        </span>
      </div>
    </div>
    <!-- end of first row -->

    <div
      class="row text-no-wrap q-my-md q-mx-sm justify-evenly items-end text-center"
    >
      <div class="col-12">
        <!-- col-md-3 -->
        <div class="col">
          <q-img
            class="q-my-md"
            :src="require(`@/assets/landing_page/wheel EN orange.svg`)"
            id="product_image"
            style="max-width: 300px"
          />
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm text-blue">
            INSTANT ANALYSIS IN PROJECTS
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm text-red">
            REDUCE TEAM WORKLOAD
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm">
            IMPROVE WAYS OF WORKING
          </div>
        </div>
      </div>

      <div class="col-12 q-mt-xl">
        <!-- col-md-4 -->
        <div class="col">
          <q-img
            class="q-my-lg"
            :src="require(`@/assets/landing_page/orange process.svg`)"
            id="pallet_image"
            style="max-width: 500px"
          />
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm text-blue">
            SIMULATE ALL PHASES AT PROJECT START
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm text-red">
            INTEGRATE TOLERANCES & REQUIREMENTS
          </div>
        </div>
        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm">
            IMPROVE SPEED & QUALITY OF DECISIONS
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
export default {
  name: "scalizePage",
  components: {},

  data() {
    return {
      position: 0,
    };
  },
};
</script>

<style scoped lang="scss"></style>
