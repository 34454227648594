<template>
    <div>
    <!-- First dialog page -->
    <q-dialog v-model="showDialog" persistent transition-show="scale" transition-hide="scale" :position="windowHeight<=1280 ? 'top':'standard'">                

        <q-card :style="{'min-width':'650px', 'min-height':'400px', 'max-height':`${windowHeight*0.7}px`}">

        

<!-- ##################################################
              ACTIONS
  ################################################## -->
          
 
       <q-card-actions align="bottom" class="items-end">
          <div class="row q-ma-xs col-12">           

            <div class="col-xs-auto">                         
              <q-btn flat label="Dimensions" @click="selectedCard='dimensions'" 
                :style="{'color': selectedCard==='dimensions' ? 'blue': 'grey'}" />
            </div>  
            
            <div class="col-xs-auto">                         
              <q-btn flat label="Volume" @click="selectedCard='volume'" 
                :style="{'color': selectedCard==='volume' ? 'blue': 'grey'}" />
            </div>  
            
             <div class="col-xs-auto">           
              <q-btn flat label="Components" @click="selectedCard='components'" 
               v-if="$store.state.general.selected_product.advancedWeightCalc"
                :style="{'color': selectedCard==='components' ? 'blue': 'grey'}" />
            </div>    

            <q-space />
            
            <div class="col-xs-auto">         
             <q-btn class="q-mx-xs" flat label="Save Changes" outline color="green" v-close-popup @click="saveCurrentProject();$router.push('/')"/>
                <q-btn class="q-mx-xs" flat outline color="black" v-close-popup @click="$router.push('/')">
                    <q-icon name="close" />
                </q-btn>
              
            </div>
            <!-- <div class="col-xs-auto">         
              <q-btn label="Close" outline color="blue" v-close-popup @click="$router.push('/')"/>
            </div> -->
          </div>
        </q-card-actions>
          
          <div  v-show="selectedCard === 'dimensions'" > 
            <q-card-section>
            <div class="row q-ma-xs" >              
                <div class="text-head-scalize-large">Specify the Product Parameters</div>              
              </div>
            </q-card-section>

          <q-card-section class="q-pt-none">
            <div class="row" >
              <div class="col-xs-6">
              <q-img :src = "require(`@/assets/product/product.png`)" id="product_image" style="max-width:200px" /> 
              </div>
            <!--
              <div class="col-md-6" ref="canvasBoxProduct">
                  <canvas id="myCanvasProduct" 
                    ref="canvasProduct" style="border:1px solid #000000;"
                    :width="canvasWidth" :height="canvasHeight"
                    />
                                
              </div>

            -->
        
              <div class="col-xs-6">

              <!-- Size Parameters -->
                <div class="row text-body-scalize-config-items" v-for="(n, index) in product.parameters" 
                    :key="index"
                    >
                    {{n.name}}
                  <div class="row q-my-xs">
                    <div class="col-xs-3"
                      v-for="(q, subIndex) in n.values" 
                      :key="subIndex"
                      >                  
                      <q-input 
                        class="q-ma-xs"
                        filled  
                        :label="subIndex"
                        v-model="product.parameters[index]['values'][subIndex]['value']"                            
                        dense
                        bg-color="white"                                 
                      >                                       
                        <template v-slot:label>                          
                          <!-- <em class="q-px-sm bg-deep-orange text-white rounded-borders">{{ subIndex }}</em> -->
                          <em :class="product.parameters[index]['values'][subIndex]['format']"> {{ subIndex }} </em>
                        </template>                        
                      </q-input>
                    
                    </div>
                  </div>
                </div>        
                
                <div class="row q-ma-xs items-center text-body-scalize-config-items">
                  <div class="col-auto">Weight (g)</div>                                        
                  <div class="col-auto q-mx-xs">
                    <q-input                                        
                      v-model="product.fixedWeight.values.target.value"
                      dense
                      borderless
                      bg-color="white"                    
                      :input-style="{
                            'color': '#204fffE6',
                            'font-size': '1.1em',
                            'text-align':'left',                            
                            'line-height': '1.4em',
                            'letter-spacing': '0.07em',
                            'font-weight': '400'
                          }"
                      :disable="$store.state.general.selected_product.advancedWeightCalc===true"
                  />
                  </div>
                </div>
                  

                     
                <div class="row q-ma-md items-center">
                  <q-toggle
                    label="Lock W=D"
                    v-model="product.calculationParameters.wEqualsD"
                />
                </div>
                  
        <!-- Adding product image -->
                  <div class="row col-12 q-my-md q-mx-xs items-center text-body-scalize"> Upload a product image</div>
                  
                  <div class="row col-12 q-my-md items-center text-body-scalize">                   
                    <q-btn     
                        class="q-mx-md"                  
                        size='md'                         
                        @click="$refs['input'].click()" 
                        outline 
                        label='Select an image'
                        color="blue" 
                        > 
                             
                        
                      </q-btn>

                      <!-- invisible Input-->         
                      <input
                            ref="input"
                            type="file" 
                            accept="image/*"                       
                            label="Your label here."                      
                            @change="uploadImage" 
                            id="file-input"         
                            style="display:none"             
                      >

                    
                  </div>
                  
                  <div class="row q-ma-md items-center">
                    <canvas ref="uploadCanvas" />
                      <!-- <q-img :src="$store.state.general.selected_product['image']" style="max-width:100px" /> -->
                  </div>
           
              </div>

              <!-- Check product parameters-->
                <div class="row dev" v-if="env=='dev'">
                  
                </div>
            </div>
          </q-card-section>


        

        </div>

<!-- ##################################################################################################################
#######################################################################################################################
                                    SECOND MENU VOLUME
#######################################################################################################################
###################################################################################################################### -->
  <!-- First Part -->
      <div v-show="selectedCard === 'volume'">      
    
         <q-card-section>
         <div class="row q-mx-xs">              
             <div class="text-head-scalize q-my-md">Specify the Volume Parameters for sensitivity analysis</div>                   
          </div>
          
          <div class="row q-mx-xs items-start">          
            <div class="col-xs-3">          
              <div class="text-input-heading-scalize">
                  Calculated 
              </div>
          </div>
          <div class="col-xs-3">          
            <div class="text-input-heading-scalize">
              Target
            </div>
          
          </div>
          <div class="col-xs-5">          
            <div class="text-input-heading-scalize">
              Sensitivity Tolerance
            </div>
          
          </div>

          </div> <!-- End of row -->

          <div class="row q-mx-xs items-start">          
            <div class="col-xs-3">  
              <div class="text-input-heading-scalize">
                  Volume (ml)
              </div>        
            </div>
            <div class="col-xs-3">  
                <div class="text-input-scalize" style="color:purple">
                {{ targetVolume }}
            </div>

            </div>

            <div class="col-xs-2">      
              <q-input type="number" v-model="tolerance.ml"  
              :input-style="{color: 'blue', 'font-size': '1.2em', 'text-algin': 'center' }" 
                dense
                borderless
              >
                <template v-slot:prepend
                >
                   +/-     
                </template>

            </q-input>
            </div>

          </div>
          
        </q-card-section>


  <!-- Second part -->
        <q-toggle 
            v-model="$store.state.general.selected_product.advancedWeightCalc"
            :label="$store.state.general.selected_product.advancedWeightCalc?'Deactivate Advanced Weight Calculation':'Activate Advanced Weight Calculation'"
            class="text-body-scalize" />
    
        <div v-if="$store.state.general.selected_product.advancedWeightCalc">
        <q-card-section>             
          <div class="text-head-scalize q-my-md">Specify the Volume Parameters for database + weights</div>     

    <!-- Brimful volume -->
            <div class="row q-mx-xs q-mt-xl items-start">          
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                    Brimful
                </div>
              </div>
          
            <div class="col-xs-3">          
              <div class="text-input-heading-scalize">
                Target
              </div>
            
            </div>

            <div class="col-xs-5">          
              <div class="text-input-heading-scalize">
                Headspace required
              </div>
          
            </div>

          </div> <!-- End of row -->
          

          <div class="row q-mx-xs items-start">          
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                    Volume(ml)
                </div>
              </div>
          
            <div class="col-xs-3">          
              <div class="text-input-scalize">
                <q-input class="q-mx-md" v-model="product.volumeParameters['brimfulVolume']['values']['target']['value']"
                type="number"
                :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                dense
                borderless>                              
                </q-input>
              </div>
            
            </div>

            <div class="col-xs-3">          
              <div class="text-input-scalize">                                
                <q-input  class="q-mx-md" type="number" 
                  v-model="product.volumeParameters['brimfulVolume']['values']['headspace']['value']"
                  :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                  dense
                  borderless
                  >                              
                </q-input>
              
              </div>
          
            </div>

          </div> <!-- End of row / End of brimful volume -->
          <q-separator class="q-my-md" />


    <!-- AVAILABLE volume -->
          <div class="row q-mx-xs q-mt-md items-start">          
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                    Available
                </div>
              </div>
          
            <div class="col-xs-3">          
              <div class="text-input-heading-scalize">
                Target
              </div>
            
            </div>

            <div class="col-xs-5">          
              <div class="text-input-heading-scalize">
                Filling head deviation
              </div>
          
            </div>

          </div> <!-- End of row -->


          <div class="row q-mx-xs items-start">          
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                    Volume(ml)
                </div>
              </div>
          
            <div class="col-xs-3">                        
                <div class="text-input-scalize" style="color:purple">
                {{product.volumeParameters['brimfulVolume']['values']['target']['value'] - product.volumeParameters['brimfulVolume']['values']['headspace']['value']}}
              </div>
            
            </div>

            <div class="col-xs-3">          
              <div class="text-input-scalize">                
                <q-input  class="q-mx-md" type="number" 
                  v-model="product.volumeParameters['availableVolume']['values']['fillingHead']['value']"
                  :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                  dense
                  borderless
                  >                              
                  <template v-slot:prepend>+/-</template>
                </q-input>
              </div>
              
          
            </div>

          </div> <!-- End of row / End of AVAILABLE volume -->
          <q-separator class="q-my-xs" />

          <!-- MAX FILL volume -->
          <div class="row q-mx-xs q-mt-md items-start">        
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                    Max. Fill
                </div>
              </div>
          
            <div class="col-xs-3">          
              <div class="text-input-heading-scalize">
                Target
              </div>
            
            </div>

            <div class="col-xs-5">          
              <div class="text-input-heading-scalize">
                Overfill for Shelf Life
              </div>
          
            </div>

          </div> <!-- End of row -->


          <div class="row q-mx-xs items-start">          
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                    Volume(ml)
                </div>
              </div>
          
            <div class="col-xs-3">          
              <div class="text-input-scalize" style="color:purple">
                {{product.volumeParameters['brimfulVolume']['values']['target']['value'] 
                - product.volumeParameters['brimfulVolume']['values']['headspace']['value']
                - product.volumeParameters['availableVolume']['values']['fillingHead']['value']
                }}
              </div>
            
            </div>

            <div class="col-xs-3">          
              
                <div class="text-input-scalize">
                  <q-input  class="q-mx-md" type="number" 
                  v-model="product.volumeParameters['maxFillVolume']['values']['overfill']['value']"
                  :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                  dense
                  borderless
                  >          
             
                  <template v-slot:prepend>+/-</template>
                </q-input>
              
              </div>
          
            </div>

          </div> <!-- End of row / End of MAX FILL volume -->
          <q-separator class="q-my-xs" />


           <!-- MAX DECLARED volume -->
          <div class="row q-mx-xs q-mt-md items-start">         
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                    Max. Declared
                </div>
              </div>
          
            <div class="col-xs-3">          
              <div class="text-input-heading-scalize">
                Target
              </div>
            
            </div>

            <div class="col-xs-3">          
              <div class="text-input-heading-scalize">
                Overfill
              </div>

            </div>

            <div class="col-xs-3">          
              <div class="text-input-heading-scalize">
                Headspace Air
              </div>
            </div>
          
            

          </div> <!-- End of row -->


          <div class="row q-mx-xs items-start">          
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                    Volume(ml)
                </div>
              </div>
          
            <div class="col-xs-3">          
              <div class="text-input-scalize" style="color:purple">
                {{product.volumeParameters['brimfulVolume']['values']['target']['value'] 
                - product.volumeParameters['brimfulVolume']['values']['headspace']['value']
                - product.volumeParameters['availableVolume']['values']['fillingHead']['value']
                - product.volumeParameters['maxFillVolume']['values']['overfill']['value']
                }}
              </div>
            s
            </div>

            <div class="col-xs-3">          
              
                <div class="text-input-scalize" style="color:purple">
                  {{
                      Math.round(product.volumeParameters['maxFillVolume']['values']['overfill']['value']/(
                      product.volumeParameters['brimfulVolume']['values']['target']['value'] 
                      - product.volumeParameters['brimfulVolume']['values']['headspace']['value']
                      - product.volumeParameters['availableVolume']['values']['fillingHead']['value']
                      - product.volumeParameters['maxFillVolume']['values']['overfill']['value']) * 100)/100
                  }} %
              </div>
              
          
            </div>

             <div class="col-xs-3">          
              
                <div class="text-input-scalize" style="color:purple">
                  {{
                     Math.round(product.volumeParameters['maxFillVolume']['values']['overfill']['value']/(
                      product.volumeParameters['brimfulVolume']['values']['target']['value'] 
                      - product.volumeParameters['brimfulVolume']['values']['headspace']['value']
                      - product.volumeParameters['availableVolume']['values']['fillingHead']['value']
                      - product.volumeParameters['maxFillVolume']['values']['overfill']['value']) * 100)/100
                  }} %
              </div>
              
          
            </div>

          </div> <!-- End of row / End of MAX DECLARED volume -->

          <q-separator class="q-my-md" />



        <!-- CONTENT WEIGHT -->
          <div class="row q-mx-xs items-start">          
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                    Content 
                </div>
              </div>
          
            <div class="col-xs-3">          
              <div class="text-input-heading-scalize">
                Density (g/ml)
              </div>
            
            </div>

            <div class="col-xs-5">          
              <div class="text-input-heading-scalize">
                Max. Filled (g)
              </div>
          
            </div>

          </div> <!-- End of row -->


          <div class="row q-mx-xs items-start">          
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                    Volume(ml)
                </div>
              </div>
          
            <div class="col-xs-3">          
              <div class="text-input-scalize">
                <q-input  class="q-mr-md" type="number" 
                  v-model="product.volumeParameters['contentWeight']['values']['density']['value']"
                  :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                  borderless
                  dense
                  >                                       
                </q-input>
              </div>
            
            </div>

            <div class="col-xs-3 justify-center">          
                <div class="text-input-scalize" style="color:purple">
                  {{
                    (product.volumeParameters['brimfulVolume']['values']['target']['value'] 
                      - product.volumeParameters['brimfulVolume']['values']['headspace']['value']
                      - product.volumeParameters['availableVolume']['values']['fillingHead']['value']) 
                    * product.volumeParameters['contentWeight']['values']['density']['value']

                }}
              </div>
              
          
            </div>

          </div> <!-- End of row / End of CONTENT WEIGHT -->


          
        </q-card-section>

        </div>
                
      </div>

<!--
#######################################################################################################################
#######################################################################################################################
                                              Third Menu Components 
#######################################################################################################################
#######################################################################################################################
-->
 <div v-if="$store.state.general.selected_product.advancedWeightCalc">
      <div v-show="selectedCard === 'components'">
                   
         <q-card-section>
            <div class="row q-ma-xs">              
              <div class="text-head-scalize">Components</div>                   
            </div>          
        </q-card-section>

        <q-card-section>

<!-- Packaging Material -->
          <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Packaging</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Format</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Container</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Closure</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Brand</div>
              </div>

          </div><!-- end of row -->

          <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">materials</div>
              </div>


          
              <div class="col-xs-2">          
               <div class="text-input-scalize">
                  <q-input  class="q-mx-md" type="text" 
                    v-model="product.componentsParameters['packagingStandard']['format']"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>        
                </div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-scalize">
                  <q-input  class="q-mx-md" type="text" 
                    v-model="product.componentsParameters['packagingStandard']['container']"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>        
                </div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-scalize">
                  <q-input  class="q-mx-md" type="text" 
                    v-model="product.componentsParameters['packagingStandard']['closure']"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>        
                </div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-scalize">
                  <q-input  class="q-mx-md" type="text" 
                    v-model="product.componentsParameters['packagingStandard']['brand']"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>        
                </div>
              </div>

          </div><!-- end of row -->

          <q-separator class="q-my-md" />          

<!-- Container Material -->
            <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Container</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Material</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Weight (g)</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">PCR</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Renewable</div>
              </div>

          </div><!-- end of row -->

          <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Standard</div>
              </div>
              
          </div>

<!-- Dynamic part -->
          <div v-if="product.componentsParameters.containerMaterials.length > 0">
            <div v-for="c in product.componentsParameters.containerMaterials" :key="c.id" class="row q-mx-xs items-center">   
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize q-ml-md">{{c.id}}</div>
              </div>

              <div class="col-xs-2">                     
                <q-input  class="q-mx-md" type="text" 
                    v-model="c.material"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>                          
              </div>

              <div class="col-xs-2">          
                <q-input  class="q-mx-md" type="text" 
                    v-model="c.weight"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>          
              </div>

              <div class="col-xs-2">          
                 <q-select class="q-mx-md" borderless dense v-model="c.pcr" :options="['yes', 'no']" />                      
              </div>

              <div class="col-xs-2">          
                <q-select class="q-mx-md" borderless dense v-model="c.renewable" :options="['yes', 'no']" />              
              </div>
          
            <div class="col-xs-1">          
                <q-btn icon="delete" @click="removeMaterial('containerMaterials', c.id)" color="red" outline dense>                                      
                </q-btn>
            </div>

            </div>
          </div>

          <div class="row q-mx-xs items-start">          
            <q-btn size="md" class="q-ma-xs" color="blue" outline dense icon="add" 
              @click="addMaterial('containerMaterials')" />
          </div>

          <q-separator class="q-ma-md" />

<!-- Closure Materials  -->       
              <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Closure</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Material</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Weight (g)</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">PCR</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Renewable</div>
              </div>

          </div><!-- end of row -->

          <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Standard</div>
              </div>
              
          </div>
        <!-- Dynamic Part -->
          <div v-if="product.componentsParameters.closureMaterials.length > 0">
            <div v-for="c in product.componentsParameters.closureMaterials" :key="c.id" class="row q-mx-xs items-center">   
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize q-ml-md">{{c.id}}</div>
              </div>

              <div class="col-xs-2">                     
                <q-input  class="q-mx-md" type="text" 
                    v-model="c.material"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>                          
              </div>

              <div class="col-xs-2">          
                <q-input  class="q-mx-md" type="text" 
                    v-model="c.weight"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>          
              </div>

              <div class="col-xs-2">          
                 <q-select class="q-mx-md" borderless dense v-model="c.pcr" :options="['yes', 'no']" />                      
              </div>

              <div class="col-xs-2">          
                <q-select class="q-mx-md" borderless dense v-model="c.renewable" :options="['yes', 'no']" />              
              </div>

               <div class="col-xs-1">          
                <q-btn icon="delete" @click="removeMaterial('closureMaterials', c.id)" color="red" outline dense>                                      
                </q-btn>
              </div>

            </div>
          </div>

          <div class="row q-mx-xs items-start">          
            <q-btn size="md" class="q-ma-xs" color="blue" outline dense icon="add" 
              @click="addMaterial('closureMaterials')" />
          </div>

      <q-separator class="q-ma-md" />

<!-- Decoration Materials  -->       
              <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Decoration</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Material</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Weight (g)</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">PCR</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Renewable</div>
              </div>

          </div><!-- end of row -->

          <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Standard</div>
              </div>
              
          </div>
          <!-- Dynamic Part -->
          <div v-if="product.componentsParameters.decorationMaterials.length > 0">
            <div v-for="c in product.componentsParameters.decorationMaterials" :key="c.id" class="row q-mx-xs items-center">   
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize q-ml-md">{{c.id}}</div>
              </div>

              <div class="col-xs-2">                     
                <q-input  class="q-mx-md" type="text" 
                    v-model="c.material"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>                          
              </div>

              <div class="col-xs-2">          
                <q-input  class="q-mx-md" type="text" 
                    v-model="c.weight"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>          
              </div>

              <div class="col-xs-2">          
                 <q-select class="q-mx-md" borderless dense v-model="c.pcr" :options="['yes', 'no']" />                      
              </div>

              <div class="col-xs-2">          
                <q-select class="q-mx-md" borderless dense v-model="c.renewable" :options="['yes', 'no']" />              
              </div>

             <div class="col-xs-1">          
                <q-btn icon="delete" @click="removeMaterial('decorationMaterials', c.id)" color="red" outline dense>                                      
                </q-btn>
            </div>
            </div>
          </div>

          <div class="row q-mx-xs items-start">          
            <q-btn size="md" class="q-ma-xs" color="blue" outline dense icon="add" 
              @click="addMaterial('decorationMaterials')" />
          </div>




          <q-separator class="q-ma-md" />

<!-- Packaging composition  -->       
          <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Packaging</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Weight</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">PCR(%)</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Virgin(%)</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-heading-scalize">Renewable (%)</div>
              </div>

          </div><!-- end of row -->


          <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">composition</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-scalize" :style="{'color': 'purple', 'font-size': '1.2em', 'text-align':'center'}">{{totalWeight}}</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-scalize" :style="{'color': 'purple', 'font-size': '1.2em', 'text-align':'center'}">{{totalPcr}}</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-scalize" :style="{'color': 'purple', 'font-size': '1.2em', 'text-align':'center'}">{{percentageVirgin}}</div>
              </div>

              <div class="col-xs-2">          
                <div class="text-input-scalize" :style="{'color': 'purple', 'font-size': '1.2em', 'text-align':'center'}">{{totalRenewable}}</div>
              </div>

          </div><!-- end of row -->

          <div class="row q-mx-xs q-mt-md items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize"></div>
              </div>

              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Recyclability (%)</div>
              </div>

              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Reused (times)
                </div>
              </div>

              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Compostable</div>
              </div>



          </div><!-- end of row -->


          <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize"></div>
              </div>

              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                  <q-input  class="q-mx-md" type="text" 
                    v-model="product.componentsParameters['packagingComposition'].recyclability"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>         
                </div>
              </div>

              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">
                  <q-input  class="q-mx-md" type="text" 
                    v-model="product.componentsParameters['packagingComposition'].reused"
                    :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"
                    dense
                    borderless
                    > 
                  </q-input>      
                </div>
              </div>

              <div class="col-xs-3">          
                <div class="text-input-heading-scalize" :style="{'font-size': '1.2em', 'text-align':'center'}">
                  <q-select  
                    class="customTextColor"                    
                    v-model="product.componentsParameters['packagingComposition'].compostable"
                    :style="{'label-color': 'blue', 'font-size': '1.2em', 'text-align':'center'}"                    
                    dense
                    borderless
                    :options="['yes', 'no']"
                    /> 
                </div>
              </div>



          </div><!-- end of row -->


          <div class="row q-mx-xs q-mt-md items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize"></div>
              </div>

              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Circularity Score (MCI)</div>
              </div>


              <div class="col-xs-3">          
                <div class="text-input-heading-scalize">Ratio Volume:Pack</div>
              </div>

              

          </div><!-- end of row -->


          <div class="row q-mx-xs items-start">          
              
              <div class="col-xs-3">          
                <div class="text-input-heading-scalize"></div>
              </div>

              <div class="col-xs-3">          
                <q-input 
                  type="text"
                  class="text-input-heading-scalize" 
                  borderless
                  dense
                  :input-style="{color: 'blue', 'font-size': '1.2em', 'text-align':'center'}"                  
                  v-model="product.componentsParameters['packagingComposition'].circularityScore" 
                  />                
              </div>
              <div class="col-xs-3">          
                <div class="text-input-scalize" :style="{'color': 'purple', 'font-size': '1.2em', 'text-align':'center'}">{{ratioVolumePack}}</div>
              </div>

          </div><!-- end of row -->


            
                
        </q-card-section>

        </div>
      
      </div>
      

      <q-card-section v-if="env=='dev'">
        <div class="row">
          Dev Area
        </div>
        <div class="row">
            {{store.state.general.selected_product.componentsParameters}}
        </div>
        

      </q-card-section>
      </q-card>
    </q-dialog>
  
  
    <!-- Configs -->
    
    <!-- Second dialog page - Details -->

     <!-- <q-dialog persistent v-model="secondDialog" >
      <q-card style="max-width: 700px;width:600px;height:800px">
        <q-card-section>
         <div class="row q-ma-xs" style="border:2px solid #000000;">              
            <h5>Select a type</h5>   
            {{ this.product }}           
          </div>
        </q-card-section> -->


  <!-- Custom Product -->
        <!-- <q-card-section>
          <div class="row"> 
            <div class="col-xs-6">
            <q-card class="cursor-pointer"
              ref="product_card_CustomProduct"         
              @click="selectProduct('CustomProduct');">              
              Custom 
              <q-img :src = "require(`@/assets/product/tube.jpg`)" id="product_tube" />
            </q-card>
          </div> -->

  <!-- Tube -->          
          <!-- <div class="col-xs-6">
             <q-card class="cursor-pointer" 
                @click="selectProduct('Tube');"                
                ref="product_card_Tube"                         
              >
              Tube 
               <q-img :src = "require(`@/assets/product/tube.jpg`)" id="product_tube" /> 
            </q-card>
          </div>
          
          </div> -->

    <!-- Tin -->                  
          <!-- <div class="row"> 
            <div class="col-xs-6">
            <q-card class="cursor-pointer"
              ref="product_card_Tin"         
              @click="selectProduct('Tin');">              
              Tin
              <q-img :src = "require(`@/assets/product/tube.jpg`)" id="product_tube" />
            </q-card>
          </div> -->

  <!-- Stick -->          
          <!-- <div class="col-xs-6">
             <q-card class="cursor-pointer" 
                @click="selectProduct('Stick');"                
                ref="product_card_Stick"                         
              >
              Stick
               <q-img :src = "require(`@/assets/product/tube.jpg`)" id="product_tube" /> 
            </q-card>
          </div>
          
          
          </div>          
          </q-card-section>
           -->

         

        <!-- <q-card-actions align="right" class="text-primary">
          <q-btn flat label="previous page" @click="firstDialog = true; secondDialog = false" />
          <q-btn flat label="next page" @click="thirdDialog = true; secondDialog = false" />
          <q-btn flat label="Close" v-close-popup />
        </q-card-actions> -->


      
      <!-- </q-card>
    </q-dialog>
 -->
    
     <!-- third dialog page -->
    <!-- <q-dialog v-model="thirdDialog" persistent transition-show="scale" transition-hide="scale">
      <q-card class="bg-teal text-white" style="width: 300px">
        <q-card-section>
          <div class="text-h6">Third dialog</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Click/Tap on the backdrop.
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="previous page" @click="secondDialog = true; thirdDialog = false" />
          <q-btn flat label="Close" v-close-popup @click="$router.push('/')" />
        </q-card-actions>

      </q-card>
    </q-dialog>
     -->

    </div>  
</template>


<script lang="ts">
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import { Loading, QSpinnerGears } from 'quasar'
import saveProject from '@/components/mixins/saveProject.js'


export default {
  components: {},
  name: 'product',
  
  
  setup(props) {
    
    const store = useStore()
    const env = computed(() => {return process.env.VUE_APP_ENV})
    const windowHeight = computed(() => {return window.innerHeight})


    const {saveCurrentProject} = saveProject()


    const showDialog  = ref(true)
    const selectedCard = ref('dimensions')

    // Canvas for product pic upload
    const uploadCanvas = ref()

    // product 
    const productStore = computed(() => {return store.state.general.selected_product})
    const product = ref(productStore)

    // Target volume
    const targetVolume = computed(() => {return Math.round(store.state.general.selected_product.parameters.height.values.Target.value
      * store.state.general.selected_product.parameters.width.values.Target.value 
      * store.state.general.selected_product.parameters.depth.values.Target.value * 100)/100  })

    // Tolerance values
    const toleranceStore = computed(() => {return store.state.sensitivity.tolerance})
    const tolerance = ref(toleranceStore)

    // Add material
    const addMaterial = ((type) => {
      if(product.value.componentsParameters[type].length > 0){
        const maxID = product.value.componentsParameters[type].reduce((a,b) => a.id > b.id ? a:b).id; 
        product.value.componentsParameters[type].push({id: maxID+1, material: 'material', weight: 0, pcr: '', renewable: '' })
      }else{
        product.value.componentsParameters[type].push({id: 1, material: 'material', weight: 0, pcr: '', renewable: '' })
      }      
    })

    // Remove material
    const removeMaterial = ((type, id) => {     
      const position = product.value.componentsParameters[type].findIndex(v => v.id === id);      
      product.value.componentsParameters[type].splice(position, 1)
      
      //product.value.componentsParameters[type] = cleanArray
    })


    const totalWeight = computed(() => {
      let weight = 0.0                        
      if (product.value.componentsParameters['containerMaterials'].length > 0){                
        weight += product.value.componentsParameters['containerMaterials'].reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight), 0)   
      }
      if (product.value.componentsParameters['closureMaterials'].length > 0){
        weight += (product.value.componentsParameters['closureMaterials'].reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }
      if (product.value.componentsParameters['decorationMaterials'].length > 0){
        weight += (product.value.componentsParameters['decorationMaterials'].reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }      
      return weight
    })


    // Calculate PCR
    const totalPcr = computed(() => {
      let weight = 0.0                        
      if (product.value.componentsParameters['containerMaterials'].length > 0){                
        weight += product.value.componentsParameters['containerMaterials'].filter(e => e.pcr==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight), 0)   
      }
      if (product.value.componentsParameters['closureMaterials'].length > 0){
        weight += (product.value.componentsParameters['closureMaterials'].filter(e => e.pcr==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }
      if (product.value.componentsParameters['decorationMaterials'].length > 0){
        weight += (product.value.componentsParameters['decorationMaterials'].filter(e => e.pcr==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }
      
      return ((weight/totalWeight.value) * 100).toFixed(2)

    })

  // Calculate Virgin %
    const percentageVirgin = computed(() => {
        let weight = 0.0                        
        if (product.value.componentsParameters['containerMaterials'].length > 0){                
          weight += product.value.componentsParameters['containerMaterials'].filter(e => e.pcr==='no').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight), 0)   
        }
        if (product.value.componentsParameters['closureMaterials'].length > 0){
          weight += (product.value.componentsParameters['closureMaterials'].filter(e => e.pcr==='no').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
        }
        if (product.value.componentsParameters['decorationMaterials'].length > 0){
          weight += (product.value.componentsParameters['decorationMaterials'].filter(e => e.pcr==='no').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
        }
      
      return ((weight/totalWeight.value) * 100).toFixed(2)
    })
    



// Calculate Renewable %
    const totalRenewable = computed(() => {
      let weight = 0.0                        
      if (product.value.componentsParameters['containerMaterials'].length > 0){                
        weight += product.value.componentsParameters['containerMaterials'].filter(e => e.renewable==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight), 0)   
      }
      if (product.value.componentsParameters['closureMaterials'].length > 0){
        weight += (product.value.componentsParameters['closureMaterials'].filter(e => e.renewable==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }
      if (product.value.componentsParameters['decorationMaterials'].length > 0){
        weight += (product.value.componentsParameters['decorationMaterials'].filter(e => e.renewable==='yes').reduce((prev, curr) => parseFloat(prev) + parseFloat(curr.weight),0))
      }
      
      return ((weight/totalWeight.value) * 100).toFixed(2)

    })

    // Calculate Ratio Volume Pack
    const ratioVolumePack = computed(() => {
        return `${(product.value.volumeParameters['brimfulVolume'].values.target.value/totalWeight.value).toFixed(0)} : 1 `
    })
    
    
    // Upload Image
    const uploadImage = (event: Event) => {

      // show laoder
        Loading.show({
          delay: 200, // ms
          spinner: QSpinnerGears,
          message: 'Uploading picture'
      })

          // Add dialog

          // reset images
          //const input: HTMLInputElement = event.target! as HTMLInputElement;
          const input: HTMLInputElement = event.target as HTMLInputElement;  
          const image = input.files![0];
          
          const reader = new FileReader();
          reader.readAsDataURL(image);

          reader.onload = (event: Event) => {
                  //uploaded_image.value = reader.result;

                  const img = new Image()
                  //img.src = event.target!.result
                  img.src = reader.result as string
                    
                    // Define what is happening on image load
                    img.onload = ((event: Event) => {
                        // Get Canvas
                        const canvas = uploadCanvas.value;      
                        // Set canvas size
                        canvas.height = 200
                        canvas.width = img.width/img.height * 200

                        var ctx = canvas.getContext("2d");                        
                        // Actual resizing
                        ctx.drawImage(img, 0, 0, img.width/img.height * 200, 200);                       
                        const product = store.state.general.selected_product
                        product['image'] =  uploadCanvas.value.toDataURL();
                        //product['image'] = reader.result;
                        store.commit('general/updateProduct', product)
                        window.console.log('original size: ' + (reader.result as string).length)
                        window.console.log('resized size: ' + product['image'].length)
                        Loading.hide(); 

                   
                      store.commit('dialogs/updateDialog', true)
                      store.commit('dialogs/updateMessage', 'uploaded image')

                  
                    })
              

                    

                  

                  //console.log(this.image);
                  //window.console.log(uploaded_image)1                  
              };      

             
            
        }



       
    
    return {
      env,
      store,
      
      windowHeight,
      
      product,
      uploadImage,
      showDialog,
      selectedCard,
      tolerance,
      targetVolume,
      addMaterial,
      removeMaterial,
      totalWeight,
      totalPcr,
      totalRenewable,
      saveCurrentProject,
      uploadCanvas,
      ratioVolumePack,
      percentageVirgin
      

    }
  },

 

    

 
}
</script>

<style lang="scss">

.customTextColor {
  .q-field__native {
    color: blue
  }
}

</style>
