<template>      
<div>
   <div v-if="loader === 'true' " class="q-ma-none q-pa-none" style="width:100%;">    
    <div class="q-ma-none"> Optimizing </div>
    <q-linear-progress query color="black" class="q-mt-sm" />
  </div>
  <div v-else class="q-qa-none" style="width:100%;">
      <q-btn 
        @click="checkValues()"        
        color="red" label="Scalize" 
        />              
  </div>

  
</div>
</template>




<script lang="ts">
import {useStore} from 'vuex'
import {computed, ref} from 'vue'
import { useRouter} from 'vue-router'
import optimizer from '@/components/mixins/optimize.ts'



export default {
  name: 'optimize',
  
  setup () {

    const env = computed(() => {return process.env.VUE_APP_ENV})
    const store = useStore()
    const router = useRouter()
    const {optimize} = optimizer()

    const loader = ref('false')
    //const errorFetch = ref(false)


     const productVolume = computed(() => {
      return  store.state.general.selected_product.parameters.height.values.Target.value *        
        store.state.general.selected_product.parameters.width.values.Target.value  *
        store.state.general.selected_product.parameters.depth.values.Target.value
      })


    const checkValues = async () => {

      if (        
        (store.state.general.selected_product.volumeParameters.brimfulVolume.values.target.value >
        store.state.general.selected_product.parameters.height.values.Target.value *        
        store.state.general.selected_product.parameters.width.values.Target.value  *
        store.state.general.selected_product.parameters.depth.values.Target.value) && 
        store.state.general.selected_product.advancedWeightCalc                 
        )
        {
          store.commit('dialogs/updateCheckCalcParameters', true)
      } else {
        loader.value = 'true';
        await optimize()
        loader.value = 'false';
      }

    }

   
      return {
        loader,
        optimize,
        checkValues,       
      }



  },


  
}
</script>
<style>
</style>