
import { computed, ref, watch } from 'vue'
import {useStore} from 'vuex'


  const options = ['GOOD', 'OK', 'NOT OK']

  const columnStyle= 'max-width:90px;min-width:60px'
  const columnStyleSelect= 'min-width:130px'
  
  const headerStyle= 'max-width:90px; min-width:60px;height:80px'
  const headerStyleSelect= 'min-width:130px; height:80px'
    

  const columns = [
          {
            name: 'id',
            required: true,
            label: 'ID',
            align: 'left',
            headerStyle: headerStyle,
            style: columnStyle,
            field: row => row.id,
            format: val => `${val}`,
            sortable: false
          },

            {name: 'product_height', type: 'float', label: 'Product Height', field: 'product_height',
                  sortable: false,   headerStyle: headerStyle, style: columnStyle,
            },

            {name: 'product_width', type: 'float', label: 'Product Width', field: 'product_width', sortable: false,
            headerStyle: headerStyle, style: columnStyle
            },
            
            {name: 'product_depth', type: 'float', label: 'Product Depth', field: 'product_depth',
              sortable: false,
              headerStyle: headerStyle, style: columnStyle,
              format: val => `${val}%`,
              },
                
          
            // Shipping Box         
            {   
            name: 'shipping_box_height',
            type: 'float',
            label: 'Shipping Box Height',
            field: 'shipping_box_height', 
            sortable: false,    
            //format: (val, row) => `${parseFloat(val).toFixed(2)}`,
            //classes: 'my-special-class',
            headerStyle: headerStyle, style: columnStyle,
              },

            {name: 'shipping_box_width', type: 'float', label: 'Shipping Box Width', field: 'shipping_box_width', sortable: false,
            headerStyle: headerStyle,
            style: columnStyle,  format: (val, row) => `${parseFloat(val).toFixed(2)}`},
            
            {name: 'shipping_box_depth', type: 'float', label: 'Shipping Box Depth', field: 'shipping_box_depth', sortable: false,
              headerStyle: headerStyle,
              style: columnStyle,
              format: (val, row) => `${parseFloat(val).toFixed(2)}`},


              {name: 'units_per_shrink', type: 'float', label: 'Units per Shrink', field: 'units_per_shrink', sortable: false,
              headerStyle: headerStyle,
              style: columnStyle},

              {name: 'shipping_box_number_shrinks', type: 'float', label: 'Shrinks per Box', field: 'shipping_box_number_shrinks', sortable: false,
              headerStyle: headerStyle,
              style: columnStyle},

                          

            // Shrink line KPI
            {name: 'shrink_line_speed', type: 'float', label: 'Shrink Line Speed', field: 'shrink_line_speed',
              sortable: false,   headerStyle: headerStyleSelect,
              style: columnStyleSelect,},

            {name: 'shipping_box_number_products', type: 'integer', label: 'Units in Shipping Box',
              field: 'shipping_box_number_products',
              align: 'left',
              headerStyle: headerStyle,
              style: columnStyle,
              sortable: false},

            // Trade KPI
            {name: 'trade_kpi', type: 'float', label: 'Trade KPI', field: 'trade_kpi',
              sortable: false, 
              headerStyle: headerStyleSelect,
              style: columnStyleSelect},

            {name: 'total_units_per_pallet', type: 'integer', label: 'Units per Pallet', field: 'total_units_per_pallet',
                          sortable: false,   headerStyle: headerStyle,
            style: columnStyle,
                          },
            
            {name: 'layer_efficiency', type: 'percent', label: 'Layer utilisation %', field: 'layer_efficiency',
                  sortable: false,  headerStyle: headerStyle,
            style: columnStyle,
                  //format: (val, row) => `${parseFloat(val).toFixed(2)*100}%` 
                  },

            // Logistics KPI
            {name: 'logistics_kpi', type: 'float', label: 'Logistics KPI', field: 'logistics_kpi',
              sortable: false,   headerStyle: headerStyleSelect,
            style: columnStyleSelect,},           

        ] // End of columns
        


// -------------- Create Component 
export default {
    name: 'decisionsTable',

   setup() {

     // Get env 
     const env = computed(() => {return process.env.VUE_APP_ENV})
     
     // Get store
     const store = useStore()     

     const data = computed(() => {
       //* Combined selected Options from sensitiviy and results both are in the decision store module
            
            // Results Sensitivity
            let intermediate = store.state.decisions.selectedOptionsSensitivity
            intermediate = intermediate.filter(Boolean);
            intermediate = intermediate.map(e => e.values).flat()

            window.console.log('intermediate after getting sensitivity results')
            window.console.log(intermediate)
            
            // add results
            window.console.log('store.state.decisions.selectedOptionsResult')
            window.console.log(store.state.decisions.selectedOptionsResult)
            intermediate = intermediate.concat(store.state.decisions.selectedOptionsResult)            

            window.console.log(intermediate)

            return intermediate
       }
       )

    // Selected options in table
     const selected_options = ref([])

     selected_options.value = store.state.decisions.decisionOptions

     watch(selected_options, (newValue) => {
       window.console.log('selected options changed')
       window.console.log(newValue)
       store.commit('decisions/updateDecisionOptions', newValue)
     })

    // Init data
    //  const data = ref([
    //   {
    //     id: 1,
    //     product_height: 237,
    //     product_width: 9.0,
    //     product_depth: 37,

    //     shipping_box_height: 237,
    //     shipping_box_width: 9.0,
    //     shipping_box_depth: 37,
    //     shrink_line_speed: '',

    //     shipping_box_number_products: 100,
    //     trade_kpi: '',


    //     total_units_per_pallet: 1000,
    //     layer_efficiency: 0.95,
    //     logistics_kpi: '',

        
    //   },
    //   {
    //     id: 2,
    //     product_height: 237,
    //     product_width: 9.0,
    //     product_depth: 37,

    //     shipping_box_height: 237,
    //     shipping_box_width: 9.0,
    //     shipping_box_depth: 37,
    //     shrink_line_speed: '',

    //     shipping_box_number_products: 200,
    //     trade_kpi: '',

    //     total_units_per_pallet: 1000,
    //     layer_efficiency: 0.95,
    //     logistics_kpi: '',


    //   },
 
    // ]) // End of data



    const saveReportOption = (id) => {
      store.commit('decisions/updateOptionSelectedForReport', id)
      
      }


    return {
      env,
      store,
      columns,
      data,
      options,
      selected_options,
      saveReportOption

    }
  }
}
