<template>

<div class="row q-ma-md">
    <div class="col-6">
       
    <div class="row q-ma-xs">
        <q-table 
            title="Projects"
            
            :rows="data"
            :columns="columns"
            
            selection="multiple"
            v-model:selected="selected_options"       
            
            row-key="projectID" 
            :pagination="pagination"

            :filter="filter"
            :filter-method="myfilterMethod"
        >


         <template v-slot:header="props">
            <q-tr :props="props">
                <q-th auto-width />
                
                <q-th
                    v-for="col in props.cols"
                    :key="col.name"
                    :props="props"
                >
                   <div class="row justify-center" style="height:40px">
                        <div class="row q-mt-xs justify-center text-body-scalize" style="height:40px">
                            {{ col.label }}
                        </div>
                    </div>

                      <div class="row q-mt-xs justify-center" style="height:40px">
                        <q-input                                
                            v-model="filter[col.field]" 
                            @input="updateFilter($event, parameter=col.field)" 
                            placeholder="filter"
                            outlined
                            dense
                            >
                            <q-tooltip content-class="bg-accent">Enter a value to filter, you can also use &lt; or &gt; </q-tooltip>
                        </q-input>
                    </div>   


                    <div class="row q-mt-xs justify-center" style="height:25px">  
                        <q-btn @click="sortData(col.field, 'asc')" size="xs" class="q-mr-xs" >
                            <q-icon  name="arrow_upward"/>
                            <q-tooltip content-class="bg-accent">Sort ascending </q-tooltip>
                        </q-btn>
                        <q-btn @click="sortData(col.field, 'desc')" size="xs">
                            <q-icon  name="arrow_downward"/>
                            <q-tooltip content-class="bg-accent">Sort descending</q-tooltip>
                        </q-btn>
                    </div>

                </q-th>
            </q-tr>
      </template>
        </q-table>
        
    </div>

     <div class="row q-ma-xs justify-start">            
        <q-btn class="q-ma-xs" outline label="Refresh Data" color="blue" @click="getProjectData" />            
        </div>

    </div> <!--End of col-->
</div>

<div v-if="env=='dev'">
    <div class="row">
        {{projects}}
    </div>
    
    <div class="row">
        value in store:
    </div>
    <div class="row">
        {{$store.state.projects.projects}}
    </div>
</div>

</template>

<script lang="js">
import {computed, ref, watch, onMounted} from 'vue'
import {useStore} from 'vuex'
import projectData from '@/components/mixins/projectData.js';


export default {
      
     setup() {
                
        const store = useStore()     
        
        const {getProjectData} = projectData()


        // Get environment 
        const env = computed(() => {
            return process.env.VUE_APP_ENV
            })

        const selected_options = ref([])

        const projects = computed (() => { return store.state.projects.projects})

        const data = ref(projects.value)
        watch( () => store.state.projects.projects, function() {                                                        
            window.console.log('projects changed')
            // Reset selected options
            selected_options.value = []
            data.value = projects.value
            });


        const columnWidth = "80px"
        const pagination = ref({
            rowsPerPage: 10 // current rows per page being displayed
            })
        
        const columns = [

              {
                    name: 'Category',
                    required: true,
                    label: 'Category',
                    align: 'left',
                    field: 'category',
                    type: 'string', // Important for filter
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",
                    headerStyle: `max-width: ${columnWidth}px;`

                },

           
                {
                    name: 'projectName',
                    required: true,
                    label: 'Name',
                    align: 'left',
                    field: 'name',
                    type: 'string', // Important for filter
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",
                    headerStyle: `max-width: ${columnWidth}px;`

                },

                    
                {
                    name: 'projectOwner',
                    required: true,
                    label: 'Owner',
                    align: 'left',
                    field: 'projectOwner',
                    type: 'string', // Important for filter
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',                    
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },
                
              
                {
                    name: 'Product Height',
                    required: true,
                    label: 'Product Height',
                    align: 'left',
                    field: 'height',
                    type: 'float', // Important for filter
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",
                    headerStyle: `max-width: ${columnWidth}px;`

                },


                {
                    name: 'Product Width',
                    required: true,
                    label: 'Product Width',
                    align: 'left',
                    field: 'width',
                    type: 'float', // Important for filter
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",
                    headerStyle: `max-width: ${columnWidth}px;`

                },


                {
                    name: 'Product Depth',
                    required: true,
                    label: 'Product Depth',
                    align: 'left',
                    field: 'depth',
                    type: 'float', // Important for filter
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",
                    headerStyle: `max-width: ${columnWidth}px;`

                },
                {
                    name: 'brimfulVolume',
                    required: true,
                    label: 'brimful Volume',
                    align: 'left',
                    field: 'brimfulVolume',
                    type: 'float', // Important for filter
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",
                    headerStyle: `max-width: ${columnWidth}px;`

                },
            

                 


        ]


         //-----------------  FILTER --------------------------------

      const filter = ref({})
      
        const myfilterMethod = () => {    
                
                // Filter by number of boxes
                //let intermediate = this.result.filter(row => parseInt(row['total_boxes']) > this.filter.total_boxes)

                // Filter by ml
                //intermediate = intermediate.filter(row => parseInt(row['ml']) > this.filter.ml)

            let intermediate = data.value;

            window.console.log('this filter')
            
            window.console.log(filter.value)
            window.console.log(intermediate)

            // relevant filters

            for (const [key, value] of Object.entries(filter.value)) {

                if (value.length > 0){

                
                window.console.log(key, value);
                window.console.log(key, value[0]);
                window.console.log("len value " + value.length);
                
                const column = columns.filter(row => row.field == key )
                
                window.console.log("column type: " +  column[0]['type'])
                
           if (value != ""){
                // Filter dataset
                switch(column[0]['type']){
                    case 'float':
                        window.console.log('filtering float')
                        window.console.log(value.substring(1, value.length))
                        switch(value[0]) {
                            case ">":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) > value.substring(1, value.length))
                                break;
                            case "<":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) < value.substring(1, value.length))
                                break;
                            default:
                                intermediate = intermediate.filter(row => parseFloat(row[key]) == value)
                                break; 
                        }
                    break;

                    case 'integer':
                        switch(value[0]) {
                            case ">":
                                intermediate = intermediate.filter(row => parseInt(row[key]) > value.substring(1, value.length))
                                break;
                            case "<":
                                intermediate = intermediate.filter(row => parseInt(row[key]) < value.substring(1, value.length))
                                break;
                            default:
                                intermediate = intermediate.filter(row => parseInt(row[key]) == value)
                                break; 
                        }
                    break;

                    case 'percent':
                        window.console.log("filter value: " +  value.substring(1, value.length)/100)
                        window.console.log("intermediate value: " +  intermediate[0][key])
                        

                        switch(value[0]) {
                            case ">":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) > value.substring(1, value.length)/100)
                                break;
                            case "<":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) < value.substring(1, value.length)/100)
                                break;
                            default:
                                intermediate = intermediate.filter(row => parseFloat(row[key]).toFixed(2) == value.substring(0, value.length)/100)
                                break; 
                        }
                    break;


                    default:
                        intermediate = intermediate.filter(row => row[key].includes(value))
                        break;
                }
           }
                

                    // if (value != ""){
                    // switch(value[0]) {
                    //     case ">":
                    //         case column[0]['type'] == 'float':
                    //             intermediate = intermediate.filter(row => parseFloat(row[key]) > value.substring(1, value.length))
                    //             break;  
                    //         case column[0]['type'] == 'integer':
                    //             intermediate = intermediate.filter(row => parseInt(row[key]) > value.substring(1, value.length))
                    //             break;
                    //     case "<":
                    //         case column[0]['type'] == 'float':
                    //             intermediate = intermediate.filter(row => parseFloat(row[key]) < value.substring(1, value.length))
                    //             break;  
                    //         case column[0]['type'] == 'integer':
                    //             intermediate = intermediate.filter(row => parseInt(row[key]) < value.substring(1, value.length))
                    //             break;
                    //     default:
                    //          if (column[0]['type'] == 'string'){ 
                    //              window.console.log('string')
                    //              intermediate = intermediate.filter(row => row[key].includes(value))
                    //          }else {
                    //             case column[0]['type'] == 'float':
                    //                 intermediate = intermediate.filter(row => parseFloat(row[key]) == value)
                    //                 break;  
                    //             case column[0]['type'] == 'integer':
                    //                 intermediate = intermediate.filter(row => parseInt(row[key]) == value)
                    //                 break;
                                 
                    //          }
                    //          break;
                    // }

                      

                    }
                    } 
        
                window.console.log('filtered')
                window.console.log(intermediate)
                return intermediate
        } // End of Filter methods
               

        // Update Filter
        const updateFilter = (value, parameter) =>{

            window.console.log("Updating filter")
            window.console.log('value ' + value)
            window.console.log('parameter: ' + parameter)
            filter.value[parameter] = value
            window.console.log(filter.value)
        }



        // Sorting
        const compareValues = (key, order = 'asc') =>{
            return function innerSort(a, b) {
                // if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // // property doesn't exist on either object
                // return 0;
                // }

                //window.console.log(typeof a[key] )

                const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
                const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

                let comparison = 0;
                if (varA > varB) {
                    comparison = 1;
                } else if (varA < varB) {
                    comparison = -1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }

        const sortData = (key, direction) => {
            window.console.log('sorting')
            window.console.log('key: ' + key)
            window.console.log('direction: ' + direction)
            window.console.log('resut')
            
            const test = data.value;
  
           
            //this.result.sort(this.compareValues(key,  direction));
            window.console.log('sorted')
            window.console.log( test.sort(compareValues(key,  direction)));

            data.value = test.sort(compareValues(key,  direction))

        

            
        }

        
    

        // Load project data
        onMounted(() => {getProjectData()})
        
        return {
            store,
            env,
            columns,
            data,
            projects,
            selected_options,
            pagination,            
            getProjectData,
            
            sortData,
            compareValues,
            updateFilter,
            myfilterMethod,
            filter,
                        
 
        }


    
        
    },
}
</script>
