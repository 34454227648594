<template>
  <div>
    <div class="row q-my-md q-mx-sm justify-evenly items-start text-center">
      <div class="col-12 col-md-4 q-mx-sm q-my-lg" style="width: 360px">
        <div class="col">
          <q-img
            class="q-ma-sm"
            :src="require(`@/assets/landing_page/COFOUNDERS_Rob.png`)"
            style="width: 270px"
          />
        </div>
        <div class="col">
          <div class="text-body-scalize-small q-my-lg text-blue">
            MANAGING DIRECTOR
          </div>

          <div class="col">
            <div class="text-body-scalize-small text-dark q-mx-sm q-my-md">
              <span class="label bg-white text-blue">ROBERT HUGHES</span>
              DEVELOPED
              <span class="label bg-white text-red">PACKAGING</span>
              FOR 10 YEARS WITH VARIOUS FOOD AND COSMETIC COMPANIES. HIS CALLING
              IS TO SIMPLIFY AND IMPROVE DECISION MAKING IN PROJECTS,
              TRANSFORMING STRATEGIC
              <span class="label bg-white text-green">INSIGHT </span>
              INTO OPERATIVE FUNCTIONALITY.
            </div>
          </div>
        </div>
      </div>
      <!-- end of first column -->

      <div class="col-12 col-md-4 q-mx-sm q-my-lg" style="width: 350px">
        <div class="col">
          <q-img
            class="q-ma-sm"
            :src="require(`@/assets/landing_page/COFOUNDERS_Jan.png`)"
            style="width: 270px"
          />
        </div>
        <div class="col">
          <div class="text-body-scalize-small q-my-lg text-blue">
            TECHNICAL DIRECTOR
          </div>

          <div class="col">
            <div class="text-body-scalize-small text-dark q-mx-sm q-my-md">
              <span class="label bg-white text-blue">JAN MAMMEN</span> HAS BUILT
              AND MANAGED ANALYTICAL TOOLS FOR OVER 10 YEARS, IN ON-PREM AND
              CLOUD DEPLOYMENT. HIS PASSION IS TO CREATE ELEGANT
              <span class="label bg-white text-red">APPLICATIONS,</span>
              SOLVING COMPLEX AND NOVEL PROBLEMS WITH
              <span class="label bg-white text-green">DATA SCIENCE.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "meetPage",
  components: {},

  data() {
    return {
      position: 0,
    };
  },
};
</script>

<style scoped lang="scss">
h6 {
  margin-bottom: 0px;
}
</style>
