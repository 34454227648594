
import { computed,ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'


// const selectModel = 'two'
// const image_product = "test_shape.png"
// const shipping_box = null
// const product = null

            // bottom for shelf
const bottom = 150

export default {
    name: 'shelf',


    setup() {
        const env = computed(() => {return process.env.VUE_APP_ENV})
        const store = useStore()               

        // Value for first product on shelf
        const initLeft = ref()

        const selectDecision = () => {
            window.console.log('selecting decision')
            store.commit("dialogs/updateDecisionDialog", true)
        }


        // ...mapGetters({
        //         result_store: 'general/result',
        //         result_option: 'general/resultOption'
        // }),

        const competitorProducts = computed(() => {return store.state.competitor.competitorProducts})
        const selectedCompetitorProducts = ref([])

        //const canvas_2dpallet = ref<HTMLCanvasElement>()
        const canvasShelf = ref<HTMLCanvasElement | null>(null)

        const selectedModel = ref()

        const tableData = ref([])


        // set Size of Font for product name on shelf
        const drawingFont = "1em Arial"


        // watch selected option in decision table
        const selectedOptions = computed(() => {         
             return store.state.decisions.decisionOptions        
        })
        // const selectedOptions = computed(() => { 
        //     if (store.state.decisions.decisionOptions.length > 0) {
        //         return store.state.decisions.decisionOptions
        //     } else {
        //         return [
        //             {
        //             'product_height': 10,
        //             'product_width': 5,
        //             'product_depth': 2,
        //             'id': 'test',
        //             }
        //         ]
        //     }
        //     } )
        
        //const selectedOptions = ref(selectedOptionsStore.value)
        
        watch(selectedOptions, (newValue) => {
            window.console.log('new Option')
            window.console.log(newValue)
            //const tableData = [selectedOptions.value.map(e => {e['product_width'], e['product_height']})]
            tableData.value = redux(selectedCompetitorProducts.value).concat(redux(selectedOptions.value))
            const canvas = canvasShelf.value as HTMLCanvasElement
            
            drawAll(canvas)
            }
            )


        // Create table data
        const keys_to_keep = ['id', 'product_width', 'product_height', 'product_depth'];


        // Array function to keep only relevant keys
        const redux = array => array.map(o => keys_to_keep.reduce((acc, curr) => {
            acc[curr] = o[curr];
            return acc;
            }, {}));
        
                  

        // Load image function
        const loadImage = async (imageUrl)  => {
            let img;
            const imageLoadPromise = new Promise(resolve => {
                img = new Image();
                img.onload = resolve;
                img.src = imageUrl;      
                });
            await imageLoadPromise;      
            return img;
            }


        // function to draw a single product
        const drawProduct = async (item, ctx, left: number, top: number, scaling: number, space: number, productType: string) => {
            window.console.log('drawing item')
            window.console.log('item.id: ' + item.id )
            
            const height = parseFloat(item['product_height'])
            const width = parseFloat(item['product_width'])

            
            // * Draw bounding box around image
            ctx.beginPath()                
            ctx.setLineDash([])
            ctx.strokeStyle = '#204fffe6'

            // Front rectanlge
            ctx.rect(left, top, width * scaling, - height * scaling)    
            window.console.log("width * scaling (1): " + width * scaling)      
            ctx.closePath();

            // Create ligns
            ctx.stroke()

            window.console.log('(2) left: ' + left)

            // add text
            ctx.font = drawingFont;
            let text_width;


            //* Add Image
            if (!item.image) {
                window.console.log('no image')
            } else {
                const img = await loadImage(item.image)
                window.console.log("IMAGE WIDTH: " + img.width)
                window.console.log("IMAGE HEIGHT: " + img.height)
                window.console.log("width * scaling (2): " + width * scaling)      

                ctx.drawImage(
                    img,
                    left,
                    top - height * scaling,
                    width * scaling,
                    height * scaling,
                )
                
            }


            //! Account for that "name" key should be used for competior product and "id" key for options 
            if (productType === 'competitor') {
                text_width =  ctx.measureText(item['name']).width;
                ctx.fillText(item['name'], left, top + 20 );
            } else {
                text_width =  ctx.measureText(item['id']).width;
                ctx.fillText(item['id'], left, top + 20 );
            }
            
                    
            window.console.log('(3) left: ' + left)
            

            window.console.log('product_width: ' + width)
            window.console.log('space: ' + space)
            window.console.log('scaling ' + scaling)
            window.console.log('(4) left: ' + left)
            left += Math.max((width + space) * scaling, text_width + space * scaling)
            window.console.log('(5) new left:' + left)

            initLeft.value = left

            
        } // End of draw single product

        // Function to draw products
        const drawProducts = async(top, canvas) => {

            initLeft.value = 20


            //! Calculate space taken by products
            // Get total width of products
            let totalWidthOwnProducts = 0
            let maxHeightOwnProducts = 0
            
            window.console.log("------------------------------------------")
            if (selectedOptions.value.length > 0) {
                totalWidthOwnProducts = selectedOptions.value.map(e => e['product_width']).reduce(
                    (previousValue, currentValue ) => previousValue + currentValue, 0
                    )                        
                    
                maxHeightOwnProducts  = selectedOptions.value.reduce((a, b)=>a['product_height'] > b['product_height']?a:b)['product_height']
            }

            window.console.log('totalWidthOwnProducts: ' + totalWidthOwnProducts  )
            
           
            // Competitor Products
            let totalWidthCompetitorProducts = 0
            let maxHeightCompetitorProducts = 0
            if(selectedCompetitorProducts.value.length > 0 ){
                totalWidthCompetitorProducts = selectedCompetitorProducts.value.map(e => parseFloat(e['product_width'])).reduce(
                     (previousValue, currentValue ) => previousValue + currentValue, 0
                     )
                
                window.console.log('totalWidthCompetitorProducts: ' + totalWidthCompetitorProducts  )
                
                maxHeightCompetitorProducts = selectedCompetitorProducts.value.reduce((a, b)=>a['product_height'] > b['product_height']?a:b)['product_height']

                window.console.log('maxHeightCompetitorProducts: ' + maxHeightCompetitorProducts  )
                
                
                     
            }
                         
            // Define space between products 20 * 0.5 or less
            const space = Math.min(...selectedOptions.value.map(e => e['product_width']), 20) * 0.5
  
            // Get total width
            const totalWidth = totalWidthOwnProducts + totalWidthCompetitorProducts + 
                space * (selectedOptions.value.length + selectedCompetitorProducts.value.length +1 )

            
            window.console.log("totalWidth:" + totalWidth)

            // Get max height 
            const maxHeight = Math.max(maxHeightOwnProducts, maxHeightCompetitorProducts)


            window.console.log('maxHeight: ' + maxHeight)

            // Get canvas
            
            window.console.log('c.width: '+ canvas.width )
            window.console.log('c.height: '+ canvas.height )
            const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;


            // Set height and width for canvas
            //c.width = canvas_width;
            //c.height = canvas_height;

            // Get selected option                        
            const scaling = Math.min(canvas.width/totalWidth, canvas.height/maxHeight * 0.5)
            window.console.log("scaling:" + scaling)
                  
            // Draw selected competitor product
            for (const item of selectedCompetitorProducts.value) {
                await drawProduct(item, ctx, initLeft.value, top, scaling, space, 'competitor' )         
            }
            
            // Draw selected products
            for (const item of selectedOptions.value) {                
                item['image'] = store.state.general.selected_product['image'] // load image
                await drawProduct(item, ctx, initLeft.value, top, scaling, space, 'option' )                    
            }

          

        } // End of function to draw products


    //* ----------- Create shelf boards
        const shelfBoard = (bottom, canvas) => {

            const short = 20;
            const length = canvas.width;
             // Get canvas
             window.console.log(canvas)
            
            const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
            ctx.strokeStyle = '#808080'

            ctx.beginPath()  
            ctx.moveTo(0, bottom);
            ctx.lineTo(length, bottom);
       
            ctx.moveTo(short, bottom - short);
            ctx.lineTo(length - short, bottom - short);

            // Connect lines left
            ctx.moveTo(0, bottom);
            ctx.lineTo(short, bottom - short);

            // Connect lines rigth
            ctx.moveTo(length, bottom);
            ctx.lineTo(length - short, bottom - short);

            ctx.closePath()

            ctx.stroke();
            

        } // End of function to draw shelf board
     




        //* ------------------ Function to draw all
        const drawAll = (canvas) => {

            // Clean canvas
            //clean_canvas("canvas_shipping_box")

            // Clean canvas
            const ctx = canvas.getContext('2d')
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Draw bottom of shelf bottom
            shelfBoard(bottom, canvas);

            // Draw shelf board top
            shelfBoard(30, canvas);
            
            // Draw products
            drawProducts(bottom * 0.95, canvas);

        } // End of draw all


        // add to competitor product
        const onCompetitorProductClick = (c) => {
            window.console.log('c.id')
            window.console.log(c.id)
           
            
            selectedCompetitorProducts.value = selectedCompetitorProducts.value.concat(c)
            const canvas = canvasShelf.value as HTMLCanvasElement
            drawAll(canvas);
            
        } // End of on competitor product click

        const removeCompetitorProducts = () =>{
            selectedCompetitorProducts.value = []
             const canvas = canvasShelf.value as HTMLCanvasElement
            drawAll(canvas);
        }


    // mounted: function() {
    //      this.draw_all()
    //     }



      // On Mounted function
        onMounted(() => {                                    
            window.console.log('mounted canvas')
            const canvas = canvasShelf.value as HTMLCanvasElement
            window.console.log(canvas)
            drawAll(canvas)
            //const canvas = canvasShelf.value as HTMLCanvasElement
            //shelfBoard (10, canvas)
        })


    
                                        

        return {
            env,
            canvasShelf,
            competitorProducts,
            selectedCompetitorProducts,
            selectedOptions,
            selectedModel,
            tableData,
            drawProduct,
            shelfBoard,
            drawAll,
            onCompetitorProductClick,
            removeCompetitorProducts,
            selectDecision  
        }
    }, // End of setup
   


               

    
}
