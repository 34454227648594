<template>
  <!-- If user is logged in -->
  <div v-if="userLoggedIn || env == 'dev' ">
    <q-layout view="lHh Lpr lFf">
      <q-header elevated class="glossy">
        <!-- Top bar -->
        <q-toolbar class="bg-white text-black items-center">
                   
          <q-btn
            flat
            dense
            round
            @click="leftDrawerOpen = !leftDrawerOpen"
            aria-label="Menu"
            icon="menu"
          />          
            <div class="text-head-scalize q-ma-md"> MENU |   </div>
            <!-- Category -->
            <select-category />
            <select-project />          

            <!-- Create new Project -->
            <q-btn
              v-if="$store.state.general.selected_category.name != null"
              label="Create Project"
              @click="$store.commit('dialogs/updateCreateProjectDialog', true)"
              class="q-ma-md q-px-xs"
              outline
              color="deep-orange"
            >
              <q-tooltip content-class="bg-accent">Create a new project</q-tooltip
              >
            </q-btn>

            |


            <!-- Database Settings -->
            <q-btn 
              class="q-ma-md q-px-xs"
              label="Database" 
              @click="$router.push('/database')" 
              color="green" 
              outline 
              style="width:120px"
              />

              |

            <!-- Assortment Analysis -->
            <q-btn 
              class="q-ma-md q-px-xs"
              label="Analytics" 
              @click="$router.push('/assortment_analysis')" 
              color="blue" 
              outline 
              style="width:120px"
              />
              
              |


            <!-- Customer Settings -->
            <q-btn 
              label="Settings" 
              class="q-ma-md q-px-xs"
              @click="$router.push('/config')" 
              color="black" 
              outline 
              style="width:120px"
              />


            <!-- Customer Settings -->
            <q-btn 
              v-if="env==='dev'"
              label="Dev" 
              class="q-ma-md q-px-xs"
              @click="$router.push('/dev')" 
              color="black" 
              outline 
              style="width:120px"
              />

             <!-- Customer Settings -->
            <q-btn 
              v-if="env==='dev'"
              label="Report" 
              class="q-ma-md q-px-xs"
              @click="$router.push('/report')" 
              color="black" 
              outline 
              style="width:120px"
              />
                             
            <q-img :src = "require(`@/assets/scalize_logo.png`)" id="logo_image" class="q-ma-md absolute-right" style="max-width:120px" />  
       
  
        </q-toolbar>
      </q-header>

      <!-- -->

      <q-drawer
        v-model="leftDrawerOpen"
        show-if-above
        bordered
        content-class="bg-white"
      >
        <div class="q-pa-md">Welcome {{ userDataStore["userEmail"] }}</div>


        <div v-if="env=='dev'" >
          <h5>Dev AREA </h5>          

          <div class="row" >
            Name {{$store.state.general.selected_project.name}}
          </div>
          <div class="row" >          
            Projects {{$store.state.user.userProjects}} <br>      
          </div>
          <div class="row" >
            Users: {{$store.state.user.users}}
          </div>



        </div>


        <!-- <div class="q-pa-md"><q-btn @click="signOut()" color="black" label="Sign out"></q-btn> </div>    -->
        <sign-out />
        <!--<q-btn @click="$msal.signOut()" label="logout" color="primary"></q-btn>-->
        <div class="q-pa-md">
          Menu Bar
          <menu-bar />
          </div>

        <!-- Test area -->
        <div v-if="env == 'test' || env == 'dev'">
          <q-btn
            label="Test Secure Function"
            @click="$router.push('/test')"
            class="q-ma-md"
          />

          

          <q-btn label="getUserData" @click="getUserData" class="q-ma-md" />
          <q-space />
          Selected Category: {{ $store.state.general.selected_category["id"] }}
          <q-space />
          
          Selected Project:
          {{ $store.state.general.selected_project["projectID"] }}          
          <q-space />

          Selected Project Name:
          {{$store.state.general.selected_project.projectName}}                  

          <div class="q-ma-md">
            User Data :
            {{ $store.state.user.userData }}
          </div>

          {{ $store.state.user.userAuthInfo }}


          <div class="q-ma-md">
            Projects
            {{ $store.state.user.userData["projects"] }}
          </div>
          <div class="q-ma-md">
            Projects
            {{ $store.state.user.userProjects }}
          </div>
        </div>
      </q-drawer>

      <q-page-container>


        <!-- -->
        <pop-up-menu />

        <!-- Dialog for errors -->        
        <message-dialog  />        

        <!-- Check calculation parameters dialog -->
        <check-calc-parameters />

      <!-- Check Sensitivity parameters dialog -->
        <check-sensitivity-parameters />
             
        <create-project />
        <edit-project />

        <router-view />

      </q-page-container>
    </q-layout>
  </div>

  <!-- This is shown if user is not authenticated -->
  <div v-else>                  
      <div v-if="$router.currentRoute.value.fullPath=='/testscalize'">
        <router-view />
      </div>
      <div v-else>          
        
        <div class="mobile-hide">         
          <main-page />        
        </div>
        
        <div class="mobile-only">          
          <main-page-mobile /> 
        </div>
    </div>
    
      <intro-video />
    
      <login-error-dialog />
  </div>
</template>



<script>
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import * as Msal from "@azure/msal-browser"
import { useCookie } from 'vue-cookie-next'
import { useQuasar } from 'quasar'
import { useMeta } from 'vue-meta'


import userData from '@/components/mixins/userData.js';
import adminData from '@/components/mixins/adminData.js';
import competitorProducts from '@/components/mixins/competitorProducts.js';
import templates from '@/components/mixins/templates.js';


import menuBar from "@/components/menuBar.vue"; // @ is an alias to /src
import PopUpMenu from "./components/general_components/popUpMenu.vue";
import createProject from "./components/createProject.vue";
import signOut from "./components/general_components/signOut.vue";
import MainPage from "./components/landing_page/MainPage.vue";
//import MainPageMobile from "./components/landing_page_mobile/MainPage.vue";
import selectProject from './components/general_components/selectProject.vue';
import selectCategory from './components/general_components/selectCategory.vue';
import editProject from '@/components/general_components/editProject.vue'
import checkCalcParameters from '@/components/general_components/checkCalcParameters.vue'
import checkSensitivityParameters from '@/components/general_components/checkSensitivityParameters.vue'

import dialog from './components/general_components/dialog.vue';
import loginErrorDialog from './components/general_components/loginErrorDialog.vue';
import introVideo from "@/components/landing_page/introVideo.vue";
// import shareProject from './components/menu_bar/shareProject.vue';




export default {
  name: 'LayoutDefault',
 
  components: {
     "menu-bar": menuBar,
     "main-page": MainPage,
     "main-page-mobile": MainPage,
     "sign-out": signOut,
     "select-category": selectCategory,
     "select-project": selectProject,    
     "pop-up-menu": PopUpMenu,
     "create-project": createProject,
     "edit-project": editProject,
     "message-dialog": dialog,
     "login-error-dialog": loginErrorDialog,
    // "share-project": shareProject,
    "intro-video": introVideo,
    "check-calc-parameters": checkCalcParameters,
    "check-sensitivity-parameters": checkSensitivityParameters
    
  },

  setup () {

    useMeta({
      title: '',
      htmlAttrs: { lang: 'en', amp: true }
    })

    const { setCookie, removeCookie, getCookie } = useCookie()
    const $q = useQuasar()
    const router = useRouter()
        
    

    const env = computed(() => {return process.env.VUE_APP_ENV})
    
    const store = useStore()

    const leftDrawerOpen = ref(true)


    const {getUserData} = userData()
    const {getProjectData} = adminData()
    const {fetchCompetitorProducts} = competitorProducts()
    const {loadShippingBoxTemplates } = templates()


    // Load all users in teant
    store.dispatch("user/loadUsers")

    // Load shipping box templates
    //store.dispatch("templates/loadShippingBoxTemplates")
    loadShippingBoxTemplates()


    // Create SAML object
    const myMSALObj = ref()
    

    const link = ''
    const msalConfig =  {
          auth: {
            clientId: process.env.VUE_APP_AD_CLIENT_ID, // This is the ONLY mandatory field; everything else is optional.
            authority:  "https://scalize.b2clogin.com/scalize.onmicrosoft.com/B2C_1_login", // Choose sign-up/sign-in user-flow as your default.
            knownAuthorities: ["scalize.b2clogin.com"], // You must identify your tenant's domain as a known authority.
            redirectUri: process.env.VUE_APP_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
          },
          cache: {
            cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
            storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
          },
        }
      
    const loginRequest = {
          scopes: ["openid", ... ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]],
          //scopes: ["openid", ... ["https://mapmorize.onmicrosoft.com/1ca7a28d-c395-41dd-b72a-9c768cb387c8/Files.Read"]],
        }


       // error dialog
    const errorDialogShow = computed(()=> { return store.state.dialogs.dialog.show})
    const errorDialogMessage = computed(()=> { return store.state.dialogs.message})
    const projects = computed(()=> { return store.state.user.userProjects.filter(
      (p) =>
          p.category["id"] == store.state.general.selected_category["id"]
      ); })
    

    const userLoggedIn = computed(()=> { return store.state.user.loggedIn})
    const userDataStore = computed(()=> { return store.state.user.userData})


    onMounted( async () => {     

        if (env.value == "dev") {
          window.console.log('Dev environment')

          getUserData(); // Get user Data 
          getProjectData();
          fetchCompetitorProducts(); // Get competitor products
          store.dispatch("general/getProductCategories")

        }else{
          // Register request
          // Form data for post request
          const requestOptions = {
                
            method: "POST",
            headers: { "Content-Type": "application/json" },
          
            body: JSON.stringify({               
              info: 'registerRequest',                  
              type: 'register_request'                                               
              })
              };


          
          const response = await fetch("https://functions-app-marketing.azurewebsites.net/api/registerRequest", requestOptions).catch((error) => {            
          //const response = await fetch("http://localhost:7071/api/registerRequest", requestOptions).catch((error) => {            
            window.console.log(error)
            })

            window.console.log(response)

        }

          
      // Autologin user if still authenticated
      autologin();

    })
    
  //* Autologin function
     const autologin = async () => {

      window.console.log('performing auto login function')
      // Get Cookie 
      const user = localStorage.getItem('scalize_user')
      window.console.log('user: ' + user)
      const silentRequest = {
        account: user,
        scopes: ["openid", ... ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]]
      }
      
      // Create myMSAL Obj
      myMSALObj.value = new Msal.PublicClientApplication(msalConfig);         

      let error = 0
      let token = {}

      // Try to get user info, will only work if token still in local storage
      try {
        token = await myMSALObj.value.acquireTokenSilent(silentRequest)
        window.console.log(token['account']['username'])
        window.console.log(token)
        error = 0
      } 
      catch(e) {
        window.console.log(e)
        error = 1
      }

      if (error == 0) {
      
      // Verify correct user name
      if (token['account']['username'] == user) {
        store.commit('user/updateMySAML', myMSALObj.value)
        store.commit('user/updateUserAuthInfo', token)
        store.commit('user/updateUserData', {
                              iss: '',
                              idToken: token['account'].idToken,
                              userEmail: token['account'].username}
                              )
          // commit login to store
           store.commit('user/updateLoggedIn', true)
          await getUserData();

          // Load projects for admin
          getProjectData();
           
          window.console.log('welcome user you are logged in')
      }
      
      else{
        window.console.log('no autlogin possible')
      }
      }
    } //! end of autlogin

    //! Cookies
    
    // Set cookies
    const tecCookieAccepted = () => {
      setCookie("tecCookiesScalize", "true", { expires: 10 });          
    }

    // Reminder
    const showCookieReminder = () => {
      window.console.log('cookie reminder')    
       
       $q.notify({
         timeout: 0,
         position: "bottom-right",
         message: `We are using Cookies for the technical functionality of our site. <br>
                         For more information refer to our Privacy Section.`,
         html: true,
        color: "black",
        actions: [
          {
            label: "Accept",
            color: "yellow",
            handler: () => {
              tecCookieAccepted();
            },
          },
          //{ label: 'Get more information', color: 'white', handler: () => { this.$router.push('Cookies') } }
        ],
      });
    }


    // Mounted
    onMounted( ()=>{
      window.console.log('proccess.env.VUE_APP_REDIRECT_URI:' + process.env.VUE_APP_REDIRECT_URI)
      window.console.log(`currentRoute: ${Object.keys(router.currentRoute.value)}`)
      window.console.log(`currentRoute: ${router.currentRoute.value.fullPath}`)

      const cookie = getCookie('tecCookiesScalize')
      window.console.log('cookie')
      window.console.log(cookie)
          if(!cookie){
            window.console.log('cookie is null')
            showCookieReminder();
          }else{
            window.console.log(getCookie('tecCookiesScalize'))
            window.console.log('cookie accepted')
      }


    }
      
      )

  return {
    env, 
    Msal,
    userLoggedIn,
    userDataStore,
    leftDrawerOpen,
    tecCookieAccepted,
    showCookieReminder,
    autologin,
    
    
    }

    
  
  } //! End SETUP

      

  }



</script>

<style lang="scss">
@import "./styles/quasar.sass";


.row {
  .dev {
      color:blue;
      background: 	#d3d3d3;
  }
}

.highlight {
  border: 1px solid blue;
}

.text-head-scalize-large {
  font-size: 1.75em;
  line-height: 1.75em;
  letter-spacing: 0.07em;
  font-weight: 400;
}

.text-head-scalize {
  font-size: 1.25em;
  line-height: 1.5em;
  letter-spacing: 0.07em;
  font-weight: 400;
}

.text-menu-scalize {
  font-size: 1.15em;
  line-height: 1.5em;
  letter-spacing: 0.07em;
  font-weight: 400;
}
.text-body-scalize {
  font-size: 1.1em;
  line-height: 1.5em;
  letter-spacing: 0.07em;
  font-weight: 400;
}

.text-body-scalize-config-items {
  font-size: 1.2em;
  line-height: 1.8em;
  letter-spacing: 0.07em;
  font-weight: 400;
}

.text-body-scalize-warning {
  font-size: 1.1em;
  line-height: 1.5em;
  letter-spacing: 0.07em;
  font-weight: 400;
  color: #ff4f20e6;
}

.text-body-scalize-small {
  font-size: 1em;
  line-height: 1.5em;
  letter-spacing: 0.07em;
  font-weight: 400;
}

.text-item {
  font-size: 1.1em;
  line-height: 1.5em;
  letter-spacing: 0.07em;
  font-weight: 400;
}

.text-body-scalize-small-table {
  font-size: 1em;
  text-align: center;
  font-weight: 400;
}

.svg-text {
    font-size:0.8em;
    font-weight: 400;
    font-family: avenir;
    fill: grey;
}
</style>
