
import {computed} from 'vue'
import {useStore} from 'vuex'

export default {
  name: 'errorDialog',
  //  props: {   
  //     message: String,
  //     show: Boolean
  // },


  setup() {    
    const store = useStore()

    
    const dialogShow = computed(() => {      
        return store.state.dialogs.dialog
        }
      
      )

      const message = computed(() => {      
        return store.state.dialogs.message
        }
      
      )

    

    const close = () => {
       store.commit('dialogs/updateDialog', {
        show: false        
      })

    }
    return {
      close,
      store,
      dialogShow,
      message
    }

  },

 


}


