import { render } from "./overview.vue?vue&type=template&id=62e6752e"
import script from "./overview.vue?vue&type=script&lang=js"
export * from "./overview.vue?vue&type=script&lang=js"
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTh,QInput,QTooltip,QBtn,QIcon});
