<template>
  <div class="q-mx-lg">
    <div v-if="version == 'english'">
      <q-btn
        class="q-my-md"
        label="German Version"
        @click="version = 'german'"
        outline
      />

      <div class="row q-my-md">
        <div class="text-body-scalize-small text-dark">
          INFORMATION IN ACCORDANCE WITH § 5 TMG:<br /><br />

          SCALIZE GmbH <br />
          LAGERLÖFSTRAßE 5 <br />
          22391 HAMBURG <br /><br />

          MANAGING DIRECTOR: ROBERT HUGHES<br />
          ROBERT.HUGHES@SCALIZE.APP<br />
          +49 40 37 616 116<br />
          <br />
          REGISTERED COURT: AMTSGERICHT HAMBURG<br />
          COMMERCIAL REGISTER: HRB 168466<br />
          VAT NR.: DE 342278674<br />
          <br />
          © 2022 SCALIZE GmbH<br />
          ALL RIGHTS RESERVED
        </div>
      </div>
      <!--end of row -->

      <div class="row q-my-xl">
        <div class="text-body-scalize-small text-dark">COPYRIGHT</div>
        <div class="q-mt-md">
          THE CONTENT AND WORKS ON THIS WEBSITE, WHICH WAS CREATED BY THE SITE
          OPERATORS, ARE SUBJECT TO GERMAN COPYRIGHT LAW. THE REPRODUCTION,
          PROCESSING, DISTRIBUTION AND ANY KIND OF EXPLOITATION OUTSIDE THE
          LIMITS OF COPYRIGHT REQUIRE THE WRITTEN CONSENT OF THE RESPECTIVE
          AUTHOR OR CREATOR. DOWNLOADS AND COPIES OF THIS SITE ARE FOR PRIVATE,
          NON-COMMERCIAL USE ONLY. AS FAR AS THE CONTENTS ON THIS SITE WERE NOT
          CREATED BY THE OPERATOR, THE COPYRIGHTS OF THIRD PARTIES ARE
          CONSIDERED. IN PARTICULAR CONTENTS OF THIRD PARTIES ARE MARKED AS
          SUCH. IF YOU SHOULD STILL BE AWARE OF A COPYRIGHT INFRINGEMENT, WE ASK
          FOR A NOTE. UPON NOTIFICATION OF VIOLATIONS, WE WILL REMOVE SUCH
          CONTENT IMMEDIATELY.
        </div>
      </div>
      <!--end of row -->
      <div class="row q-my-xl">
        <div class="text-body-scalize-small text-dark">
          DISPUTE RESOLUTION
        </div>
        <div class="q-mt-md">
          THE EUROPEAN COMMISSION PROVIDES A PLATFORM FOR ONLINE DISPUTE
          RESOLUTION (ODR), WHICH CAN BE FOUND HERE
          http://ec.europa.eu/consumers/odr/. WE ARE NOT WILLING OR OBLIGED TO
          PARTICIPATE IN DISPUTE RESOLUTION PROCEDURES BEFORE A CONSUMER DISPUTE
          RESOLUTION BODY.
        </div>
      </div>
    </div>

    <div v-else>
      <q-btn
        class="q-my-md"
        label="English Version"
        @click="version = 'english'"
        outline
      />
      <div class="row q-my-md">
        <div class="text-body-scalize-small text-dark">
          ANGABEN GEMÄß § 5 TMG:<br /><br />

          SCALIZE GmbH <br />
          LAGERLÖFSTRAßE 5 <br />
          22391 HAMBURG <br /><br />

          GESCHÄFTSFÜHRER: ROBERT HUGHES<br />
          ROBERT.HUGHES@SCALIZE.APP<br />
          +49 40 37 616 116<br />
          <br />
          REGISTERGERICHT: AMTSGERICHT HAMBURG<br />
          HANDELSREGISTER: HRB 168466<br />
          UST-IDNR.: DE 342278674<br />
          <br />
          © 2022 SCALIZE GmbH<br />
          ALLE RECHTE VORBEHALTEN
        </div>
      </div>
      <!--end of row -->

      <div class="row q-my-xl">
        <div class="text-body-scalize-small text-dark">URHEBERRECHT</div>
        <div class="q-mt-md text-dark">
          DIE DURCH DIE SEITENBETREIBER ERSTELLTEN INHALTE UND WERKE AUF DIESEN
          SEITEN UNTERLIEGEN DEM DEUTSCHEN URHEBERRECHT. DIE VERVIELFÄLTIGUNG,
          BEARBEITUNG, VERBREITUNG UND JEDE ART DER VERWERTUNG AUßERHALB DER
          GRENZEN DES URHEBERRECHTES BEDÜRFEN DER SCHRIFTLICHEN ZUSTIMMUNG DES
          JEWEILIGEN AUTORS BZW. ERSTELLERS. DOWNLOADS UND KOPIEN DIESER SEITE
          SIND NUR FÜR DEN PRIVATEN, NICHT KOMMERZIELLEN GEBRAUCH GESTATTET.
        </div>
      </div>
      <div class="row q-my-xl">
        <div class="text-body-scalize-small text-dark">
          STREITBEILEGUNG
        </div>
        <div class="q-mt-md">
          DIE EUROPÄISCHE KOMMISSION STELLT EINE PLATTFORM ZUR
          ONLINE-STREITBEILEGUNG (OS) BEREIT, DIE SIE HIER FINDEN
          http://ec.europa.eu/consumers/odr/. WIR SIND NICHT BEREIT ODER
          VERPFLICHTET, AN STREITBEILEGUNGSVERFAHREN VOR EINER
          VERBRAUCHERSCHLICHTUNGSSTELLE TEILZUNEHEMEN.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "imprintPage",
  components: {},

  data() {
    return {
      position: 0,
      version: "english",
    };
  },
};
</script>

<style scoped lang="scss">
h6 {
  margin-bottom: 0px;
}
</style>
