
import {useStore} from 'vuex'
import {ref, computed} from 'vue'
import AppVue from '@/App.vue'

export default {
    setup() {
        
        const env = computed(() => {return  process.env.VUE_APP_ENV})
        const store = useStore()

        
        const columns = [ {
                    name: 'id',
                    required: true,
                    label: 'Article',
                    align: 'left',
                    field: row => row.article,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",                                      
                },
                {
                    name: 'Height',
                    required: true,
                    label: 'Height',
                    align: 'center',
                    field: row => row.height,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",                                      
                },
                {
                    name: 'Depth',
                    required: true,
                    label: 'Depth',
                    align: 'center',
                    field: row => row.depth,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",                                      
                },
                {
                    name: 'Width',
                    required: true,
                    label: 'Width',
                    align: 'center',
                    field: row => row.width,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",                                      
                },
                {
                    name: 'products_on_pallet',
                    required: true,
                    label: 'Products on Pallet',
                    align: 'center',
                    field: row => row.total_units_on_pallet,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",                                      
                },
                {
                    name: 'products_in_shipper',
                    required: true,
                    label: 'Products in Shipping Box',
                    align: 'center',
                    field: row => row.units_shipping_box,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",                                      
                },
                ]
        
        const dataStore = computed(() => store.state.massAnalysis.previewData)

        const data = ref(dataStore.value)

      

        const pagination = ref()

        // Remove rows
        const removeArticle = (article) => {
            window.console.log(article)
            window.console.log(article.article)
            const filteredData = data.value.filter(e => e.article != article.article)
            data.value = filteredData
            store.commit("massAnalysis/updatePreviewData", data.value)
            
        }


        return {
            env,
            columns,
            data,
            pagination,
            removeArticle
        }
}

}


