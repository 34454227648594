<template>


<!-- Front -->
  <rect 
    :x="xPositionScaled"
    :y="zPositionScaled"
    :width="scaledWidth"
    :height="scaledHeight"
    :fill="boxColor"
    stroke="white"
    />

    
  
  <!-- Side -->
  <polygon 
        :points="`
        ${xPositionScaled + scaledWidth},${zPositionScaled} 
        ${xPositionScaled + scaledWidth},${zPositionScaled + scaledHeight}         
        ${xPositionScaled + scaledWidth + xShiftDepth },${zPositionScaled - yShiftDepth + scaledHeight}
        ${xPositionScaled + scaledWidth + xShiftDepth },${zPositionScaled - yShiftDepth}
    `"
    :fill="boxColor"
    stroke="white"
    />

  <!-- Top -->
  <polygon 
        :points="`
        ${xPositionScaled},${zPositionScaled} 
        ${xPositionScaled + xShiftDepth},${zPositionScaled - yShiftDepth}         
        ${xPositionScaled + scaledWidth + xShiftDepth},${zPositionScaled -yShiftDepth}         
        ${xPositionScaled + scaledWidth},${zPositionScaled}         
    `"
    :fill="boxColor"
    stroke="white"
    />
<!-- 
    <text :x="xPositionScaled" :y="zPositionScaled" class="small" fill="black">
      {{Math.round(zPositionScaled)}}
      </text>
    
    <text 
      :x="xPositionScaled + scaledWidth + xShiftDepth" 
      :y="zPositionScaled - yShiftDepth + scaledHeight" 
      class="small" fill="white">
      {{Math.round(zPositionScaled - yShiftDepth + scaledHeight)}}
      </text>


      <text 
      x="50" 
      :y="zPositionScaled - 200" 
      class="small" 
      fill="red">
      
      {{zPositionCorrected * scaling  + startY - scaledHeight}}
      {{zPosition/palletDepth * palletDepthCorrected}}
    
      </text> -->
  
  <!-- Back rectanlge-->
 

          
  
</template>


<script lang="ts">

  export default {
    'name': 'shipper',
    'props': {
      scaling: {
        type: Number,
        required: true
      },
      width: {
        type: Number,
        required: true
      },
      depth: {
        type: Number,
        required: true
      },
      height: {
        type: Number,
        required: true
      },      
      
      xPosition: {
        type: Number,
        required: true
      },
      
      zPosition: {
        type: Number,
        required: true
      },

       boxType: {
        type: String,
        required: true
      },

      palletWidth: {
        type: Number,
        required: true
      },

      palletDepth: {
        type: Number,
        required: true
      },
      
      startY: {
        type: Number,
        required: true
      },
      
      spaceLeft: {
        type: Number,
        required: true
      }     

      
     
    },

    setup(props){
      

      const reduction = 0.01 // Set greater zero to see a little gap between shippers

    let boxColor
      if (props.boxType === 'a'){
        boxColor = "blue"
      }else{
        boxColor = "red"
      }


      const scaledWidth = props.width * props.scaling
      const scaledDepth = props.depth * props.scaling
      const scaledHeight = props.height * props.scaling          
      
      const xShiftDepth = Math.sqrt(Math.pow(scaledDepth, 2) / 2 )
      const yShiftDepth = Math.sqrt(Math.pow(scaledDepth, 2) / 2 )


      const palletDepthCorrected = Math.sqrt(Math.pow(props.palletDepth, 2)/2)
      
      const zPositionCorrected = -(props.zPosition/props.palletDepth * palletDepthCorrected)
      const zPositionScaled = zPositionCorrected * props.scaling  + props.startY - scaledHeight
      const xPositionScaled = props.xPosition * props.scaling + props.spaceLeft - zPositionCorrected * props.scaling
      

      return {
        scaledWidth,
        scaledDepth,
        scaledHeight,        
        yShiftDepth,
        xShiftDepth,
        xPositionScaled,
        zPositionScaled,
        palletDepthCorrected,
        zPositionCorrected,
        boxColor
      }
    }
  }
</script>