<template>
    <!-- This is shown if user is not authenticated -->
    <div>            
      <div v-if="env=='dev|test'">
        <h4> Dev AREA </h4>
        {{store.state.use.userData}}
      </div>
        <q-btn 
            class="bg-black text-white"
            label="Sign out" @click="signOut();" 
        />
    </div>
  
</template>

<script>
import {computed} from 'vue'
import {useStore} from 'vuex'



export default {
  name: 'signOut',

  setup() {
      const env = computed(() => {return process.env.VUE_APP_ENV})
      const store = useStore()
      
      const myMSALObj = computed(() => {return store.state.user.mySAML})


      const signOut = () => {
        // Choose which account to logout from.    
        const logoutRequest = {
          account: myMSALObj.value.getAccountByHomeId()
        };
    
      myMSALObj.value.logout(logoutRequest);

      }
       

      return {
        env,        
        signOut
      }

  } //! End of Setup
  
} 
      


</script>

<style lang="scss">


</style>
