import { render } from "./productsPage.vue?vue&type=template&id=735a7f1e&scoped=true"
import script from "./productsPage.vue?vue&type=script&lang=js"
export * from "./productsPage.vue?vue&type=script&lang=js"

import "./productsPage.vue?vue&type=style&index=0&id=735a7f1e&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-735a7f1e"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn});
