
// https://dev.to/deciduously/reactive-canvas-with-typescript-and-vue-1ne9

<template>
           
    <div class="row q-ma-xs q-pa-md">    
        <q-btn label="drawSmartBox" @click="draw" />           
    </div>
    <div class="row q-ma-xs q-pa-md">    
        <canvas             
            ref="canvasShippingBox" 
            :height="canvasHeight"
            :width="canvasWidth"
             />                     
    </div>

        
</template>

<script lang="ts">
//import MixinResult from '@/components/mixins/mixinsResult.ts';
import {computed, ref, watch, onMounted} from 'vue'
import { useStore } from 'vuex'


interface innerBox {
    xPosition: number
    zPosition: number
    width: number
    depth: number
    height: number
    type: string
    
}


export default {
    name: 'resultShippingBox',
    
    setup() {

      

        const canvasHeight = computed(() => {return window.innerHeight/5})
        const canvasWidth = computed(() => {return window.innerWidth/5})


        const data = ref('')
        
        //const {cleanCanvas} = MixinResult()

        //const canvas_2dpallet = ref<HTMLCanvasElement>()
        const canvasShippingBox = ref<HTMLCanvasElement | null>(null)



        const draw = () => { 
              
            const dataToDraw = {
                shipping_box : {
                    width: 400,
                    depth: 350,
                    height: 100,
                    air_height: 0,
                    air_depth: 0,
                    air_width: 0,
                    
                   layer: {
                    0: {
                        0: {                            
                            xPosition: 0, zPosition: 0, width: 120, depth: 100, height: 50, type: 'a'
                            },
                        1: {                            
                            xPosition: 120, zPosition: 0, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        2: {                            
                            xPosition: 240, zPosition: 0, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        3: {                            
                            xPosition: 0, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        4: {                            
                            xPosition: 120, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        5: {                            
                            xPosition: 240, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        6: {                            
                            xPosition: 0, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        7: {                            
                            xPosition: 120, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        8: {                            
                            xPosition: 240, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            }
                    }
                } // End of Layer
            }

          } // End of Data
                
                const canvas = canvasShippingBox.value as HTMLCanvasElement
                drawSmartBox(canvas, dataToDraw)
            }//     //drawPallet()
          
        
         onMounted(() => {//     // Check for no selected Option            


          const dataToDraw = { 
              shipping_box : {
                width: 400,
                depth: 200,
                height: 100,
                air_height: 10,
                air_depth: 5,
                air_width: 2,
                
                layer: {
                    0: {
                        1: {                            
                            xPosition: 0, zPosition: 0, width: 12.0, depth: 10, height: 0, type: 'b'
                            }
                    }
                }
            }

          }          // End of Data
                const canvas = canvasShippingBox.value as HTMLCanvasElement
                drawSmartBox(canvas, dataToDraw)
            }//     //drawPallet()
          )                               


        //* ---------------- Draw Smart Box ----------------
         const drawSmartBox = (canvas, option) => {

            window.console.log('drawing shipping box')

            // Get canvas
            
            const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;            
            
            // wipes the canvas context
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Get selected option                        
            const input =  option
            window.console.log('input:' + input)
            //const input = this.$store.state.general.data.filter(row => row.id == this.selected_option)[0]

            // Get shipping box
            const shipping_box = input['shipping_box']

                       // scale shipping box
            const scaling = canvas.height / (Math.sqrt(Math.pow(shipping_box['depth'], 2) + Math.pow(shipping_box['width'], 2)) + shipping_box['height']) * 0.9

                                          
            // Shipping box measures
            const start_x = 0
            const start_y = 0
            const width = shipping_box['width'] * scaling                        
            const depth = shipping_box['depth'] * scaling           
                        
            //ctx!.arc(95, 50, 40, 0, 2 * Math.PI);

            ctx!.beginPath();                
            ctx.setLineDash([]);
            ctx.strokeStyle = '#204FFF';    

            // Front rectanlge
            ctx.rect(start_x, start_y, width, depth);

            
            // Create ligns
            ctx!.stroke();


            // Draw shrinks
            window.console.log("-------------------------------")
            window.console.log(shipping_box['layer'])


            for (const [key, value] of Object.entries(shipping_box['layer'])) {
                window.console.log('----- layer -----------');
                window.console.log(`${key}: ${value}`);

                const layer = value as Record<string, unknown>

               Object.keys(layer).forEach(key => {

                   window.console.log(`key: ${key}`)
                   const shrink = layer[key] as innerBox
                                     
                   drawProduct(
                        ctx,
                        shrink.xPosition * scaling,
                        shrink.zPosition * scaling,
                        shrink.width * scaling,
                        shrink.depth * scaling,        
                        shrink['type']                
                        
                        )

                
                })




            } // end of draw products

         } //* End of Draw Smartbox
     


        // Function to draw products
        const drawProduct = (ctx, x, y, width, depth, type) => {

                

                //ctx.setLineDash([2, 2]);
                if (type==='a'){
                    ctx.strokeStyle = "#00FF00"; 
                } else {
                    ctx.strokeStyle = "#FF0000"; 
                }
                ctx!.beginPath();   
                
                // front rectangle
                ctx.rect(x, y, width, depth);

                                
                // Create ligns
                ctx!.stroke();

        }
            

        return {
            
            canvasShippingBox,
            canvasHeight,
            canvasWidth,            
            data,
            draw
            
        }

    }
}

</script>



<style scoped>
</style>