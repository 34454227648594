<template>
    <div class="row q-ma-xs col-12 text-body-scalize justify-start items-center" v-if="details===true">
      Move top layer:
      <q-btn @click="liftTopLayerValue = liftTopLayerValue + 0.1 "   
        outline 
        class="q-ml-md"
        ><q-icon name="add"></q-icon>      
      </q-btn>
      <q-btn         
        @click="liftTopLayerValue = liftTopLayerValue - 0.1 "         
        outline 
        class="q-ml-xs"
        ><q-icon name="remove"></q-icon>
        </q-btn>
        
      <q-btn label="Switch Blocks" @click="switchAxis()"       
          class="q-mx-md"
          outline 
        />
    </div>             
    
   
    <div class="row col-12" v-if="(env==='dev' && details)">
      <q-btn 
        class="q-ma-xs"
        label="Switch Perspective" @click="switchPerspective()" outline color="blue"
        />                     
    </div>
     

  <div class="row" ref="divPallet" :style="{'border':'none'}">
    <svg :height="svgHeight" :width="svgWidth" >      
      
      <pallet 
        :key="perspective + counter"
        :x="spaceLeft" 
        :y="startY" 
        :projection="perspective"        
        :z=0 
        :usedHeight = "palletUsedHeight * scaling"
        :baseHeight="palletBaseHeight * scaling" 
        :height="palletHeight * scaling" 
        :width="palletWidth * scaling" 
        :depth="palletDepth * scaling" 
        :scaling="scaling"
      />
        
        
      <pallet-layer 
        v-for="(n, index) in numberLayers" 
        :key="index + perspective + counter + mirrorAxis + liftTopLayerValue" 
        :projection= "perspective"
        :palletDepth= "palletDepth * scaling"
        :palletWidth= "palletWidth * scaling"
        :scaling = "scaling"
        :invert= "index % 2 === 0"  
        :mirrorAxis="mirrorAxis"                     
        :shippers="layer"
        :spaceLeft="spaceLeft" 
        :spaceBottom="numberLayers.length - index > 1?startY - index * layerHeight * scaling : startY - index * layerHeight * scaling * liftTopLayerValue" 
      />

                    

    </svg>

  </div>

   <div class="row q-ma-xs col-12 text-body-scalize justify-center items-center" v-if="details===false">
      <q-btn 
        label="Details" 
        @click="$store.commit('dialogs/updatePalletDetails', true)" 
        v-if="details===false"
        outline
        class="q-mx-xs"
        size="md"
      />

    </div>


  
</template>




<script lang="ts">



// interface Shipper {
//   zPosition: number;
//   xPosition: number;
//   width: number;
//   depth: number;
//   height: number;
//   type: string;

// }

//import shipperPallet from "@/components/visualisations/shipperPallet.vue"
import palletLayer from "@/components/visualisations/palletLayer.vue"
import pallet from "@/components/visualisations/pallet.vue"
import{ref, computed, watch, onMounted} from "vue"
import{useStore} from "vuex"
import {Shipper} from "@/types/shippingBox"

  export default {
    'name': 'projections',
    
    components: {   
      "pallet-layer": palletLayer,   
      "pallet": pallet,
    },

    props: {
      details: {
        type: Boolean,
        default: false
      },
      liftTopLayer: {
        type: Number,
        default: 1
      },
      enlarge: {
        type: Boolean,
        default: false
      },
    },
   
    
   
    setup(props){
      
     
      const env = computed(() => {return process.env.VUE_APP_ENV})
      const store = useStore()

      const liftTopLayerValue = ref(props.liftTopLayer)

      const perspective = ref('cabinet')
      const switchPerspective = () => {
        window.console.log("new perspective")
        if (perspective.value === 'cabinet'){
          perspective.value = "military"
        }else{
          perspective.value = "cabinet"
        }

      }
      
      // axis for which to mirror inversion of each layer
      const mirrorAxis = ref('depth')
      const switchAxis = () => {
        if (mirrorAxis.value === 'width'){
          mirrorAxis.value = 'depth'
        }else {
          mirrorAxis.value = 'width'
        }
      }
      
      // Div for pallet‚
      const divPallet = ref()
      
      const svgHeight = ref(250)

      if (props.enlarge){
        svgHeight.value = 350
      }
      const svgWidth = ref(window.innerWidth/4)

      const spaceLeft = computed( () =>  { 
        return perspective.value === "cabinet"? svgWidth.value * 0.3 : svgWidth.value * 0.6
      }
        
        )
      const spaceBottom = computed( () =>  {
        return perspective.value === "cabinet"? svgHeight.value * 0.2 : svgHeight.value * 0.5
      }
        )

      const palletWidth = ref(80)
      const palletDepth = ref(120)
      const palletHeight = ref(155)
      const palletUsedHeight = ref(155)
      const layerHeight = ref(10)
      
      const palletBaseHeight = 10
      const counter = ref(0)


      const scaling = ref(Math.min(svgWidth.value/palletWidth.value, svgHeight.value/(palletHeight.value + palletDepth.value)) * 0.7)

      // Define starting point for Y
      const startY = computed(() => {return svgHeight.value - spaceBottom.value - palletBaseHeight})

      
      const layer = ref([{ 
            "xPosition": 0,
            "zPosition": 77.79999999999998,
            "width": 77.8,
            "depth": 19.2,
            "height": 25.1,
            "type": "a"}])
            
      const selected_option = computed(() => {
          if (store.state.general.optionToVisualize != null) {
                  return store.state.general.optionToVisualize['id']
              }else{
                  return ''
              }
          }
          )


        // Update Graph after new value received
        const update = (newValue) => {

          let dataToDraw;
           if (newValue.slice(0,2) !== "s_" ) {            
            dataToDraw = store.getters['general/resultOption']({id: newValue})
          } else {
            window.console.log('GETTING SENSITIVITY OPTION')
            dataToDraw = store.getters['general/sensitivityOption']({id: newValue});
        }
        if (dataToDraw!==undefined){
              palletWidth.value = dataToDraw.pallet.width
              palletDepth.value = dataToDraw.pallet.depth
              palletHeight.value = dataToDraw.pallet.height
              palletUsedHeight.value = dataToDraw.pallet.total_height
              layerHeight.value = dataToDraw.product.height


                window.console.log(`divPallet.value.clientWidth: ${divPallet.value.clientWidth}`)
                svgWidth.value = divPallet.value.clientWidth
                svgHeight.value = divPallet.value.clientHeight
                
                //spaceLeft.value = divPallet.value.clientWidth * 0.2
                //startY.value = svgHeight.value * 0.8

                scaling.value = Math.min(divPallet.value.clientWidth/palletWidth.value, divPallet.value.clientHeight/(palletHeight.value + palletDepth.value)) * 0.7

                window.console.log(`scaling: ${scaling.value}`)
                

                // Get layer
                layer.value = []
                for (const [key, value] of Object.entries(dataToDraw.pallet.layer)){    

                let shipper: Shipper = value as Shipper

                layer.value.push({
                  zPosition: shipper.zPosition as number, 
                  xPosition: shipper.xPosition as number, 
                  width: shipper.width as number, 
                  depth: shipper.depth as number, 
                  height: shipper.height as number, 
                  type: shipper.type as string
                  })
                }

                // Create number of layers
                numberLayers.value = []

                for (let index = 1; index <= dataToDraw.pallet.number_layers; index++) {
                  numberLayers.value.push({"type": "a", "number": index})
                }

              counter.value ++                  
            }else{
              window.console.log('data to draw is null')
            }
      
                
      
        }
        // Watch selected option change to update picture
        watch(selected_option, (newValue) => {
            window.console.log('selected option changed')
            if (newValue == '') {
                window.console.log('no option selected')
            } else {
                update(newValue)
                 }
          })
                 //layer.value = dataToDraw.value
                //window.console.log(dataToDraw['pallet']['layer'])
              
            
      const numberLayers = ref( [
        {"type": "a", "number": 1},
        {"type": "b", "number": 2}, 
        {"type": "a", "number": 3},
        {"type": "b", "number": 4},
        {"type": "a", "number": 5},
        {"type": "b", "number": 6},
        ])


       const layer_shippers = [        
          { 
            "xPosition": 0,
            "zPosition": 77.79999999999998,
            "width": 77.8,
            "depth": 19.2,
            "height": 25.1,
            "type": "a"},
           
          {
              "xPosition": 1.0000000000000036,
              "zPosition": 0,
              "width": 19.2,
              "depth": 77.8,
              "height": 25.1,
              "type": "b"
          },
           {
                "xPosition": 20.2,
                "zPosition": 0,
                "width": 19.2,
                "depth": 77.8,
                "height": 25.1,
                "type": "b"
            },
             
            {
            "xPosition": 39.39999999999999,
                "zPosition": 0,
                "width": 19.2,
                "depth": 77.8,
                "height": 25.1,
                "type": "b"
            },
            {
              "xPosition": 58.599999999999994,
              "zPosition": 0,
              "width": 19.2,
              "depth": 77.8,
              "height": 25.1,
              "type": "b"
            }
       ]



      onMounted(() => {//     // Check for no selected Option                         
            if (selected_option.value == '') {
                window.console.log('no option selected')
            } else {
                update(selected_option.value)
            }
          })
              

      return {
        env,
        svgHeight,
        svgWidth,         
        spaceLeft,
        startY,
        palletWidth,   
        palletDepth,
        palletHeight,
        palletUsedHeight,
        palletBaseHeight,
        layerHeight,
        numberLayers,
        layer_shippers,
        switchPerspective,
        perspective,
        scaling,
        layer,
        counter,
        divPallet,
        mirrorAxis,
        switchAxis,
        liftTopLayerValue
      
        

        
        
      }
    }
  }
</script>