
import {computed, watch, ref, onMounted} from 'vue'

import {Point, Box} from '@/types/box.ts'



export default {
    name: 'resultProduct',
    'props': {
      
      product: {
        type: Object,
        required: true
      },
      svgWidth: {
        type: Number,
        required: true
      },
      svgHeight: {
        type: Number,
        required: true
      }
    },

    
          
    setup(props) {
        const env = computed(() => {return process.env.VUE_APP_ENV})
      
        const scaling = computed(() => {
          return Math.min(props.svgWidth/props.product.width, props.svgHeight/(props.product.height + props.product.depth)) * 0.5
          })
        
        const spaceLeft = computed(() => {return props.svgWidth * 0.3 })
        const spaceTop = computed(() => {return props.svgHeight * 0.2 })
            
        const spaceBottom = computed(() => {
          return props.svgHeight - spaceTop.value - props.product.height * scaling.value
          }) 
        
                      
        const productToDraw = ref(new Box(new Point(0, 0, 0), 10, 20, 18))                
        
        
        const boxColor = "white"
        const strokeColor = "blue"
        const strokeColorArrows = "grey"
        const opacity = 1


        const box = new Box(
          new Point(spaceLeft.value, spaceTop.value, 0), 
          props.product.width * scaling.value, 
          props.product.depth * scaling.value, 
          props.product.height * scaling.value
          )                
        box.cabinetProjection(62)

        productToDraw.value = box              

         onMounted(() => {//     // Check for no selected Option            
            window.console.log('selected option changed')  
                          
            
          })
                    

        return {
            env, 
            
            productToDraw,
            
            strokeColorArrows,

            scaling,
            boxColor,
            strokeColor,
            opacity,
                        
            spaceLeft,
            spaceTop,
            spaceBottom
            
            
        }

    },


  


    
               
/* --------------------- Mounted ------------------------------------ */
    // mounted: function() {     
        
        //cleanCanvas("canvas_product");
        //this.draw_product();
        
    // } // END OF MOUNTED
}
