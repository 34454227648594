export default {
    namespaced: true,
    
    /* ------------------- STATE -------------------------------------------------------- */
    state: {

      // userData
      userData: {
        iss: '',
        idToken: '',
        userEmail: '',
        userID: ''
      },

      userProjects: [],

      userAuthInfo: null,

      loggedIn: false,
      
      mySAML: null,
      
      // Users in tenant
      users: []
        
    },

     


    /* ------------------- MUTATIONS -------------------------------------------------------- */
    mutations: {

       // Function to update product
       updateLoggedIn(state, payload: boolean){
        state.loggedIn = payload
        
      },

      // Function to update product
      updateUserData(state, payload){
        window.console.log('updating user data')
        state.userData = payload
        
      },

      // Function to update product
      updateUserProjects(state, payload){
        window.console.log('updating user projects')
        window.console.log(payload)
        state.userProjects = payload        
      },


      updateUserAuthInfo(state, payload) {
        window.console.log('updating user AUTH INFO')
        state.userAuthInfo = payload;
      },


      updateMySAML(state, payload) {
        state.mySAML = payload;
      },

      updateUsers(state, payload) {
        state.users = payload;
      }  



    },
    actions: {

      async loadUsers({state, commit}) {

        window.console.log('!!!!!!!Loading user data!!!!!!!')

        // Form data for post request to share project
        const requestOptions = {
            
          method: "POST",
          headers: { "Content-Type": "application/json" },
        
          body: JSON.stringify({ 
            actionType: 'usersInSubscription',            
            user: state.userData,
                        
            })
            };

                  
          const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "queryCosmos" , requestOptions).catch((error) => {
            window.console.log(error)       
            }) ;

                
          const data = await (response as Response).json(); 

          commit('updateUsers', data.data)


      }

    },
    modules: {
    }
  }
  
