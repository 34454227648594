<template>



<!-- <report :factorReport="0.2" :bodyKey="2" :printVersion="true" :showBody="false" :showButtons="true" /> -->

<report :factorReport="0.5" :bodyKey="1" :printVersion="false"/>

  
  
    
</template>

<script lang="ts">
import { onMounted, ref, computed } from 'vue';
import report from "@/components/report/Report.vue"



export default {
  name: 'Home',
  components: {
    'report': report

  },

  setup(){


    const env = computed(() => {return process.env.VUE_APP_ENV})

    return {env}
    
}

}
</script>


