<template>    
    
      <q-dialog v-model="dialogShow" >
        <q-card class="q-ma-md items-center" flat bordered>                    
            <q-card-actions class="justify-end">
                <q-btn                   
                  color="black"                                
                  label="Close" 
                  @click="close();"                  
                />
          
        </q-card-actions>
          <q-card-section>
              <div class="text-head-scalize justify-center">                        
                    Verification of Calculation Parameters
              </div>
          </q-card-section>                  

          <q-card-section v-if="checkVolume" class="text-body-scalize">
          
            <div class="row q-mx-md">
              The brimful volume is higher than the possible volume (height x depth x width). 
              This could lead problems regarding weight restrictions. Would you like to ingore weight
              restriction on the pallet?
            </div>
             <div class="row q-ma-md">               
                  <q-toggle class="q-ma-md" label="ignore weights" v-model="ignoreWeights" color="blue" />
                   <q-btn class="q-ma-md" label="Change product settings" 
                      @click="$router.push('product');$store.commit('dialogs/updateCheckCalcParameters', false)"
                      color="blue" outline/>
                                         
              </div>

          </q-card-section>

          <q-card-section v-if="checkToleranceSensitivity" class="text-body-scalize">
            <div class="row q-mx-md">
              The current sensitivty tolerance is more than 2% of the total product volume. 
              This could lead to very long optimization time of the sensitivity analysis.              
            </div>
            
            <div class="row q-ma-md">
              <q-toggle class="q-ma-md" label="Set tolerance to 2%" v-model="restrictTolerance" color="blue" />                   
            </div>
           
          </q-card-section>

          <q-card-section>
             <div class="row q-mx-md justify-end">
              <q-btn class="q-ma-md" 
                      label="Scalize" color="red" @click="ajustedOptimize()" />
              </div>
          </q-card-section>


        
         

      </q-card>
      </q-dialog>

      
</template>


<script lang="ts">
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import optimizer from '@/components/mixins/optimize.ts'


export default {
  name: 'errorDialog',
  //  props: {   
  //     message: String,
  //     show: Boolean
  // },


  setup() {    
    const store = useStore()
    const {optimize, getSensitivity} = optimizer()


    // Show Dialog
    const dialogShow = computed(() => {      
        return store.state.dialogs.checkCalcParameters
        })

    const productVolume = computed(() => {
      return  store.state.general.selected_product.parameters.height.values.Target.value *        
        store.state.general.selected_product.parameters.width.values.Target.value  *
        store.state.general.selected_product.parameters.depth.values.Target.value
      })


    const checkVolume = computed(() => {
      let check;
      if (store.state.general.selected_product.advancedWeightCalc){
        check = store.state.general.selected_product.volumeParameters.brimfulVolume.values.target.value > productVolume.value
      }else{
        check = false
      }
      return check
    }
    )


    const ignoreWeights = ref(false)
    const restrictTolerance = ref(false)


    // Get values for sensitivity Tolerance
    const checkToleranceSensitivity = computed(() => {
      return  store.state.sensitivity.tolerance.ml / productVolume.value > 0.02
    })

    
    

      

    const ajustedOptimize= async () => {
      
      if (ignoreWeights.value){
        const parameters = store.state.userSettings.calculationParameters
        parameters.ignoreWeightPallet = true
        store.commit('userSettings/updateCalculationParameters', parameters)
        
      }

      if (restrictTolerance.value){        
        store.commit('sensitivity/updateTolerance', {ml: productVolume.value * 0.02})
        
      }      
      store.commit('dialogs/updateCheckCalcParameters', false)

      window.console.log(store.state.sensitivity.tolerance)
      optimize()


    }
    

    const close = () => {
       store.commit('dialogs/updateCheckCalcParameters', false)

    }
    return {
      close,
      store,
      dialogShow,
      checkVolume,
      ajustedOptimize,
      checkToleranceSensitivity,
      productVolume,
      ignoreWeights,
      restrictTolerance
      
    }

  },

 


}


</script>


<style scoped>

</style>