export default {
    namespaced: true,
    
    /* ------------------- STATE -------------------------------------------------------- */
    state: {

     
      // Selected Options from full analysis
      projects: [],
      
                        
    },
     


    /* ------------------- MUTATIONS -------------------------------------------------------- */
    mutations: {

     // Set selected options Full
     updateProjects(state, payload: []){
        state.projects = payload
      },

          


    },
    actions: {
    },
    modules: {
    }
  }
  
