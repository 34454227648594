import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import { VueCookieNext } from 'vue-cookie-next'
import { createMetaManager } from 'vue-meta'



const app = createApp(App)

app.use(VueCookieNext)
app.use(Quasar, quasarUserOptions)

app.use(store)
app.use(router)
app.use(createMetaManager()) // add this line


app.mount('#app')

