<template>
  <q-page>
   <main-page />
  </q-page>
</template>

<script lang="ts">
import MainPage from  '@/components/decisions/decisionsPage.vue'

export default {
  name: 'Decisions',
  components: {    
    'main-page': MainPage    

  },
};
</script>


<style scoped lang="scss">
@import "../styles/quasar.sass";

.text-h6 {
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.q-img{
  max-width: 150px;
  max-height: 325px
}

.q-card{
   height: 250px;
   max-width: 200px;
}

.q-item{
  color: blue;
  font-size: 1.2em;
}


.item_value{
 color: $primary;
 
}


.item_key{
 color:black;
}

</style>
