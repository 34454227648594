<template>
<div>
        <!-- selection of columns -->
        <div class="row q-pa-xs q-ma-md items-center" > 
            <q-select
                v-model="visibleColumns"
                @update:model-value="selectColumns"
                multiple
                outlined
                color="blue"
                dense
                options-dense
                :display-value="$q.lang.table.columns"
                emit-value
                map-options
                :options="columns"
                option-value="name"
                options-cover
                label="Select Columns"
                class="q-mx-md"                                    
                style="min-width:160px"        
                >
                  <template v-slot:option="scope">
                    <q-item v-bind="scope.itemProps">
                        <!-- <q-item-section avatar>
                        <q-icon :name="scope.group" />
                        </q-item-section> -->
                        <q-item-section> 
                            <q-item-label caption>{{ scope.opt.group }}</q-item-label>
                            <q-item-label>{{ scope.opt.label }}</q-item-label>
                        </q-item-section>
                    </q-item>
                    </template>
            </q-select>

            <div class="dev" v-if="env==='dev'">
                {{visibleColumns}}
            </div>


            <!-- Unselect all option -->
            <q-btn class="q-mx-md"  outline color="black" label="Unselect all" @click="unselectAll()" >
                <q-tooltip content-class="bg-accent">Unselect all results </q-tooltip>
            </q-btn> 
  
            
            <!-- Reset filter -->            
            <q-btn class="q-mx-md"  outline color="green" label="clear filters" @click="filter={}" >
                <q-tooltip content-class="bg-accent">Clear all filters </q-tooltip>
            </q-btn> 
  
            <!-- Excel export -->
            <q-btn class="q-mx-md" outline color="blue" label="Export" @click="exportExcel(data)" >
                <q-tooltip content-class="bg-accent">Export results to Excel </q-tooltip>
            </q-btn>
           
          

            <!-- Sensitivity analysis -->
            <!-- <q-btn 
                v-if="$store.state.general.dataSensitivity.length > 0"                   
                size="md"
                color="red"
                outline                        
                label="sensitivity"                
                style="max-width:160px"        
                class="q-mx-md"
                @click="showSensitivity=!showSensitivity;" 
                /> -->
            <div v-if="store.state.sensitivity.sensitivityRun === 'started'" class="text-body-scalize text-red">            
                <q-spinner-bars class="q-mx-md" 
                    color="red"
                    size="2em"
                />                
                Sensitivity Analysis               
            </div>
            <div v-else>
                 <!-- Run Sensitivity -->
            <q-btn class="q-mx-md" outline color="red" label="Run Sensitivity" @click="checkSensitivityParameters()" >
                <q-tooltip content-class="bg-accent">Run Sensitivity Analysis</q-tooltip>
            </q-btn>

            </div>
            
       
            
        </div>

<!-- -------------- TABLE -------------------------->
    <q-table 
        title="Configuration Results"
        :rows="data" 
        :columns="columns"
        row-key="id"
        selection="multiple"
        v-model:selected="selectedOptions"        
        @update:selected="makeSelection"

        :visible-columns="visibleColumns"
        v-model:pagination="pagination"

        :filter="filter"
        :filter-method="myfilterMethod"
        >

         <!-- <template #top-right>
            <q-btn
            dense
            flat
            icon="fullscreen"
            @click="fullscreen = !fullscreen"
            />
        </template>
               -->
        <!--Body -->
        <template v-slot:body="props">
            <q-tr :props="props">

                <q-td>
                    <q-checkbox dense v-model="props.selected" />
                </q-td>

                <q-td auto-width>
                    <q-btn size="sm" color="blue" 
                        round 
                        dense 
                        @click="props.expand = !props.expand" 
                        :icon="props.expand ? 'remove': 'add'" />
                </q-td>

                
                <q-td auto-width>
                    <q-btn size="sm" 
                        color="blue" 
                        round 
                        dense 
                        outline                        
                        @click="drawOption(props.row)" 
                        icon="draw" 
                        >
                         <q-tooltip content-class="bg-accent">Click to draw selected option.</q-tooltip>
                    </q-btn>
                </q-td>

                <q-td
                    v-for="col in props.cols"
                    :key="col.name"
                    :props="props"                                        
                    >
                    {{col.value}}
                </q-td>

          
            </q-tr>

            <q-tr v-show="props.expand" :props="props" >
                <q-td colspan="100%">

                    <!-- Check if sensitivity is still running -->
                     <div class="row q-ma-md" v-if="$store.state.sensitivity.sensitivityRun === 'started'">                    
                        <q-linear-progress size="35px" query color="black" class="q-my-md q-mx-xl" > 
                            <div class="absolute-full flex flex-center">
                                <q-badge class="text-body-scalize-small" color="white" text-color="accent" 
                                    label="Wait for sensitivity analysis to be finished" />
                            </div>
                        </q-linear-progress>
                    </div>

                    <div class="row q-ma-md text-body-scalize-warning" v-else-if="($store.state.sensitivity.sensitivityRun === 'not_executed') &&
                    ($store.state.general.dataSensitivity.length === 0)">                    
                         Please click "RUN SENSITIVITY" to start sensitivity analysis.
                    </div>
                    
                    <div v-else-if="($store.state.sensitivity.sensitivityRun === 'executed') || ($store.state.general.dataSensitivity.length > 0) ">                        
                        <!-- Definition of sub table -->
                        <sub-table
                            v-if="dataSensitivity.filter(e => 
                                //e.shipping_box_number_products == props.row.shipping_box_number_products &&
                                e.units_per_shrink == props.row.units_per_shrink &&
                                e.product_orientation == props.row.product_orientation && 
                                e.shipping_box_number_shrinks == props.row.shipping_box_number_shrinks &&
                                e.total_units_per_pallet >= props.row.total_units_per_pallet                         
                                ).length > 0"                     
                            
                            :parentOption="parseInt(props.row.id)" 

                            :data="dataSensitivity.filter(e => 
                            //e.shipping_box_number_products == props.row.shipping_box_number_products &&
                            e.units_per_shrink == props.row.units_per_shrink &&
                            e.product_orientation == props.row.product_orientation && 
                            e.shipping_box_number_shrinks == props.row.shipping_box_number_shrinks &&
                            e.total_units_per_pallet >= props.row.total_units_per_pallet                         
                            )" 
                            />
                        
                        <div v-else class="text-body-scalize-warning">
                            No Option with higher or equal layer efficiency available.
                        </div>
                    </div>
                     
                </q-td>

            </q-tr>

        </template>

    <!-- Header -->
        <template v-slot:header="props">
            <q-tr :props="props">
                <q-th auto-width />                                    

                <q-th auto-width />
                <q-th auto-width />
                <q-th
                    v-for="col in props.cols"
                    :key="col.name"
                    :props="props"
                >
                    <div class="row justify-center" style="height:25px">
                        <div class="row q-mt-xs justify-center text-body-scalize-small-table" style="height:40px">
                            {{ col.group }}
                        </div>
                    </div>
                   <div class="row justify-center" style="height:80px">
                        <div class="row q-mt-xs justify-center text-body-scalize-small-table" style="height:40px">
                            {{ col.label }}
                        </div>
                    </div>

                      <div class="row q-mt-xs justify-center" style="height:40px">
                        <q-input 
                            v-if="col.name != 'id'"                               
                            v-model="filter[col.field]" 
                            @input="updateFilter($event, parameter=col.field)" 
                            placeholder="filter"
                            outlined
                            dense
                            >
                            <q-tooltip content-class="bg-accent">Enter a value to filter, you can also use &lt; or &gt; </q-tooltip>
                        </q-input>
                    </div>   


                    <div class="row q-mt-xs justify-center items-center" style="height:40px">  
                        <div v-if="col.name != 'id'"  >
                            <q-btn @click="sortData(col.field, 'asc')" size="xs" class="q-mx-xs" >
                                <q-icon  name="arrow_upward"/>
                                <q-tooltip content-class="bg-accent">Sort ascending </q-tooltip>
                            </q-btn>
                            <q-btn @click="sortData(col.field, 'desc')" size="xs" class="q-mx-xs">
                                <q-icon  name="arrow_downward"/>
                                <q-tooltip content-class="bg-accent">Sort descending</q-tooltip>
                            </q-btn>
                        </div>
                    </div>

                </q-th>
            </q-tr>
      </template>

         
         <!-- Header  -->            
            <!-- <template v-slot:header-cell="props">
                
                <q-th :props="props">
                    <div class="row justify-center" style="height:80px">
                        <div class="text-body-scalize-small-table">
                            {{ props.col.label }}
                        </div>
                    </div>
                        
                    <div class="row q-mt-xs justify-center" style="height:40px">
                        <q-input                                
                            v-model="filter[props.col.field]" 
                            @input="updateFilter($event, parameter=props.col.field)" 
                            placeholder="filter"
                            outlined
                            dense
                            >
                            <q-tooltip content-class="bg-accent">Enter a value to filter, you can also use &lt; or &gt; </q-tooltip>
                        </q-input>
                    </div>   

                      <div class="row q-mt-xs justify-center" style="height:25px">  
                        <q-btn @click="sortData(props.col.field, 'asc')" size="xs" class="q-mr-xs" >
                            <q-icon  name="arrow_upward"/>
                            <q-tooltip content-class="bg-accent">Sort ascending </q-tooltip>
                        </q-btn>
                        <q-btn @click="sortData(props.col.field, 'desc')" size="xs">
                            <q-icon  name="arrow_downward"/>
                            <q-tooltip content-class="bg-accent">Sort descending</q-tooltip>
                        </q-btn>
                    </div>
                                                        
                </q-th>
            
            </template>    -->
             <!-- End of header -->      

    </q-table>
    <div class="row col-12 dev q-ma-md" v-if="env=='dev'">
        <div class="row q-ma-md">
            Total Options: {{ data.length }} <br><br>
        </div>
        <div class="row q-ma-md">
            Total Options Sensitivity: {{ dataSensitivity.length }} <br><br>
        </div>
        <div v-if="$store.state.decisions.selectedOptionsResult.length > 0">
            <div class="row q-ma-md">
                Store Selected: {{ $store.state.decisions.selectedOptionsResult }}
            </div>
            <div class="row q-ma-md">
                
            </div>
        </div>
    </div>
</div>

</template>


<script lang="js">
import { ref, computed, watch} from 'vue'
import { useStore} from 'vuex'
import subTable from '@/components/general_components/subTable.vue'

import exportData from '@/components/mixins/exportData.js'
import optimizer from '@/components/mixins/optimize.ts'

const columnWidth = 90

export default {
    name: 'nestedTable',
    components: {'sub-table': subTable},
    
    setup() {
        
        
        const env = computed(() => process.env.VUE_APP_ENV)
        const store = useStore() 

        const {exportExcel} = exportData()
        const {getSensitivity, checkSensitivityParameters} = optimizer()

        
        // const data = [
        //     {'id': 1, 'a': 1, 'b': 3, 'c': 6},
        //     {'id': 2, 'a': 2, 'b': 4, 'c': 5},
        //     {'id': 3, 'a': 3, 'b': 5, 'c': 5},
        //     ]

        const dataStore = computed(() => store.getters['general/result'])                       
        const data = ref(dataStore.value) // Data which is used for the table
        watch(() => store.getters['general/result'], function() {                                                        
                    data.value = dataStore.value
                });

        // store.watch((state, getters) => getters['general/result'], () => {
            //         window.console.log('value changes detected via vuex watch');
            // })


        // Sensitivity DAta
        const dataStoreSensitivity = computed(() => store.getters['general/resultSensitivity']) 
        const dataSensitivity = ref(dataStoreSensitivity.value)
        watch(() => store.getters['general/resultSensitivity'], function() {
                    window.console.log('SENSITIVTY VALUES IN STORE CHANGED');
                        dataSensitivity.value = dataStoreSensitivity.value
                });
        
        //ref(store.getters['general/resultSensitivity'].filter(e => e.shipping_box_number_products == matchFilter))
        //const data = ref(store.getters['general/result'])

        const selectedOptionsStore = computed(() => {
            return store.state.decisions.selectedOptionsResult
        })
        
        const selectedOptions = ref(selectedOptionsStore.value)

        
        // Select options and save to store
        const makeSelection = () =>{            
            window.console.log('making selection in main table')
            window.console.log(selectedOptions.value)
            store.commit('decisions/updateSelectedOptionsResult', selectedOptions.value)
        }


        // Select options and save to store
        const unselectAll = () =>{                        
            window.console.log("unselecting all options")
            selectedOptions.value = []
            store.commit('decisions/updateSelectedOptionsResult', [])
        }


        const pagination = ref({
                rowsPerPage: 10 // current rows per page being displayed
                })

        // this function is called when clicked on draw button
        const drawOption = (opt) => {
            window.console.log('clicked on draw button')
            window.console.log(opt)
            store.commit('general/updateOptionToVisualize', opt)
        }

        // Columns
        const visibleColumnsStore = computed(() => {return store.state.userSettings.selectedColumns})        
        const visibleColumns = ref(visibleColumnsStore.value)
        
        const selectColumns = () => {       
            store.commit("userSettings/updateSelectedColumns", visibleColumns.value)
            }


        const colorPalletHeader = "#027BE3"
        // const columns = [
        //     {name: 'id', field: 'id', label:'id'},
        //     {name: 'a', field: 'a', label:'a'},
        //     {name: 'b', field: 'b', label:'b'},
        //     {name: 'c', field: 'c', label:'c'},
        //     ]

         
        // Columns
        const columns = [
                {
                    name: 'id',
                    type: 'string',
                    required: true,
                    label: 'Result ID',
                    align: 'integer',
                    field: row => row.id,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },


                // -------------------- Product --------------------------------------------------
                
                {name: 'product_height', type: 'float', label: 'Height', field: 'product_height',
                    headerStyle: `max-width: ${columnWidth}px; color:#204fffE6`,
                    group: 'Product'},

                {name: 'product_width', type: 'float', label: 'Width', field: 'product_width', sortable: false,
                 headerStyle: `max-width: ${columnWidth}px; color:#204fffE6`,
                 group: 'Product'},
                
                {name: 'product_depth', type: 'float', label: 'Depth', field: 'product_depth',
                headerStyle: `max-width: ${columnWidth}px; color:#204fffE6`,
                group: 'Product'},
         
                {name: 'product_weight', type: 'float', label: 'Weight (g)', field: 'product_weight',
                sortable: false, 
                headerStyle: `max-width: ${columnWidth}px; color:#204fffE6`,
                group: 'Product'},

                {name: 'ml', type: 'float', label: 'ml', field: 'ml', sortable: false, 
                    headerStyle: `max-width: ${columnWidth}px; color:#204fffE6`,
                    group: 'Product'},

                
                {
                    name: 'product_volume_weight', type: 'float', label: 'Volume Weight (g)', field: 'product_volume_weight',                
                    sortable: false, 
                    headerStyle: `max-width: ${columnWidth}px; color:#204fffE6`,
                    group: 'Product'
                    },
                
                {
                name: 'product_packaging_weight', type: 'float', label: 'Packaging Weight (g)', field: 'product_packaging_weight',                
                sortable: false, headerStyle: `max-width: ${columnWidth}px`,
                     group: 'Product'},

            // ---------------- Shipping Box ------------------------------------- //
                {   
                    name: 'shipping_box_height',
                    type: 'float',
                    label: 'Height',
                    field: 'shipping_box_height', 
                    sortable: false,
                    //style: "width: 10px",
                    headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                    format: (val, row) => `${parseFloat(val).toFixed(2)}`,
                    group: 'Shipper'   
                  
                },

                {name: 'shipping_box_width', type: 'float', label: 'Width', field: 'shipping_box_width', sortable: false,  
                    headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                     format: (val, row) => `${parseFloat(val).toFixed(2)}`,
                    group: 'Shipper'   
                    },

                {name: 'shipping_box_depth', type: 'float', label: 'Depth', field: 'shipping_box_depth', sortable: false,
                    headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                    format: (val, row) => `${parseFloat(val).toFixed(2)}`,
                    group: 'Shipper'   
                },
                
                {name: 'shipping_box_config', type: 'string', label: 'Config', field: 'shipping_box_config', sortable: false, 
                    headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                    group: 'Shipper'   
                    },
                
                {name: 'units_per_shrink', type: 'float', label: 'Units per Shrink', field: 'units_per_shrink', sortable: false,
                    headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                    group: 'Shipper'   
                    },
                
                {name: 'number_shrinks', type: 'integer', label: 'Shrinks per Box', field: 'shipping_box_number_shrinks', sortable: false,
                    headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                    group: 'Shipper'   
                    },
                
                {name: 'shipping_box_number_layers', type: 'integer', label: 'Layers per Box', field: 'shipping_box_number_layers', sortable: false,
                    headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                    group: 'Shipper'   
                    },
                
                {name: 'product_orientation', type: 'string', label: 'Unit Orientation in Shrink', field: 'product_orientation', sortable: false,
                    headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                    group: 'Shipper'   
                    },
                
                {name: 'shipping_box_number_products', type: 'integer', label: 'Units in Shipping Box', field: 'shipping_box_number_products',
                 sortable: false, 
                 headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                 group: 'Shipper'   
                 },

                {name: 'shipping_box_weight', type: 'float', label: 'Weight (kg)', field: 'shipping_box_weight',
                 sortable: false, 
                 headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                    format: (val, row) => `${(val/1000).toFixed(3)}`,
                    group: 'Shipper'                          
                 },
                
                {name: 'shipping_box_packaging_material_weight', type: 'float', label: 'Weight Material (g)', field: 'shipping_box_packaging_material_weight',
                 sortable: false, 
                 headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                    format: (val, row) => `${val.toFixed(2)}`,
                    group: 'Shipper'                       
                 },
                
                // -------------- Pallet ------------------------------------------------- //                                
                 {name: 'pallet_height', type: 'float', label: 'Height', field: 'pallet_height', 
                     sortable: false, 
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                      sort: (a, b) => parseInt(a, 10) - parseInt(b, 10),                     
                     group: 'Pallet'
                     },

                 {name: 'pallet_total_height', type: 'float', label: 'Total height', field: 'pallet_total_height',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                     format: (val, row) => `${val.toFixed(2)}`,
                     group: 'Pallet'},

                {name: 'number_shipping_boxes_per_layer', type: 'integer', label: 'Shipping Boxes per Layer', field: 'total_boxes_per_layer', 
                     sortable: false, 
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                      sort: (a, b) => parseInt(a, 10) - parseInt(b, 10), 
                     group: 'Pallet'},
                     

                {name: 'number_layers', type: 'integer', label: 'Layers per Pallet', field: 'number_layers',
                     sortable: false, 
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                     sort: (a, b) => parseInt(a, 10) - parseInt(b, 10),
                     group: 'Pallet'},
              

                {name: 'number_shipping_boxes', type: 'integer', label: 'Shipping Boxes', field: 'total_boxes',
                     sortable: false, 
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                     sort: (a, b) => parseInt(a, 10) - parseInt(b, 10) ,
                     group: 'Pallet'},
                
                  
                {name: 'total_units_per_pallet', type: 'integer', label: 'Product Units', field: 'total_units_per_pallet',
                     sortable: false, 
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                     group: 'Pallet'
                     },

                {name: 'layer_efficiency', type: 'percent', label: 'Layer utilisation %', field: 'layer_efficiency',
                     sortable: false, 
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                     format: (val, row) => `${val.toFixed(2)*100}%`,
                     group: 'Pallet'},

                {name: 'pallet_height_utilisation', type: 'percent', label: 'Height utilisation %', field: 'pallet_height_utilisation',
                     sortable: false, 
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                     format: (val, row) => `${val.toFixed(2)*100}%`,
                     group: 'Pallet'},

                {name: 'pallet_efficiency', type: 'percent', label: 'Utilisation %', field: 'pallet_efficiency',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                     format: (val, row) => `${val.toFixed(2)*100}%`,
                     group: 'Pallet'},
                                 
               

                {name: 'pallet_block', type: 'float', label: 'Block', field: 'pallet_block',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                     format: (val, row) => `${val}`,
                     group: 'Pallet'},

                
                {name: 'pallet_weight', type: 'float', label: 'Weight (kg)', field: 'pallet_weight',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                     format: (val, row) => `${(val/1000).toFixed(2)}`,
                     group: 'Pallet'},
                     
                     
                {name: 'layer_weight', type: 'float', label: 'Layer Weight (kg)', field: 'pallet_layer_weight',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px`,
                     format: (val, row) => `${(val/1000).toFixed(2)}` ,
                     group: 'Pallet'},
                
                {name: 'number_layers_to_substract', type: 'float', label: 'Layers to be removed', field: 'pallet_number_layers_to_substract',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                    group: 'Pallet'},
                

                {name: 'truck_number_pallets', type: 'integer', label: 'Pallets on Truck', field: 'truck_number_pallets',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px; color:${colorPalletHeader}`,
                     group: 'Pallet'
                    },    
                
                {name: 'truck_pallets_weight', type: 'integer', label: 'Weight Pallets on Truck (kg)', field: 'truck_pallets_weight',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px`,
                     format: (val, row) => `${(val/1000).toFixed(2)}`,
                     group: 'Pallet'
                     
                    },    

                 
                                                                      
                     
                    
            
         
                
                       
            ]


            // Append columns if second pallet is true

                
        if(store.state.general.secondPallet.status){

            const additionalColumns = [                                                     
                {  
                    name: 'number_shipping_boxes_second_pallet', type: 'integer', label: '2. Pallet: Shipping Boxes', field: 'second_total_boxes',
                    sortable: false, headerStyle: `max-width: ${columnWidth}px`, 
                    sort: (a, b) => parseInt(a, 10) - parseInt(b, 10) 
                    },
                {  
                    name: 'second_total_units_per_pallet', type: 'integer', label: '2. Pallet: Units', field: 'second_total_units_per_pallet',
                    sortable: false, headerStyle: `max-width: ${columnWidth}px`, 
                    sort: (a, b) => parseInt(a, 10) - parseInt(b, 10) 
                    },

                {  
                    name: 'second_pallet_truck_number_pallets', type: 'integer', label: '2. Pallet: Pallets on Truck',
                    field: 'second_pallet_truck_number_pallets',
                    sortable: false, headerStyle: `max-width: ${columnWidth}px`, 
                    sort: (a, b) => parseInt(a, 10) - parseInt(b, 10) 
                    },

                 {
                    name: 'second_pallet_truck_pallets_weight', type: 'integer', label: '2. Pallet: Weight Pallets on Truck (kg)',
                    field: 'second_pallet_truck_pallets_weight',
                    sortable: false,
                    headerStyle: `max-width: ${columnWidth}px`,                
                    format: (val, row) => `${(val/1000).toFixed(2)}`
                    
                    },    


                

            ]

            // Append columns
            additionalColumns.forEach(element => 
                columns.push(element)
            )

            
                                      
        }

        

    //-----------------  FILTER --------------------------------

      const filter = ref({})
      
        const myfilterMethod = () => {    
                
            // Filter by number of boxes
            //let intermediate = this.result.filter(row => parseInt(row['total_boxes']) > this.filter.total_boxes)

            // Filter by ml
            //intermediate = intermediate.filter(row => parseInt(row['ml']) > this.filter.ml)

            let intermediate = data.value;

            window.console.log('this filter')
            
            window.console.log(filter.value)
            window.console.log(intermediate)

            // relevant filters

            for (const [key, value] of Object.entries(filter.value)) {

                if (value.length > 0){

                
                window.console.log(key, value);
                window.console.log(key, value[0]);
                window.console.log("len value " + value.length);
                
                const column = columns.filter(row => row.field == key )
                
                window.console.log("column type: " +  column[0]['type'])
                
           if (value != ""){
                // Filter dataset
                switch(column[0]['type']){
                    case 'float':
                        window.console.log('filtering float')
                        window.console.log(value.substring(1, value.length))
                        switch(value[0]) {
                            case ">":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) > value.substring(1, value.length))
                                break;
                            case "<":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) < value.substring(1, value.length))
                                break;
                            default:
                                intermediate = intermediate.filter(row => parseFloat(row[key]) == value)
                                break; 
                        }
                    break;

                    case 'integer':
                        switch(value[0]) {
                            case ">":
                                intermediate = intermediate.filter(row => parseInt(row[key]) > value.substring(1, value.length))
                                break;
                            case "<":
                                intermediate = intermediate.filter(row => parseInt(row[key]) < value.substring(1, value.length))
                                break;
                            default:
                                intermediate = intermediate.filter(row => parseInt(row[key]) == value)
                                break; 
                        }
                    break;

                    case 'percent':
                        window.console.log("filter value: " +  value.substring(1, value.length)/100)
                        window.console.log("intermediate value: " +  intermediate[0][key])
                        

                        switch(value[0]) {
                            case ">":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) > value.substring(1, value.length)/100)
                                break;
                            case "<":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) < value.substring(1, value.length)/100)
                                break;
                            default:
                                intermediate = intermediate.filter(row => parseFloat(row[key]).toFixed(2) == value.substring(0, value.length)/100)
                                break; 
                        }
                    break;


                    default:
                        intermediate = intermediate.filter(row => row[key].includes(value))
                        break;
                }
           }
                

                    // if (value != ""){
                    // switch(value[0]) {
                    //     case ">":
                    //         case column[0]['type'] == 'float':
                    //             intermediate = intermediate.filter(row => parseFloat(row[key]) > value.substring(1, value.length))
                    //             break;  
                    //         case column[0]['type'] == 'integer':
                    //             intermediate = intermediate.filter(row => parseInt(row[key]) > value.substring(1, value.length))
                    //             break;
                    //     case "<":
                    //         case column[0]['type'] == 'float':
                    //             intermediate = intermediate.filter(row => parseFloat(row[key]) < value.substring(1, value.length))
                    //             break;  
                    //         case column[0]['type'] == 'integer':
                    //             intermediate = intermediate.filter(row => parseInt(row[key]) < value.substring(1, value.length))
                    //             break;
                    //     default:
                    //          if (column[0]['type'] == 'string'){ 
                    //              window.console.log('string')
                    //              intermediate = intermediate.filter(row => row[key].includes(value))
                    //          }else {
                    //             case column[0]['type'] == 'float':
                    //                 intermediate = intermediate.filter(row => parseFloat(row[key]) == value)
                    //                 break;  
                    //             case column[0]['type'] == 'integer':
                    //                 intermediate = intermediate.filter(row => parseInt(row[key]) == value)
                    //                 break;
                                 
                    //          }
                    //          break;
                    // }

                      

                    }
                    } 
        
                window.console.log('filtered')
                window.console.log(intermediate)
                return intermediate
        } // End of Filter methods
               

        // Update Filter
        const updateFilter = (value, parameter) =>{

            window.console.log("Updating filter")
            window.console.log('value ' + value)
            window.console.log('parameter: ' + parameter)
            filter.value[parameter] = value
            window.console.log(filter.value)
        }



        // Sorting
        const compareValues = (key, order = 'asc') =>{
            return function innerSort(a, b) {
                // if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // // property doesn't exist on either object
                // return 0;
                // }

                //window.console.log(typeof a[key] )

                const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
                const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

                let comparison = 0;
                if (varA > varB) {
                    comparison = 1;
                } else if (varA < varB) {
                    comparison = -1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }

        const sortData = (key, direction) => {
            window.console.log('sorting')
            window.console.log('key: ' + key)
            window.console.log('direction: ' + direction)
            window.console.log('resut')
            
            const test = data.value;
  
           
            //this.result.sort(this.compareValues(key,  direction));
            window.console.log('sorted')
            window.console.log( test.sort(compareValues(key,  direction)));

            data.value = test.sort(compareValues(key,  direction))

        

            
        }



        return {
            env,
            store,
            exportExcel,
            columns,
            data,
            dataSensitivity,
            selectedOptions,
            makeSelection,
            visibleColumns,
            pagination,
            selectColumns,
            
            sortData,
            compareValues,
            updateFilter,
            myfilterMethod,
            filter,
            
            drawOption,
            unselectAll,

            getSensitivity,
            checkSensitivityParameters
        
            
        }
    }

}

</script>



<style scoped>
.q-table td, .q-table th {
    /* don't shorten cell contents */
    white-space: normal !important;
    height: 40px;
}
</style>