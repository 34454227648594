<template>
  <q-page>
    <!-- Spacer row -->
    <div class="row q-ma-xl" />
    <div class="text-body-scalize q-my-md q-ml-lg"> Projects in Database </div>

        
    <q-separator class="q-ma-xs" />
    
    <overview />
    
    
    <!-- Row for dev Information -->
    <div class="q-my-md q-ml-lg" v-if="env=='dev'" >
      Box for Dev information          
    </div> 


    
  </q-page>
</template>

<script lang="ts">
import{computed} from 'vue'
import overview from '@/components/projectDB/overview.vue'


export default {
  name: 'Config',
  components: {       
      'overview': overview,
      
  },

  setup(){
    const env = computed(() => {return process.env.VUE_APP_ENV})

    return {
      env
    }

  }

  

};
</script>

<style>

</style>
