
export default {
  name: "decisionsPage",
  components: {},

  data() {
    return {
      position: 0,
    };
  },
};
