import { render } from "./shelf.vue?vue&type=template&id=6141fde8"
import script from "./shelf.vue?vue&type=script&lang=ts"
export * from "./shelf.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnDropdown,QList,QItem,QBtn,QBtnToggle});qInstall(script, 'directives', {ClosePopup,Ripple});
