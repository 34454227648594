
import { ref, computed } from "vue";

import login from "@/components/general_components/login.vue";
import aboutPage from "@/components/landing_page/aboutPage.vue";
import productsPage from "@/components/landing_page/productsPage.vue";
import scalizePage from "@/components/landing_page/scalizePage.vue";
import decisionsPage from "@/components/landing_page/decisionsPage.vue";
import returnsPage from "@/components/landing_page/returnsPage.vue";
import demoPage from "@/components/landing_page/demoPage.vue";
import meetPage from "@/components/landing_page/meetPage.vue";
import needsPage from "@/components/landing_page/needsPage.vue";
import imprintPage from "@/components/landing_page/imprintPage.vue";
import privacyPage from "@/components/landing_page/privacyPage.vue";


//import scroll from 'quasar/src/utils/scroll.js';
//const { getScrollPosition, setScrollPosition } = scroll

export default {
  name: "mainPage",
  components: {
    login,
    "about-page": aboutPage,
    "products-page": productsPage,

    "scalize-page": scalizePage,
    "decisions-page": decisionsPage,
    "returns-page": returnsPage,
    "demo-page": demoPage,
    "meet-page": meetPage,
    "needs-page": needsPage,
    "imprint-page": imprintPage,
    "privacy-page": privacyPage,
    
  },

  setup() {
    const env = computed(() => {
      return process.env.VUE_APP_ENV;
    });

    const customer = computed(() => {return process.env.VUE_APP_CUSTOMER})

    const position = ref(0);


    const showVideo = ref(false)

    const header = ref();
    const about = ref();
    const decisions = ref();
    const products = ref();
    const scalize = ref();
    const returns = ref();
    const demo = ref();
    const meet = ref();
    const needs = ref();
    const privacy = ref();
    const imprint = ref();

    const pages = ref([
      {
        name: "about",
        element: about,
        order: 0,
        visible: true,
        height: 0,
        start: 0,
        end: 0,
      },

      {
        name: "products",
        element: products,
        order: 1,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },

      {
        name: "scalize",
        element: scalize,
        order: 2,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "returns",
        element: returns,
        order: 3,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "decisions",
        element: decisions,
        order: 4,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },

      {
        name: "demo",
        element: demo,
        order: 5,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "meet",
        element: meet,
        order: 6,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "needs",
        element: needs,
        order: 7,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "imprint",
        element: imprint,
        order: 8,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
      {
        name: "privacy",
        element: privacy,
        order: 9,
        visible: false,
        height: 0,
        start: 0,
        end: 0,
      },
    ]);

    const scrollToTop = (val) => {
      window.scrollTo(0, val);
    };

    // Scroll to area
    const scrollToArea = (name: string) => {
      const pixPosition = pages.value.filter((x) => x.name == name)[0]["start"];
      window.scrollTo(0, pixPosition);
    };

    // Action on scroll
    const onScroll = (e) => {
      // window.console.log('Scrolling top position:' + e)
      position.value = e;

      window.console.log("e:" + e);

      //const this.elements = ["about", "meet_scalize", "products"]

      // Create array
      //const arrayElements = elements.map(x => x as HTMLDivElement)

      //const all_rows = this.$refs.all_rows as HTMLDivElement;
      //const header = header.value as HTMLDivElement;

      // window.console.log('pages');
      window.console.log(pages);

      // get height for each element
      //let sumHeight = 0;
      let sumHeight = header.value.clientHeight;
      window.console.log("header.value.clientHeight");
      window.console.log(header.value.clientHeight);

      pages.value.forEach((x) => {
        // Get element as div

        window.console.log("x.element.value");
        window.console.log(x.element);
        // window.console.log("x.element")
        // window.console.log(x.element)
        window.console.log(x.element.clientHeight);

        // Get height

        // eslint-disable-line no-use-before-define
        x.height = x.element.clientHeight;

        //x.height = 10

        // Set start height of element
        x.start = sumHeight; // - 155 would set the about page to under the header (155px high), but the other pages don't align to the top of their seperators

        // Add own height
        sumHeight += x.height;

        // set end height
        x.end = sumHeight;

        // toggle visibiltiy
        if (e >= x.start && e < x.end) {
          x.visible = true;
        } else {
          x.visible = false;
        }
      });
    }; //! end of on SCROLL

    return {
      env,
      customer,
      about,
      header,
      decisions,
      products,
      scalize,
      returns,
      demo,
      meet,
      needs,
      imprint,
      privacy,
      pages,
      showVideo,
      scrollToTop,
      scrollToArea,
      onScroll,
    };
  },
};
