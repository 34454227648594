import { render } from "./Report.vue?vue&type=template&id=3a1de72e&scoped=true"
import script from "./Report.vue?vue&type=script&lang=ts"
export * from "./Report.vue?vue&type=script&lang=ts"

import "./Report.vue?vue&type=style&index=0&id=3a1de72e&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-3a1de72e"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QSpace,QImg,QSeparator});
