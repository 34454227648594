import { render } from "./pallet.vue?vue&type=template&id=d6630bd0&scoped=true"
import script from "./pallet.vue?vue&type=script&lang=ts"
export * from "./pallet.vue?vue&type=script&lang=ts"

import "./pallet.vue?vue&type=style&index=0&id=d6630bd0&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-d6630bd0"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardActions,QBtn,QSpace,QBtnDropdown,QIcon,QCardSection,QImg,QInput,QCheckbox,QSelect,QSlider});qInstall(script, 'directives', {ClosePopup});
