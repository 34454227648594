<template>    
    
      <q-dialog v-model="showDialog" >
        <q-card class="q-ma-md items-center" style="width:200px;height:180px" flat bordered>            
          <q-card-section>
              <div class="text-body-scalize justify-center">                        
                    Option Select
              </div>
          </q-card-section>                  

            <q-card-actions class="justify-end">
                <q-btn                   
                  color="black"                                
                  label="Close" 
                  @click="close();"                  
                />
          
        </q-card-actions>
        
         
      </q-card>
      </q-dialog>

      
</template>


<script lang="ts">
import {computed, ref} from 'vue'
import {useStore} from 'vuex'

export default {
  name: 'errorDialog',
  // props: {   
  //     message: String,
  //     show: Boolean
  //  },


  setup(props) {    
    const store = useStore()


    const showDialog = computed(() => {return store.state.dialogs.decisionDialog}) 
      
  
    const close = () => {       
      store.commit('dialogs/updateDecisionDialog', false)
  
      }
    
    return {
      close,
      store,
      showDialog      
    }

  }

 


}


</script>


<style scoped>

</style>