<template>
<div class="row q-ma-xs">
    <div class="text-head-scalize">Projects in Database</div>
    {{selected_options}}
</div>
<div class="row q-ma-md">
    <div class="col-6">
       
    <div class="row q-ma-xs">
        <q-table 
            selection="multiple"
            :rows="projects"
            :columns="columns"
            v-model:selected="selected_options"       
            row-key="projectID" 
            :pagination="pagination"
        >
        </q-table>
        
    </div>

     <div class="row q-ma-xs justify-start">
            <q-btn class="q-ma-xs" outline label="Delete Selected" color="red" @click="deleteProjects" />
            <q-btn class="q-ma-xs" outline label="Refresh Data" color="blue" @click="getProjectData" />
            
        </div>

    </div> <!--End of col-->
</div>

</template>

<script lang="ts">
import {computed, ref, watch, onMounted} from 'vue'
import {useStore} from 'vuex'
import deleteProjectTools from '@/components/mixins/deleteProject.js';
import adminData from '@/components/mixins/adminData.js';


export default {
      
     setup() {
                
        const store = useStore()     
        const {deleteProject} = deleteProjectTools()  
        const {getProjectData} = adminData()


        // Get environment 
        const env = computed(() => {
            return process.env.VUE_APP_ENV
            })

        const selected_options = ref([])

        const projects = computed (() => { return store.state.admin.projects})
        watch( () => store.state.admin.projects, function() {                                                        
            window.console.log('projects changed')
            // Reset selected options
            selected_options.value = []
            return store.state.admin.projects
            });

        const columnWidth = "80px"
        const pagination = ref({
            rowsPerPage: 10 // current rows per page being displayed
            })
        
        const columns = [
           
                {
                    name: 'projectName',
                    required: true,
                    label: 'Name',
                    align: 'left',
                    field: 'name',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",
                    headerStyle: `max-width: ${columnWidth}px;`

                },
                {
                    name: 'projectID',
                    required: true,
                    label: 'ID',
                    align: 'left',
                    field: 'projectID',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },
                {
                    name: 'projectOwner',
                    required: true,
                    label: 'Owner',
                    align: 'left',
                    field: 'projectOwner',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',                    
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },

                 {
                    name: 'projectViewer',
                    required: true,
                    label: 'Viewer',
                    align: 'left',
                    field: 'projectViewer',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',                    
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },


        ]

        
       // Delete project
       const deleteProjects = async () => {
           window.console.log(selected_options.value)

           for (const el of selected_options.value ){
            await deleteProject(el)
           }
           
        //    selected_options.value.forEach(element => {
        //        window.console.log("------------ Deleting ---------")
        //        window.console.log(element)
        //        window.console.log("------------ Deleting ---------")
        //        deleteProject(element)               
        //    })
       }


        // on Mounted
        onMounted(() => {getProjectData()})
   
        
        return {
            store,
            env,
            columns,
            projects,
            selected_options,
            pagination,
            deleteProjects,
            getProjectData
                        
 
        }
        
    },
}
</script>
