<template>
  <div class="q-pa-md">
    <div class="row" v-if="env=='dev'" >      
      <h4> Dev Area </h4>
      Selected options from decision table:
      {{store.state.decisions.decisionOptions.map(e =>e.id)}}
      
      Selected options from results table: 
      {{data.map(e =>e.id)}}
    </div>
    
    <div class="row">
      <q-table
        title="Decisions"
        :rows="data"
        :columns="columns"            
        row-key="id"
        selection="multiple"
        v-model:selected="selected_options"     
        binary-state-sort
        virtual-scroll
      >
      
      <template v-slot:header="props">

        <q-tr :props="props">
                  <q-th auto-width />                                           
                  <q-th auto-width />                                           
                  <q-th
                      v-for="col in props.cols"
                      :key="col.name"
                      :props="props"
                  >
                    <div class="row justify-center items-start" style="height:5em">
                        <div 
                        class="row q-mt-xs items-start justify-center scalize-table-header" 
                            style="height:40px">
                            {{ col.label }}
                        </div>
                    </div>
                  </q-th>
        </q-tr>

      </template>

        
        <!-- <template v-slot:header-selection="scope">
          <q-toggle v-model="scope.selected" />
        </template>

        <template v-slot:body-selection="scope">
          <q-toggle v-model="scope.selected" />
        </template>
        -->
  <!------------------------------- Body ------------------------------------->
        <template v-slot:body="props" >
          <q-tr :props="props" class="scalize-table-row">

            <q-td auto-width>
                <q-checkbox dense v-model="props.selected" />
            </q-td>

            <q-td auto-width>
                <q-btn label="report" outline  color="blue" @click="saveReportOption(props.row.id);$router.push('/report')" />
            </q-td>


            <q-td auto-width key="id" :props="props">            
                {{ props.row.id }}            
            </q-td>
            
  <!-- Product Values -->
            <q-td auto-width key="product_height" :props="props" >          
                {{ props.row.product_height }}   
              
            </q-td>

            <q-td auto-width key="product_width" :props="props">          
                {{ props.row.product_width }}
              
            </q-td>

            <q-td auto-width key="product_depth" :props="props">
              {{ props.row.product_depth }}      
            </q-td>

  <!-- Shipper  Values -->
            <q-td auto-width key="shipping_box_height" :props="props">
              {{ props.row.shipping_box_height.toFixed(2)  }}        
            </q-td>

            <q-td key="shipping_box_width" :props="props">
              {{ props.row.shipping_box_width.toFixed(2) }}        
            </q-td>

            <q-td auto-width key="shipping_box_depth" :props="props">
              {{ props.row.shipping_box_depth.toFixed(2)  }}           
            </q-td>

            <q-td auto-width key="units_per_shrink" :props="props">
                {{ props.row.units_per_shrink  }}           
              </q-td>

            <q-td auto-width key="shipping_box_number_shrinks" :props="props">
                {{ props.row.shipping_box_number_shrinks }}           
              </q-td>


              

            
      <!-- Line Speed KPI -->
            <q-td auto-width key="shrink_line_speed" :props="props" :class="`scalize-custom-column-${props.row.shrink_line_speed}`">
              <div class="row items-center justify-end">
                <div class="text-pre-wrap">{{ props.row.shrink_line_speed }}</div>
                <q-btn icon='arrow_drop_down' size="xs" text-color="blue" class="q-ml-md" outline />
                <q-popup-edit v-model="props.row.shrink_line_speed" buttons >              
                  <!-- <q-input type="number" v-model="props.row.shipping_box_depth" dense autofocus /> -->
                    <q-select v-model="props.row.shrink_line_speed" :options="options" label="Select a category" dense autofocus style="width:150px; height:50px" />             
                </q-popup-edit>
              </div>
            </q-td>

            <q-td auto-width key="shipping_box_number_products" :props="props">
              {{ props.row.shipping_box_number_products }}           
            </q-td>


        <!-- Trade KPI -->
          <q-td auto-width  key="trade_kpi" :props="props" :class="`scalize-custom-column-${props.row.trade_kpi}`">
              
              <div class="row items-center justify-end">
                <div class="text-pre-wrap">{{ props.row.trade_kpi }}</div>            
                
                <q-btn  icon='arrow_drop_down' size="xs" text-color="blue" class="q-ml-md" outline />
                <q-popup-edit v-model="props.row.trade_kpi" buttons>              
                  <!-- <q-input type="number" v-model="props.row.shipping_box_depth" dense autofocus /> -->
                    <q-select v-model="props.row.trade_kpi" :options="options" label="Select a category" dense autofocus style="width:150px; height:50px" />             
                </q-popup-edit>
              
              </div>
                
            </q-td>


          <!-- Logistics -->

          <q-td auto-width  key="total_units_per_pallet" :props="props">
              {{ props.row.total_units_per_pallet }}
            </q-td>

          <q-td auto-width key="layer_efficiency" :props="props">
              {{ `${(props.row.layer_efficiency * 100).toFixed(2)}%` }}           
                      
          </q-td>


            <!-- Logistics KPI -->
          <q-td auto-width key="logistics_kpi" :props="props" :class="`scalize-custom-column-${props.row.logistics_kpi}`">
            
            <div class="row items-center justify-end">
              <div class="text-pre-wrap">{{ props.row.logistics_kpi }}</div>
              <q-btn icon='arrow_drop_down' size="xs" text-color="blue" class="q-ml-md" outline />
                <q-popup-edit v-model="props.row.logistics_kpi" buttons>              
                <!-- <q-input type="number" v-model="props.row.shipping_box_depth" dense autofocus /> -->
                  <q-select v-model="props.row.logistics_kpi" :options="options" label="Select a category" dense autofocus  style="width:150px; height:50px" />             
              </q-popup-edit>
            </div>

            </q-td>

          
          
      
          </q-tr>
        </template>
      </q-table>
    </div>
    

  </div>
</template>

<script lang="ts">
import { computed, ref, watch } from 'vue'
import {useStore} from 'vuex'


  const options = ['GOOD', 'OK', 'NOT OK']

  const columnStyle= 'max-width:90px;min-width:60px'
  const columnStyleSelect= 'min-width:130px'
  
  const headerStyle= 'max-width:90px; min-width:60px;height:80px'
  const headerStyleSelect= 'min-width:130px; height:80px'
    

  const columns = [
          {
            name: 'id',
            required: true,
            label: 'ID',
            align: 'left',
            headerStyle: headerStyle,
            style: columnStyle,
            field: row => row.id,
            format: val => `${val}`,
            sortable: false
          },

            {name: 'product_height', type: 'float', label: 'Product Height', field: 'product_height',
                  sortable: false,   headerStyle: headerStyle, style: columnStyle,
            },

            {name: 'product_width', type: 'float', label: 'Product Width', field: 'product_width', sortable: false,
            headerStyle: headerStyle, style: columnStyle
            },
            
            {name: 'product_depth', type: 'float', label: 'Product Depth', field: 'product_depth',
              sortable: false,
              headerStyle: headerStyle, style: columnStyle,
              format: val => `${val}%`,
              },
                
          
            // Shipping Box         
            {   
            name: 'shipping_box_height',
            type: 'float',
            label: 'Shipping Box Height',
            field: 'shipping_box_height', 
            sortable: false,    
            //format: (val, row) => `${parseFloat(val).toFixed(2)}`,
            //classes: 'my-special-class',
            headerStyle: headerStyle, style: columnStyle,
              },

            {name: 'shipping_box_width', type: 'float', label: 'Shipping Box Width', field: 'shipping_box_width', sortable: false,
            headerStyle: headerStyle,
            style: columnStyle,  format: (val, row) => `${parseFloat(val).toFixed(2)}`},
            
            {name: 'shipping_box_depth', type: 'float', label: 'Shipping Box Depth', field: 'shipping_box_depth', sortable: false,
              headerStyle: headerStyle,
              style: columnStyle,
              format: (val, row) => `${parseFloat(val).toFixed(2)}`},


              {name: 'units_per_shrink', type: 'float', label: 'Units per Shrink', field: 'units_per_shrink', sortable: false,
              headerStyle: headerStyle,
              style: columnStyle},

              {name: 'shipping_box_number_shrinks', type: 'float', label: 'Shrinks per Box', field: 'shipping_box_number_shrinks', sortable: false,
              headerStyle: headerStyle,
              style: columnStyle},

                          

            // Shrink line KPI
            {name: 'shrink_line_speed', type: 'float', label: 'Shrink Line Speed', field: 'shrink_line_speed',
              sortable: false,   headerStyle: headerStyleSelect,
              style: columnStyleSelect,},

            {name: 'shipping_box_number_products', type: 'integer', label: 'Units in Shipping Box',
              field: 'shipping_box_number_products',
              align: 'left',
              headerStyle: headerStyle,
              style: columnStyle,
              sortable: false},

            // Trade KPI
            {name: 'trade_kpi', type: 'float', label: 'Trade KPI', field: 'trade_kpi',
              sortable: false, 
              headerStyle: headerStyleSelect,
              style: columnStyleSelect},

            {name: 'total_units_per_pallet', type: 'integer', label: 'Units per Pallet', field: 'total_units_per_pallet',
                          sortable: false,   headerStyle: headerStyle,
            style: columnStyle,
                          },
            
            {name: 'layer_efficiency', type: 'percent', label: 'Layer utilisation %', field: 'layer_efficiency',
                  sortable: false,  headerStyle: headerStyle,
            style: columnStyle,
                  //format: (val, row) => `${parseFloat(val).toFixed(2)*100}%` 
                  },

            // Logistics KPI
            {name: 'logistics_kpi', type: 'float', label: 'Logistics KPI', field: 'logistics_kpi',
              sortable: false,   headerStyle: headerStyleSelect,
            style: columnStyleSelect,},           

        ] // End of columns
        


// -------------- Create Component 
export default {
    name: 'decisionsTable',

   setup() {

     // Get env 
     const env = computed(() => {return process.env.VUE_APP_ENV})
     
     // Get store
     const store = useStore()     

     const data = computed(() => {
       //* Combined selected Options from sensitiviy and results both are in the decision store module
            
            // Results Sensitivity
            let intermediate = store.state.decisions.selectedOptionsSensitivity
            intermediate = intermediate.filter(Boolean);
            intermediate = intermediate.map(e => e.values).flat()

            window.console.log('intermediate after getting sensitivity results')
            window.console.log(intermediate)
            
            // add results
            window.console.log('store.state.decisions.selectedOptionsResult')
            window.console.log(store.state.decisions.selectedOptionsResult)
            intermediate = intermediate.concat(store.state.decisions.selectedOptionsResult)            

            window.console.log(intermediate)

            return intermediate
       }
       )

    // Selected options in table
     const selected_options = ref([])

     selected_options.value = store.state.decisions.decisionOptions

     watch(selected_options, (newValue) => {
       window.console.log('selected options changed')
       window.console.log(newValue)
       store.commit('decisions/updateDecisionOptions', newValue)
     })

    // Init data
    //  const data = ref([
    //   {
    //     id: 1,
    //     product_height: 237,
    //     product_width: 9.0,
    //     product_depth: 37,

    //     shipping_box_height: 237,
    //     shipping_box_width: 9.0,
    //     shipping_box_depth: 37,
    //     shrink_line_speed: '',

    //     shipping_box_number_products: 100,
    //     trade_kpi: '',


    //     total_units_per_pallet: 1000,
    //     layer_efficiency: 0.95,
    //     logistics_kpi: '',

        
    //   },
    //   {
    //     id: 2,
    //     product_height: 237,
    //     product_width: 9.0,
    //     product_depth: 37,

    //     shipping_box_height: 237,
    //     shipping_box_width: 9.0,
    //     shipping_box_depth: 37,
    //     shrink_line_speed: '',

    //     shipping_box_number_products: 200,
    //     trade_kpi: '',

    //     total_units_per_pallet: 1000,
    //     layer_efficiency: 0.95,
    //     logistics_kpi: '',


    //   },
 
    // ]) // End of data



    const saveReportOption = (id) => {
      store.commit('decisions/updateOptionSelectedForReport', id)
      
      }


    return {
      env,
      store,
      columns,
      data,
      options,
      selected_options,
      saveReportOption

    }
  }
}
</script>

<style lang="scss" scoped>


.scalize-table-header {
  color: black;
  font-size: 1.1em;  
  text-align: center;
  font-weight: 400;  
  word-wrap: break-word;
  white-space: normal !important;
  
  

}

.scalize-table-row {
  color: black;
  font-size: 0.9em;    
  text-align: center;
  font-weight: 400;
    
}

.scalize-custom-column-GOOD {
  color: green;     
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  
}


.scalize-custom-column-NOT.OK {
  color: red;
  
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  
}

.scalize-custom-column-OK {
  color: blue;
  
  text-align: center;
  vertical-align: middle;
  font-weight: 400; 
  
}

.scalize-column-id {
  color: blue;
  text-align: center;
  font-weight: 400;
  
  
}



</style>