<template>  
    <div v-html="template" />       
</template>

<script lang="js">


export default {
  name: 'privacyPage',
  components: {
   
  },

  props: {
      templateName: String
  },


  data(){ 
      return{        
        template: ''       
    }
  },


  methods: {
  
    // * Get Template
   async getTemplate() {
       //* Define request parameters            
      const requestOptions = {
        
        method: "GET",
        headers: { "Content-Type": "application/json" },
        
        };
      
      const response = await fetch('assets/templates/' + this.templateName, requestOptions).catch((error) => {
              window.console.log('Error handling')        
              window.console.log(error)
                                 
      })

      const response_from_api = await response.text();
      
      // window.console.log('response_from_api')
      // window.console.log(response_from_api)
      this.template = response_from_api;


       
  }

  },

  mounted(){
    window.console.log('mounting')
    this.getTemplate();
}


};
</script>


<style scoped lang="scss">

h6 {
  margin-bottom: 0px;
 }


</style>