<template>    
    
      <q-dialog v-model="dialogShow" >
        <q-card class="q-ma-md items-center" flat bordered>                    
            <q-card-actions class="justify-end">
                <q-btn                   
                  color="black"                                
                  label="Close" 
                  @click="close();"                  
                />
          
        </q-card-actions>
          <q-card-section>
              <div class="text-head-scalize justify-center">                        
                    Verification of Sensitivity Parameters
              </div>
          </q-card-section>                  

          
          <q-card-section v-if="checkToleranceSensitivity" class="text-body-scalize">
            <div class="row q-mx-md">
              The current sensitivty tolerance is more than 2% of the total product volume. 
              This could lead to very long optimization time of the sensitivity analysis.              
            </div>
            
            <div class="row q-ma-md">
              <q-toggle class="q-ma-md" label="Set tolerance to 2%" v-model="restrictTolerance" color="blue" />                   
            </div>
           
          </q-card-section>

          <q-card-section>
             <div class="row q-mx-md justify-end">
              <q-btn class="q-ma-md" 
                  label="Run Sensitivity" color="red" @click="ajustedOptimize()" />
              </div>
          </q-card-section>


        
         

      </q-card>
      </q-dialog>

      
</template>


<script lang="ts">
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import optimizer from '@/components/mixins/optimize.ts'


export default {
  name: 'errorDialog',
  //  props: {   
  //     message: String,
  //     show: Boolean
  // },


  setup() {    
    const store = useStore()
    const {getSensitivity} = optimizer()


    // Show Dialog
    const dialogShow = computed(() => {      
        return store.state.dialogs.checkSensitivityParameters
        })

    const productVolume = computed(() => {
      return  store.state.general.selected_product.parameters.height.values.Target.value *        
        store.state.general.selected_product.parameters.width.values.Target.value  *
        store.state.general.selected_product.parameters.depth.values.Target.value
      })



    const ignoreWeights = ref(false)
    const restrictTolerance = ref(false)


    // Get values for sensitivity Tolerance
    const checkToleranceSensitivity = computed(() => {
      return  store.state.sensitivity.tolerance.ml / productVolume.value > 0.02
    })

          
    const ajustedOptimize= async () => {           

      if (restrictTolerance.value){        
        store.commit('sensitivity/updateTolerance', {ml: productVolume.value * 0.02})        
        }      
      store.commit('dialogs/updateCheckSensitivityParameters', false)

      window.console.log(store.state.sensitivity.tolerance)
      getSensitivity()


    }
    

    const close = () => {
       store.commit('dialogs/updateCheckSensitivityParameters', false)

    }
    return {
      close,
      store,
      dialogShow,      
      ajustedOptimize,
      checkToleranceSensitivity,
      productVolume,
      ignoreWeights,
      restrictTolerance
      
    }

  },

 


}


</script>


<style scoped>

</style>