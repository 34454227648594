
import { useStore } from 'vuex'
import { computed} from "vue";
import { useRouter} from 'vue-router'
import adminData from '@/components/mixins/adminData.js';

import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';

export default function() {
   
    const store = useStore()
    const router = useRouter()

    const {getProjectData} = adminData()


    const env = computed(() => process.env.VUE_APP_ENV) 


    //* ----------- Deleting project ---------------------------
    const deleteProject = async(project) => {
        window.console.log(`deleting project: ${project['projectID']}`);
        Loading.show({
            delay: 400, // ms
            spinner: QSpinnerGears,
            message: 'Deleting project'
        })
    

    // Form data for post request
    const requestOptions = {        
        method: "POST",
        headers: { "Content-Type": "application/json" },        
        body: JSON.stringify({             
            actionType: 'deleteProject',
            user: store.state.user.userData,
            projectID: project['projectID'],
            projectName: project['name'],                    
            })
        };

    const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "updateCosmos" , requestOptions).catch((error) => {
        window.console.log(error)       
        }           
        );

    
    const data = await (response).json();


    if (data['content'] == "not authorized"){        

        store.commit('dialogs/updateMessage',  'You are not the project owner and not authorized to delete the project.')
        store.commit('dialogs/updateDialog', true)

        Loading.hide()

                   

    }else{
        window.console.log("Response:" + data)
        // Refresh user data
        // Reset project
        //this.$store.commit('general/updateSelectedProject', {})
        await getProjectData()
        Loading.hide()
        store.commit('dialogs/updateMessage',  'Deleted Project.')
        store.commit('dialogs/updateDialog', true)

        
     
    
  }     

 
 
  
} // End of deleting project

    //
    return {
      deleteProject
    }
  }
          
  