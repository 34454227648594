
// https://dev.to/deciduously/reactive-canvas-with-typescript-and-vue-1ne9

<template>
           
    <div class="row q-ma-xs q-pa-md">    
        <q-btn label="drawSmartBox" @click="draw" />           
    </div>
    <div class="row q-ma-xs q-pa-md">    
        <canvas             
            ref="canvasShippingBox" 
            :height="canvasHeight"
            :width="canvasWidth"
             />                     
    </div>

        
</template>

<script lang="ts">
//import MixinResult from '@/components/mixins/mixinsResult.ts';
import {computed, ref, watch, onMounted} from 'vue'
import { useStore } from 'vuex'


interface innerBox {
    xPosition: number
    zPosition: number
    width: number
    depth: number
    height: number
    type: string
    
}


export default {
    name: 'resultShippingBox',
    
    setup() {

      

        const canvasHeight = computed(() => {return window.innerHeight/5})
        const canvasWidth = computed(() => {return window.innerWidth/5})


        const data = ref('')
        
        //const {cleanCanvas} = MixinResult()

        //const canvas_2dpallet = ref<HTMLCanvasElement>()
        const canvasShippingBox = ref<HTMLCanvasElement | null>(null)



        const draw = () => { 
              
            const dataToDraw = {
                shipping_box : {
                    width: 400,
                    depth: 350,
                    height: 100,
                    air_height: 0,
                    air_depth: 0,
                    air_width: 0,
                    
                   layer: {
                    0: {
                        0: {                            
                            xPosition: 0, zPosition: 0, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        1: {                            
                            xPosition: 120, zPosition: 0, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        2: {                            
                            xPosition: 240, zPosition: 0, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        3: {                            
                            xPosition: 0, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        4: {                            
                            xPosition: 120, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        5: {                            
                            xPosition: 240, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        6: {                            
                            xPosition: 0, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        7: {                            
                            xPosition: 120, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        8: {                            
                            xPosition: 240, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            }
                    }
                } // End of Layer
            }

          } // End of Data
                
                const canvas = canvasShippingBox.value as HTMLCanvasElement
                drawSmartBox(canvas, dataToDraw)
            }//     //drawPallet()
          
        
         onMounted(() => {//     // Check for no selected Option            


          const dataToDraw = { 
              shipping_box : {
                width: 400,
                depth: 200,
                height: 100,
                air_height: 10,
                air_depth: 5,
                air_width: 2,
                
                layer: {
                    0: {
                        1: {                            
                            xPosition: 0, zPosition: 0, width: 12.0, depth: 10, height: 0, type: 'b'
                            }
                    }
                }
            }

          }          // End of Data
                const canvas = canvasShippingBox.value as HTMLCanvasElement
                drawSmartBox(canvas, dataToDraw)
            }//     //drawPallet()
          )
            
            
        // Draw standard shipping box
        const drawShippingBox = (canvas, option) => {

            window.console.log('drawing shipping box')

            // Get canvas
            
            const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;            
            
            // wipes the canvas context
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Get selected option                        
            const input =  option
            window.console.log('input:' + input)
            //const input = this.$store.state.general.data.filter(row => row.id == this.selected_option)[0]

            // Get shipping box
            const shipping_box = input['shipping_box']

            
            // scale shipping box
            const scaling = canvas.height / (Math.sqrt(Math.pow(shipping_box['depth'], 2) + Math.pow(shipping_box['width'], 2)) + shipping_box['height']) * 0.9

            window.console.log('Scaling: '+ scaling)

            // add to width and height
            const factor_width = Math.sqrt(Math.pow(shipping_box['depth'], 2) / 2) * scaling
            const factor_height = Math.sqrt(Math.pow(shipping_box['depth'], 2) / 2) * scaling

            window.console.log("factor_width: " + factor_width)

            // Add Edges for canvas
            
            // Calculate correct canvas width and height
     

            // Get drawing context
      
            // Shipping box measures
            const left = 0
            const width = shipping_box['width'] * scaling
            const right = left + width      
            const bottom = canvas.height
            const height = -shipping_box['height'] * scaling 


            window.console.log('canvasheight:' + canvas.height )
            window.console.log('canvaswidth:' + canvas.width )
            window.console.log('height:' + height )
            window.console.log('bottom:' + bottom )
                        
            //ctx!.arc(95, 50, 40, 0, 2 * Math.PI);

            ctx!.beginPath();                
            ctx.setLineDash([]);
            ctx.strokeStyle = '#204FFF';    

            // Front rectanlge
            ctx.rect(left, bottom, width, height);

            // back rectangle
            ctx.rect(left + factor_width, bottom - factor_height, width, height);

            // Bottom line left
            ctx.moveTo(left, bottom);
            ctx.lineTo(left + factor_width, bottom - factor_height);

            // Bottom line right
            ctx.moveTo(left + width, bottom);
            ctx.lineTo(left + width + factor_width, bottom - factor_height );


            // Top line left
            ctx.moveTo(left, bottom + height );
            ctx.lineTo(left + factor_width, bottom + height - factor_height);

            // Top line right
            ctx.moveTo(left + width, bottom + height );
            ctx.lineTo(left + width + factor_width, bottom + height - factor_height);

            // Create ligns
            ctx!.stroke();


            // ------------------- Draw prodcuts -----------------------------------

            // get product
            const product = input['product']
            

            // add to width and height
            let product_width = product['width'] * scaling
            let product_depth = product['depth'] * scaling

            // Correct for orientation of product
            if (shipping_box['product_orientation'] === 'side'){
                product_width = product['depth'] * scaling
                product_depth = product['width'] * scaling
            }
          
            
            
            const product_height = product['height'] * scaling
            
            // const product_row_distance = this.shipping_box['cm_space_row_tubes'] * scaling
            // const product_side_distance = this.shipping_box['air_width'] * scaling

            const factor_product_width = Math.sqrt(Math.pow(product_depth, 2) / 2) 
            const factor_product_height = Math.sqrt(Math.pow(product_depth, 2) / 2) 
            

            let init_x = left + shipping_box['air_width'] * scaling;
            let init_y = bottom - (product['height'] + shipping_box['air_height']) * scaling;

             // Iterate over rows with
            for (let i = 0; i < shipping_box['rows_depth']; i++) {          
                window.console.log(i)     
               //init_x = 20
               let x = init_x
               const y = init_y
               // Iterate over rows depth
                for(let n = 0; n < shipping_box['rows_width']; n++){
      
                    drawProduct(
                        ctx,
                        x,
                        y,                                
                        product_width,
                        product_depth,
                        product_height,
                        0,
                        0
                    )

                //y += this.product['height'] * 0.5
                x += product_width + shipping_box['cm_space_row_width_product'] * scaling
                
                }

                //init_x += factor_product_width
                init_x += factor_product_width 
                init_y += - factor_product_height
            }

        }




        //* ---------------- Draw Smart Box ----------------
         const drawSmartBox = (canvas, option) => {

            window.console.log('drawing shipping box')

            // Get canvas
            
            const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;            
            
            // wipes the canvas context
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Get selected option                        
            const input =  option
            window.console.log('input:' + input)
            //const input = this.$store.state.general.data.filter(row => row.id == this.selected_option)[0]

            // Get shipping box
            const shipping_box = input['shipping_box']

                       // scale shipping box
            const scaling = canvas.height / (Math.sqrt(Math.pow(shipping_box['depth'], 2) + Math.pow(shipping_box['width'], 2)) + shipping_box['height']) * 0.9

            
            // add to width and height
            const factor_width = Math.sqrt(Math.pow(shipping_box['depth'], 2) / 2) * scaling
            const factor_height = Math.sqrt(Math.pow(shipping_box['depth'], 2) / 2) * scaling
                              
            // Shipping box measures
            const left = 0
            const width = shipping_box['width'] * scaling            
            const bottom = canvas.height
            const height = -shipping_box['height'] * scaling           
                        
            //ctx!.arc(95, 50, 40, 0, 2 * Math.PI);

            ctx!.beginPath();                
            ctx.setLineDash([]);
            ctx.strokeStyle = '#204FFF';    

            // Front rectanlge
            ctx.rect(left, bottom, width, height);

            // back rectangle
            ctx.rect(left + factor_width, bottom - factor_height, width, height);

            // Bottom line left
            ctx.moveTo(left, bottom);
            ctx.lineTo(left + factor_width, bottom - factor_height);

            // Bottom line right
            ctx.moveTo(left + width, bottom);
            ctx.lineTo(left + width + factor_width, bottom - factor_height );


            // Top line left
            ctx.moveTo(left, bottom + height );
            ctx.lineTo(left + factor_width, bottom + height - factor_height);

            // Top line right
            ctx.moveTo(left + width, bottom + height );
            ctx.lineTo(left + width + factor_width, bottom + height - factor_height);

            // Create ligns
            ctx!.stroke();


            // Draw shrinks
            window.console.log("-------------------------------")
            window.console.log(shipping_box['layer'])


            for (const [key, value] of Object.entries(shipping_box['layer'])) {
                window.console.log('----- layer -----------');
                window.console.log(`${key}: ${value}`);

                const layer = value as Record<string, unknown>

               Object.keys(layer).forEach(key => {

                   window.console.log(`key: ${key}`)
                   const innerBox = layer[key] as innerBox
                   window.console.log(`innerBox Width: ${innerBox.width}`)                                   
                  
                   drawProduct(
                        ctx,
                        left + innerBox.xPosition * scaling + Math.sqrt(Math.pow(innerBox.zPosition * scaling, 2) / 2),
                        bottom - Math.sqrt(Math.pow(innerBox.zPosition * scaling, 2) / 2) - innerBox.height * scaling,
                        innerBox.width * scaling,
                        innerBox.depth * scaling,
                        innerBox.height * scaling,
                        0,
                        0
                        )

                
                })




            } // end of draw products

         } //* End of Draw Smartbox
     


        // Function to draw products
        const drawProduct = (ctx, left, bottom, width, depth, height, space_width, space_depth) => {

                // add to width and height
                const factor_width = Math.sqrt(Math.pow(depth, 2) / 2) 
                const factor_height = Math.sqrt(Math.pow(depth, 2) / 2) 

                // window.console.log('left:' + left)
                // window.console.log('bottom:' + bottom)
                // window.console.log('width:' + width)

                ctx.setLineDash([2, 2]);
                ctx.strokeStyle = "#FF4F20"; 
                ctx!.beginPath();   
                
                // front rectangle
                ctx.rect(left, bottom, width, height);

                // back rectangle
                ctx.rect(left + factor_width, bottom - factor_height, width, height);
                //ctx.rect(z-scaling_width, y-scaling_height, z-scaling_width+width, y+scaling_height*height);

                // Bottom line left
                ctx.moveTo(left, bottom);
                ctx.lineTo(left + factor_width, bottom - factor_height);

                // Bottom line right
                ctx.moveTo(left + width, bottom);
                ctx.lineTo(left + width + factor_width, bottom - factor_height );


                // Top line left
                ctx.moveTo(left, bottom + height );
                ctx.lineTo(left + factor_width, bottom + height - factor_height);

                // Top line right
                ctx.moveTo(left + width, bottom + height );
                ctx.lineTo(left + width + factor_width, bottom + height - factor_height);

                // Create ligns
                ctx!.stroke();

        }
            

        return {
            
            canvasShippingBox,
            canvasHeight,
            canvasWidth,            
            data,
            draw
            
        }

    }
}

</script>



<style scoped>
</style>