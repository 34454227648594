
import { useStore } from 'vuex'
import { computed } from "vue";
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';

export default function() {
   
    const store = useStore()

    const env = computed(() => process.env.VUE_APP_ENV) 
   

    //* --- Function to get user Data
    const fetchCompetitorProducts = async () => {

      window.console.log('getting user data inside function')
            
      // Show loading
      Loading.show({
          delay: 400, // ms
          spinner: QSpinnerGears,
          message: 'Loading competitor products'
      })

      let user_email = ''
      if(env.value=='dev') {
          user_email = 'jan.mammen@scalize.app'
      }else{
          user_email = store.state.user.userData.userEmail
      }
     
      window.console.log('Getting Cosmos DB')
      
      const url = process.env.VUE_APP_FUNCTION_URI + "queryCosmos"
      window.console.log('url: ' + url)
      
      const requestOptions = {
        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          userEmail: user_email,
          actionType: 'competitorProducts'
          })
      };
      const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "queryCosmos" , requestOptions).catch((error) => {
              window.console.log(error)

          store.commit('dialogs/updateMessage', 'Error querying API: your competitor products could not be loaded.')
          store.commit('dialogs/updateDialog', true)    
              
              // Hide loading
              Loading.hide();              
        }
          
          
        );

      // placeholder for data
      let responseJson;
      

      if (response.status == 200) {
        window.console.log(response)
        responseJson = await response.json();
        window.console.log("Response:" + responseJson)
                                
        store.commit('competitor/updateCompetitorProductsDB', responseJson.data)        

        // Hide loading
        Loading.hide();        
      
      }else{
        
        store.commit('dialogs/updateMessage', 'Error loading Competitor Products')
        store.commit('dialogs/updateDialog', true)                              
        Loading.hide();
      }


    } // End of function to get user Data

  //* -------------------------------------------------
  //* Remove competitor product
  //* -------------------------------------------------
    const removeCompetitorProducts = async (ids) => {

       // Show loading
       Loading.show({
        delay: 400, // ms
        spinner: QSpinnerGears,
        message: 'Removing competitor product.'
    })

      let user_email = ''
      let userID
      if(env.value=='dev') {
          user_email = 'jan.mammen@scalize.app'
          userID = 2
      }else{
          user_email = store.state.user.userData.userEmail
          userID = parseInt(store.state.user.userData.userID)
      }

      const requestOptions = {
        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          userEmail: user_email,
          userID: userID,
          actionType: 'removeCompetitorProduct',
          ids: ids
          })
      };
      const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "updateCosmos" , requestOptions).catch((error) => {
          window.console.log(error)
              
          store.commit('dialogs/updateMessage', 'Error querying API: your projects could ne be loaded.')
          store.commit('dialogs/updateDialog', true)    
              
          // Hide loading
          Loading.hide();              
        }
                    
        );

      // placeholder for data
      let responseJson;
      

      if (response.status == 200) {
        window.console.log(response)
        responseJson = await response.json();
        window.console.log("Response:" + responseJson)     
        
        // Refresh Data
        fetchCompetitorProducts()

        // Hide loading
        Loading.hide();
      
      }else{
        
        store.commit('dialogs/updateMessage', 'Error deleting Competitor Products')
        store.commit('dialogs/updateDialog', true)                              
        Loading.hide();
      }



    }



  //* -------------------------------------------------
  //* Remove competitor product
  //* -------------------------------------------------
  const updateCompetitorProduct = async (data) => {

    let user_email = ''
    let userID
    if(env.value=='dev') {
        user_email = 'jan.mammen@scalize.app'
        userID = 2
    }else{
        user_email = store.state.user.userData.userEmail
        userID = store.state.user.userData.userID
    }

    const requestOptions = {
      
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        title: "Request",
        userEmail: user_email,
        userID: userID,
        actionType: 'updateCompetitorProduct',
        data: data
        })
    };
    const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "updateCosmos" , requestOptions).catch((error) => {
        window.console.log(error)
            
        store.commit('dialogs/updateMessage', 'Error querying API: competitor product could ne be changed.')
        store.commit('dialogs/updateDialog', true)    
            
        // Hide loading
        Loading.hide();              
      }
                  
      );

    // placeholder for data
    let responseJson;
    

    if (response.status == 200) {
      window.console.log(response)
      responseJson = await response.json();
      window.console.log("Response:" + responseJson)     
      
      // Refresh Data
      fetchCompetitorProducts()

      // Hide loading
      Loading.hide();
    
    }else{
      
      store.commit('dialogs/updateMessage', 'Error updating Competitor Products')
      store.commit('dialogs/updateDialog', true)                              
      Loading.hide();
    }



  }

    //
    return {
      fetchCompetitorProducts,
      removeCompetitorProducts,
      updateCompetitorProduct
    }
  }
          
  