
import { useStore } from 'vuex'
import { computed} from "vue";
import { useRouter} from 'vue-router'


import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';

export default function() {
   
    const store = useStore()
    const router = useRouter()


    const env = computed(() => process.env.VUE_APP_ENV) 


    // function to load selected project   
    const loadSelectedProject = async (id) => {
      
      // Reseting data
      store.commit("general/updateResult", []);
      store.commit("general/updateResultSensitivity", []);
      window.console.log('getting user data inside function')
            
      // Show loading
      Loading.show({
          delay: 400, // ms
          spinner: QSpinnerGears,
          message: 'Loading project.'
      })

      let user_email = ''
      if(env.value=='dev') {
          user_email = 'jan.mammen@outlook.com'
      }else{
          user_email = store.state.user.userData.userEmail
      }
     
      window.console.log('Getting Cosmos DB')
      
      const url = process.env.VUE_APP_FUNCTION_URI + "queryCosmos"
      window.console.log('url: ' + url)
      
      const requestOptions = {
        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          userEmail: user_email,
          actionType: 'project',
          projectID: id
          })
      };
      const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "queryCosmos" , requestOptions).catch((error) => {
              window.console.log(error)
              
         store.commit('dialogs/updateErrorDialog', {
              show: true,
              message: 'Error querying API: your projects could ne be loaded.'
          })
              
              // Hide loading
              Loading.hide();              
        }
          
          
        );

      // placeholder for data
      let result;
      

      if (response.status == 200){
        window.console.log(response)
        result = await response.json();
        

        if(result.message == "project found"){
        
        // reset sensitivity run
        store.commit("sensitivity/updateSensitivityRun", "not_executed")
        

        // Updating user data in store
        //store.commit('user/updateUserData', data.user)
        //store.commit('user/updateUserProjects', data.projects)

        // Update values for specific project in general store
        window.console.log('result.data["product"]')
        window.console.log(result.data["product"])

        // USer Settings
        if("userSettings" in result.data){
          store.commit('userSettings/updateCalculationParameters', result.data['userSettings'].calculationParameters)
          store.commit('userSettings/updateSelectedColumns', result.data['userSettings'].selectedColumns)
        }


        // Update data
        store.commit("general/updateProduct", result.data["product"]);
        
        store.commit("general/updateShippingBox", result.data["shippingBox"]);
        
        store.commit("general/updatePallet", result.data["pallet"]);

        // Second Pallet
        // USer Settings
        if("secondPallet" in result.data){
          store.commit("general/updateSecondPallet", result.data["secondPallet"]);
        }
        
        
        window.console.log('result.data["results"]')
        window.console.log(`Objects in project ${Object.keys(result.data)}`)
        
        

        if('results' in result.data) {
          window.console.log('results in project')
          store.commit("general/updateResult", result.data["results"]);
        }else{
          window.console.log('no results data')
        }

        if("dataSensitivity" in result.data){
          //! Update/Reset sensitivity
          window.console.log('sensitivity data included')
          store.commit("general/updateResultSensitivity", result.data["dataSensitivity"]);
        } else{
          window.console.log('no sensitivity data')
          
        }


          // Restore selected options from result, sensitivity and decisions table

          //! Selected Options Decision table
          if("selectedOptionsDecisions" in result.data){
            const selectedDecisionsKeys = result.data['selectedOptionsDecisions']
            const dataDecisions = store.getters['general/result']                       
            
            store.commit("decisions/updateDecisionOptions", dataDecisions.filter(function(item){
              return selectedDecisionsKeys.indexOf(item.id) > -1;
            }));
          }else{           
            store.commit("decisions/updateDecisionOptions", []);
  
          }

          //* Results
          //if("selectedOptionsResults" in result.data) {
          if(result.data["selectedOptionsResults"].length > 0) {
            window.console.log("selectedOptionsResults")
            const selectedResultsKeys = result.data['selectedOptionsResults']     
            window.console.log(selectedResultsKeys)
            
            // window.console.log(result.data["results"].filter(function(item){
            //   return selectedResultsKeys.indexOf(item.id) > -1;
            // }))
            
            //! Use Getter here to get correctly transformed data for the table
            store.commit("decisions/updateSelectedOptionsResult",  store.getters['general/result'].filter(function(item){
              return selectedResultsKeys.indexOf(item.id) > -1;
            }));
          
          }else{           
          store.commit("decisions/updateSelectedOptionsResult", []);

        }

          //* Sensitivity
          //if("selectedOptionsSensitivity" in result.data){
          if(result.data["selectedOptionsSensitivity"].length > 0) {
            const selectedSensitivityKeys = result.data['selectedOptionsSensitivity']   
            
              //! Use Getter here to get correctly transformed data for the table
              store.commit("decisions/updateSelectedOptionsSensitivity", store.getters['general/resultSensitivity'].filter(function(item){
              return selectedSensitivityKeys.indexOf(item.id) > -1;
            }));
        }else{
          store.commit("decisions/updateSelectedOptionsSensitivity",[])
        }


        router.push('/')
        

        // Hide loading
        Loading.hide();

      
      }else{
        
          store.commit('dialogs/updateMessage','Error loading project.')
        store.commit('dialogs/updateDialog',true)
                          
          Loading.hide();


        }
      }


    }

    //
    return {
      loadSelectedProject
    }
  }
          
  