




export default function() {
   

 // Draw Pallet on Canvas
 const drawPallet = (canvas, option) => {
    window.console.log('drawing 2d pallet')
    
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const width: number = canvas.width;
    const height: number  = canvas.height;

    // Define linewidth for boxes
    const lineWidth = 2;
    //const selected_option: string = this.$store.state.general.selected_option; 
    
                          
    // Extract first layer as basis for Canvas
    const data = option['pallet']['layer'];

    //window.console.log(data)
    window.console.log('--------------------')
    window.console.log(option)
    window.console.log('--------------------')
    
    // get canvas parameters
    //const canvas: HTMLCanvasElement = canvas_2dpallet as HTMLCanvasElement; // element need to casted  
    //const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
    
    //let width = 450;
    //let height = 300;

    window.console.log('Width Canvas: ' + width)
    window.console.log('Height Canvas: ' + height)

    // Use for calculating scaling:
    const scaling: number = Math.min(
            height/Math.max(option['pallet']['width'], option['pallet']['depth']),
            width/Math.max(option['pallet']['width'], option['pallet']['depth'])
        )
    window.console.log('Scaling: ' + scaling)
    
    // wipes the canvas context
    ctx.clearRect(0, 0, width, height);
    
    // Get Boxes
    const Boxes: Array<string> = Object.keys(data)

    
    // Draw boxes
    for (const i of Boxes){
        window.console.log(data[i])
        
        if (data[i].type === 'a' ){         
            ctx.strokeStyle ="#027BE3";   
            // ctx.fillStyle = "#ffffff";
            
        } else if (data[i].type === 'b' ) {
            ctx.strokeStyle ="#262626";
            // ctx.fillStyle = "#262626";                        
        
        } else if (data[i].type === 'c' ) {
            ctx.strokeStyle ="#ff4f20e6";
            // ctx.fillStyle = "#262626";                        
        }

        // Draw rectangle
        ctx.beginPath();
        ctx.rect(
                data[i].xPosition  * scaling , 
                data[i].zPosition * scaling, 
                (data[i].width) * scaling , 
                (data[i].depth) * scaling 
            );
        ctx.closePath();
        ctx.stroke();
        
        // Fill rectangle
        // ctx.fillRect(
        //     //ctx.rect(
        //         data[i].xPosition  * scaling , 
        //         data[i].zPosition * scaling, 
        //         (data[i].width) * scaling  - lineWidth, 
        //         (data[i].depth) * scaling  - lineWidth
        //     );

    
}    

// Draw pallet boundries
ctx.beginPath();
        ctx.rect(
                0, 
                0, 
                option['pallet']['width'] * scaling , 
                option['pallet']['depth'] * scaling                     
                );
        ctx.closePath();
        ctx.stroke();

}


return {
    drawPallet
}

}