
//import MixinResult from '@/components/mixins/mixinsResult.ts';
import {computed, ref, watch, onMounted} from 'vue'
import { useStore } from 'vuex'


interface innerBox {
    xPosition: number
    zPosition: number
    width: number
    depth: number
    height: number
    type: string    
}

interface product {
    xPosition: number
    zPosition: number
    width: number
    depth: number
    height: number,
    orientation: string

}


export default {
    name: 'resultShippingBox',
    
    setup() {


        const env = computed(() => {return process.env.VUE_APP_ENV})

        const store = useStore()
      

        const canvasHeight = computed(() => {return window.innerHeight/4})
        const canvasWidth = computed(() => {return window.innerWidth/4})


        const selected_option = computed(() => {
            if (store.state.general.optionToVisualize != null) {
                    return store.state.general.optionToVisualize['id']
                }else{
                    return ''
                }
            }
            )


        // Define colors
        const colors = {
            shrink_fill_a: "#e9e9e980",
            shrink_fill_b: "#e9e9e980",
            shrink_border: "#fff",
            shrink_plastic_width: "#ff4f20e6",
            shrink_plastic_depth: "#204fffe6",
            air_width: "#00c80080",  // Green 
            air_depth: "#fff97f80", // Yellow
            product_border: "#262626e6",
            product_fill: "#fff",
            text_color: "#262626e6"
        }
        


        const data = ref({
            product:{
                depth: 2,
                widht: 3,
                height: 5
            },
            shipping_box : {
                    width: 400,
                    depth: 350,
                    height: 100,
                    air_height: 0,
                    air_depth: 0,
                    air_width: 0,
                    units_per_shrink:0,
                    number_shrinks_per_layer: 0,
                    number_layer: 0,
                    number_products:0,
                    
                   layer: {
                    0: {
                        0: {                            
                            xPosition: 0, zPosition: 0, width: 120, depth: 100, height: 50, type: 'a'
                            },
                        1: {                            
                            xPosition: 120, zPosition: 0, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        2: {                            
                            xPosition: 240, zPosition: 0, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        3: {                            
                            xPosition: 0, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        4: {                            
                            xPosition: 120, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        5: {                            
                            xPosition: 240, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        6: {                            
                            xPosition: 0, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        7: {                            
                            xPosition: 120, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        8: {                            
                            xPosition: 240, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            }
                    }
                } // End of Layer
            }

          } // End of Data
        )       
        
        //const {cleanCanvas} = MixinResult()

        //const canvas_2dpallet = ref<HTMLCanvasElement>()
        const canvasShippingBox = ref<HTMLCanvasElement | null>(null)

        // Draw for test data
        const draw = () => { 
              
            const dataToDraw = {
                shipping_box : {
                    width: 400,
                    depth: 350,
                    height: 100,
                    air_height: 0,
                    air_depth: 0,
                    air_width: 0,
                    
                   layer: {
                    0: {
                        0: {                            
                            xPosition: 0, zPosition: 0, width: 120, depth: 100, height: 50, type: 'a'
                            },
                        1: {                            
                            xPosition: 120, zPosition: 0, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        2: {                            
                            xPosition: 240, zPosition: 0, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        3: {                            
                            xPosition: 0, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        4: {                            
                            xPosition: 120, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        5: {                            
                            xPosition: 240, zPosition: 100, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        6: {                            
                            xPosition: 0, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        7: {                            
                            xPosition: 120, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            },
                        8: {                            
                            xPosition: 240, zPosition: 200, width: 120, depth: 100, height: 50, type: 'b'
                            }
                    }
                } // End of Layer
            }

          } // End of Data
                
                const canvas = canvasShippingBox.value as HTMLCanvasElement
                drawSmartBox(canvas, dataToDraw)
            }//     //drawPallet()
          
        
         onMounted(() => {//    
                              
            const dataToDraw = store.getters['general/resultOption']({id: selected_option.value})
            data.value = dataToDraw

        //   const dataToDraw = { 
        //       shipping_box : {
        //         width: 400,
        //         depth: 200,
        //         height: 100,
        //         air_height: 10,
        //         air_depth: 5,
        //         air_width: 2,
                
        //         layer: {
        //             0: {
        //                 1: {                            
        //                     xPosition: 0, zPosition: 0, width: 12.0, depth: 10, height: 0, type: 'b'
        //                     }
        //             }
        //         }
        //     }

        //   }          // End of Data
                const canvas = canvasShippingBox.value as HTMLCanvasElement
                drawSmartBox(canvas, dataToDraw)
            }//     //drawPallet()
          )                               


        //* ---------------- Draw Smart Box ----------------
         const drawSmartBox = (canvas, option) => {

            window.console.log('drawing shipping box')

            // Get canvas
            
            const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;            
            
            // wipes the canvas context
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            // Get selected option                        
            const input =  option
            window.console.log('input:' + input)
            //const input = this.$store.state.general.data.filter(row => row.id == this.selected_option)[0]

            // Get shipping box
            const shipping_box = input['shipping_box']

            // Get product
            const product = input['product']

                       // scale shipping box
            const scaling = Math.min(canvas.width / shipping_box['width'], canvas.height / shipping_box['depth'])

                                          
            // Shipping box measures
            const start_x = 0
            const start_y = 0
            const width = shipping_box['width'] * scaling                        
            const depth = shipping_box['depth'] * scaling           
                        
            //ctx!.arc(95, 50, 40, 0, 2 * Math.PI);

            ctx!.beginPath();                
            ctx.setLineDash([]);
            ctx.strokeStyle = '#262626e6';    

            // Outer Box 
            ctx.rect(start_x, start_y, width, depth);            
            ctx.closePath();
            ctx!.stroke();

            
            ctx!.beginPath();                
            ctx.strokeStyle = '#204fffe6';    

            // Inner Box
            const inner_x = start_x + (shipping_box['width'] - shipping_box['inner_width'])/2 * scaling
            const inner_y = start_y + (shipping_box['depth'] - shipping_box['inner_depth'])/2 * scaling
            ctx.rect(
                    inner_x,
                    inner_y,
                    shipping_box['inner_width'] * scaling,
                    shipping_box['inner_depth'] * scaling 
                    );            
            ctx.closePath();
            ctx!.stroke();
            

            // ----------- Draw air gaps ---------------------------

            //* (1) --- Air Width
            ctx.beginPath();
            ctx.strokeStyle = colors.air_width; 
            ctx.fillStyle = colors.air_width
            
            // Air width left
            ctx.rect(
                inner_x, 
                inner_y,
                shipping_box['air_width'] * scaling,
                shipping_box['inner_depth'] * scaling
                )

            // Air width right
            ctx.rect(
                inner_x + (shipping_box['inner_width'] - shipping_box['air_width']) * scaling, 
                inner_y,
                shipping_box['air_width'] * scaling,
                shipping_box['inner_depth'] * scaling
                )

            ctx.fill()

            ctx.closePath();
            ctx!.stroke();


            //* --- Air Depth
            
            ctx.beginPath();
            ctx.strokeStyle = colors.air_depth; 
            ctx.fillStyle = colors.air_depth; 
            

            // Air width top
            ctx.rect(
                inner_x, 
                inner_y,
                shipping_box['inner_width'] * scaling,
                shipping_box['air_depth'] * scaling
                )

            ctx.rect(
                inner_x, 
                inner_y + (shipping_box['inner_depth'] - shipping_box['air_depth']) *scaling,
                shipping_box['inner_width'] * scaling,
                shipping_box['air_depth'] * scaling
                )

            ctx.fill();
            ctx.closePath();
            ctx!.stroke();

            ctx.beginPath()
            
            //* Add legend below
            // (1) Air width and depth
            // const firstEntryY =  shipping_box['depth'] * scaling + 20
            // const startX = 10
            // const widthBoxes = shipping_box['inner_width']/10 * scaling
            // const depthBoxes = widthBoxes/2

            // ctx.rect(
            //     startX, 
            //     firstEntryY,
            //     widthBoxes,
            //     depthBoxes
            //     )
            // ctx.fill();
            // ctx.closePath();
            // ctx!.stroke();

            // ctx.beginPath()
            // ctx.font = '1.2em avenir';
            // ctx.strokeStyle = 'rgba(0,0,0,1)';
            // ctx.fillStyle = 'rgba(0,0,0,1)'; 
            // let text = ctx.measureText('Air width/depth')
            // ctx.fillText(
            //     'Air width/depth',
            //     startX + widthBoxes*1.1,
            //     firstEntryY + depthBoxes * 0.9
            //     );
            // ctx.closePath();
            // ctx!.stroke();

            // // (2) Air depth
            // ctx.fillStyle = 'rgba(102,51,153,1)'; 
            // ctx.rect(
            //     startX + text.width + widthBoxes * 2, 
            //     firstEntryY ,
            //     widthBoxes,
            //     depthBoxes,
            //     )
            // ctx.fill();
            // ctx.closePath();
            // ctx!.stroke();

            // ctx.beginPath()
            // ctx.font = '1.2em avenir';
            // ctx.strokeStyle = 'rgba(0,0,0,1)';
            // ctx.fillStyle = 'rgba(0,0,0,1)'; 
            // ctx.fillText(
            //     'Shrink plastic depth',
            //     startX + text.width + widthBoxes * 3.1, 
            //     firstEntryY + depthBoxes * 0.9
            //     );
            // ctx.closePath();
            // ctx!.stroke();


            // (2) Air width
            // ctx.fillStyle = 'rgba(0,200,0,1)'; 
            // ctx.rect(
            //     startX, 
            //     firstEntryY + 2 * depthBoxes,
            //     widthBoxes,
            //     depthBoxes
            //     )
            // ctx.fill();
            // ctx.closePath();
            // ctx!.stroke();

            // ctx.beginPath()
            // ctx.font = '1.2em avenir';
            // ctx.strokeStyle = 'rgba(0,200,0,1)';
            // ctx.fillStyle = 'rgba(0,200,0,1)'; 
            // ctx.fillText(
            //     'Shrink plastic width',
            //     startX + widthBoxes*1.1,
            //     firstEntryY + 2.9 * depthBoxes,
            //     );
            // ctx.closePath();
            // ctx!.stroke();
                                

        //* -------------- Draw shrinks -----------------------
            window.console.log("-------------------------------")
            window.console.log(shipping_box['layer'])
            

                for (const [key, value] of Object.entries(shipping_box['layer'])) {
   
                    const shrink = value as innerBox                 
                    
                    drawShrink(
                            ctx,
                            shrink.xPosition * scaling + inner_x,
                            shrink.zPosition * scaling + inner_y,
                            shrink.width * scaling,
                            shrink.depth * scaling,        
                            shrink['type'],
                            shipping_box['shrinks_plastic_depth'] * scaling,
                            shipping_box['shrinks_plastic_width'] * scaling
                            )

                    // Draw products in shrink
                    let init_x
                    let init_y
                    if (shrink['type'] == 'a'){
                        init_x = shrink.xPosition * scaling + inner_x + shipping_box['shrinks_plastic_width'] * scaling
                        init_y =  shrink.zPosition * scaling + inner_y + shipping_box['shrinks_plastic_depth'] * scaling                    
                        }
                    
                    if (shrink['type'] == 'b'){
                        init_x = shrink.xPosition * scaling + inner_x + shipping_box['shrinks_plastic_depth'] * scaling
                        init_y =  shrink.zPosition * scaling + inner_y + shipping_box['shrinks_plastic_width'] * scaling                    
                        
                    }

                    
                    window.console.log('------------------------')
                    window.console.log('draw product')
                    window.console.log('------------------------')

                    // Face oriented shrink
                    for (let p = 0; p < shipping_box['units_per_shrink']; p++){
                        if (shrink['type'] == 'a'){
                            drawProduct(
                                ctx, 
                                init_x,
                                init_y,
                                product['width'] * scaling,
                                product['depth'] * scaling,
                                shipping_box['product_orientation']
                                )

                            if (shipping_box['product_orientation'] === 'face'){
                                init_y += product['depth'] * scaling
                            } else {
                                init_y += product['width'] * scaling
                        }
                        }
                        
                        // Side oriented shrink
                        if (shrink['type'] == 'b'){
                            
                            drawProduct(
                                ctx, 
                                init_x,
                                init_y,
                                product['depth'] * scaling,
                                product['width'] * scaling,
                                shipping_box['product_orientation']
                            )

                            if (shipping_box['product_orientation'] === 'face'){
                                init_x += product['depth'] * scaling
                            } else {
                                init_x += product['width'] * scaling
                            }
                        
                                                
                        }
                        }
                    

                } // end of draw products            

         } //* End of Draw Smartbox
     


        // Function to draw products
        const drawShrink = (ctx, x, y, width, depth, type, shrinks_plastic_depth, shrinks_plastic_width) => {

                //const shrinks_plastic_depth = 4
                //const shrinks_plastic_width = 3
                                

                //ctx.setLineDash([2, 2]);
                const lineWidth = 2
                                            
                // Product
                
                if (type==='a'){
                    
                    ctx.beginPath()                    
                    ctx.fillStyle = colors.shrink_fill_a;       
                    ctx.lineWidth = lineWidth;                    
                    ctx.strokeStyle = colors.shrink_border
                    ctx.rect(x + shrinks_plastic_width + lineWidth,
                            y + shrinks_plastic_depth + lineWidth,
                            width - 2 * shrinks_plastic_width - lineWidth * 2,
                            depth - 2 * shrinks_plastic_depth - lineWidth * 2);                            
                    ctx.fillRect(x + shrinks_plastic_width, y + shrinks_plastic_depth, width - 2 * shrinks_plastic_width, depth - 2 * shrinks_plastic_depth); 
                    ctx.closePath();
                    ctx.stroke()

                    // shrinks_plastic_depth 
                    ctx.beginPath()               
                    ctx.strokeStyle = colors.shrink_plastic_depth; 
                    ctx.fillStyle = colors.shrink_plastic_depth; 
                    ctx.fillRect(x, y, shrinks_plastic_width, depth); // left
                    ctx.fillRect(x + width - shrinks_plastic_width, y, shrinks_plastic_width, depth); // right
                    ctx.closePath();
                    ctx.stroke()

                    // shrinks_plastic_width    
                    ctx.beginPath()               
                    ctx.strokeStyle = colors.shrink_plastic_width; 
                    ctx.fillStyle = colors.shrink_plastic_width; 
                    ctx.fillRect(x, y, width, shrinks_plastic_depth); // top
                    ctx.fillRect(x, y + depth - shrinks_plastic_depth, width, shrinks_plastic_depth); // Bottom 
                    ctx.closePath();
                    ctx.stroke()


                    // Orientation of shrink
                    // ctx.font = "1.5em Avenir"
                    // ctx.fillStyle = colors.text_color
                    // const textSize = ctx.measureText("Face")
                    // ctx.fillText("Face", x + textSize.width/3 , y + depth/2 +  shrinks_plastic_depth * 2); 
                
                
                } else {
                    //! Type B
                    //* All inverted
                    ctx.beginPath()                    
                    ctx.fillStyle = colors.shrink_fill_b; 
                    ctx.strokeStyle = colors.shrink_border
                    ctx.rect(x + shrinks_plastic_depth + lineWidth,
                            y + shrinks_plastic_width + lineWidth,
                            width - 2 * shrinks_plastic_depth - lineWidth*2,
                            depth - 2 * shrinks_plastic_width - lineWidth*2);                            
                    ctx.fillRect(x + shrinks_plastic_depth, y + shrinks_plastic_width, width - 2 * shrinks_plastic_depth, depth - 2 * shrinks_plastic_width);                     
                    ctx.closePath();
                    ctx.stroke()
                    

                    // shrinks_plastic_depth    
                    ctx.beginPath()               
                    ctx.strokeStyle = colors.shrink_plastic_depth; 
                    ctx.fillStyle = colors.shrink_plastic_depth; 
                    ctx.fillRect(x, y, width, shrinks_plastic_width); // top
                    ctx.fillRect(x, y + depth - shrinks_plastic_width, width, shrinks_plastic_width); // Bottom 
                    ctx.closePath();
                    ctx.stroke()


                    // shrinks_plastic_width 
                    ctx.beginPath()               
                    ctx.strokeStyle = colors.shrink_plastic_width; 
                    ctx.fillStyle = colors.shrink_plastic_width; 
                    ctx.fillRect(x, y, shrinks_plastic_depth, depth); // left
                    ctx.fillRect(x + width - shrinks_plastic_depth, y, shrinks_plastic_depth, depth); // right
                    ctx.closePath();
                    ctx.stroke()
                    // Orientation of shrink
                    // ctx.font = "1.5em Avenir"
                    // ctx.fillStyle = colors.text_color
                    // const textSize = ctx.measureText("Side")
                    // ctx.fillText("Side", x + textSize.width/3 , y + depth/2 +  shrinks_plastic_depth * 2); 
                }
                                   
                                                

        } // End of draw shrink

        // * -------------- Function to draw products in shrink 
        const drawProduct = (ctx, x, y, width, depth, orientation) => {

            //const shrinks_plastic_depth = 4
            //const shrinks_plastic_width = 3
                            

            //ctx.setLineDash([2, 2]);
            const lineWidth = 2
                    ctx.fillStyle = colors.product_fill;       
                ctx.lineWidth = lineWidth;                    
                ctx.strokeStyle = colors.product_border
                                        
            // Product
            
            if (orientation==='face'){
                
                ctx.beginPath()                    
            
                ctx.rect(
                        x + lineWidth,
                        y + lineWidth,
                        width - lineWidth * 2,
                        depth - lineWidth * 2
                        );                            
                ctx.fillRect(x , y, width , depth); 
            
                ctx.closePath();
                ctx.stroke()                

                // Orientation of shrink
                // ctx.font = "1.5em Avenir"
                // ctx.fillStyle = colors.text_color
                // const textSize = ctx.measureText("Face")
                // ctx.fillText("Face", x + textSize.width/3 , y + depth/2 +  shrinks_plastic_depth * 2); 
            
            
            } else {
                //! Orientation 'side
                //* All inverted
                ctx.beginPath()                                       
                ctx.rect(
                        x + lineWidth,
                        y + lineWidth,
                        depth  - lineWidth * 2,
                        width - lineWidth * 2
                        );                            
                ctx.fillRect(x, y, depth, width);                     
                ctx.closePath();
                ctx.stroke()
                                    
                // Orientation of shrink
                // ctx.font = "1.5em Avenir"
                // ctx.fillStyle = colors.text_color
                // const textSize = ctx.measureText("Side")
                // ctx.fillText("Side", x + textSize.width/3 , y + depth/2 +  shrinks_plastic_depth * 2); 
            }
                                                

        } // End of draw product
            

        return {
            env,    
            canvasShippingBox,
            canvasHeight,
            canvasWidth,            
            data,
            draw,
            selected_option,
            colors
            
        }

    }
}

