<template>
  
  <div class="row q-ma-md">
    <q-btn label="sensitivty" outline @click="selectedCard='sensitivity'" />
    
  </div>
  <table-sensitiviy v-if="selectedCard==='sensitivity'"/>

</template>


<script lang="js">

import {ref} from 'vue'
import tableSensitivity from '@/components/dev/tableSensitivity.vue'



export default {
    name: 'sensitivityTable',
    components: {'table-sensitiviy': tableSensitivity},
    
    setup() {
      const selectedCard = ref()

      return {
        selectedCard
      }
    }

}

</script>



<style scoped>
.q-table td, .q-table th {
    /* don't shorten cell contents */
    white-space: normal !important;
    height: 40px;
}
</style>