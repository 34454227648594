<template>
<div class="row q-ma-md items-center text-body-scalize">
    <h5>Calculation Parameters:</h5>
</div>

<!-- Shipping Box ratio -->
<div class="row q-ma-xs items-start">
    <div class="col-3">
        <div class="row q-ma-md text-body-scalize">Threshold Ratio Shipping Box Width to Depth:</div>
        <div class="row q-ma-md text-body-scalize-small">
            [This defines how quadratic shipping boxes are allowed to be, e.g. 90% means 
            that width = 9cm and depth = 10cm are still acceptable.]
        </div>
    </div>
    
    <div class="col-auto">
          <q-input  
                :style="{width: '90px'}"                 
                :input-style="{color: 'blue'}"
                dense 
                type="number"
                v-model="$store.state.userSettings.calculationParameters.thresholdWidthDepthRatioShippingbox" >        
                  <template v-slot:append>%</template>
            </q-input>
    </div>
</div>

<!-- Layer efficiency -->
<div class="row q-ma-xs items-start">
    <div class="col-3">
        <div class="row q-ma-md text-body-scalize">Threshold Layer Efficiency:</div>
        <div class="row q-ma-md text-body-scalize-small"> 
            [This the minimum level of layer efficiency (how much space on the pallet is occupied) 
            which needs to be reached
            (both for general results and sensitivity).]
    </div>
    </div>
    
    <div class="col-auto">            
            <q-input 
                :style="{width: '90px'}"        
                :input-style="{color: 'blue'}"         
                dense
                type="number"
                v-model="$store.state.userSettings.calculationParameters.layerEfficiency" >        
            <template v-slot:append>%</template>
            </q-input>
        </div>

</div>

<!-- Substraction of layers -->
<div class="row q-ma-xs items-start">
    <div class="col-3">
        <div class="row q-ma-md text-body-scalize">Layer substraction</div>
        <div class="row q-ma-md text-body-scalize-small"> 
            [If activated pallets with a higher weight than in the limitation will be shown and indicated
                how many layers will need to be deducted to stay within weight limit.]
    </div>
    </div>
    
    <div class="col-auto">            
            <q-toggle 
                :style="{width: '90px'}"        
                :input-style="{color: 'blue'}"         
                dense                
                v-model="$store.state.userSettings.calculationParameters.layerSubstraction" >        
            
            </q-toggle>
        </div>

</div>

        

<div class="row dev" v-if="env==='dev'">
        {{$store.state.userSettings.calculationParameters}}
    </div>
</template>

<script lang="ts">
import {ref, computed} from 'vue'
import {useStore} from 'vuex'



export default {
     
     
     setup() {
        
        //! vue3
        const store = useStore()         

        // Get environment 
        const env = computed(() => {
            return process.env.VUE_APP_ENV
            })
        
    

   
        
        return {
            env,
                        
        }
        
    },
}
</script>
