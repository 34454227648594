
import{computed, ref} from 'vue'
import {useStore} from 'vuex'
import customProduct from '@/components/config/customProduct.vue'
import projectAdmin from '@/components/config/projectAdmin.vue'
import competitorProducts from '@/components/config/competitorProductAdmin.vue'
import logs from '@/components/config/logs.vue'
import userSettings from '@/components/config/userSettings.vue'
import saveProject from '@/components/mixins/saveProject'

export default {
  name: 'Config',
  components: {   
      'custom-product': customProduct,
      'project-admin': projectAdmin,
      'competitor-products': competitorProducts,
      'user-settings': userSettings,
      'logs': logs
  },

  setup(){
    const env = computed(() => {return process.env.VUE_APP_ENV})
    const store = useStore()
    
    const {saveCurrentProject} = saveProject()


        //* Function to update Project Name
        const save = async () => {                                                
            await saveCurrentProject();      
            
            store.commit('dialogs/updateMessage', 'Project updated.')
            store.commit('dialogs/updateDialog', true)
        }


    const selectedCard = ref('dimensions')

    return {
      env,
      selectedCard,
      save
    }

  }

  

};
