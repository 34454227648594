
import {ref, computed} from 'vue'
import {useStore} from 'vuex'



export default {
     
     
     setup() {
        
        //! vue3
        const store = useStore()         

        // Get environment 
        const env = computed(() => {
            return process.env.VUE_APP_ENV
            })
        
    

   
        
        return {
            env,
                        
        }
        
    },
}
