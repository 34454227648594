
import {ref,  computed, onMounted, watch} from 'vue'
import {useStore} from 'vuex'
//import ResultTable from '@/components/result/ResultTable.vue'; // @ is an alias to /src
//import pallet from '@/components/result/2dPallet.vue'; // @ is an alias to /src

import resultProduct from '@/components/visualisations/product.vue'; // @ is an alias to /src
import resultShippingBox from '@/components/result/resultShippingBox.vue'; // @ is an alias to /src
import resultShippingBoxSVG from '@/components/visualisations/shippingBoxSVG.vue'; // @ is an alias to /src

//import dThreePallet from '@/components/result/3dPallet.vue'; // @ is an alias to /src
//import Shelf from '@/components/result/Shelf.vue'; // @ is an alias to /src
//import structurePallet from '@/components/result/structurePallet.vue'; // @ is an alias to /src
import structurePalletSVG from '@/components/visualisations/pallet2d.vue'; // @ is an alias to /src
import threeDimPallet from '@/components/visualisations/threeDimPallet.vue'; // @ is an alias to /src
import palletDetails from '@/components/result/palletDetails.vue'; // @ is an alias to /src

//import sensitivityMenu from '@/components/result/sensitivityMenu.vue'; // @ is an alias to /src
//import sensitivitySelectTable from '@/components/result/sensitivitySelectTable.vue'; // @ is an alias to /src

import nestedTable from '@/components/result/mainTable.vue'; // @ is an alias to /src
import SmartBox2D from '@/components/result/smartBox2D.vue'



export default {
  name: 'Result',
   components: {    
    //ResultTable,
    //pallet,
    //dThreePallet,
    'result-product': resultProduct,
    'result-shipping-box': resultShippingBox,
    'result-shipping-box-svg': resultShippingBoxSVG,
    //Shelf,
     //'structure-pallet': structurePallet,
     'structure-pallet-svg': structurePalletSVG,
     'three-dim-pallet': threeDimPallet,
    // 'sensitivity-menu': sensitivityMenu,
    // 'sensitivity-select-table': sensitivitySelectTable,
    'nested-table': nestedTable,
    'smart-box-2d': SmartBox2D,
    'pallet-details': palletDetails

  },


  setup () {
    const env = computed(() => {
          return process.env.VUE_APP_ENV
        })
    const store = useStore()
    
    // Counter for product component
    const productCounter = ref(0)
    const shipperCounter = ref(0)

    const data = ref(
        {
            shipping_box: {
                shrinks_plastic_width: 0,
                shrinks_plastic_depth: 0,                
                gap_between_shrinks: 0,
                air_height: 0.2,
                air_width: 0.2,
                air_depth: 0.2,
                
                layer_carton_wrapping_width: 2,
                layer_carton_wrapping_depth: 2,
                thickness_carton: 0.3,

                number_products: 3, 
                weight: 1000,

                number_shrinks_per_layer: 0,                 
                units_per_shrink: 1, 
                number_layers: 1,
                smart_box: false,
                product_orientation: "face",
                height: 20,
                width: 20,
                depth: 7
            },

            product: {
              height: 5,
              width: 4,
              depth: 2,
              brimfulvolume: 250,
              headspace: 5,
              fillinghead: 5
            },
            

            pallet: {
              total_boxes: 20,
              layer_efficiency: 1,
              pallet_efficiency: 1,
              total_weight: "",
              width: 120,
              depth: 80,
              block:2,
              total_boxes_per_layer:2,
              layer: {
                "0_0": {xPosition: 100, yPosition:0, xPositionEnd: 10, yPositionEnd: 20, depth: 40, width:30},
                "0_1": {xPosition: 80, yPosition:0, xPositionEnd: 20, yPositionEnd: 20, depth: 40, width:30},
              },

              truck_number_pallets: 10,
              truck_pallets_weight: 20

            },

            
            
        }
        )
              
        
  
    const productImage = ref<HTMLDivElement | null>(null)
    
    // Get parameters for each of four illustrations
    const productHeight = computed(() => window.innerWidth/8)
    const productWidth = computed(() => window.innerWidth/8)


    // Parameters shipper
    const shipperHeight = computed(() => window.innerWidth/8)
    const shipperWidth = computed(() => window.innerWidth/8)
    
    const shipperSpace = 0.2


     // Parameters pallet
    const palletHeight = computed(() => window.innerWidth/8)
    const palletWidth = computed(() => window.innerWidth/8)
          

    const showSensitivity = ref(false)
      
     
    //const smartBoxDetails = computed(() => {return store.state.dialogs})
    const smartBoxDetails = computed(() => {return store.state.dialogs.smartBoxDetails})
    
    const palletDetails = computed(() => {return store.state.dialogs.palletDetails})

    // Update function fo fetch new data
    const update = (idValue) => {      
      let dataToDraw;
       if (idValue.slice(0,2) !== "s_" ) {
         dataToDraw = store.getters['general/resultOption']({id: idValue})                
      } else {
        window.console.log('GETTING SENSITIVITY OPTION')
        dataToDraw = store.getters['general/sensitivityOption']({id: idValue});
      }
      if (dataToDraw!==undefined){
        data.value = dataToDraw    
        productCounter.value++    
        shipperCounter.value++    
      }else{
        window.console.log('data to draw is null')
      }
      
      
    }
    
    // Get selected option
    const selected_option = computed(() => {
            if (store.state.general.optionToVisualize != null) {
                    return store.state.general.optionToVisualize['id']
                }else{
                    return ''
                }
            }
            )
    // Watch selected option change to update picture
    watch(selected_option, (newValue) => {
        window.console.log('selected option changed')                
        update(newValue)                                                    
    })

    const test = () => {
      window.console.log("test resize")
      productCounter.value++ 
      shipperCounter.value++    
      }

      
      onMounted(() => { //     // Check for no selected Option                              
        if (selected_option.value == '') {
            window.console.log('no option selected')            
           
            window.addEventListener("resize", test);
            
        } else {                
            //productHeight.value = productImage.value.clientHeight          
            //productWidth.value = productImage.value.clientWidth
            update(selected_option.value)  
            
        }//     //drawPallet()
           productCounter.value++      
           shipperCounter.value++      
           
      })

    return {
      env,
      data,      
      selected_option,
      smartBoxDetails,
      palletDetails,
      showSensitivity,
      productImage,
      productHeight,
      productWidth,
      productCounter,
      shipperCounter,
      shipperHeight,
      shipperWidth,
      shipperSpace,
      palletHeight,
      palletWidth
      
    }
  }
 

    
};
