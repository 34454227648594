
import {computed, ref, watch} from 'vue'
import {useStore} from 'vuex'
import saveProject from '@/components/mixins/saveProject.js'
import templates from '@/components/mixins/templates.js'


export default {
  name: 'shippingBox',
  data() {
    return {
      firstDialog: true,
      secondDialog: false,
      thirdDialog: false,
      canvasWidth: 100,
      canvasHeight: 100,       
    }
  },


  setup() {

    const env = computed(() => {return  process.env.VUE_APP_ENV})

    const store = useStore()
    const windowHeight = computed(() => {return window.innerHeight})

    const {saveCurrentProject} = saveProject()
    const {loadShippingBoxTemplates, addShippingBoxTemplate} = templates()

    const shippingBoxStore = computed(() => {return store.state.general.shippingBox})    
    const shippingBox = ref(shippingBoxStore.value)
    watch(shippingBoxStore, (newValue) => {
       window.console.log('shipping box changed')              
       shippingBox.value = newValue
     })



    const templateName = ref('')
    const showTemplateSave = ref(false)
    const showLoadedTemplates = ref(false)

    // Function load template
    const loadTemplate = (id, name) => {
 
      const selectedTemplate =  store.state.templates.templatesShippingBox.filter(e => e.templateID===id)[0]
 
      store.commit("general/updateShippingBox", {
        name: selectedTemplate.name,
        parameters: selectedTemplate.data.parameters,
        materialParameters: selectedTemplate.data.materialParameters,
        fixedBoxParameters: selectedTemplate.data.fixedBoxParameters,
        optimizationParameters: selectedTemplate.data.optimizationParameters,
        limitParameters: selectedTemplate.data.limitParameters
        })

      // Update message that template was loaded
      store.commit('dialogs/updateMessage', `Loaded Template: ${name}`)
      store.commit('dialogs/updateDialog', true)   
  
    }


    const shippingBoxTemplates = computed(() => store.state.templates.templatesShippingBox.map(({name, templateID}) => { 
      return {"name": name, "templateID": templateID }
    }
    ))
  


    return {
      env,
      windowHeight,
      shippingBox,
      templateName,
      showTemplateSave,
      shippingBoxTemplates,
      showLoadedTemplates,
      saveCurrentProject,
      addShippingBoxTemplate,
      loadTemplate 
          
    }

  }

  
  
}
