export default {
    namespaced: true,
    
    /* ------------------- STATE -------------------------------------------------------- */
    state: {

      // Selected Options from full analysis
      calculationParameters: {
            thresholdWidthDepthRatioShippingbox: 80,
            layerEfficiency: 85,
            layerSubstraction: false,
            ignoreWeightPallet: false // This will ignore weightes regarding pallet total and layer
      },      

      // Selected columns in result table
      selectedColumns: [ 
        'id', 
        'product_height',
        'product_width',
        'product_depth',
        'shipping_box_height',
        'shipping_box_width',
        'shipping_box_depth',
        'shipping_box_number_products',
        'shipping_box_weight',        
        'product_orientation',
        'number_shipping_boxes_per_layer',
        'number_layers',
        'number_shipping_boxes',
        'total_units_per_pallet',
        'layer_efficiency',
        'pallet_height_utilisation',
        'pallet_weight'
        ]
                        
    },
     


    /* ------------------- MUTATIONS -------------------------------------------------------- */
    mutations: {

      updateCalculationParameters(state, payload){
        state.calculationParameters = payload        
        },
      
             

      updateSelectedColumns(state, payload){
        state.selectedColumns = payload        
        },
      
             

    },
    actions: {
    },
    modules: {
    }
  }
  
