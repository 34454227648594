
<template>
<!-- Dialog to edit Project -->
    <q-dialog v-model="showEditProject" :persistent="false" @escape-key="showEditProject=false">
      <q-card style="width:350px; height:200px">
        
        <q-item-section>                       
          <q-input v-model="projectName" class="q-ma-md" label="Project name" style="width:180px;" />           
          
        </q-item-section>
        
        <q-card-actions>
          
          <q-btn class="q-my-md q-ml-md" 
            label="Save"
            @click="updateProjectName();" 
            outline
            color="blue"
            />  
          <q-btn class="q-my-md q-ml-md" 
            outline 
            color="red"
            label="Delete Project" 
            @click="deleteProject();" />   
            
            <q-btn 
              color="black"
              outline
              label="Close" 
              @click="showEditProject=$store.commit('dialogs/updateEditProjectDialog', false);
              projectName.value=store.state.general.selected_project.name"
              
              />        
         </q-card-actions>
      </q-card>      
    </q-dialog>
    
</template>


<script lang="ts">
import {computed, ref, watch} from 'vue'
import {useStore} from 'vuex'
import { Loading, QSpinnerGears } from 'quasar'
import saveProject from '@/components/mixins/saveProject'
import userData from '@/components/mixins/userData.js'


export default{
    name: 'editProject',
    setup(){

        const store = useStore()

        //const { saveCurrentProject} = saveProject()
        const {getUserData} = userData()
        const {saveCurrentProject} = saveProject()

        const projectStore = computed(() => {return store.state.general.selected_project})
         watch(projectStore, (newValue) => {
           window.console.log('project changed')
           projectName.value = newValue['name']
           })

        const projectName = ref('')
        //onMounted(() => {projectName.value = projectNameStore.value})
        
        // const projectName = computed({
        //   get(): void {            
        //     return store.state.general.selected_project['name']
        //   },
        //   set(newValue): void {
        //     const project = store.state.general.selected_project
        //     project['name'] = newValue
        //     store.commit('general/updateSelectedProject', newProject) 
        //   } 
        //   })
       

        const showEditProject = computed(() => {return store.state.dialogs.editProjectDialog})

        //* Function to update Project Name
        const updateProjectName = async () => {
            window.console.log('Project Name: ' + projectName.value)
            const newProject = store.state.general.selected_project
            newProject['name'] = projectName.value
            store.commit('general/updateSelectedProject', newProject)   
            await saveCurrentProject();      
            
            store.commit('dialogs/updateMessage', 'Project updated.')
            store.commit('dialogs/updateDialog', true)
        }
        
    //* ----------- Deleting project ---------------------------
    const deleteProject = async() => {
            window.console.log('delete project');
            Loading.show({
                delay: 400, // ms
                spinner: QSpinnerGears,
                message: 'Deleting project'
            })
        

        // Form data for post request
        const requestOptions = {
            
        method: "POST",
        headers: { "Content-Type": "application/json" },
        
        body: JSON.stringify({ 
            
            actionType: 'deleteProject',

            user: store.state.user.userData,

            projectID: store.state.general.selected_project['id'],
            projectName: store.state.general.selected_project['name'],        
            
            })
            };

        const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "updateCosmos" , requestOptions).catch((error) => {
            window.console.log(error)       
            }           
            );

        
        const data = await (response as Response).json();


        if (data['content'] == "user not allowed to change values"){

            store.commit('dialogs/updateMessage',  'User is not project Owner and is now allowed to delete the project.')
            store.commit('dialogs/updateDialog', true)

                       

        }else{
            window.console.log("Response:" + data)
            // Refresh user data
            window.console.log('refreshing user data')
            // Reset project
            //this.$store.commit('general/updateSelectedProject', {})
            store.commit('dialogs/updateMessage',  'Deleted Project.')
            store.commit('dialogs/updateDialog', true)

                    
            projectName.value = '';
            Loading.hide();        
            await getUserData();

            window.console.log('hiding edit Project window')
            

            // Reset project
            store.commit('general/updateSelectedProject', {
              name: '',
              projectID: '',
            })
        
      }     

     
     
      
    } // End of deleting project

    
        // const saveProject = async() =>  {
        //             // verify sensitivity run finished
        //     if (store.state.sensitivity.sensitivityRun == 'started'){

        //         store.commit('dialogs/updateErrorDialog', {
        //                 show: true,
        //                 message: 'Please wait for sensitivity analysis to be finished.'
        //             })
                
        //         }else{


        //         window.console.log('saving project');
        //         Loading.show({
        //             delay: 400, // ms
        //             spinner: QSpinnerGears,
        //             message: 'Saving your project'
        //         })



        //     // Get current time for adding time stamp to product update
        //     const currentdate = new Date();
        //     const current_time =  currentdate.getDate() + "/"
        //                 + (currentdate.getMonth()+1)  + "/" 
        //                 + currentdate.getFullYear() + " @ "  
        //                 + currentdate.getHours() + ":"  
        //                 + currentdate.getMinutes() + ":" 
        //                 + currentdate.getSeconds();

        //     // Form data for post request
        //     const requestOptions = {
                
        //     method: "POST",
        //     headers: { "Content-Type": "application/json" },
            
        //     body: JSON.stringify({ 
                
        //         actionType: 'saveProject',

        //         user: store.state.user.userData,

        //         projectID: store.state.general.selected_project['projectID'],
        //         projectName: store.state.general.selected_project['projectName'],

        //         updated_time: current_time,
                
        //         category: store.state.general.selected_category,
        //         product: store.state.general.selected_product,
        //         shippingBox: store.state.general.shippingBox,
        //         pallet: store.state.general.selected_pallet,
        //         results: store.state.general.data,
        //         dataSensitivity: store.state.general.dataSensitivity.slice(1, 20)
                
        //         })
        //         };


        //     window.console.log('requestOptions')
        //     window.console.log(requestOptions)
        //     const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "updateCosmos" , requestOptions).catch((error) => {
        //         window.console.log(error)       
        //         }
                
                
        //         );

            
        //     const data = await (response as Response).json();


        //     if (data['content'] == "user not allowed to change values"){

        //         store.commit('dialogs/updateErrorDialog', {
        //             show: true,
        //             message: 'User is not project Owner and cannot update project values.'
        //         })
                

        //     }else{
        //         window.console.log("Response:" + data)

        //         // Refresh user data
        //         window.console.log('refreshing user data')
        //         await getUserData();
        //     }

            

        //     Loading.hide();      

        //     } 


        //} // End of save project

        return {updateProjectName, deleteProject, showEditProject, projectName,   saveCurrentProject}



    },
}


</script>

