
export default {
  name: "aboutPage",
  components: {},

  data() {
    return {
      position: 0,
    };
  },
};
