
    
   export default {
    setup(props){
      
      const scaling = 3
      const shippers = [ { "zPosition": 54.3, "xPosition": 0, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 54.3, "xPosition": 10.8, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 54.3, "xPosition": 21.6, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 54.3, "xPosition": 32.400000000000006, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 54.3, "xPosition": 43.2, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 54.3, "xPosition": 54, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 26, "xPosition": 68.6, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 26, "xPosition": 94.3, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 36.8, "xPosition": 68.6, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 36.8, "xPosition": 94.3, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 47.599999999999994, "xPosition": 68.6, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 47.599999999999994, "xPosition": 94.3, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 58.39999999999999, "xPosition": 68.6, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 58.39999999999999, "xPosition": 94.3, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 69.19999999999999, "xPosition": 68.6, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 69.19999999999999, "xPosition": 94.3, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 0, "xPosition": 0, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 0, "xPosition": 25.7, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 10.8, "xPosition": 0, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 10.8, "xPosition": 25.7, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 21.6, "xPosition": 0, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 21.6, "xPosition": 25.7, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 32.400000000000006, "xPosition": 0, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 32.400000000000006, "xPosition": 25.7, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 43.2, "xPosition": 0, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 43.2, "xPosition": 25.7, "width": 25.7, "depth": 10.8, "height": 11.5, "type": "b" }, 
      { "zPosition": 0, "xPosition": 55.19999999999999, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 0, "xPosition": 65.99999999999999, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 0, "xPosition": 76.79999999999998, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 0, "xPosition": 87.59999999999998, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 0, "xPosition": 98.39999999999998, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 0, "xPosition": 109.19999999999997, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" }, 
      { "zPosition": 25.7, "xPosition": 51.4, "width": 10.8, "depth": 25.7, "height": 11.5, "type": "a" } 
      ]      


      // shippers.sort((a,b) => {
      //     if (b.zPosition > a.zPosition) return 1;
      //     if (b.zPosition < a.zPosition) return -1;

      //     if (b.xPosition < a.xPosition) return 1;
      //     if (b.xPosition > a.xPosition) return -1;
      //     })
    

      return {                
        shippers,
        scaling        
      }
    }
  }
