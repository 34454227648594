
export default {
  name: "returnsPage",
  components: {},

  data() {
    return {
      position: 0,
      visualPage: true,
    };
  },
};
