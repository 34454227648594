import { render } from "./resultsTable.vue?vue&type=template&id=6633df1a"
import script from "./resultsTable.vue?vue&type=script&lang=ts"
export * from "./resultsTable.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTd,QBtn,QTooltip,QTh,QDialog,QCard,QCardActions,QCardSection});
