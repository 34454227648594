
export default {
  name: "imprintPage",
  components: {},

  data() {
    return {
      position: 0,
      version: "english",
    };
  },
};
