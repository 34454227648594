
//! for sensitivity we are currently only saving best 50 results


import { useStore } from 'vuex'
import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import userData from '@/components/mixins/userData.js';
import adminData from '@/components/mixins/adminData.js';

export default function() {

    const store = useStore()

    const {getUserData} = userData()
    const {getProjectData} = adminData()
   
    /*----------------------- Save project -------------------------------*/

    

    const saveCurrentProject = async() => {
 
     // verify sensitivity run finished     
     window.console.log('saving project');
     Loading.show({
           delay: 400, // ms
           spinner: QSpinnerGears,
           message: 'Saving your project'
       })
 
 
 
     // Get current time for adding time stamp to product update
     const currentdate = new Date();
     const current_time =  currentdate.getDate() + "/"
                 + (currentdate.getMonth()+1)  + "/" 
                 + currentdate.getFullYear() + " @ "  
                 + currentdate.getHours() + ":"  
                 + currentdate.getMinutes() + ":" 
                 + currentdate.getSeconds();


      // Check if results & sensitivity have already been calculated 
      window.console.log('store.state.general.data.length: ' + store.state.general.data )
      const resultData = store.state.general.data.length > 0 ? store.state.general.data : []
      
      
      //! Decide which results from sensitivity to save! 
      // Currently saving for each configuration
       // get unique configs of shipping boxes (units per shrink) x (number shrinks) the best option (max number of units)
      const unique_values = [... new Set(store.state.general.data.map(row => 
        `${row.shipping_box.number_shrinks}-${row.shipping_box.units_per_shrink}`))
      ]


      const sensitivityData = []

      unique_values.forEach(v => {        
        let shrinks = parseInt(v.split('-')[0])
        let products_in_shrink = parseInt(v.split('-')[1])
        // console.log(`Number shrinks: ${shrinks}`)
        // console.log(`Number shrinks: ${products_in_shrink}`)
        let x = store.state.general.dataSensitivity.filter(row => (row.shipping_box.number_shrinks === shrinks && row.shipping_box.units_per_shrink === products_in_shrink))        

        if (x.length > 0){
          x = x.filter(row => row.pallet.total_boxes ===  Math.max(...x.map(o => o.pallet.total_boxes)))[0]
          sensitivityData.push(x)                
        }
        //console.log(x.map(row => row.d).reduce())      
        }    
        )

      //const sensitivityData = store.state.general.dataSensitivity.length > 0 ? store.state.general.dataSensitivity.slice(1, 30) : []
 
     // Form data for post request
      const requestOptions = { 
         
       method: "POST",
       headers: { "Content-Type": "application/json" },
     
       body: JSON.stringify({ 
         
        actionType: 'saveProject',

        user: store.state.user.userData,
        userSettings: {
          calculationParameters: store.state.userSettings.calculationParameters,
          selectedColumns: store.state.userSettings.selectedColumns,
        },

        projectID: store.state.general.selected_project['id'],
        name: store.state.general.selected_project['name'],

        updated_time: current_time,
        
        category: store.state.general.selected_category,
        product: store.state.general.selected_product,
        
        shippingBox: store.state.general.shippingBox,
        pallet: store.state.general.selected_pallet,
        secondPallet: store.state.general.secondPallet,
        
        results: resultData,
        dataSensitivity: sensitivityData,


      // Remember selected options from user
        selectedOptionsSensitivity: store.state.decisions.selectedOptionsSensitivity.map(e => e.id),
        selectedOptionsResults: store.state.decisions.selectedOptionsResult.map(e => e.id),
        selectedOptionsDecisions: store.state.decisions.decisionOptions.map(e =>e.id)
        
         
         })

         
         };
 
 
       window.console.log('requestOptions')
       window.console.log(requestOptions)
       const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "updateCosmos" , requestOptions).catch((error) => {
         
          window.console.log(error)  
          

         }
           
           
         );
 
       
       const data = await response.json();
       //const data = await (response as Response).json();
 
 
       if (data['content'] === "user not allowed to change values"){

          store.commit('dialogs/updateMessage',                
          'User is not project Owner and cannot update project values.'
              )
          store.commit('dialogs/updateDialog',                
            true
          )
 
       } else if (data['content']==='project could not be saved, size too big'){
          store.commit('dialogs/updateMessage',                
            'Project is too large to be saved'
              )
          store.commit('dialogs/updateDialog',                
            true
          )     

 
       }else{
         window.console.log("Response:" + data)
 
         // Refresh user data
         window.console.log('refreshing project data')
         await getProjectData()
         window.console.log('refreshing user data')
         await getUserData();

         // Give warning that sensitivty run was not yet finish
         if (store.state.sensitivity.sensitivityRun == 'started'){
          store.commit('dialogs/updateMessage',                
                  'Sensitivity analysis still running. Save again after run finished'
              )
           store.commit('dialogs/updateDialog',                
             true
          )
         }   
      
       Loading.hide();      
 
       }
 
       } 


       return {
        saveCurrentProject
      }
  }
          
  