<template>
    <div class="row q-ma-xs">
        <div class="text-head-scalize">Competitor Products in Database</div>
    </div>
    <div class="row q-ma-md">            
        <q-table 
            selection="multiple"
            :rows="products"
            :columns="columns"
            v-model:selected="selected_options"       
            row-key="id" 
            :pagination="pagination"
        >


         <!--Body -->
        <template v-slot:body="props">
            <q-tr :props="props">
                <q-td>
                    <q-checkbox dense v-model="props.selected" />
                </q-td>

                <q-td
                    v-for="col in props.cols"
                    :key="col.name"
                    :props="props"
                >
                {{col.value}}
                </q-td>

                <q-td auto-width>
                    <q-btn size="xs" color="blue"                         
                        dense 
                        @click="editProduct(props.row.productID)" 
                        icon="edit" />
                </q-td>
            </q-tr>
        </template>

    <!-- Header -->
         <template v-slot:header="props">
            <q-tr :props="props">
                 <q-td>
                    <q-checkbox dense v-model="props.selected" />
                </q-td>                              
                <q-th
                    v-for="col in props.cols"
                    :key="col.name"
                    :props="props"
                >
                {{col.label}}
                </q-th>
                <q-th auto-width />
            </q-tr>
         </template>


        </q-table>        
    </div>

    <div class="row q-ma-xs justify-start">
            <q-btn class="q-ma-xs" outline label="Delete Selected" color="red" @click="removeCompetitorProducts(selected_options.map(e => e.productID))" />
            <q-btn class="q-ma-xs" outline label="Refresh Data" color="blue" @click="fetchCompetitorProducts" />            
    </div>

    
<!-- Dev Area -->
    <div class="row" v-if="env=='dev'">
        <h5>Dev info</h5>
        <div class="row q-ma-md">{{selected_options}}</div>
        <div class="row q-ma-md">Option IDs: </div>
        <div class="row q-ma-md">{{selected_options.map(e => e.productID)}}</div>
    </div>



<!-- Edit Dialog -->
    <q-dialog v-model="editDialog">
        
        <q-card>
         <q-card-actions align="right" class="text-primary">
             <div class="col-xs-auto"> 
                <q-btn class="q-mx-xs" label="Save" outline color="blue" v-close-popup 
                    @click="updateCompetitorProduct(optionToEdit)"
                    />
                <q-btn color="black" outline @click="editDialog=false"><q-icon name="close" /></q-btn>
             </div>
                 
            </q-card-actions>
            
            <q-card-section>
                <h6>Product: {{optionToEdit.name}}</h6>
            </q-card-section>

            

            <q-card-section>
                <div class="row justify-center">
                    <div class="col-xs-5">
                        <q-img :src="optionToEdit.image" style="max-width: 150px" />
                    </div>
                    
                    <div class="col-xs-6">
                        <div class="row q-mx-xl" >
                            <q-input type="number" v-model="optionToEdit.product_height" label="height" />
                        </div>
                        <div class="row q-mx-xl" >
                            <q-input type="number" v-model="optionToEdit.product_width" label="width" />
                        </div>
                        <div class="row q-mx-xl" >
                            <q-input type="number" v-model="optionToEdit.product_depth" label="depth" />
                        </div>                                
                    </div>
                </div>
            </q-card-section>

                        
           

            <q-card-section v-if="env==='dev'">
                <h4>Dev Info </h4>
                {{optionToEdit}}
            </q-card-section>
        </q-card>
    </q-dialog>


</template>

<script lang="ts">
import {computed, ref, watch} from 'vue'
import {useStore} from 'vuex'
import competitorProducts from '@/components/mixins/competitorProducts.js';


export default {
      
     setup() {
                
        const store = useStore()     
        const {fetchCompetitorProducts, removeCompetitorProducts, updateCompetitorProduct} = competitorProducts()          

        const editDialog = ref(false)


        // Get environment 
        const env = computed(() => {
            return process.env.VUE_APP_ENV
            })

        const selected_options = ref([])

        const products = computed (() => { return store.state.competitor.competitorProducts})
        watch( () => store.state.competitor.competitorProducts, function() {                                                        
            window.console.log('competitor Produts changes')
            // Reset selected options
            selected_options.value = []
            return store.state.competitor.competitorProducts
            });

        const columnWidth = "80px"
        const pagination = ref({
            rowsPerPage: 10 // current rows per page being displayed
            })
        
        const columns = [
           
                {
                    name: 'name',
                    required: true,
                    label: 'Product Name',
                    align: 'left',
                    field: 'name',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",
                    headerStyle: `max-width: ${columnWidth}px;`

                },
         
                {
                    name: 'product_height',
                    required: true,
                    label: 'Height',
                    align: 'left',
                    field: 'product_height',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: black; text-align: center",
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },
                {
                    name: 'product_width',
                    required: true,
                    label: 'Width',
                    align: 'left',
                    field: 'product_width',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',                    
                    style: "color: black; text-align: center",
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },

                 {
                    name: 'product_depth',
                    required: true,
                    label: 'Depth',
                    align: 'left',
                    field: 'product_depth',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',             
                    style: "color: black; text-align: center",       
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },

                {
                    name: 'userEmail',
                    required: true,
                    label: 'Added by',
                    align: 'left',
                    field: 'userEmail',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',                    
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },
                {
                    name: 'productID',
                    required: true,
                    label: 'Product ID',
                    align: 'left',
                    field: 'productID',
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",
                    headerStyle: `max-width: ${columnWidth}px;`

                },

                


        ]


        // Edit specific product
        const optionToEdit = ref()
        const editProduct = ((productID) => {

            optionToEdit.value = store.getters['competitor/product']({productID: productID})
            editDialog.value = true

            window.console.log(productID)
            window.console.log(optionToEdit.value)

        })

        
       // Delete project
    //    const deleteProjects = () => {
    //        window.console.log(selected_options.value)
    //        selected_options.value.forEach(element => {
    //            window.console.log(element)
    //            deleteProject(element)               
    //        })
    //    }



   
        
        return {
            store,
            env,
            columns,
            products,
            selected_options,
            pagination,
            editDialog,
            fetchCompetitorProducts,
            removeCompetitorProducts,
            updateCompetitorProduct,
            editProduct,
            optionToEdit
                        
 
        }
        
    },
}
</script>

<style scoped lang="scss">

.q-input{
    min-width: 100px
}

</style>
