
import{computed} from 'vue'
import overview from '@/components/projectDB/overview.vue'


export default {
  name: 'Config',
  components: {       
      'overview': overview,
      
  },

  setup(){
    const env = computed(() => {return process.env.VUE_APP_ENV})

    return {
      env
    }

  }

  

};
