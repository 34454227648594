<template>
    <q-page>
      <shipping-box />
    </q-page>
</template>

<script lang="ts">
import shippingBox from '@/components/home/shippingBox.vue'; // @ is an alias to /src

export default {
  name: 'ViewShippingBox',
  components: {    
    'shipping-box': shippingBox

  },

                        
  };
</script>
