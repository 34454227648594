import { render } from "./mainTable.vue?vue&type=template&id=61d7c964&scoped=true"
import script from "./mainTable.vue?vue&type=script&lang=js"
export * from "./mainTable.vue?vue&type=script&lang=js"

import "./mainTable.vue?vue&type=style&index=0&id=61d7c964&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-61d7c964"

export default script
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSpinnerBars from 'quasar/src/components/spinner/QSpinnerBars.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QItem,QItemSection,QIcon,QItemLabel,QBtn,QTooltip,QSpinnerBars,QTable,QTr,QTd,QCheckbox,QLinearProgress,QBadge,QTh,QInput});
