<template>
    <q-page>
      <div class="row q-mx-md q-mt-xl text-head-scalize-large"> Assortment Analysis </div>

        
      <div class="row q-ma-md text-body-scalize items-center">
        <div class="col-auto q-mx-md">
          <q-input class="q-ma-xs" label="Pallet Width" v-model="pallet.width"> </q-input>
          <q-input class="q-ma-xs" label="Pallet Depth" v-model="pallet.depth"> </q-input>
          <q-input class="q-ma-xs" label="Pallet Height (ex. base)" v-model="pallet.height"> 
            <q-tooltip>Max height for stacked shippers</q-tooltip>
          </q-input>
        </div>              
      
        <div class="col-auto">
            <q-option-group
              :options="blockPatterns"
              type="checkbox"
              v-model="selectedBlockPatterns"
            />
            
        <!-- <q-btn-dropdown class="q-ma-md" outline color="blue" label="Select Block Pattern" >
          <q-list>
         
            <q-item @click="setBlockPattern(1)" clickable v-close-popup>1-Block</q-item>
            <q-item @click="setBlockPattern(2)" clickable v-close-popup>2-Block</q-item>
            <q-item @click="setBlockPattern(3)" clickable v-close-popup>3-Block</q-item>
            <q-item @click="setBlockPattern(4)" clickable v-close-popup>4-Block</q-item>
            <q-item @click="setBlockPattern(5)" clickable v-close-popup>5-Block</q-item> 
          </q-list>
        </q-btn-dropdown> -->
      </div>

       
      
      </div>
      
      <div class="row q-ma-md text-head-scalize"><excel-upload/></div>

       <div class="row q-ma-md">
          <q-btn outline class="q-mx-md" color="red" label="Start Evaluation" @click="startAnalysis" />      
        </div>    

        
      <div class="row q-ma-md">
        <div class="col-xs-5 q-ma-md">
          <upload-table />
        </div>
        <div class="col-xs-6 q-ma-md">          
          <results-table />
        </div>
      </div>

            
    
         
    </q-page>
</template>

<script lang="ts">

import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import excelUpload from '@/components/mass_analysis/excelUpload.vue'
import uploadTable from '@/components/mass_analysis/uploadTable.vue'
import massAnalysis from '@/components/mixins/massAnalysis'
import resultsTable from '@/components/mass_analysis/resultsTable.vue'


export default {
  name: 'ViewMassAnalysis',
  components: {   
    'excel-upload': excelUpload,
    'upload-table': uploadTable,
    'results-table': resultsTable

  },

  setup() {

    const store = useStore()
    const {startMassAnalysis} = massAnalysis()



    const storePallet = computed(() => {return store.state.massAnalysis.pallet})
    //const storePalletDepth = computed(() => {return store.state.massAnalysis.palletDepth})
    //const storePalletHeight = computed(() => {return store.state.massAnalysis.palletHeight})

    const pallet = ref(storePallet.value)
    //const depth = ref(storePalletDepth.value)
    //const height = ref(storePalletHeight.value)

    const blockPatterns = [
        { label: '1-Block', value: 1 },
        { label: '2-Block', value: 2, color: 'blue' },
        { label: '3-Block', value: 3, color: 'blue' },
        { label: '4-Block', value: 4, color: 'blue' },
        { label: '5-Block', value: 5, color: 'blue' },
      ]
    const selectedBlockPatterns = ref([2, 3, 4])

    
    

    const startAnalysis = () => {
      window.console.log('Starting analysis')
      if (selectedBlockPatterns.value.length === 0){
          store.commit('dialogs/updateMessage','Please select at least one block pattern.')
          store.commit('dialogs/updateDialog', true)

      }else{
        startMassAnalysis(selectedBlockPatterns.value)
      }

    }

    return {
      startAnalysis,
    
      pallet,
      blockPatterns,
      selectedBlockPatterns

    
    }
  }

                        
  };
</script>
