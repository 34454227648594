


//* Class Point
export class Point {
    x: number;
    y: number;
    z: number;
  
    constructor(x, y, z) {
      this.x = x
      this.y = y
      this.z = z
    }   
  
  }
//* Class Box
export class Box {
  bottomFrontLeft: Point;
  bottomFrontRight: Point;
  bottomBackLeft: Point;
  bottomBackRight: Point;
  topFrontLeft: Point;
  topFrontRight: Point;
  topBackLeft: Point;
  topBackRight: Point;
  
  width: number;
  depth: number;
  height: number;
      
// Constructor function
  constructor(bottomFrontLeft, width, depth, height) {
    this.bottomFrontLeft = bottomFrontLeft
    this.width = width
    this.depth = depth
    this.height = height
                   
    this.bottomFrontRight = new Point( this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y, 0)
    this.bottomBackLeft = new Point( this.bottomFrontLeft.x, this.bottomFrontLeft.y, this.depth)
    this.bottomBackRight = new Point( this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y, this.depth)

    this.topFrontLeft = new Point( this.bottomFrontLeft.x, this.bottomFrontLeft.y + this.height, 0)
    this.topFrontRight = new Point(this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y + this.height, 0)
    this.topBackLeft = new Point( this.bottomFrontLeft.x, this.bottomFrontLeft.y + this.height, this.depth)
    this.topBackRight = new Point( this.bottomFrontLeft.x + this.width, this.bottomFrontLeft.y + this.height, this.depth)    
    
  }

   //function 
   disp():void { 
      console.log("Function displays Engine is  :   "+ this.width) 
   }

   // Cabinet Projection
    cabinetProjection(alpha: number): void{

        const points = [
            this.bottomFrontLeft, this.bottomFrontRight, this.bottomBackLeft, this.bottomBackRight,
            this.topFrontLeft, this.topFrontRight, this.topBackLeft, this.topBackRight
        ]


        points.forEach(p => {
            p.x = p.x + 0.5 * p.z * Math.cos(alpha)
            p.y = p.y + 0.5 * p.z * Math.sin(alpha)
        })                        

    }

    // Isometric Projection
    // https://en.wikipedia.org/wiki/Isometric_projection#cite_note-1
    isometrixProjection(): void{

        const points = [
            this.bottomFrontLeft, this.bottomFrontRight, this.bottomBackLeft, this.bottomBackRight,
            this.topFrontLeft, this.topFrontRight, this.topBackLeft, this.topBackRight
        ]


        points.forEach(p => {
            p.x = (1/Math.sqrt(6)) * (Math.sqrt(3) * p.x  - Math.sqrt(3) * p.z)
            p.y = (1/Math.sqrt(6)) * ( p.x + 2 * p.y + p.z)
        })                        

    }
    } // end of box class


