export default {
    namespaced: true,
    
    /* ------------------- STATE -------------------------------------------------------- */
    state: {

     
      // Selected Options from full analysis
      competitorProducts: [],
      selectedCompetitorProduct: [],
                        
    },
     


    /* ------------------- MUTATIONS -------------------------------------------------------- */
    mutations: {

     // Set selected options Full
     updateCompetitorProducts(state, payload: []){
        state.competitorProducts.push(payload)
      },

      updateCompetitorProductsDB(state, payload: []){
        state.competitorProducts = payload
      },
           


    },
    actions: {
    },

    getters: {

      // Prepare data for results table
     // Getter for specific option
    product: (state) => (value) => {
        window.console.log('id:' + value['productID'])        
        const intermediate = state.competitorProducts.filter(row => row.productID === value['productID'])[0]
        window.console.log(intermediate)
        //return first_result.filter(row => row.id == 1)
        return intermediate
    },
  }, // End of getters
    modules: {
    }
  }
  
