<template>
<div>
     <q-table 
        title="Sensitivity Results"
        :rows="data" 
        :columns="columns"
        row-key="id"
        selection="multiple"
        v-model:selected="selectedOptions"        
        @update:selected="makeSelection"
        :visible-columns="visibleColumns"
        dense
        flat
        bordered       
        card-class="bg-grey-2 text-black"                 
        >


        <!-- Body props-->
         <template v-slot:body="props">
            <q-tr :props="props">

                <q-td>
                    <q-checkbox dense v-model="props.selected" />
                </q-td>
                      
                <q-td auto-width>
                    <q-btn size="sm" 
                        color="blue" 
                        round 
                        dense 
                        outline                        
                        @click="drawOption(props.row)" 
                        icon="draw" 
                        >
                         <q-tooltip content-class="bg-accent">Click to draw selected option.</q-tooltip>
                    </q-btn>
                </q-td>

                <q-td
                    v-for="col in props.cols"
                    :key="col.name"
                    :props="props"                                        
                    >
                    {{col.value}}
                </q-td>

          
            </q-tr>

        </template>


        <!-- Header Props-->
            <template v-slot:header="props">
                <q-tr :props="props">
                    
                    <q-th auto-width />
                    <q-th auto-width />
                    <q-th
                        v-for="col in props.cols"
                        :key="col.name"
                        :props="props"
                    >
                    <div class="row justify-center" style="height:25px">
                            <div class="row q-mt-xs justify-center text-body-scalize-small-table" style="height:40px">
                                {{ col.group }}
                            </div>
                        </div>
                    <div class="row justify-center" style="height:60px">
                            <div class="row q-mt-xs justify-center text-body-scalize-small-table" style="height:35px">
                                {{ col.label }}
                            </div>
                        </div>

                      <!-- <div class="row q-mt-xs justify-center" style="height:40px">
                        <q-input                                
                            v-model="filter[col.field]" 
                            @input="updateFilter($event, parameter=col.field)" 
                            placeholder="filter"
                            outlined
                            dense
                            >
                            <q-tooltip content-class="bg-accent">Enter a value to filter, you can also use &lt; or &gt; </q-tooltip>
                        </q-input>
                    </div>    -->


                    <!-- <div class="row q-mt-xs justify-center" style="height:25px">  
                        <q-btn @click="sortData(col.field, 'asc')" size="xs" class="q-mr-xs" >
                            <q-icon  name="arrow_upward"/>
                            <q-tooltip content-class="bg-accent">Sort ascending </q-tooltip>
                        </q-btn>
                        <q-btn @click="sortData(col.field, 'desc')" size="xs">
                            <q-icon  name="arrow_downward"/>
                            <q-tooltip content-class="bg-accent">Sort descending</q-tooltip>
                        </q-btn>
                    </div> -->

                </q-th>
            </q-tr>
      </template>

    </q-table>

    <div v-if="env=='dev'">
        Input Data length: {{ data.length }} <br>    
        Selected: {{selectedOptions}} <br>
    </div>
    
</div>
</template>


<script lang="js">
import {  ref, toRefs, computed} from 'vue'
import {  useStore} from 'vuex'

const columnWidth = 90

export default {
    name: 'subTable',
    props: {

        data: {
            type: Array,
            required: true
        },
                              
        parentOption: {
            type: Number,
            required: true
        }
    },


    setup (props) {
        
        //! vue3
        const env = computed(()=> {return process.env.VUE_APP_ENV})
        const store = useStore() 
        
        const {data} = toRefs(props)
        

        //  const data = [
        //      {'id': 1, 'shipping_box_number_products': 6, 'shipping_box_height': 3, 'c': 6},
        //      {'id': 2, 'shipping_box_number_products': 6, 'shipping_box_height': 3, 'c': 6},
        //      {'id': 3, 'shipping_box_number_products': 6, 'shipping_box_height': 3, 'c': 6},
             
        //      ]       


        // Fetch selected Options from store
        const selectedOptionsStore = computed(() => {
            const intermediate  = store.state.decisions.selectedOptionsSensitivity.filter(e => e.parentOption == props.parentOption)

            if (intermediate.length > 0){
                return intermediate[0]['values']
            }else{
                return []
            }
        }
        )

        // Init selected options
        const selectedOptions = ref(selectedOptionsStore.value)

        const makeSelection = () =>{
            window.console.log('making selection in sensitivity options')
            
            store.commit('decisions/updateSelectedOptionsSensitivity',{
                'parentOption': props.parentOption,
                'values': selectedOptions.value
            }
            )
        }


        // this function is called when clicked on draw button
        const drawOption = (opt) => {
            window.console.log('clicked on draw button')
            window.console.log(opt)
            store.commit('general/updateOptionToVisualize', opt)
        }



        const pagination = ref({
                rowsPerPage: 10 // current rows per page being displayed
                })


        const visibleColumns = computed(() => {       
            return store.state.userSettings.selectedColumns
            })

        // const visibleColumns = [ 
        //         'id', 
        //         'product_height',
        //         'product_width',
        //         'product_depth',
        //         'number_shrinks',
        //         'shipping_box_number_products',
        //         'number_shrinks',     
        //         'units_per_shrink',           
        //         'product_orientation',
        //         'number_shipping_boxes',
        //         'shipping_box_weight',
        //         'number_shipping_boxes_per_layer',
        //         'number_layers',
        //         'total_units_per_pallet',
        //         'layer_efficiency',
        //         'pallet_height_utilisation',
        //         'pallet_block',
        //         'pallet_weight'
        //         ]


        // const columns = [
        //     {name: 'id', field: 'id', label:'id'},
        //     {name: 'a', field: 'a', label:'a'},
        //     {name: 'b', field: 'b', label:'b'},
        //     {name: 'c', field: 'c', label:'c'},
        //     ]

         // Columns
        const columns = [
                {
                    name: 'id',
                    required: true,
                    label: 'Option ID',
                    align: 'left',
                    field: row => row.id,
                    format: val => `${val}`,
                    
                    style: "color: blue; text-align: center",
                    headerStyle: `max-width: ${columnWidth}px;`
                    
                },

            // -------------------- Product
                {name: 'ml', type: 'float', label: 'ml', field: 'ml', sortable: false, 
                    headerStyle: `max-width: ${columnWidth}px`,
                    format: (val, row) => `${Math.round(val * 100)/100}`,
                    group: 'Product'
                },

                {name: 'product_height', type: 'float', label: 'Height', field: 'product_height',
                    sortable: false,headerStyle: `max-width: ${columnWidth}px`,
                    group: 'Product'
                    },

                {name: 'product_width', type: 'float', label: 'Width', field: 'product_width', sortable: false,
                 headerStyle: `max-width: ${columnWidth}px`,
                 group: 'Product'
                 },
                
                {
                    name: 'product_depth', type: 'float', label: 'Depth', field: 'product_depth',
                    sortable: false, headerStyle: `max-width: ${columnWidth}px`,
                    group: 'Product'},

                {
                    name: 'product_volume_weight', type: 'float', label: 'Volume Weight (g)', field: 'product_volume_weight',                
                    sortable: false, 
                    headerStyle: `max-width: ${columnWidth}px; color:#204fffE6`,
                    group: 'Product'
                    },
                
                {
                    name: 'product_packaging_weight', type: 'float', label: 'Packaging Weight (g)', field: 'product_packaging_weight',                
                    sortable: false, headerStyle: `max-width: ${columnWidth}px`,
                    group: 'Product'
                    },
         

                // ------------------------------------ Shipping Box
                {   
                    name: 'shipping_box_height',
                    type: 'float',
                    label: 'Height',
                    field: 'shipping_box_height', 
                    sortable: false,
                    //style: "width: 10px",
                    headerStyle: `max-width: ${columnWidth}px`,
                    format: (val, row) => `${parseFloat(val).toFixed(2)}`,
                    group: 'Shipper'
                  
                },

                {name: 'shipping_box_width', type: 'float', label: 'Width', field: 'shipping_box_width', sortable: false,  
                    headerStyle: `max-width: ${columnWidth}px`, format: (val, row) => `${parseInt(val).toFixed(2)}`,
                    group: 'Shipper'
                    },

                {name: 'shipping_box_depth', type: 'float', label: 'Depth', field: 'shipping_box_depth', sortable: false,
                    headerStyle: `max-width: ${columnWidth}px`, format: (val, row) => `${parseFloat(val).toFixed(2)}`,
                    group: 'Shipper'
                    },
                
                {name: 'shipping_box_config', type: 'string', label: 'Config', field: 'shipping_box_config', sortable: false, 
                    headerStyle: `max-width: ${columnWidth}px`,
                    group: 'Shipper'
                    },
                
                  
                {name: 'units_per_shrink', type: 'float', label: 'Units per Shrink', field: 'units_per_shrink', sortable: false,
                    headerStyle: `max-width: ${columnWidth}px`,
                    group: 'Shipper'
                    },
                
                {name: 'number_shrinks', type: 'integer', label: 'Shrinks per Box', field: 'shipping_box_number_shrinks', sortable: false,
                    headerStyle: `max-width: ${columnWidth}px`,
                    group: 'Shipper'
                    },
                
                {name: 'rows_width', type: 'float', label: 'Units per Shrink', field: 'rows_width', sortable: false,
                    headerStyle: `max-width: ${columnWidth}px`,
                    group: 'Shipper'
                    },
                
                {name: 'rows_depth', type: 'float', label: 'Shrinks per Box', field: 'rows_depth', sortable: false,
                    headerStyle: `max-width: ${columnWidth}px`,
                    group: 'Shipper'
                    },
                
                {name: 'product_orientation', type: 'string', label: 'Unit Orientation in Shrink', field: 'product_orientation', sortable: false,
                    headerStyle: `max-width: ${columnWidth}px`,
                    group: 'Shipper'
                    },
                
                {name: 'shipping_box_number_products', type: 'integer', label: 'Units in Shipping Box', field: 'shipping_box_number_products',
                 sortable: false, headerStyle: `max-width: ${columnWidth}px`,
                 group: 'Shipper'
                 },


                {name: 'shipping_box_weight', type: 'flotat', label: 'Weight Shipping Box (kg)', field: 'shipping_box_weight',
                 sortable: false, headerStyle: `max-width: ${columnWidth}px`,
                    format: (val, row) => `${(val/1000).toFixed(2)}`,
                    group: 'Shipper'                       
                 },
                 
                 {name: 'shipping_box_weight', type: 'float', label: 'Weight (kg)', field: 'shipping_box_weight',
                 sortable: false, 
                 headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                    format: (val, row) => `${(val/1000).toFixed(3)}`,
                    group: 'Shipper'                       
                 },
                
                {name: 'shipping_box_packaging_material_weight', type: 'float', label: 'Weight Material (g)', field: 'shipping_box_packaging_material_weight',
                 sortable: false, 
                 headerStyle: `max-width: ${columnWidth}px; color:#262626e6`,
                    format: (val, row) => `${val.toFixed(2)}`,
                    group: 'Shipping Box'                       
                 },
                
                
            // -------------- Pallet ------------------------------------             
              {name: 'pallet_height', type: 'float', label: 'Height', field: 'pallet_height', 
                     sortable: false, 
                     headerStyle: `max-width: ${columnWidth}px;`,
                      sort: (a, b) => parseInt(a, 10) - parseInt(b, 10),                     
                     group: 'Pallet'
                     },

                {name: 'pallet_total_height', type: 'float', label: 'Total height', field: 'pallet_total_height',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px`,
                     format: (val, row) => `${val.toFixed(2)}`,
                    group: 'Pallet' },       
                     

                {name: 'number_shipping_boxes_per_layer', type: 'integer', label: 'Shipping Boxes per Layer', field: 'total_boxes_per_layer', 
                     sortable: false, headerStyle: `max-width: ${columnWidth}px`, sort: (a, b) => parseInt(a, 10) - parseInt(b, 10),
                     group: 'Pallet'
                     },

                {name: 'number_layers', type: 'integer', label: 'Layers', field: 'number_layers',
                     sortable: false, 
                     headerStyle: `max-width: ${columnWidth}px`, 
                     sort: (a, b) => parseInt(a, 10) - parseInt(b, 10),
                     group: 'Pallet'
                      },

                
                {name: 'number_shipping_boxes', type: 'integer', label: 'Shipping Boxes per Pallet', field: 'total_boxes',
                     sortable: false, headerStyle: `max-width: ${columnWidth}px`, 
                     sort: (a, b) => parseInt(a, 10) - parseInt(b, 10),
                     group: 'Pallet' },
                
                {name: 'total_units_per_pallet', type: 'integer', label: 'Units', field: 'total_units_per_pallet',
                     sortable: false, headerStyle: `max-width: ${columnWidth}px`,
                     group: 'Pallet'
                     },


                {name: 'layer_efficiency', type: 'percent', label: 'Layer utilisation %', field: 'layer_efficiency',
                     sortable: false, headerStyle: `max-width: ${columnWidth}px`,
                     format: (val, row) => `${val.toFixed(2)*100}%`,
                     group: 'Pallet'
                      },

                {name: 'pallet_height_utilisation', type: 'percent', label: 'Height utilisation %', field: 'pallet_height_utilisation',
                     sortable: false, 
                     headerStyle: `max-width: ${columnWidth}px`,
                     format: (val, row) => `${val.toFixed(2)*100}%`,
                     group: 'Pallet' 
                     },                

                {name: 'pallet_efficiency', type: 'percent', label: 'Utilisation %', field: 'pallet_efficiency',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px`,
                     format: (val, row) => `${val.toFixed(2)*100}%`,
                     group: 'Pallet' 
                     },
                                 
        
                {name: 'pallet_block', type: 'float', label: 'Block', field: 'pallet_block',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px`,
                     format: (val, row) => `${val}`,
                     group: 'Pallet' },       
                     
                  {name: 'pallet_weight', type: 'float', label: 'Pallet Weight (kg)', field: 'pallet_weight',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px`,
                     format: (val, row) => `${(val/1000).toFixed(2)}` ,
                     group: 'Pallet'
                     }, 
                     
                     
                 {name: 'layer_weight', type: 'float', label: 'Layer Weight (kg)', field: 'pallet_layer_weight',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px`,
                     format: (val, row) => `${(val/1000).toFixed(2)}` ,
                     group: 'Pallet'
                     },
                
                {name: 'number_layers_to_substract', type: 'float', label: 'Layers to be removed', field: 'pallet_number_layers_to_substract',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px;`,
                    group: 'Pallet'
                    },
                

                {name: 'truck_number_pallets', type: 'integer', label: 'Pallets on Truck', field: 'truck_number_pallets',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px;`,
                     group: 'Pallet'
                    },    
                
                {name: 'truck_pallets_weight', type: 'integer', label: 'Weight Pallets on Truck (kg)', field: 'truck_pallets_weight',
                     sortable: false,
                     headerStyle: `max-width: ${columnWidth}px`,
                     format: (val, row) => `${(val/1000).toFixed(2)}`,
                     group: 'Pallet'
                     
                    },   


                    
    
                
                       
            ]




    //-----------------  FILTER --------------------------------

      const filter = ref({})
      
        const myfilterMethod = () => {    
                
                // Filter by number of boxes
                //let intermediate = this.result.filter(row => parseInt(row['total_boxes']) > this.filter.total_boxes)

                // Filter by ml
                //intermediate = intermediate.filter(row => parseInt(row['ml']) > this.filter.ml)

            let intermediate = data.value;

            window.console.log('this filter')
            
            window.console.log(filter.value)
            window.console.log(intermediate)

            // relevant filters

            for (const [key, value] of Object.entries(filter.value)) {

                if (value.length > 0){

                
                window.console.log(key, value);
                window.console.log(key, value[0]);
                window.console.log("len value " + value.length);
                
                const column = columns.filter(row => row.field == key )
                
                window.console.log("column type: " +  column[0]['type'])
                
           if (value != ""){
                // Filter dataset
                switch(column[0]['type']){
                    case 'float':
                        window.console.log('filtering float')
                        window.console.log(value.substring(1, value.length))
                        switch(value[0]) {
                            case ">":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) > value.substring(1, value.length))
                                break;
                            case "<":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) < value.substring(1, value.length))
                                break;
                            default:
                                intermediate = intermediate.filter(row => parseFloat(row[key]) == value)
                                break; 
                        }
                    break;

                    case 'integer':
                        switch(value[0]) {
                            case ">":
                                intermediate = intermediate.filter(row => parseInt(row[key]) > value.substring(1, value.length))
                                break;
                            case "<":
                                intermediate = intermediate.filter(row => parseInt(row[key]) < value.substring(1, value.length))
                                break;
                            default:
                                intermediate = intermediate.filter(row => parseInt(row[key]) == value)
                                break; 
                        }
                    break;

                    case 'percent':
                        window.console.log("filter value: " +  value.substring(1, value.length)/100)
                        window.console.log("intermediate value: " +  intermediate[0][key])
                        

                        switch(value[0]) {
                            case ">":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) > value.substring(1, value.length)/100)
                                break;
                            case "<":
                                intermediate = intermediate.filter(row => parseFloat(row[key]) < value.substring(1, value.length)/100)
                                break;
                            default:
                                intermediate = intermediate.filter(row => parseFloat(row[key]).toFixed(2) == value.substring(0, value.length)/100)
                                break; 
                        }
                    break;


                    default:
                        intermediate = intermediate.filter(row => row[key].includes(value))
                        break;
                }
           }
                

                    // if (value != ""){
                    // switch(value[0]) {
                    //     case ">":
                    //         case column[0]['type'] == 'float':
                    //             intermediate = intermediate.filter(row => parseFloat(row[key]) > value.substring(1, value.length))
                    //             break;  
                    //         case column[0]['type'] == 'integer':
                    //             intermediate = intermediate.filter(row => parseInt(row[key]) > value.substring(1, value.length))
                    //             break;
                    //     case "<":
                    //         case column[0]['type'] == 'float':
                    //             intermediate = intermediate.filter(row => parseFloat(row[key]) < value.substring(1, value.length))
                    //             break;  
                    //         case column[0]['type'] == 'integer':
                    //             intermediate = intermediate.filter(row => parseInt(row[key]) < value.substring(1, value.length))
                    //             break;
                    //     default:
                    //          if (column[0]['type'] == 'string'){ 
                    //              window.console.log('string')
                    //              intermediate = intermediate.filter(row => row[key].includes(value))
                    //          }else {
                    //             case column[0]['type'] == 'float':
                    //                 intermediate = intermediate.filter(row => parseFloat(row[key]) == value)
                    //                 break;  
                    //             case column[0]['type'] == 'integer':
                    //                 intermediate = intermediate.filter(row => parseInt(row[key]) == value)
                    //                 break;
                                 
                    //          }
                    //          break;
                    // }

                      

                    }
                    } 
        
                window.console.log('filtered')
                window.console.log(intermediate)
                return intermediate
        } // End of Filter methods
               

        // Update Filter
        const updateFilter = (value, parameter) =>{

            window.console.log("Updating filter")
            window.console.log('value ' + value)
            window.console.log('parameter: ' + parameter)
            filter.value[parameter] = value
            window.console.log(filter.value)
        }



        // Sorting
        const compareValues = (key, order = 'asc') =>{
            return function innerSort(a, b) {
                // if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // // property doesn't exist on either object
                // return 0;
                // }

                //window.console.log(typeof a[key] )

                const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
                const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

                let comparison = 0;
                if (varA > varB) {
                    comparison = 1;
                } else if (varA < varB) {
                    comparison = -1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }

        const sortData = (key, direction) => {
            window.console.log('sorting')
            window.console.log('key: ' + key)
            window.console.log('direction: ' + direction)
            window.console.log('resut')
            
            const test = data.value;
  
           
            //this.result.sort(this.compareValues(key,  direction));
            window.console.log('sorted')
            window.console.log( test.sort(compareValues(key,  direction)));

            data.value = test.sort(compareValues(key,  direction))

        

            
        }




        return {
            env,
            columns,        
            selectedOptions,
            makeSelection,
            visibleColumns,
            pagination,
            sortData,
            compareValues,
            updateFilter,
            myfilterMethod,
            filter,
            drawOption
     
            
        }
    }

}

</script>


<style scoped>
.q-table td, .q-table th {
    /* don't shorten cell contents */
    white-space: normal !important;
    height: 40px;
    
}


</style>