

<template>        

    <div class="row q-ma-xs">
            <!-- <q-table
                :rows="[data]" 
                :columns="columns"
                row-key="article"         
            /> -->
                            
            <div class="row col-12 q-ma-xs text-body-scalize">                
                <div class="col">Article: </div>
                <div class="col">
                    <span :style="{'color':'blue'}">{{ data.article }}</span>
                </div>
            </div>

            <div class="row col-12 q-ma-xs text-body-scalize">                
                <div class="col">Delta Products: </div>
                <div class="col"><span :style="{'font-weight':600, 'color': data.delta>0 ? 'green': 'red'}">{{ data.delta }}</span></div>
            </div>
            
            <div class="row col-12 q-ma-xs text-body-scalize">                
                <div class="col">Total Boxes per Layer: </div>
                <div class="col"><span :style="{'color':'blue'}">{{ data.total_boxes_per_layer }}</span></div>
            </div>

            <div class="row col-12 q-ma-xs text-body-scalize">                
                <div class="col">Total Boxes per Pallet: </div>
                <div class="col"><span :style="{'color':'blue'}">{{ data.total_boxes }}</span></div>
            </div>

            <div class="row col-12 q-ma-xs text-body-scalize">                
                <div class="col">Total Products per Pallet: </div>
                <div class="col"><span :style="{'color':'blue'}">{{ data.total_products }}</span></div>
            </div>

            <div class="row col-12 q-ma-xs text-body-scalize">                
                <div class="col">Block Pattern: </div>
                <div class="col"><span :style="{'color':'blue'}">{{ data.block }}</span></div>
            </div>

            <div class="row col-12 q-ma-xs text-body-scalize">                
                <div class="col">Shipping Box Width: </div>
                <div class="col"><span :style="{'color':'blue'}">{{ data.article_width }}</span></div>
            </div>
            
            <div class="row col-12 q-ma-xs text-body-scalize">                
                <div class="col">Shipping Box Depth: </div>
                <div class="col"><span :style="{'color':'blue'}">{{ data.article_depth }}</span></div>
            </div>
            <div class="row col-12 q-ma-xs text-body-scalize">                
                <div class="col">Shipping Box Height: </div>
                <div class="col"><span :style="{'color':'blue'}">{{ data.article_height }}</span></div>
            </div>
            
        </div>    

    <div class="row q-mx-xs q-mt-lg text-head-scalize"> Pallet Scheme   </div>
    <div class="row q-ma-xs">    
        <canvas ref="mass_analysis_2d_pallet" :height="canvasHeight" :width="canvasWidth"></canvas>      
    </div>
    <div class="row q-ma-xs" v-if="env==='dev'">    
        Canvas Width: {{canvasWidth}}
        Canvas Height: {{canvasHeight}}
        
    </div>

    <div class="row q-ma-md" v-if="env==='dev'">
        {{ data }}
    </div>

</template>


<script lang="ts">
import {ref, computed, onMounted} from 'vue'
import drawPalletFunctions from '@/components/mixins/drawPallet.ts'


export default {
    name: 'palletScheme2D',

    props: {
        data: {
            type: Array,
            required: true
        }
    },

    setup(props) {

        const env = computed(() => {return process.env.VUE_APP_ENV})
        const {drawPallet} = drawPalletFunctions()
        const canvasHeight = computed(() => {return window.innerHeight/3})
        const canvasWidth = computed(() => {return window.innerWidth/4})
        
        //const canvas_2dpallet = ref<HTMLCanvasElement>()
        const mass_analysis_2d_pallet = ref<HTMLCanvasElement | null>(null)


        const columns = [ {
                    name: 'article',
                    required: true,
                    label: 'Article',
                    align: 'left',
                    field: row => row.article,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",                                      
                },
                // {
                //     name: 'Height',
                //     required: true,
                //     label: 'Height',
                //     align: 'center',
                //     field: row => row.article_height,
                //     format: val => `${val}`,
                //     classes: 'bg-white ellipsis',
                //     style: "color: blue; text-align: center",                                      
                // },
                // {
                //     name: 'Depth',
                //     required: true,
                //     label: 'Depth',
                //     align: 'center',
                //     field: row => row.article_depth,
                //     format: val => `${val}`,
                //     classes: 'bg-white ellipsis',
                //     style: "color: blue; text-align: center",                                      
                // },
            ]
        

        // const {data} = toRefs(props)
        
        // // Draw pallet scheme
        // const drawOption = (data) => {            
        //     drawPallet(canvas, data)
            
        // }


        onMounted(() => {
            const canvas  = mass_analysis_2d_pallet.value as HTMLCanvasElement
            drawPallet(canvas, props.data)
        })
                

        return {                  
            env,                             
            canvasHeight,
            canvasWidth,
            mass_analysis_2d_pallet,
            columns
            
            
        }
}

}


</script>


