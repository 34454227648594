import { render } from "./Home.vue?vue&type=template&id=68ea42a0&scoped=true"
import script from "./Home.vue?vue&type=script&lang=ts"
export * from "./Home.vue?vue&type=script&lang=ts"

import "./Home.vue?vue&type=style&index=0&id=68ea42a0&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-68ea42a0"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QImg,QBtn,QInput,QTooltip,QCard,QItem});
