
import {computed, ref} from 'vue'
import { useMeta } from 'vue-meta'
import {useStore} from 'vuex'


export default {
  name: 'Home',
  setup() {
    const env = computed(() => {return process.env.VUE_APP_ENV})
    const store = useStore()
    useMeta({ title: 'Home Page ' })


    // Paramters show on configuration page
    const productParametersToShow = ['Height', 'Width']    
    const shippingBoxParameters = ['thicknessCarton', 'cartonWrappingHeight', 'cartonWrappingWidth', 'cartonWrappingDepth']
    const palletParameters = ['height', 'width', 'depth']


    // product 
    const productStore = computed(() => {return store.state.general.selected_product})
    const product = ref(productStore)

    // shipper
    const shipperStore = computed(() => {return store.state.general.shippingBox})
    const shipper = ref(shipperStore)


    // pallet
    const palletStore = computed(() => {return store.state.general.selected_pallet})
    const pallet  = ref(palletStore)


     
    //  const filteredProductParameters = computed(() => {return Object.keys(store.state.general.selected_product.parameters).filter(
    //               key => productParametersToShow.includes(key)).reduce(
    //                 (obj, key) => {obj[key] = store.state.general.selected_product.parameters[key]; return obj},{})

    //  })

    const filteredShippingBoxParameters = computed(() => {return Object.keys(store.state.general.shippingBox.materialParameters).filter(
                  key => shippingBoxParameters.includes(key)).reduce(
                    (obj, key) => {obj[key] = store.state.general.shippingBox.materialParameters[key]; return obj},{})
      })

    const filteredPalletParameters = computed(() =>  {return Object.keys(store.state.general.selected_pallet.parameters.basic).filter(
                  key =>  palletParameters.includes(key)).reduce(
                    (obj, key) => {obj[key] = store.state.general.selected_pallet.parameters.basic[key]; return obj},{})

      })


    // Update Product dimension
    const updateProductDimension = (value, dimension) => {
        window.console.log(`updating product width: ${value * 0.01}`)
        const currentProduct = store.state.general.selected_product        
        currentProduct['parameters'][dimension]['values']['Min']['value'] = Math.round(value * (1 - 0.03) * 10)/10
        currentProduct['parameters'][dimension]['values']['Max']['value'] = Math.round(value * (1 + 0.03) * 10)/10
        store.commit('general/updateProduct', currentProduct)        
      }

  


    
    return {env, 
    product,
    shipper,
    pallet,
    productParametersToShow,
    filteredShippingBoxParameters, filteredPalletParameters,
     updateProductDimension
    }
  }
  
  
  }  
