import { createStore } from 'vuex'

import general from './modules/general'
import user from './modules/user'
import sensitivity from './modules/sensitivity'
import dialogs from './modules/dialogs'
import decisions from './modules/decisions'
import competitor from './modules/competitor'
import admin from './modules/admin'
import projects from './modules/projects'
import userSettings from './modules/userSettings'
import templates from './modules/templates'
import massAnalysis from './modules/massAnalysis'
import testScalize from './modules/testScalize'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    general,
    user,
    sensitivity,
    dialogs,
    decisions,
    competitor,
    admin,
    projects,
    userSettings,
    templates,
    massAnalysis,
    testScalize
  }
})
