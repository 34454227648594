import { render } from "./MassAnalysis.vue?vue&type=template&id=635a6018"
import script from "./MassAnalysis.vue?vue&type=script&lang=ts"
export * from "./MassAnalysis.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QInput,QTooltip,QOptionGroup,QBtnDropdown,QList,QItem,QBtn});qInstall(script, 'directives', {ClosePopup});
