

import htmlTemplate from "@/components/general_components/htmlTemplate.vue";

export default {
  name: "privacyPage",
  components: {
    "html-template": htmlTemplate,
  },

  data() {
    return {
      version: "english",
      templateNameGerman: "data_privacy_german.html",
      templateNameEnglish: "data_privacy_english.html",
      privacy: false
    };
  },

  mounted() {
    window.console.log("mounting");
  },
};
