
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import saveProject from '@/components/mixins/saveProject.js'

export default {
  name: 'pallet',


  setup() {
    const env = computed(() => {return  process.env.VUE_APP_ENV})
    const store = useStore()

    const {saveCurrentProject} = saveProject()

    const showDialog = ref(true)      
    const pallet_id =  ref('standard')

     const selectedCard = ref('dimensions')

    const palletStore = computed(() => {return store.state.general.selected_pallet})    
    const pallet = ref(palletStore.value)
    
    
    const secondPalletStore = computed(() => {return store.state.general.secondPallet})    
    const secondPallet = ref(secondPalletStore.value)


    const activateSecondPallet = ((payload) => {
      store.commit('general/activateSecondPallet', payload)
    })


    return {
      env,
      pallet,
      secondPallet,
      showDialog,
      pallet_id,
      selectedCard,
      saveCurrentProject,
      activateSecondPallet
    }

  }
 
        
  }
  
