

Table component has four types of column (this is relevant for how the data is filtered):
- text
- integer
- float
- percent



<template>
    <div>       
       
        <q-dialog v-model="projectChoice" persistent transition-show="scale" transition-hide="scale">
            <q-card style="width:700px;height:450px;border-color:grey"  bordered flat>
                <q-card-actions align="right">
                    <!-- <q-btn flat label="Details" @click="secondDialog = true; firstDialog = false" /> -->
                    <q-btn label="Close" color="black" v-close-popup />
                </q-card-actions>
            
        <q-card-section class="q-pt-none text-head-scalize">
            Project 
        </q-card-section>
       
        <q-card-section>
            <div class="row q-mx-xs" >       
                <q-btn-dropdown color="black" outline  class="q-ma-md q-px-xs" label="Select a category">
                   <q-list bordered v-close-popup>
                        <q-item 
                            v-for="c in categories" 
                            :key="c.name" 
                            clickable 
                            v-ripple
                            :active="link === c.name"
                            active-class="highlight"
                            @click="onCategoryClick(c); link = c.name"
                            >
                            {{ c.name }}
                        </q-item>
                    </q-list>
                </q-btn-dropdown>    

                <select-project />
            
                 <!-- <q-btn-dropdown color="blue" outline class="q-ma-xs" label="Select a project">
                    <q-list>
                        <q-item clickable v-close-popup @click="onItemClick(p)" v-for="p in projects" :key=p.id >
                            <q-item-section >
                                <q-item-label>{{p.projectName}} </q-item-label>
                            </q-item-section>
                        </q-item>
                       
                    </q-list>

                 </q-btn-dropdown> -->

            </div>
            <div class="row q-mx-xs" >  
                 <q-btn 
                    label="Create new project" 
                    class="q-ma-md q-px-xs" 
                    outline  
                    color="deep-orange" 
                    @click="$store.commit('dialogs/updateCreateProjectDialog', true)" 
                    v-close-popup />                                    
                </div>

                
            </q-card-section>

            <q-separator class="q-ma-md" />
                       

        <q-card-section class="q-pt-none text-head-scalize">
            Advanced Analytics
        </q-card-section>
        <q-card-section class="q-pt-none text-body-scalize">
            <q-btn
            label="Assortment Analysis"
            @click="$router.push('/assortment_analysis');projectChoice=false"
            outline
            color="blue"
            class="q-mx-xs"
            />
            
  
        </q-card-section>


   

      </q-card>
    </q-dialog>
       
    </div>  

</template>



<script lang="ts">
import {computed} from 'vue'
import selectProject from '@/components/general_components/selectProject.vue'
import {useStore} from 'vuex'


export default {
    name: 'popUpMenu',   
    components: {
        'select-project': selectProject
    },
    props: {
        show: Boolean
    },


    setup(){
        const store = useStore()

        const categories = computed(() => store.state.general.categories)

        const onCategoryClick = (category: string) => {
            window.console.log('category clicked')
            window.console.log(category)
            window.console.log(store.state.user.userProjects)
            window.console.log(store.state.general.selected_category["name"])
            window.console.log(store.state.user.userProjects.filter(p => p["category"] == category) )
            store.commit('general/updateSelectedCategory', category)
        }

      

        return {
            store,
            categories,            
            onCategoryClick
        }
    }
    ,

/* --------------------- Data ------------------------------------ */
    data() {
        return {
            counter: 1,
            projectChoice: true,
            link: ''
        }

        
    },



    // get result() {
    //     window.console.log( this.$store.state.general.data)
    //     return this.$store.state.general.data
    // },
/* --------------------- WATCH ------------------------------------ */
    

/* --------------------- Computed ------------------------------------ */
    computed: {
       
    

           // Get environment
        env() {       
          return process.env.VUE_APP_ENV
        },

      
       
        
        },
  

  
       

        
/* --------------------- Mounted ------------------------------------ */
    mounted: function() {
       window.console.log('pop up menu mounted')
       
        
} // End of mounted

}
</script>


<style scoped lang="scss">
.q-input{
 
}

.text-body2 {
    color: blue
}


.q-table td, .q-table th {
    /* don't shorten cell contents */
    white-space: normal !important;
    height: 40px;
}

.text-body2{
    height: 10px;
}
</style>