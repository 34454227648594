

<template>
 
      <q-table 
        title="Results"
        :rows="data" 
        :columns="columns"
        row-key="article"         
        :visible-columns="visibleColumns"
        v-model:pagination="pagination"
        >

        <template v-slot:body="props">
            <q-tr :props="props">
                

                <q-td
                    v-for="col in props.cols"
                    :key="col.name"
                    :props="props"                                        
                    >
                    {{col.value}}
                </q-td>


                
                <q-td auto-width :style="{'text-align': 'center'}">
                    <q-btn size="sm" 
                        color="blue" 
                        round 
                        dense 
                        outline                        
                        @click="drawOption(props.row)" 
                        icon="draw" 
                        >
                         <q-tooltip content-class="bg-accent">Click to draw selected option.</q-tooltip>
                    </q-btn>
                </q-td>

          
            </q-tr>

        </template>

        <!-- Header -->
         <template v-slot:header="props">
            <q-tr :props="props">                
                <q-th
                    v-for="col in props.cols"
                    :key="col.name"
                    :props="props"
                > {{ col.label }} </q-th>
                <q-th auto-width :style="{'text-align': 'center'}">
                    Check pattern
                </q-th>
            </q-tr>
                
         </template>
      </q-table> <!-- End of table -->

      <!-- <div class="row q-ma-md justify-start" v-if="data.length>0">
         <q-btn class="q-mx-md" size="md" color="red" label="Export" @click="exportExcel(data)" >
            <q-tooltip content-class="bg-accent">Export results to Excel </q-tooltip>
            </q-btn>
      </div> -->


<!-- Dialog Box -->
      <q-dialog v-model="$store.state.dialogs.massAnalysisResultDialog">
          <q-card :height="cardHeight" :width="cardWidth" flat bordered>
              <q-card-actions class="q-ma-md justify-end">
                  <q-btn label="close" @click="closeDialog()" outline />              
              </q-card-actions>
                <q-section v-if="env==='dev'" class="dev">              
                    Card Width: {{cardWidth}}
                    Card Height: {{cardHeight}}            
                </q-section>
              
              <q-card-section class="text-head-scalize-large">
                Summary
              </q-card-section>
              
              <!-- Canvas to draw on -->
              <q-card-section class="q-mx-md">             
                    <!-- <canvas ref="mass_analysis_2d_pallet" :height="canvasHeight" :widht="canvasWidth"></canvas>   -->
                    <pallet-scheme :data="selectedOption" />
              </q-card-section>
              <!-- end of canvas -->              
          </q-card>
  
      </q-dialog>

<!-- 
        <div v-if="env==='dev'">
            {{$store.state.massAnalysis.results}}
        </div> -->

</template>


<script lang="ts">
import {useStore} from 'vuex'
import {ref, computed, onMounted} from 'vue'
//import drawPalletFunctions from '@/components/mixins/drawPallet.ts'
import palletScheme from '@/components/mass_analysis/palletScheme2D.vue'
import exportData from '@/components/mixins/exportData.js'

export default {
    components: {
        'pallet-scheme': palletScheme
    },

    setup() {

        const store = useStore()
        const env = computed(() => {return process.env.VUE_APP_ENV})
        //const {drawPallet} = drawPalletFunctions()
        const {exportExcel} = exportData()

        const cardHeight = computed(() => {return window.innerHeight/2.5})        
        const cardWidth = computed(() => {return window.innerWidth/3})

        
        const selectedOption = ref()

        
        const pagination = ref(5)
        const columns = [ 
                 {
                    name: 'Article',
                    required: true,
                    label: 'Article',
                    align: 'left',
                    field: row => row.article,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",                                      
                },
                {
                    name: 'boxes_per_layer',
                    required: true,
                    label: 'Boxes per Layer',
                    align: 'center',
                    field: row => row.total_boxes_per_layer,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",   
                    sortable: true,                                   
                },
                {
                    name: 'total_products',
                    required: true,
                    label: 'Products on Pallet',
                    align: 'center',
                    field: row => row.total_products,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",      
                    sortable: true,                                
                },
                {
                    name: 'delta',
                    required: true,
                    label: 'Delta Products on Pallet',
                    align: 'center',
                    field: row => row.delta,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",    
                    sortable: true,                                  
                },

                {
                    name: 'block_pattern',
                    required: true,
                    label: 'Block Pattern',
                    align: 'center',
                    field: row => row.block,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: center",   
                    sortable: true,                                   
                },
                
                
                ]
        
        const data = computed(() => store.state.massAnalysis.results)



        // Draw pallet scheme
        const drawOption = (data) => {
            selectedOption.value = data
            window.console.log("drawing option")
            window.console.log(data.pallet)
            store.commit('dialogs/updateMassAnalysisResultDialog', true)
            

        }

        const closeDialog = () => {
            store.commit('dialogs/updateMassAnalysisResultDialog', false)
        }
        
        
             

        return {
            env,
            columns,
            data,
            drawOption,
            closeDialog,
            exportExcel,
            pagination,     
            selectedOption,
            cardHeight,
            cardWidth
        }
}

}


</script>


