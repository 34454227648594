

<template>
    
    
    <div class="row q-mx-md "> 
        <q-btn @click="$refs['uploadButton'].click()" label="Select a file" outline />
        <input type="file" @change="uploadForPreview" ref="uploadButton" hidden color="blue" /> 
    </div>    
    
    <!-- <div v-if="env === 'dev'">
        <div class="row q-ma-md"> Loaded data:</div>
        <div class="row q-ma-md">{{$store.state.massAnalysis.uploadedData}}</div>
    </div> -->


    <q-dialog v-model="previewDialog">
        <q-card class="q-ma-md" style="min-width:800px">
            <q-card-section>
                <preview-table />
            </q-card-section>
        
            <q-card-section>
                <q-btn class="q-ma-xs" label="upload" @click="upload(); previewDialog=false" outline color="blue" />
                <q-btn class="q-ma-xs" label="close" @click="previewDialog=false" outline color="black" />
            </q-card-section>
            
        </q-card>
    </q-dialog>

</template>


<script lang="ts">
import XLSX from 'xlsx';
import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import previewTable from "@/components/mass_analysis/previewTable.vue"

export default {

    components: {
        "preview-table": previewTable
    },
    
    setup() {
        
        const store = useStore()
        const env = computed(() => {return process.env.VUE_APP_ENV})

        const dataPreview = ref()
        const previewDialog = ref(false)
        
        // Upload excel data
        const uploadForPreview = async (event) =>  {

            window.console.log('Fetching Excel')
            
            //const response = await fetch(section.url);
            //const data = await response.blob();
            const data =  event.target!.files[0]
            window.console.log(data)
         
            const reader = new FileReader();
            reader.onload = function (input) {
                //console.log(event.target!.result); // the XKSX content as string
                const bstr = input.target!.result
                const wb = XLSX.read(bstr, { type: 'binary' });
                window.console.log(wb)
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                
                window.console.log(ws)
                /* Convert array of arrays */
                const dataSheet = XLSX.utils.sheet_to_json(ws, { header: 1 });
                //window.console.log(dataSheet)                
                parseExcel(dataSheet.splice(1, dataSheet.length))
                
            };

            reader.readAsBinaryString(data)
            //reader.readAsArrayBuffer(data)

            // Parse Excel
            function parseExcel(data){
                const dataPrepared = [] as any;
                
                data.forEach((row) => {
                     window.console.log(row)
                        dataPrepared.push(
                        {      
                        article: row[0],
                        total_units_on_pallet: parseInt(row[1]),
                        height: parseInt(row[4]),
                        width: parseInt(row[3]),
                        depth: parseInt(row[2]),
                        units_shipping_box: parseInt(row[5]),                        
                        })
                    });    
                                    
                window.console.log("#################################")
                window.console.log(dataPrepared)
                // window.console.log(rawData.length)
                window.console.log("#################################")  
                
                // Update preview Data
                dataPreview.value = dataPrepared
                previewDialog.value = true

                store.commit("massAnalysis/updatePreviewData", dataPrepared)
              

            }
                        

        }

        const upload = () => {                        
            
            const dataToUpload =  store.state.massAnalysis.previewData
            // Add to store
            store.commit("massAnalysis/updateUploadedData", dataToUpload )
        }

        return { 
            uploadForPreview,           
            upload,
            env,
            previewDialog

            
        }
}

}


</script>


