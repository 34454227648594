<template>
  <div>
    <div class="row q-mb-lg justify-center">
      <div class="text-head-scalize text-center text-dark q-my-md">
        IMPROVE SPEED TO MARKET AND
        <span class="label bg-white text-no-wrap text-red"
          >INCREASE PROJECT REVENUE</span
        >
      </div>
    </div>
    <!-- end of first row -->

    <div
      class="row text-no-wrap q-my-md q-mx-sm justify-evenly items-end text-center"
    >
      <div class="col-12">
        <!-- col-md-4 -->
        <div class="col">
          <q-img
            class="q-my-lg"
            :src="
              require(`@/assets/landing_page/75_REVOLUTION_speed_graphic.svg`)
            "
            id="calendar_image"
            style="max-width: 300px"
          />
        </div>

        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm text-red">
            INCREASE PIPELINE REVENUE > 8%
          </div>
        </div>
      </div>

      <div class="col-12">
        <!-- col-md-3 -->

        <div class="col q-mx-sm">
          <q-img
            class="q-my-md"
            :src="
              require(`@/assets/landing_page/75 RETURNS_pipeline value.svg`)
            "
            id="product_image"
            style="max-width: 370px"
          />
        </div>
      </div>
    </div>

    <div class="row q-mt-xl justify-center">
      <div class="text-head-scalize text-center text-dark q-my-md">
        OPTIMIZE PROJECT INVESTMENTS
        <span class="label text-no-wrap bg-white text-dark">AND </span>
        <span class="label text-no-wrap bg-white text-blue"
          >CAPTURE SAVINGS</span
        >
      </div>
    </div>
    <div
      class="row text-no-wrap q-my-md q-mx-sm justify-evenly items-end text-center"
    >
      <div class="col-12">
        <!-- col-md-4 -->
        <div class="col">
          <q-img
            class="q-my-lg"
            :src="require(`@/assets/landing_page/75 RETURNS_graph.svg`)"
            style="max-width: 280px"
          />
        </div>

        <div class="col" style="">
          <div class="text-body-scalize text-dark q-my-sm q-mx-sm text-blue">
            SAVE UP TO 20% IN TRANSPORT COSTS
          </div>
        </div>
      </div>

      <div class="col-12">
        <!-- col-md-4 -->
        <div class="col">
          <q-img
            class="q-my-lg"
            :src="
              require(`@/assets/landing_page/75 RETURNS_investment value.svg`)
            "
            style="max-width: 425px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "returnsPage",
  components: {},

  data() {
    return {
      position: 0,
      visualPage: true,
    };
  },
};
</script>

<style scoped lang="scss">
h6 {
  margin-bottom: 0px;
}
</style>
