
// https://dev.to/deciduously/reactive-canvas-with-typescript-and-vue-1ne9

<template>
    <q-card flat bordered class="dialog-card">
        <q-card-section>
            <div class="row justify-end">
                <q-btn class="q-ma-xs" outline label="close"
                    @click="$store.commit('dialogs/updatePalletDetails', false)" />
            </div>
        </q-card-section>
        
        <q-card-section class="q-ma-xs text-head-scalize">
            Details on Pallet    
        </q-card-section>

        <q-card-section>
            <three-dim-pallet :details=true :enlarge=true />
        </q-card-section>           
   
    </q-card>
</template>

<script lang="ts">
//import MixinResult from '@/components/mixins/mixinsResult.ts';
import {computed, ref, onMounted} from 'vue'
import { useStore } from 'vuex'
import threeDimPallet from '@/components/visualisations/threeDimPallet.vue';



export default {
    name: 'detailsPallet',
    components: {
        "three-dim-pallet": threeDimPallet
    },
    
    setup() {


        const env = computed(() => {return process.env.VUE_APP_ENV})

        const store = useStore()
                             
            

        return {
            env,                                                                        
            
        }

    }
}

</script>



<style scoped lang="scss">

.dialog-card {    
    
    max-width:30%
    }

</style>