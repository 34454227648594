
import { onMounted, ref, computed } from 'vue';
import report from "@/components/report/Report.vue"



export default {
  name: 'Home',
  components: {
    'report': report

  },

  setup(){


    const env = computed(() => {return process.env.VUE_APP_ENV})

    return {env}
    
}

}
