
// https://dev.to/deciduously/reactive-canvas-with-typescript-and-vue-1ne9

<template>            
    
    <div v-if="env=='dev'" class="row q-ma-xs q-pa-md">                     
   
    </div>

      <svg :height="svgHeight" :width="svgWidth">
      <!-- Back rectangle -->
      <polygon 
          :points="`
          ${productToDraw.bottomBackLeft.x},${productToDraw.bottomBackLeft.y}
          ${productToDraw.topBackLeft.x },${productToDraw.topBackLeft.y}
          ${productToDraw.topBackRight.x},${productToDraw.topBackRight.y}
          ${productToDraw.bottomBackRight.x},${productToDraw.bottomBackRight.y}         
          `"
          :fill="boxColor"
          :fill-opacity="opacity"
          :stroke="strokeColor"
      />

  <!--Right Side -->
    <polygon 
      :points="`
        ${productToDraw.bottomFrontRight.x},${productToDraw.bottomFrontRight.y} 
        ${productToDraw.bottomBackRight.x},${productToDraw.bottomBackRight.y}         
        ${productToDraw.topBackRight.x },${productToDraw.topBackRight.y}
        ${productToDraw.topFrontRight.x},${productToDraw.topFrontRight.y}
      `"
      :fill="boxColor"
      :fill-opacity="opacity"
      :stroke="strokeColor"
      /> 
      
    <!--Left Side -->
    <polygon 
      :points="`
        ${productToDraw.bottomFrontLeft.x},${productToDraw.bottomFrontLeft.y} 
        ${productToDraw.bottomBackLeft.x},${productToDraw.bottomBackLeft.y}         
        ${productToDraw.topBackLeft.x },${productToDraw.topBackLeft.y}
        ${productToDraw.topFrontLeft.x},${productToDraw.topFrontLeft.y}
      `"
      :fill="boxColor"
      :fill-opacity="opacity"
      :stroke="strokeColor"
      /> 
      
  <!-- Bottom (actually even if says top in coordinates)-->
    <polygon 
      :points="`
        ${productToDraw.topBackLeft.x},${productToDraw.topBackLeft.y}      
        ${productToDraw.topBackRight.x},${productToDraw.topBackRight.y}      
        ${productToDraw.topFrontRight.x},${productToDraw.topFrontRight.y}     
        ${productToDraw.topFrontLeft.x},${productToDraw.topFrontLeft.y}     
        `"
      :fill="boxColor"
      :fill-opacity="opacity"
      :stroke="strokeColor"
      />

      <!-- Top (actually top even if it says bottom in coordinates)-->
      <polygon 
        :points="`
          ${productToDraw.bottomBackLeft.x},${productToDraw.bottomBackLeft.y}      
          ${productToDraw.bottomBackRight.x},${productToDraw.bottomBackRight.y}      
          ${productToDraw.bottomFrontRight.x},${productToDraw.bottomFrontRight.y}       
          ${productToDraw.bottomFrontLeft.x},${productToDraw.bottomFrontLeft.y}     
        `"
        :fill="boxColor"
        :fill-opacity="opacity"
        :stroke="strokeColor"
        />
      
      <!-- Front rectangle -->
      <polygon 
        :points="`
          ${productToDraw.bottomFrontLeft.x},${productToDraw.bottomFrontLeft.y}
          ${productToDraw.topFrontLeft.x },${productToDraw.topFrontLeft.y}
          ${productToDraw.topFrontRight.x},${productToDraw.topFrontRight.y}
          ${productToDraw.bottomFrontRight.x},${productToDraw.bottomFrontRight.y}         
        `"
        :fill="boxColor"
        fill-opacity="0.2"
        :stroke="strokeColor"
        />

    
  <!-- Markers -->
      <defs>
        <marker id="arrowhead" markerWidth="2" markerHeight="3" 
          refX="0" refY="1.5" orient="auto">
          <polygon points="0 0, 2 1.5, 0 3" 
          :fill="strokeColorArrows" />
        </marker>
      </defs>
      
    <!-- Arrow for width -->
    <line 
      :x1="productToDraw.topFrontLeft.x" 
      :y1="productToDraw.topFrontLeft.y * 1.05" 
      :x2="productToDraw.topFrontRight.x" 
      :y2="productToDraw.topFrontLeft.y * 1.05"
      :stroke="strokeColorArrows" 
      stroke-width="2"     
      marker-end="url(#arrowhead)"     
      />

    <text 
        :x="(productToDraw.bottomFrontLeft.x + productToDraw.bottomFrontRight.x)/2 * 0.8" 
        :y="productToDraw.topFrontLeft.y * 1.2"  
        class="svg-text"
        >{{product.width}} cm
      </text>

    <!-- Arrow for Depth -->
    <line 
      :x1="productToDraw.topFrontRight.x + svgWidth * 0.05" 
      :y1="productToDraw.topFrontRight.y" 
      :x2="productToDraw.topBackRight.x + svgWidth * 0.05" 
      :y2="productToDraw.topBackLeft.y"  
      :stroke="strokeColorArrows" 
      stroke-width="2" 
      marker-end="url(#arrowhead)"     
      />

    

    <text 
      :x="productToDraw.topBackRight.x + svgWidth * 0.07" 
      :y="(productToDraw.topFrontRight.y + productToDraw.topBackLeft.y)/2 * 1.1"  
      class="svg-text"
      
      >{{product.depth}} cm
        
      </text>

    <!-- Arrow for Height -->
    <line 
      :x1="productToDraw.bottomFrontLeft.x - svgWidth * 0.03" 
      :x2="productToDraw.bottomFrontLeft.x - svgWidth * 0.03" 
      :y1="productToDraw.topFrontLeft.y"
      :y2="productToDraw.bottomFrontLeft.y" 
      :stroke="strokeColorArrows" 
      stroke-width="2" 
      marker-end="url(#arrowhead)"     
      />

    <text 
        :x="productToDraw.bottomFrontLeft.x * 0.2" 
        :y="(productToDraw.bottomFrontLeft.y + productToDraw.topFrontLeft.y)/2"  
        class="svg-text"   
        >{{product.height}} cm
      </text>
      </svg>
    

    <!--<q-btn label="drawPallet" @click="drawPallet" /> -->
    
</template>

<script lang="ts">
import {computed, watch, ref, onMounted} from 'vue'

import {Point, Box} from '@/types/box.ts'



export default {
    name: 'resultProduct',
    'props': {
      
      product: {
        type: Object,
        required: true
      },
      svgWidth: {
        type: Number,
        required: true
      },
      svgHeight: {
        type: Number,
        required: true
      }
    },

    
          
    setup(props) {
        const env = computed(() => {return process.env.VUE_APP_ENV})
      
        const scaling = computed(() => {
          return Math.min(props.svgWidth/props.product.width, props.svgHeight/(props.product.height + props.product.depth)) * 0.5
          })
        
        const spaceLeft = computed(() => {return props.svgWidth * 0.3 })
        const spaceTop = computed(() => {return props.svgHeight * 0.2 })
            
        const spaceBottom = computed(() => {
          return props.svgHeight - spaceTop.value - props.product.height * scaling.value
          }) 
        
                      
        const productToDraw = ref(new Box(new Point(0, 0, 0), 10, 20, 18))                
        
        
        const boxColor = "white"
        const strokeColor = "blue"
        const strokeColorArrows = "grey"
        const opacity = 1


        const box = new Box(
          new Point(spaceLeft.value, spaceTop.value, 0), 
          props.product.width * scaling.value, 
          props.product.depth * scaling.value, 
          props.product.height * scaling.value
          )                
        box.cabinetProjection(62)

        productToDraw.value = box              

         onMounted(() => {//     // Check for no selected Option            
            window.console.log('selected option changed')  
                          
            
          })
                    

        return {
            env, 
            
            productToDraw,
            
            strokeColorArrows,

            scaling,
            boxColor,
            strokeColor,
            opacity,
                        
            spaceLeft,
            spaceTop,
            spaceBottom
            
            
        }

    },


  


    
               
/* --------------------- Mounted ------------------------------------ */
    // mounted: function() {     
        
        //cleanCanvas("canvas_product");
        //this.draw_product();
        
    // } // END OF MOUNTED
}
</script>



<style scoped>
.small { font: 0.7em avenir; }
</style>