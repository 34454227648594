import { render } from "./Result.vue?vue&type=template&id=6f20ce60&scoped=true"
import script from "./Result.vue?vue&type=script&lang=ts"
export * from "./Result.vue?vue&type=script&lang=ts"

import "./Result.vue?vue&type=style&index=0&id=6f20ce60&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-6f20ce60"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QDialog,QPageSticky,QBtn,QLinearProgress,QTooltip,QImg});
