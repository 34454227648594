

import XLSX from 'xlsx';
import { saveAs } from 'file-saver';


export default function() {
   

    const exportExcel = (inputData) => {

      const wb = XLSX.utils.book_new();
      wb.Props = {
          Title: "Scalize Result Overview",
          Subject: "Test",
          Author: "Scalize.app",
          CreatedDate: new Date(2021,12,19)
      };


      // Set sheetname
      wb.SheetNames.push("results");

      // Transform array of dict two array
      const data = inputData.map(row => Object.values(row))

      // const header = [this.visibleColumns]
      const header = inputData.map(row => Object.keys(row))
      const header_row = [header[0]]


      // Set Data
      //const ws_data = [['hello' , 'world']];  //a row with 2 columns

      // Concatenate header and data
      const ws_data = header_row.concat(data);  // export headers

      const ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets["results"] = ws;
      const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

          function s2ab(s) { 
              const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
              const view = new Uint8Array(buf);  //create uint8array as viewer
              for (let i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
              return buf;    
      }

      //saveAs("https://httpbin.org/image", "image.jpg");
      saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'summary.xlsx');
      

        //new Blob([s2ab(wbout)],{type:"application/octet-stream"}), 'test.xlsx';

      } // End of function
      return {
        exportExcel
      }

}