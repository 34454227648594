
import { useStore } from 'vuex'
import { computed } from "vue";
import userData from '@/components/mixins/userData.js';

export default function() {
   
    const store = useStore()

    const env = computed(() => process.env.VUE_APP_ENV) 
    
    const {getUserData} = userData()
   

    //* --- Function to get user Data
    const getProjectData = async () => {

      window.console.log('getting project data')
                  
      
      const url = process.env.VUE_APP_FUNCTION_URI + "queryCosmos"
            
      const requestOptions = {
        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",          
          actionType: 'allProjectsDetails'
          })
      };
      const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "queryCosmos" , requestOptions).catch((error) => {
              window.console.log(error)              
              
              store.commit('dialogs/updateMessage', 'Error querying API: your projects could ne be loaded.')
              store.commit('dialogs/updateDialog', true)           
                          
        }
                    
        );

      // placeholder for data
      let responseJson;
      

      if (response.status == 200) {
        window.console.log("-----------------")
        
        responseJson = await response.json();
        window.console.log(responseJson.data)
      
        // Updating user data in store
        store.commit('projects/updateProjects', responseJson.data)        

        //! Need to update this
        //store.commit('user/updateUserProjects', )
        //store.state.user.userProjects 
        await getUserData()
     
      
      }else{
        
        store.commit('dialogs/updateMessage', 'Error loading user data')
        store.commit('dialogs/updateDialog', true)                              
        
      }


    } // End of function to get user Data

    //
    return {
      getProjectData
    }
  }
          
  