


//import draggableDiv from '@/components/report/draggableDiv.vue'; // @ is an alias to /src


export default {
  name: 'Home',
  components: {
    //'draggable-div': draggableDiv
  },
  props: {
    height: {
      type: Number,
      required: true
    },
    width: {
      type: Number,
      required: true   
    },
    pallet: {
      type: Array,
      required: true
    }
    
    },

    setup(props) {
      const scaling = Math.min(props.width/props.pallet.width, props.height/props.pallet.depth) * 0.5

      const startLeft = props.width * 0.3
      const startBottom = props.height * 0.3
      const strokeColorArrows = "grey"
      

      return {
        scaling,
        startLeft,
        startBottom,
        strokeColorArrows
      }
    }

  
};
