import { render } from "./optimize.vue?vue&type=template&id=a2b6cd10"
import script from "./optimize.vue?vue&type=script&lang=ts"
export * from "./optimize.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLinearProgress,QBtn});
