import { render } from "./decisionsTable.vue?vue&type=template&id=5fe1ee90&scoped=true"
import script from "./decisionsTable.vue?vue&type=script&lang=ts"
export * from "./decisionsTable.vue?vue&type=script&lang=ts"

import "./decisionsTable.vue?vue&type=style&index=0&id=5fe1ee90&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-5fe1ee90"

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTr,QTh,QToggle,QTd,QCheckbox,QBtn,QPopupEdit,QInput,QSelect});
