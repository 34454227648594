
import {ref, computed, onMounted} from 'vue'
import drawPalletFunctions from '@/components/mixins/drawPallet.ts'


export default {
    name: 'palletScheme2D',

    props: {
        data: {
            type: Array,
            required: true
        }
    },

    setup(props) {

        const env = computed(() => {return process.env.VUE_APP_ENV})
        const {drawPallet} = drawPalletFunctions()
        const canvasHeight = computed(() => {return window.innerHeight/3})
        const canvasWidth = computed(() => {return window.innerWidth/4})
        
        //const canvas_2dpallet = ref<HTMLCanvasElement>()
        const mass_analysis_2d_pallet = ref<HTMLCanvasElement | null>(null)


        const columns = [ {
                    name: 'article',
                    required: true,
                    label: 'Article',
                    align: 'left',
                    field: row => row.article,
                    format: val => `${val}`,
                    classes: 'bg-white ellipsis',
                    style: "color: blue; text-align: left",                                      
                },
                // {
                //     name: 'Height',
                //     required: true,
                //     label: 'Height',
                //     align: 'center',
                //     field: row => row.article_height,
                //     format: val => `${val}`,
                //     classes: 'bg-white ellipsis',
                //     style: "color: blue; text-align: center",                                      
                // },
                // {
                //     name: 'Depth',
                //     required: true,
                //     label: 'Depth',
                //     align: 'center',
                //     field: row => row.article_depth,
                //     format: val => `${val}`,
                //     classes: 'bg-white ellipsis',
                //     style: "color: blue; text-align: center",                                      
                // },
            ]
        

        // const {data} = toRefs(props)
        
        // // Draw pallet scheme
        // const drawOption = (data) => {            
        //     drawPallet(canvas, data)
            
        // }


        onMounted(() => {
            const canvas  = mass_analysis_2d_pallet.value as HTMLCanvasElement
            drawPallet(canvas, props.data)
        })
                

        return {                  
            env,                             
            canvasHeight,
            canvasWidth,
            mass_analysis_2d_pallet,
            columns
            
            
        }
}

}


