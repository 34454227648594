<template>
 <div class="col gt-sm text-center">
              <q-img
                class="q-my-md"
                :src="require(`@/assets/landing_page/logo.svg`)"
                style="max-width: 320px"
              />              
  </div>
   <div class="col lt-md text-center">
              <q-img
                class="q-my-md"
                :src="require(`@/assets/landing_page/logo.svg`)"
                style="max-width: 280px"
              />
  </div>

  <div class="row q-ma-md text-head-scalize justify-center text-dark">
    TEST CALCULATION
  </div>
  <div class="row q-ma-md text-head-scalize justify-center">
    <q-img :src = "require(`@/assets/test_scalize_page/blue_box_dimensions.svg`)" id="product_image" style="max-width:120px" /> 
  </div>
  <div class="row q-ma-md text-head-scalize justify-center text-blue">
    PRODUCT DIMENSIONS
  </div>

  <div class="row q-ma-xs text-body-scalize justify-center items-center" style="color:#204fffE6">
    <div class="col-auto q-mx-xs">
      HEIGHT
    </div>
    <div class="col-auto q-mx-xs">
      <q-input 
        :input-style="{
          'color': '#262626e6', 'font-size': '1.1em', 
          'text-align': 'center',
          // 'border': 'solid' ,
          // 'border-width': '1px' 
        }"        
        :style="{'max-width': '40px'}"        
        dense
        
        v-model="height"
        />
    </div>
    <div class="col-auto q-mx-xs">
      CM
    </div>
  </div>
  
  <div class="row q-ma-xs text-body-scalize justify-center items-center" style="color:#204fffE6">
    <div class="col-auto q-mx-xs">
      WIDTH&nbsp;
    </div>
    <div class="col-auto q-mx-xs">      
      <q-input 
        :input-style="{
          'color': '#262626e6', 'font-size': '1.1em',
          'text-align': 'center',
          // 'border': 'solid',
          // 'border-width': '1px' 
          }"  
        :style="{'max-width': '40px'}"
        dense
        
        v-model="width"
        />              
    </div>
    <div class="col-auto q-mx-xs">
      CM
    </div>
  </div>

  <div class="row q-ma-xs text-body-scalize justify-center items-center" style="color:#204fffE6">
    <div class="col-auto q-mx-xs">
      DEPTH&nbsp;
    </div>
    <div class="col-auto q-mx-xs">      
      <q-input
        :input-style="{
          'color': '#262626e6', 'font-size': '1.1em', 'text-align': 'center',
        //  'border': 'solid' ,
        //  'border-width': '1px'
         }" 
        :style="{'max-width': '40px'}"
        dense        
        v-model="depth"
        />        
        
    </div>
    <div class="col-auto q-mx-xs">  
      CM
    </div>
  </div>


  <div class="row q-mt-md text-body-scalize justify-center items-center text-blue">    
      UNITS PER SHIPPING BOX      
  </div>
  <div class="row text-body-scalize justify-center items-center" style="color:black">  
    <q-input
      :input-style="{
        color: '#262626e6', 'font-size': '1.1em', 'text-align': 'center',
         //'border': 'solid' ,
         //'border-width': '1px' 
         }" 
      :style="{'max-width': '40px'}"
      dense
      v-model="unitsPerBox"
      />        

  </div>

<!-- Trigger Button -->
  <div class="row q-mt-md q-mx-md text-body-scalize justify-center items-center text-red" :style="{'height': '5em'}">  
    <div class="row q-mx-md justify-center items-center text-center"
       v-if="calculating_sensitivity||calculating_shipper">{{calculatingText}}
    </div>      
    <div v-else>
      <q-btn label="CALCULATE" class="q-mx-xs" color="red" @click="checkProduct();animateText()" /> 
    </div>
    <!-- <q-btn label="Check your Design" class="q-ma-xs" color="red" @click="getSensitivity()" outline />     -->
  </div>

  



 <!-- Results -->
 <div class="row q-mt-lg text-head-scalize justify-center items-center text-dark" >    
      POTENTIAL UNITS PER PALLET
  </div>

  <div class="row q-mt-lg text-body-scalize justify-center" style="color:#00c800e6"> 
    <div class="col-auto q-ma-xs items-start">      
      <div class="row text-dark">PALLET</div>
      <div class="row text-dark">HEIGHT</div>
      <div class="row q-mt-xs text-dark text-body-scalize-table">CCG 1
           <q-tooltip>
          <div class="row text-body-scalize">CCG 1: </div>
            <div class="row text-body-scalize">Width: 120cm</div>
            <div class="row text-body-scalize">Depth: 80cm</div>
            <div class="row text-body-scalize">Height: 105cm</div>
            <div class="row text-body-scalize">(incl. Base: 15cm)</div>
        </q-tooltip>
      </div>
      
      <div class="row text-dark text-body-scalize-table">EUL 1        
        <q-tooltip>
          <div class="row text-body-scalize">EUL 1: </div>
            <div class="row text-body-scalize">Width: 120cm</div>
            <div class="row text-body-scalize">Depth: 80cm</div>
            <div class="row text-body-scalize">Height: 120cm</div>
            <div class="row text-body-scalize">(incl. Base: 15cm)</div>
        </q-tooltip>
      </div>

      <div class="row text-dark  text-body-scalize-table">CCG 2
          <q-tooltip>
          <div class="row text-body-scalize">CCG 2: </div>
            <div class="row text-body-scalize">Width: 120cm</div>
            <div class="row text-body-scalize">Depth: 80cm</div>
            <div class="row text-body-scalize">Height: 195cm</div>
            <div class="row text-body-scalize">(incl. Base: 15cm)</div>
        </q-tooltip>
      </div>
      
      <div class="row text-dark  text-body-scalize-table">EUL 2
         <q-tooltip>
          <div class="row text-body-scalize">EUL 2: </div>
            <div class="row text-body-scalize">Width: 120cm</div>
            <div class="row text-body-scalize">Depth: 80cm</div>
            <div class="row text-body-scalize">Height: 240cm</div>
            <div class="row text-body-scalize">(incl. Base: 15cm)</div>
        </q-tooltip>
      </div>
    </div>

    <div class="col-auto q-ma-xs items-center">
      <div class="row justify-center text-blue">OPTIMIZED</div>
      <div class="row justify-center text-blue">SHIPPER</div>
      
      
      <div class="row q-my-md justify-center items-center" v-if="calculating_shipper" />
      
      <div class="row justify-center items-center" v-if="calculating_shipper">
        <q-spinner-bars size="xl" class="text-blue"/>
      </div>
      
      <div v-else>
        <div class="row q-mt-xs justify-center text-dark text-body-scalize-table" >{{units_ccg1_optimized_shipper}}</div>
        <div class="row justify-center text-dark text-body-scalize-table">{{units_eul1_optimized_shipper}}</div>
        <div class="row justify-center text-dark  text-body-scalize-table" >{{units_ccg2_optimized_shipper}}</div>
        <div class="row justify-center text-dark  text-body-scalize-table" >{{units_eul2_optimized_shipper}}</div>
      </div>
    </div>

    <div class="col-auto q-ma-xs items-right" v-if="optimizedShipper">
    
    <div class="row justify-center text-green">OPTIMIZED
              <q-tooltip class="text-boday-scalize-small bg-white text-blue text-center" v-model="optimizeDesignTooltip" 
                   anchor="top middle" self="bottom middle" :offset="[14, 30]"> 
                   <div class="row">CLICK TO OPTIMIZE DESIGN</div>
                    <q-icon name="keyboard_arrow_down" size="md"/>
                    </q-tooltip>
    </div>
    <div class="row justify-center text-green">DESIGN</div>
                                    
          <div class="row q-mt-xs justify-center text-dark text-body-scalize-table" v-if="ccg1_design_optimized!==undefined">{{ccg1_design_optimized.total_units}}</div>
          <div v-else class="row q-mt-xs justify-center items-center text-body-scalize-table">
                <q-spinner-bars v-if="calculating_sensitivity_ccg1" size="1.4em" class="text-green"/>   
                <div v-else class="row q-mt-xs justify-center items-center">                                           
                  <q-btn size="xs" outline color="red" label="calc" @click="getSensitivitySingle('ccg1')" />
                </div>         
          </div>
          
          <div class="row justify-center text-dark text-body-scalize-table" v-if="eul1_design_optimized!==undefined">{{eul1_design_optimized.total_units}}</div>
          <div v-else class="row q-mt-xs justify-center items-center text-body-scalize-table">              
                <q-spinner-bars v-if="calculating_sensitivity_eul1" size="1.4em" class="text-green"/>
                 <div v-else class="row q-mt-xs justify-center items-center  text-body-scalize-table">
                  <q-btn size="xs" outline color="red" label="calc" @click="getSensitivitySingle('eul1')" />
                </div>            
          </div>
          
          <div class="row justify-center text-dark text-body-scalize-table" v-if="ccg2_design_optimized!==undefined">{{ccg2_design_optimized.total_units}}</div>
          <div v-else class="row q-mt-xs justify-center items-center text-body-scalize-table">              
                <q-spinner-bars v-if="calculating_sensitivity_ccg2" size="1.4em" class="text-green"/>  
                 <div v-else class="row q-mt-xs justify-center items-center  text-body-scalize-table">              
                  <q-btn  size="xs" outline color="red" label="calc" @click="getSensitivitySingle('ccg2')" />
                </div>            
          </div>

          <div class="row justify-center text-dark text-body-scalize-table" v-if="eul2_design_optimized!==undefined">{{eul2_design_optimized.total_units}}</div>
          <div v-else class="row q-mt-xs justify-center items-center text-body-scalize-table">              
                <q-spinner-bars v-if="calculating_sensitivity_eul2" size="1.4em" class="text-green"/>            
                  <div v-else class="row q-mt-xs justify-center items-center  text-body-scalize-table">              
                    <q-btn  size="xs" outline color="red" label="calc" @click="getSensitivitySingle('eul2')" />
                </div>
          </div>

        
      
    </div>

  </div>

  <div class="row q-ma-md text-body-scalize justify-center items-center text-center">
          
  <a href="https://calendly.com/scalize-calendar/60min" target="_blank" :style="{'color': '#FF4f20E6'}">MEET US</a> &nbsp; 
  TO SEE A DEMO & ALL RESULTS

  </div>

  <div class="row dev" v-if="env==='dev'">

  <div class="row col-12">
    <q-btn label="test queue" @click="testQueue()" />
  </div>
    <div class="row col-12 q-ma-xs text-head-scalize text-blue">Shipper optimized</div>

    <div v-if="ccg1_shipper_optimized!==undefined" >    

    <div class="row q-ma-xs text-body-scalize text-blue">
        Product : {{ccg1_shipper_optimized.data.product}}
      </div>
      
      <div class="row q-ma-xs">
        Best Shipper CCG 1: {{ccg1_shipper_optimized.data.shipping_box}}
      </div>
      
      <div class="row q-ma-xs">
        Best Shipper CCG 1 Block pattern: {{ccg1_shipper_optimized.data.pallet.block}}
      </div>
    </div>


    <div v-if="ccg2_shipper_optimized!==undefined" >    
      <div class="row q-ma-xs">
        Best Shipper CCG 2: {{ccg2_shipper_optimized.data.shipping_box}}
      </div>
      <div class="row q-ma-xs">
        Best Shipper CCG 2 Block pattern: {{ccg2_shipper_optimized.data.pallet.block}}
      </div>
    </div>


    <div v-if="eul1_shipper_optimized!==undefined" >
      <div class="row q-ma-xs">
        Best Shipper EUL 1: {{eul1_shipper_optimized.data.shipping_box}}
      </div>
      <div class="row q-ma-xs">
        Best Shipper EUL 1 Block pattern: {{eul1_shipper_optimized.data.pallet.block}}
      </div>
    </div>
    
    <div v-if="eul2_shipper_optimized!==undefined" >
      <div class="row q-ma-xs">
        Best Shipper EUL 2: {{eul2_shipper_optimized.data.shipping_box}}
      </div>
      <div class="row q-ma-xs">
        Best Shipper EUL 2 Block pattern: {{eul2_shipper_optimized.data.pallet.block}}
      </div>
    </div>

  <!-- Product design optimized -->
    <div class="row col-12 q-ma-xs text-head-scalize text-blue">Product Design optimized</div>
    
    <div v-if="ccg1_design_optimized!==undefined" >
      <div class="row">
        Best Desing CCG 1: {{ccg1_design_optimized}}
      </div>
    </div>
    
    <div v-if="ccg2_design_optimized!==undefined" >
      <div class="row">
        Best Design CCG 2: {{ccg2_design_optimized}}
      </div>
    </div>
    
    <div v-if="eul1_design_optimized!==undefined" >
      <div class="row">
        Best Design EUL 1: {{eul1_design_optimized}}
      </div>
    </div>

    <div v-if="eul2_design_optimized!==undefined" >
      <div class="row">
        Best Design EUL 2: {{eul2_design_optimized}}
      </div>
    </div>

    <div class="row col-12 q-ma-xs text-head-scalize text-blue"> Logs Check Product: </div> 
    <div class="row q-ma-xs">
      {{logsCheckProduct}}
    </div>


    <div class="row col-12 q-ma-xs text-head-scalize text-blue"> Logs Sensitivity: </div> 
    <div class="row q-ma-xs text-body-scalize"> CCG 1 </div>      
    <div class="row q-ma-xs">
      {{ ccg1_design_optimized_logs }}
    </div>

    <div class="row q-ma-xs text-body-scalize"> CCG 2 </div>      
    <div class="row q-ma-xs">
      {{ ccg2_design_optimized_logs }}
    </div>

    <div class="row q-ma-xs text-body-scalize"> EUL 1 </div>      
    <div class="row q-ma-xs">
      {{ eul1_design_optimized_logs }}
    </div>

    <div class="row q-ma-xs text-body-scalize"> EUL 2 </div>      
    <div class="row q-ma-xs">
      {{ eul2_design_optimized_logs }}
    </div>

    
    
  </div> <!-- End of dev-->



</template>


<script lang="ts">


import {ref, computed, onMounted} from 'vue'
import type { Ref } from 'vue'
import { useStore } from 'vuex'



interface Product{
    id: string;
    image: string;
    parameters: {
      height: {
        values: {
          Target: {value: number; format: string;};
          Min: {value: number; format: string;};
          Max: {value: number; format: string;};
        };
      name: string;
      comment: string;
      };
      
      width:  {
        values: {
          Target: {value: number; format: string;};
          Min: {value: number; format: string;};
          Max: {value: number; format: string;};
          };
        name: string; 
        comment: string;
        };
      
      depth:  {
        values: {
          Target: {value: number; format: string;};
          Min: {value: number; format: string;};
          Max: {value: number; format: string;};
          };
        name: string;
        comment: string;
        };
                    
      
    };

    calculationParameters: {
      wEqualsD: boolean;
    };

    volumeParameters: {

      brimfulVolume: {
        values: {
          target: {name: string; value: number; format: string;},
          headspace: {name: string; value: number; format: string;};              
        };
        name: string; comment: string;
      };
      
      availableVolume: {
        values: {              
          fillingHead: {name: string; value: number; format: string;};              
        },
        name: string; comment: string;
      };

      maxFillVolume: {
        values: {              
          overfill: {name: string; value: number; format: string;}              
        };
        name: string; comment: string;
      };
      
      maxDeclaredVolume: {
        values: {              
        };
        name: string; comment: string;
      };

      contentWeight: {
        values: {
          density: {name: string; value: number; format: string;};          
        };
        name: string; comment: string;
      };
    };

    componentsParameters: {
      packagingStandard: {
        name: string; format: string; container: string; closure: string; brand: string;
      };
      containerMaterials: [];
      closureMaterials: [];
      decorationMaterials: [];
      packagingComposition: {
        recyclability: number;
        reused: number;
        compostable: string; // %            
        circularityScore: number;
      };
      
    }
}



// result object

interface result{
  data: {
    shippingBox: {width: number};
    pallet: {block: number};
  }
}



export default {
  setup() {
    
    const env = computed(() => {return process.env.VUE_APP_ENV})
    const store = useStore()


    const optimizedShipper = ref(false)
    const optimizeDesignTooltip = ref(false)

    const calculating_sensitivity = ref(false)
    const calculating_sensitivity_ccg1 = ref(false)
    const calculating_sensitivity_ccg2 = ref(false)
    const calculating_sensitivity_eul1 = ref(false)
    const calculating_sensitivity_eul2 = ref(false)
    const calculating_shipper = ref(false)
    const calculatingText = ref("")

    const myInterval = ref()

    const logsCheckProduct = ref()
    const ccg1_design_optimized_logs = ref()
    const ccg2_design_optimized_logs = ref()
    const eul1_design_optimized_logs = ref()
    const eul2_design_optimized_logs = ref()

    const height = ref(17.0)
    const width = ref(7.3)
    const depth = ref(3.6)
    const unitsPerBox = ref(12)

  
    const units_ccg1_optimized_shipper= ref()
    const units_ccg2_optimized_shipper = ref()
    const units_eul1_optimized_shipper = ref()
    const units_eul2_optimized_shipper = ref()

    const ccg1_shipper_optimized:  Ref<result | undefined> = ref()
    const ccg2_shipper_optimized:  Ref<result | undefined> = ref()
    const eul1_shipper_optimized:  Ref<result | undefined> = ref()
    const eul2_shipper_optimized:  Ref<result | undefined> = ref()
    

    const ccg1_design_optimized:  Ref<result | undefined> = ref()
    const ccg2_design_optimized:  Ref<result | undefined> = ref()
    const eul1_design_optimized:  Ref<result | undefined> = ref()
    const eul2_design_optimized:  Ref<result | undefined> = ref()



    const resultsSensitivity = ref({})



// Calculate sensitivity for one value
const getSensitivitySingle = async (id: string) => {

      window.console.log("Product Sensitivity v0.1")
      window.console.log(`calling sensitivity single ID: ${id}`)

      if(id === "ccg1"){       
        calculating_sensitivity_ccg1.value = true
      }else if(id === "ccg2"){        
        calculating_sensitivity_ccg2.value = true
      }else if(id === "eul1"){        
        calculating_sensitivity_eul1.value = true
      }else if(id === "eul2"){        
        calculating_sensitivity_eul2.value = true
      }

      calculating_sensitivity.value = true
      
      
      const tolerance_ml = 15.0

      // Double check that this is in centimeter
      const tolerance_dimension = 0.3

      // Init product
      const product= ref(store.state.testScalize.product as Product)

      // Replace values
      product.value.parameters.depth.values.Target.value = depth.value as number
      product.value.parameters.width.values.Target.value = width.value as number
      product.value.parameters.height.values.Target.value = height.value as number
            
      product.value.parameters.depth.values.Min.value = +depth.value - tolerance_dimension
      product.value.parameters.width.values.Min.value = +width.value - tolerance_dimension
      product.value.parameters.height.values.Min.value = +height.value - tolerance_dimension

      product.value.parameters.depth.values.Max.value = +depth.value + tolerance_dimension
      product.value.parameters.width.values.Max.value = +width.value + tolerance_dimension
      product.value.parameters.height.values.Max.value = +height.value + tolerance_dimension

      window.console.log(`Type: ${typeof(product.value.parameters.height.values.Max.value)}`)
      window.console.log(`Product Height Target: ${product.value.parameters.height.values.Target.value}`)
      window.console.log(`Product Height Max: ${product.value.parameters.height.values.Max.value}`)
  
      // shipping Box
      const shippingBox = store.state.testScalize.shippingBox
      shippingBox.parameters.minNumberProducts.value = unitsPerBox.value
      shippingBox.parameters.maxNumberProducts.value = unitsPerBox.value


       // Register request
      registerRequest(`check_sensitivity`);

      
      // Get result
      const result = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize[`pallet_${id}`],
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json()).catch(error => {
        window.console.log(error)
        if(id === "ccg1"){        
          calculating_sensitivity_ccg1.value = false
        }else if(id === "ccg2"){        
          calculating_sensitivity_ccg2.value = false
        }else if(id === "eul1"){        
          calculating_sensitivity_eul1.value = false
        }else if(id === "eul2"){        
          calculating_sensitivity_eul2.value = false
        }
          calculating_sensitivity.value = false
      })

      if(id === "ccg1"){
        ccg1_design_optimized.value = result.data
        calculating_sensitivity_ccg1.value = false
      }else if(id === "ccg2"){
        ccg2_design_optimized.value = result.data
        calculating_sensitivity_ccg2.value = false
      }else if(id === "eul1"){
        eul1_design_optimized.value = result.data
        calculating_sensitivity_eul1.value = false
      }else if(id === "eul2"){
        eul2_design_optimized.value = result.data
        calculating_sensitivity_eul2.value = false
      }

      window.console.log(`Result: ${result.data}`)

    calculating_sensitivity.value = false
            
}


const getSensitivity = async () => {

      window.console.log("calling sensitivity SEQUENTIALLY")
      
      // Activate spinners
      calculating_sensitivity.value = true      
      
      const tolerance_ml = 10.0

      // Double check that this is in centimeter
      const tolerance_dimension = 0.5

      // Init product
      const product= ref(store.state.testScalize.product as Product)

      // Replace values
      product.value.parameters.depth.values.Target.value = depth.value as number
      product.value.parameters.width.values.Target.value = width.value as number
      product.value.parameters.height.values.Target.value = height.value as number
            
      product.value.parameters.depth.values.Min.value = +depth.value - tolerance_dimension
      product.value.parameters.width.values.Min.value = +width.value - tolerance_dimension
      product.value.parameters.height.values.Min.value = +height.value - tolerance_dimension

      product.value.parameters.depth.values.Max.value = +depth.value + tolerance_dimension
      product.value.parameters.width.values.Max.value = +width.value + tolerance_dimension
      product.value.parameters.height.values.Max.value = +height.value + tolerance_dimension

      window.console.log(`Type: ${typeof(product.value.parameters.height.values.Max.value)}`)
      window.console.log(`Product Height Target: ${product.value.parameters.height.values.Target.value}`)
      window.console.log(`Product Height Max: ${product.value.parameters.height.values.Max.value}`)
  
      // shipping Box
      const shippingBox = store.state.testScalize.shippingBox
      shippingBox.parameters.minNumberProducts.value = unitsPerBox.value
      shippingBox.parameters.maxNumberProducts.value = unitsPerBox.value

      
      // CCG 1
      const result_ccg1 = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_ccg1,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json())


      ccg1_design_optimized.value = result_ccg1.data

      window.console.log(result_ccg1.logs)


      window.console.log(ccg1_design_optimized.value)

      // EUL1 2
      const result_eul1 = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_eul1,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json())

      eul1_design_optimized.value = result_eul1.data

      window.console.log(eul1_design_optimized.value)


       // CCG 2
      const result_ccg2 = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_ccg2,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json())

      ccg2_design_optimized.value = result_ccg2.data

      window.console.log(ccg2_design_optimized.value)

       // EUL 2
      const result_eul2 = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_eul2,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json())

      eul2_design_optimized.value = result_eul2.data

      window.console.log(eul2_design_optimized.value)


      calculating_sensitivity.value = false      





      

      // fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({ 
      //     title: "Request",
      //     auth: '',
      //     pallet: store.state.testScalize.pallet_ccg2,
      //     product: product.value,
      //     shippingBox: shippingBox,          
      //     secondPallet: {status: false},    
      //     toleranceML: tolerance_ml,
      //     type: 'check_sensitivity',
      //     parameters: store.state.testScalize.calculationParameters
      //     })
      // }).then(res => res.json()),



}
  //* ------  check Sensitivity paraellel ------------------------ *//
    const getSensitivityParallel = async () => {

      window.console.log("calling sensitivity PARALLEL")
      
      // Activate spinners
      calculating_sensitivity.value = true      
      
      const tolerance_ml = 10.0

      // Double check that this is in centimeter
      const tolerance_dimension = 0.5

      // Init product
      const product= ref(store.state.testScalize.product as Product)

      // Replace values
      product.value.parameters.depth.values.Target.value = depth.value as number
      product.value.parameters.width.values.Target.value = width.value as number
      product.value.parameters.height.values.Target.value = height.value as number
            
      product.value.parameters.depth.values.Min.value = +depth.value - tolerance_dimension
      product.value.parameters.width.values.Min.value = +width.value - tolerance_dimension
      product.value.parameters.height.values.Min.value = +height.value - tolerance_dimension

      product.value.parameters.depth.values.Max.value = +depth.value + tolerance_dimension
      product.value.parameters.width.values.Max.value = +width.value + tolerance_dimension
      product.value.parameters.height.values.Max.value = +height.value + tolerance_dimension

      window.console.log(`Type: ${typeof(product.value.parameters.height.values.Max.value)}`)
      window.console.log(`Product Height Target: ${product.value.parameters.height.values.Target.value}`)
      window.console.log(`Product Height Max: ${product.value.parameters.height.values.Max.value}`)
  
      // shipping Box
      const shippingBox = store.state.testScalize.shippingBox
      shippingBox.parameters.minNumberProducts.value = unitsPerBox.value
      shippingBox.parameters.maxNumberProducts.value = unitsPerBox.value
      
      


    const [result_ccg1, result_ccg2, result_eul1, result_eul2] = await Promise.allSettled([
      
      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" ,  {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_ccg1,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json()),

      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_ccg2,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json()),

      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_eul1,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json()),
        
      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" , {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",
          auth: '',
          pallet: store.state.testScalize.pallet_eul2,
          product: product.value,
          shippingBox: shippingBox,          
          secondPallet: {status: false},    
          toleranceML: tolerance_ml,
          type: 'check_sensitivity',
          parameters: store.state.testScalize.calculationParameters
          })
      }).then(res => res.json()),

    ]    
    );

    
    if (result_ccg1.status === "fulfilled"){      
      ccg1_design_optimized.value = result_ccg1.value.data
      ccg1_design_optimized_logs.value = result_ccg1.value.logs
    }
    if (result_ccg2.status === "fulfilled"){      
      ccg2_design_optimized.value = result_ccg2.value.data
      ccg2_design_optimized_logs.value = result_ccg2.value.logs
    }
    if (result_eul1.status === "fulfilled"){
      eul1_design_optimized.value = result_eul1.value.data
      eul1_design_optimized_logs.value = result_eul1.value.logs
    }
    if (result_eul2.status === "fulfilled"){
      eul2_design_optimized.value = result_eul2.value.data
      eul2_design_optimized_logs.value = result_eul2.value.logs
    }

    calculating_sensitivity.value = false
    window.console.log("finished sensitivity")     


    // Register request
    registerRequest("check_sensitivity");

    // Stop text anmiation
    stopTextAnmiation();

    }
    
  //* -------------------------------- Function to evaluate the product ---------------------
     //* --- Function to get user Data
    const checkProduct = async () => {                   
    window.console.log("calling check product")
    // register request                            
      
      // Activate spinners
      calculating_shipper.value = true


       ccg1_design_optimized.value = undefined
       ccg2_design_optimized.value = undefined
       eul1_design_optimized.value = undefined
       eul2_design_optimized.value = undefined
            
      const requestOptions = {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",          
          type: 'check_product',    
          product: {height: height.value, width: width.value, depth: depth.value},
          unitsPerBox: unitsPerBox.value
          
          })
      };
      const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProduct" , requestOptions).catch((error) => {
              window.console.log(error)                                        
        }
                    
        ) as Response;

      // placeholder for data
      let responseJson;
      

      if (response.status == 200) {
        window.console.log("-----------------")
        
        responseJson = await response.json();
        window.console.log(responseJson)
        
        units_ccg1_optimized_shipper.value = responseJson.data.ccg1_optimized_shipper.total_units
        units_ccg2_optimized_shipper.value = responseJson.data.ccg2_optimized_shipper.total_units
        units_eul1_optimized_shipper.value = responseJson.data.eul1_optimized_shipper.total_units
        units_eul2_optimized_shipper.value = responseJson.data.eul2_optimized_shipper.total_units

        ccg1_shipper_optimized.value = responseJson.data.ccg1_optimized_shipper
        ccg2_shipper_optimized.value = responseJson.data.ccg2_optimized_shipper
        eul1_shipper_optimized.value = responseJson.data.eul1_optimized_shipper
        eul2_shipper_optimized.value = responseJson.data.eul2_optimized_shipper

        // Save logs
        logsCheckProduct.value = responseJson.logs


        window.console.log("finished check product")
        calculating_shipper.value = false
        optimizedShipper.value = true
        optimizeDesignTooltip.value = true

        // Register request
        registerRequest("check_product");

          
           
      }else{        
        window.console.log("an error happened")  
        calculating_shipper.value = false
      }


    } // End of function to check the product



    // Register request 
    const registerRequest = (typeOfCheck:string) => {
     fetch("https://functions-app-marketing.azurewebsites.net/api/registerRequest", 
           {                
            method: "POST",
            headers: { "Content-Type": "application/json" },
          
            body: JSON.stringify({               
              info: 'testProduct',                        
              type: typeOfCheck,
              product: {height: height.value, width: width.value, depth: depth.value},
              unitsPerBox: unitsPerBox.value
              })
              }
            ).catch((error) => {            
          
            window.console.log(error)
      }) 

      
    }
      


    const checkAll = async () =>
    { 
      
      ccg1_design_optimized.value = undefined
      ccg2_design_optimized.value = undefined
      eul1_design_optimized.value = undefined
      eul2_design_optimized.value = undefined
      
      await checkProduct()
      window.console.log("finished getting product")
      await getSensitivity()
            
      
  }
             
      // Testing Function
    const doJob = (x,sec)  => {
      return new Promise(resolve => {
      console.log('Start: ' + x);
        setTimeout(() => {
            console.log('End: ' + x);
          resolve(x);
        }, sec *1000);
      });
    }


    // animate text

    const typeText = () => {       
          const message = "CALCULATING - TEST OPTIMIZATION CAN TAKE A MOMENT..."
          const currentMessage = calculatingText.value

          //window.console.log("running type text")
          //window.console.log(currentMessage)
      
          if (currentMessage.length < message.length){
            calculatingText.value = currentMessage + message[currentMessage.length]
          }else{
            calculatingText.value = "C"
          }
          
    }
    
    const animateText = () => {
       myInterval.value = setInterval(typeText, 150);            
    }

    const stopTextAnmiation = () => {
      clearInterval(myInterval.value)
    }


  //* --------------------------- Mounted --------------------------------------
  const testQueue = async () => {


    const storageUri = "https://jantestfunc.queue.core.windows.net/test-queue/messages"

    fetch(storageUri, 
           {                
            method: "GET",
            headers: { "Authorization": "",
            "x-ms-date":  ""},
          
            // body: JSON.stringify({               
            //   info: 'testProduct',                        
            //   type: 'check_product',
            //   product: {height: height.value, width: width.value, depth: depth.value},
            //   unitsPerBox: unitsPerBox.value
            //   })
              }
            ).catch((error) => {            
          
            window.console.log(error)
      }) 
  }

  //* --------------------------- Mounted --------------------------------------
    onMounted(() => {
      
      // Send warm up requests 
      window.console.log("sending warm up requests")

      registerRequest("loaded_check_product_page");

      // (1) Warm-up Sensitivity
      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProductSensitivity" ,  {        
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",                    
          type: 'warm_up',          
          })
      })

      // (2) Warm-Up Check Product              
      fetch(process.env.VUE_APP_FUNCTION_URI + "checkYourProduct" , {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          title: "Request",          
          type: 'warm_up'                    
          })
      })
                    
        

    })
    
    

    return {
      env,
      width,
      height,
      depth,
      unitsPerBox,

      ccg1_shipper_optimized,
      ccg2_shipper_optimized,
      eul1_shipper_optimized,
      eul2_shipper_optimized,
      
      
      ccg1_design_optimized,
      ccg2_design_optimized,
      eul1_design_optimized,
      eul2_design_optimized,

      units_ccg1_optimized_shipper,
      units_ccg2_optimized_shipper,
      units_eul1_optimized_shipper,
      units_eul2_optimized_shipper,


      checkProduct,
      getSensitivity,
      checkAll,
      registerRequest,
      resultsSensitivity,
      calculating_sensitivity,
      calculating_sensitivity_ccg1,
      calculating_sensitivity_ccg2,
      calculating_sensitivity_eul1,
      calculating_sensitivity_eul2, 
      calculating_shipper,

      logsCheckProduct,
      ccg1_design_optimized_logs,
      ccg2_design_optimized_logs,
      eul1_design_optimized_logs,
      eul2_design_optimized_logs,

      animateText,
      calculatingText,

      testQueue,
      getSensitivitySingle,
      optimizedShipper,
      optimizeDesignTooltip

    }
    
  },
}
</script>



<style scoped lang="scss">

.text-body-scalize-table{
  line-height: 1.9em;
}

</style>
