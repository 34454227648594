

import {ref, computed} from 'vue'
import {useStore} from 'vuex'
import excelUpload from '@/components/mass_analysis/excelUpload.vue'
import uploadTable from '@/components/mass_analysis/uploadTable.vue'
import massAnalysis from '@/components/mixins/massAnalysis'
import resultsTable from '@/components/mass_analysis/resultsTable.vue'


export default {
  name: 'ViewMassAnalysis',
  components: {   
    'excel-upload': excelUpload,
    'upload-table': uploadTable,
    'results-table': resultsTable

  },

  setup() {

    const store = useStore()
    const {startMassAnalysis} = massAnalysis()



    const storePallet = computed(() => {return store.state.massAnalysis.pallet})
    //const storePalletDepth = computed(() => {return store.state.massAnalysis.palletDepth})
    //const storePalletHeight = computed(() => {return store.state.massAnalysis.palletHeight})

    const pallet = ref(storePallet.value)
    //const depth = ref(storePalletDepth.value)
    //const height = ref(storePalletHeight.value)

    const blockPatterns = [
        { label: '1-Block', value: 1 },
        { label: '2-Block', value: 2, color: 'blue' },
        { label: '3-Block', value: 3, color: 'blue' },
        { label: '4-Block', value: 4, color: 'blue' },
        { label: '5-Block', value: 5, color: 'blue' },
      ]
    const selectedBlockPatterns = ref([2, 3, 4])

    
    

    const startAnalysis = () => {
      window.console.log('Starting analysis')
      if (selectedBlockPatterns.value.length === 0){
          store.commit('dialogs/updateMessage','Please select at least one block pattern.')
          store.commit('dialogs/updateDialog', true)

      }else{
        startMassAnalysis(selectedBlockPatterns.value)
      }

    }

    return {
      startAnalysis,
    
      pallet,
      blockPatterns,
      selectedBlockPatterns

    
    }
  }

                        
  };
