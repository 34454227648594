import store from "..";

export default {
    namespaced: true,
    
    /* ------------------- STATE -------------------------------------------------------- */
    state: {

     
      // Selected Options from full analysis
      templatesShippingBox: [],
      
                        
    },
     


    /* ------------------- MUTATIONS -------------------------------------------------------- */
    mutations: {

        // Set selected options Full
        updateTemplatesShippingBox(state, payload: []){
            state.templatesShippingBox = payload
        },          

    },

    actions: {

        // Loading Shipping Box templates
        // async loadShippingBoxTemplates({state, commit}) {

        //     window.console.log('!!!!!!!Loading Shipping Box Templates data!!!!!!!')
    
        //     // Form data for post request to share project
        //     const requestOptions = {
                
        //       method: "POST",
        //       headers: { "Content-Type": "application/json" },
            
        //       body: JSON.stringify({ 
        //         actionType: 'queryShippingBoxTemplates',            
        //         user: state.userData,

                            
        //         })
        //         };
    
                      
        //       const response = await fetch(process.env.VUE_APP_FUNCTION_URI + "queryCosmos" , requestOptions).catch((error) => {
        //         window.console.log(error)       
        //         }) ;
    
                    
        //       const data = await (response as Response).json(); 
    
        //       commit('updateTemplatesShippingBox', data.data)

    
        //     } // End load shipping box templates

    },

    modules: {
    }
  }
  
