<template>
<div class="row col-12" >
  <div class="col-3">
    <q-select class="q-ma-md" label="Select a page" :options="options" v-model="page" />
  </div>
</div>
  <q-page>    
    <div class="row">
      <h5>PAllet 3D</h5>
     <pallet-3d />
    </div>
    <div class="row">
      <projection />
    </div>

     <div class="row">
      <pallet-drawing-order />
    </div>

    <!-- <animated-pallet /> -->
    <animated v-if="page==='animated'"/>
    <shelf v-if="page==='shelf'"/>
    <smart-box />
    <h5>Smart Box 2D </h5>
    <smart-box-2d />
  </q-page>
</template>

<script lang="js">

import {useStore} from 'vuex'
import {onMounted, ref} from 'vue'

import animated from '@/components/testing/animated.vue'
import shelf from '@/components/testing/shelf.vue'
import smartBox from '@/components/testing/smartBox.vue'
import smartBox2D from '@/components/testing/smartBox2D.vue'
import pallet3d from '@/components/report/pallet3dSVG.vue'
import projection from '@/components/testing/projections.vue'
import palletDrawingOrder from '@/components/testing/pallet_drawing_order.vue'
//import animatedPallet from '@/components/testing/3dPallet.vue'

export default {
  name: 'Testing',
  components: {
    animated: animated,
    shelf: shelf,
    'smart-box': smartBox,
    'smart-box-2d': smartBox2D,
    'pallet-3d': pallet3d,
    projection,
    'pallet-drawing-order': palletDrawingOrder
    //'animated-pallet': animatedPallet
  },

  setup(){
      const store = useStore() 

      const options = ["animated", "shelf"]

      const page = ref('shelf')

      

       onMounted(() => {
          console.log('mounted!')

    })
      return {store, options, page}

      
  },

  
  
};
</script>
